import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import _ from 'lodash';
import React, { LegacyRef, useCallback, useEffect, useRef } from 'react';

import { ANIMATION_DURATION } from './Graph';

const AnimatedRect: React.FC<{
    fill: string;
    width: number;
    x: number;
    y: number;
    height: number;
    dataTest?: string;
}> = ({ fill, width, x, y, height, dataTest }) => {
    const ref = useRef<SVGRectElement | null>();
    const drawRect = useCallback(
        node => {
            node.attr('height', height)
                .attr('y', y)
                .attr('fill', fill);
        },
        [fill, height, y]
    );

    useEffect(() => {
        const selectedNode = select(ref.current as SVGRectElement);
        if (
            _.isNil(selectedNode.attr('fill')) ||
            _.isNil(selectedNode.attr('y')) ||
            _.isNil(selectedNode.attr('height'))
        ) {
            selectedNode.call(drawRect);
        } else {
            selectedNode
                .transition()
                .ease(easeLinear)
                .duration(ANIMATION_DURATION)
                .call(drawRect);
        }
    }, [drawRect]);

    return <rect ref={ref as LegacyRef<SVGRectElement>} width={width} x={x} data-test={dataTest} />;
};

export default AnimatedRect;
