import { getSignal } from '@api/index';
import useOpconData from '@data/hooks/useOpconData';
import usePerformData from '@data/hooks/usePerformData';
import { Loadable, LoadableType } from '@data/loadable';
import { OpCon } from '@features/opcon/types';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import always from 'lodash/fp/always';
import React, { ReactElement } from 'react';

import { DateRange, HydratedEntity, IntervalTypes, PerformSegmentBy } from '../../types';
import { TEMPERATURE_REQUEST_ATTRIBUTES } from './queries';
import { Status } from './types';

const temperaturesInterval = configureGetCreateTimeInterval<IntervalTypes>({
    days: 'quarter_hour',
    weeks: 'hour',
    months: 'day',
    quarters: 'day',
    years: 'day',
});

export const EnvironmentConditionsContext = React.createContext<{
    opCon: { status: Status; data?: OpCon };
    ambientTemperature: { status: Status; data?: number[] };
}>({ opCon: { status: Status.LOADING }, ambientTemperature: { status: Status.LOADING } });

export default function EnvironmentConditionsProvider({
    allowedVehicles,
    dateRange,
    driverIds,
    shouldFetchTemperatureInfo,
    vehicleIds,
    children,
}: {
    allowedVehicles: string[];
    dateRange: DateRange;
    driverIds: (string | null)[];
    shouldFetchTemperatureInfo: boolean;
    vehicleIds: string[];
    children: ReactElement;
}) {
    const groupBy = [
        driverIds.filter(Boolean).length && 'driver-id',
        vehicleIds.filter(Boolean).length && 'asset-id',
    ].filter((group): group is string => Boolean(group));

    const opConDataRequestForSelectedVehicle = useOpconData({
        dateRange,
        vehicleIds,
        driverIds,
        groupBy,
        debounced: false,
    });

    const opCon = Loadable.cata(
        opConDataRequestForSelectedVehicle,
        data => ({ status: Status.SUCCESS, data: _.get(data, [0], {}) }),
        always({ status: Status.FAILED }),
        always({ status: Status.LOADING }),
        always({ status: Status.LOADING })
    );

    const requestDataForAllVehiclesOfOneDriver = driverIds.length === 1 && vehicleIds.length === 0;

    const ambientTemperatureData = usePerformData(TEMPERATURE_REQUEST_ATTRIBUTES, {
        variables: {
            ...dateRange,
            driverIds,
            vehicleIds: requestDataForAllVehiclesOfOneDriver ? allowedVehicles : vehicleIds,
            segmentBy: PerformSegmentBy.interval,
            intervalType: temperaturesInterval(dateRange),
        } as Variables,
        debounced: false,
        shouldMakeRequest: shouldFetchTemperatureInfo,
    }) as LoadableType<HydratedEntity[]>;

    const ambientTemperature = Loadable.cata(
        ambientTemperatureData,
        temperatureData => {
            const processedTemperatureData = temperatureData.reduce((acc: number[], dataEntry) => {
                const avgTemperatureSignal = getSignal<number | undefined>(
                    dataEntry,
                    'averageAmbientTemperature',
                    undefined
                );

                if (avgTemperatureSignal !== undefined) {
                    acc.push(avgTemperatureSignal);
                }

                return acc;
            }, []);

            return { status: Status.SUCCESS, data: processedTemperatureData };
        },
        always({ status: Status.FAILED }),
        always({ status: Status.LOADING }),
        () => {
            return shouldFetchTemperatureInfo ? { status: Status.LOADING } : { status: Status.SUCCESS };
        }
    );

    const context = { ambientTemperature, opCon };

    return <EnvironmentConditionsContext.Provider value={context}>{children}</EnvironmentConditionsContext.Provider>;
}
