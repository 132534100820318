import { TimeSegmentUnit } from '@components/graphComponents/types';
import moment from 'moment';

import { DateRange } from '../types';

const difference = (dateRange: DateRange, type: TimeSegmentUnit) =>
    Math.abs(moment(dateRange.start).diff(dateRange.end, type, true));

const configureGetCreateTimeInterval = <T>(config: { days: T; weeks: T; months: T; quarters: T; years: T }) => (
    dateRange: DateRange
): T => {
    if (difference(dateRange, 'month') <= 1) {
        return config.days;
    }

    if (difference(dateRange, 'month') <= 6) {
        return config.weeks;
    }

    if (difference(dateRange, 'year') <= 2) {
        return config.months;
    }

    if (difference(dateRange, 'year') <= 4) {
        return config.quarters;
    }

    return config.years;
};

export default configureGetCreateTimeInterval;
