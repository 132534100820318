import { AuthToken, OpconQueryPayload } from '../types';
import { fetchPostWithJSONResponse } from './api';
import { decodeOpconRequest, OpConApiType } from './decoder/opConDecoder';

export async function fetchOpcon(baseUrl: string, payload: OpconQueryPayload, authToken: AuthToken) {
    const rawResponse = ((await fetchPostWithJSONResponse(
        baseUrl,
        'reports',
        payload,
        authToken
    )) as unknown) as OpConApiType; //TODO: change api response to correctly assume type.
    const entities = decodeOpconRequest(rawResponse);

    return {
        entities,
    };
}
