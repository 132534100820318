import { UseCaseKey } from '@pages/driverAnalysis/driverAnalysisReducer';
import { UseCaseConfig } from '@pages/driverAnalysis/useCaseConfig';
import { trackingAttributes } from '@utils/tracking';
import cn from 'classnames';
import { camelCase, upperFirst } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface UseCasesI {
    onSelect: (key: UseCaseKey) => void;
    active: string;
    useCaseConfig: UseCaseConfig[];
    location?: string;
}

const UseCases: React.FC<UseCasesI> = ({ onSelect, active, useCaseConfig = [], location = '' }) => (
    <div className="btn-toolbar">
        <div className="btn-group display-flex flex-row">
            {useCaseConfig.map(({ key }) => (
                <button
                    key={key}
                    className={cn('btn btn-default ToggleButton', active === key ? 'active' : '')}
                    onClick={(): void => onSelect(key as UseCaseKey)}
                    data-test={'useCaseButton' + key}
                >
                    <div
                        {...trackingAttributes(
                            'click',
                            'perform, chartSelect',
                            `selectChart${upperFirst(location)}`,
                            `dataSelect, ${location}, ${camelCase(key)}`
                        )}
                    >
                        <FormattedMessage id={`view-data.${key}`} />
                    </div>
                </button>
            ))}
        </div>
    </div>
);

export default UseCases;
