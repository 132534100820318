import { createSlice } from '@reduxjs/toolkit';
import {
    CURRENT_MONTH,
    CURRENT_WEEK,
    CURRENT_YEAR,
    LAST_10_DAYS,
    LAST_14_DAYS,
    LAST_MONTH,
    LAST_WEEK,
    LAST_YEAR,
    TODAY,
    YESTERDAY,
} from '@utils/dateRangePresets';
import _ from 'lodash';

import { env } from '../../env';

export type PermissionsState = {
    legacy: LegacyPermissionsType;
    featureToggles: Record<string, unknown>;
};

export type LegacyPermissionsType = {
    submodules: { [key: string]: boolean };
    useCases: { [key: string]: boolean };
    dateRangePresets: { [key: string]: boolean };
    intervalTypes: { [key: string]: boolean };
    isTodayAllowed?: boolean;
    isTimeSelectionAllowed?: boolean;
    tenDaysLimit?: boolean;
    isOperatingConditionsAllowed?: boolean;
    isLoaded: boolean;
    perform?: string[];
    Perform?: string[];
    Advance?: string[];
    advance?: string[];
    driverInfo?: boolean;
    PerformReducedBus?: string[];
    performReducedBus?: string[];
};

const toBooleanValues = (obj: Record<string, boolean>) => _.mapValues(obj, Boolean);

const prepareLegacyPermissions = (permissions: LegacyPermissionsType): LegacyPermissionsType => ({
    ...permissions,
    submodules: toBooleanValues(permissions.submodules),
    useCases: toBooleanValues(permissions.useCases),
    dateRangePresets: {
        ...toBooleanValues(permissions.dateRangePresets),
        [LAST_14_DAYS]: permissions.dateRangePresets.lastMonth,
    },
    intervalTypes: toBooleanValues(permissions.intervalTypes),
    perform: permissions.Perform || permissions.perform || [],
    advance: permissions.Advance || permissions.advance || [],
    performReducedBus: permissions.PerformReducedBus || permissions.performReducedBus || [],
});

const DAY = 'day';
const WEEK = 'week';
const MONTH = 'month';
const QUARTER = 'quarter';
const HALFYEAR = 'halfyear';
const YEAR = 'year';

const USE_CASE_STANDARD = 'standard';
//const USE_CASE_SPEEDING = 'speeding';
//const USE_CASE_FORESIGHTED_DRIVING = 'foresightedDriving';

const SUBMODULE_HISTORY = 'history';

export const defaultState: PermissionsState = {
    legacy: prepareLegacyPermissions({
        submodules: {
            [SUBMODULE_HISTORY]: true,
        },
        useCases: {
            [USE_CASE_STANDARD]: true,
        },
        dateRangePresets: {
            [TODAY]: false,
            [YESTERDAY]: true,
            [LAST_10_DAYS]: true,
            [LAST_14_DAYS]: false,
            [CURRENT_WEEK]: true,
            [CURRENT_MONTH]: false,
            [CURRENT_YEAR]: false,
            [LAST_WEEK]: false,
            [LAST_MONTH]: false,
            [LAST_YEAR]: false,
        },
        intervalTypes: {
            [DAY]: true,
            [WEEK]: false,
            [MONTH]: false,
            [QUARTER]: false,
            [HALFYEAR]: false,
            [YEAR]: false,
        },
        isTodayAllowed: false,
        tenDaysLimit: true,
        isOperatingConditionsAllowed: false,
        isLoaded: false,
    }),
    featureToggles: env.featureToggles as Record<string, unknown>,
};

const permissionSlice = createSlice({
    name: 'permission',
    initialState: defaultState,
    reducers: {
        storeLegacyPermissions(state, { payload }) {
            return {
                ...state,
                legacy: { ...state.legacy, ...prepareLegacyPermissions(payload), isLoaded: true },
            };
        },
    },
});

export const { storeLegacyPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;
