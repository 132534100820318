import { FormattedMessage } from 'react-intl';

const Release520 = () => (
    <div style={{ marginLeft: '110px' }}>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.2.0.editableReportConfigurations" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.editableReportConfigurations.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.2.0.customizedOverspeed" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.customizedOverspeed.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.2.0.increasedResolution" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.increasedResolution.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.2.0.essentialsPhaseOut" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.essentialsPhaseOut.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.2.0.operationalDaysInExport" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.operationalDaysInExport.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.2.0.latamExport" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.latamExport.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.bugFixes" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.2.0.bugFixes" values={{ line_break: <br /> }} />
        </p>
    </div>
);

export default Release520;
