import _ from 'lodash';

import { Driver, getSignal } from '../api';
import { Vehicle } from './../../../mocks/src/types-data/types';

export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

const DEFAULT_VALUE_EXTRACTOR = (value: unknown) => {
    if (typeof value === 'string') {
        return value.toLowerCase() || '';
    }

    if (_.isNil(value)) {
        return -1 * Infinity;
    }
    return value;
};

// Those aren't signals but need to be sorted on. By either the vehicle name or the full name.
// could be a property of each column
export const findValueExtractor = (propertyName: string | undefined) => {
    switch (propertyName) {
        case 'vehicle':
        case 'vehicles':
        case 'vehicleNames':
            return (vehicles: Vehicle[] | undefined) =>
                (vehicles || []).map(vehicle => _.get(vehicle, 'name', '-').toLocaleLowerCase());
        case 'drivers':
        case 'explicitDrivers':
            return (drivers: Driver[] | undefined) =>
                (drivers || []).map(driver =>
                    `${_.get(driver, 'lastName', '-')}, ${_.get(driver, 'firstName', '-')}, ${_.get(
                        driver,
                        'displayName',
                        '-'
                    )}`.toLocaleLowerCase()
                );
        case 'end':
        case 'start':
        case 'tripStartEnd':
            return (val: string | number | Date) => new Date(val);
        case 'system':
            return (vehicles: Vehicle[] | undefined) =>
                (vehicles || []).map(vehicle => _.get(vehicle, 'system', '').toLowerCase());
        default:
            return DEFAULT_VALUE_EXTRACTOR;
    }
};

// There is a special case where driver and vehicles aren't a signals, but we still need to sort on them.
const getPropertyValue = (
    element: Record<string, unknown> | undefined,
    property: string | undefined,
    defaultValue: unknown
) => {
    if (!element || !property) {
        return element;
    }

    return getSignal(element, property, defaultValue);
};

export type VehicleValueExtractor = (vehicles: Vehicle[] | undefined) => string[];
export type SystemValueExtractor = (vehicles: Vehicle[] | undefined) => string[];
export type DriverValueExtractor = (drivers: Driver[] | undefined) => string[];
export type DateValueExtractor = (val: string | number | Date) => Date;

export function sortByProperty(
    array: any[] | any,
    property: string | undefined,
    sortDirection = SortDirection.ASCENDING,
    valueExtractor:
        | ((value: any) => any)
        | VehicleValueExtractor
        | DriverValueExtractor
        | DateValueExtractor = DEFAULT_VALUE_EXTRACTOR
) {
    const isPropertyBeingSortedIsArray = _.isArray(getPropertyValue(array[0], property, null));

    if (isPropertyBeingSortedIsArray) {
        return _.orderBy(
            array,
            [
                element => (getPropertyValue(element, property, []) as []).length,
                element => valueExtractor(getPropertyValue(element, property, {})),
            ],
            [sortDirection, sortDirection]
        );
    }

    if (property === 'start') {
        return _.orderBy(
            array,
            [
                element => valueExtractor(getPropertyValue(element, property, null)),
                element => valueExtractor(getPropertyValue(element, 'end', null)),
            ],
            [sortDirection, sortDirection]
        );
    }

    return _.orderBy(array, element => valueExtractor(getPropertyValue(element, property, null)), sortDirection);
}
