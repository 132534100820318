import RioCheckbox from '@rio-cloud/rio-uikit/Checkbox';
import ContentLoader from 'react-content-loader';
import { Controller, ControllerProps } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

type Props<Form> = {
    message: string;
} & Omit<ControllerProps<Form>, 'render'>;

export default function Checkbox<Form>(props: Props<Form>) {
    const { name, message, ...rest } = props;
    return (
        <Controller
            name={name}
            {...rest}
            render={({ field }) => (
                <div data-test={`create-form-${name}`}>
                    <div className="display-flex align-items-end">
                        <RioCheckbox name={name} onChange={field.onChange} checked={Boolean(field.value)} />
                        <span className="text-color-darker text-size-12 margin-left-5">
                            <FormattedMessage id={message} />
                        </span>
                    </div>
                </div>
            )}
        />
    );
}

export function LoadingCheckbox() {
    return (
        <ContentLoader
            className="display-block"
            speed={2}
            height={34}
            viewBox="0 0 400 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="300" height="30" />
        </ContentLoader>
    );
}
