import Column from '@components/Column';
import OverlayTriggerWithInjectedIntl from '@components/OverlayTriggerWithInjectedIntl';
import { CalculationParamObject } from '@features/settings/reducer';
import StatusBar from '@rio-cloud/rio-uikit/StatusBar';
import Tooltip, { TooltipProps } from '@rio-cloud/rio-uikit/Tooltip';
import { getRatingCSSColor } from '@utils/rating';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';

import { Column as ColumnType } from '../../columns/createColumn';
import { HydratedEntity } from '../../types';

const DisciplineTitle = ({
    title,
    entity,
    calculationParams,
    tooltipPlacement = 'bottom-start',
}: {
    title: ColumnType;
    entity: HydratedEntity;
    calculationParams: { [key: string]: CalculationParamObject };
    tooltipPlacement?: TooltipProps['placement'];
}) => {
    const value = title.valueExtractor(entity) as number;
    const { color: textColor, background } = getRatingCSSColor(value);
    const intl = useIntl();
    const disciplineNameInCalculationParams = title.dataField
        .replace('Rating', '')
        .replace('V2', '')
        .toLowerCase();
    const shouldShowTooltip =
        calculationParams[disciplineNameInCalculationParams] &&
        calculationParams[disciplineNameInCalculationParams].enabled &&
        calculationParams[disciplineNameInCalculationParams].weight === 0;

    const tooltip = (
        <Tooltip id={`ratingEnabledWithZeroWeightWarning.${title.key}`} width={350} textAlignment="left">
            <FormattedMessage id="ratingEnabledWithZeroWeightWarning" values={{ line_break: <br /> }} />
        </Tooltip>
    );

    return (
        <div className="display-flex align-items-center">
            <div className={`${title.icon} rioglyph text-size-18`} />
            <div className="width-200 ellipsis-1 margin-left-10 margin-right-10 text-color-dark">
                <FormattedMessage id={title.labelId} />
            </div>
            {shouldShowTooltip ? (
                <OverlayTriggerWithInjectedIntl placement={tooltipPlacement} overlay={tooltip}>
                    <span className="display-flex align-items-center margin-right-10" data-test="info-overlay">
                        <span className="rioglyph rioglyph-info-sign text-color-info text-size-18" />
                    </span>
                </OverlayTriggerWithInjectedIntl>
            ) : (
                <div className="margin-right-10" style={{ minWidth: '18px' }}></div>
            )}

            <StatusBar
                label={{
                    className: 'text-right width-45 margin-left-10',
                    value: title.formatter(value),
                    alignment: 'right',
                    color: textColor,
                }}
                progress={[
                    {
                        percentage: value,
                        color: background,
                        tooltip: (
                            <IntlProvider locale={intl.locale} messages={intl.messages}>
                                <Column column={title} entity={entity} />
                            </IntlProvider>
                        ),
                    },
                ]}
            />
        </div>
    );
};

export default DisciplineTitle;
