import { env } from '../../env';

const { PERFORMANCE_PLATFORM_INFO } = env.runtimeConfig.backend;

const configureFetchLegacyPermissions = (token: string, fetch = window.fetch) => () =>
    fetch(`${PERFORMANCE_PLATFORM_INFO}/permissions`, {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
        method: 'GET',
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        throw new Error('"legacy permissions" call failed');
    });

export default configureFetchLegacyPermissions;
