import { getSignal } from '@api/index';
import WithUnit from '@components/WithUnit';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import toPercent from '@utils/toPercent';
import _ from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { ValidUnit } from '../../../constants/units';
import { HydratedEntity } from '../../../types';
import aggregateSlopeInBuckets from '../../environmentConditions/aggregateSlopeInBuckets';
import { Slope } from '../../environmentConditions/types';
import DisplayWarningConditionally from '../components/DisplayOpconWarningConditionally';
import { FONT_SIZES } from './styles';

const styles = StyleSheet.create({
    topologyDataItem: {
        fontSize: FONT_SIZES.SUB_TITLE,
        flexDirection: 'column',
        padding: 10,
        paddingLeft: 20,
        flex: 1,
    },
    value: { fontFamily: 'Bold', fontSize: FONT_SIZES.TEXT },
    topologyData: {
        flex: 1,
        display: 'flex',
    },
    title: {
        fontSize: FONT_SIZES.SUB_TITLE,
    },
    topologyDataTop: {
        flexDirection: 'row',
        borderBottom: '#2a3740',
        borderBottomWidth: 1.2,
    },
    topologyDataBottom: {
        flexDirection: 'row',
    },
    dataItemTitle: {
        fontSize: FONT_SIZES.TEXT,
        width: '25%',
        padding: 10,
        paddingLeft: 20,
        justifyContent: 'center',
        fontFamily: 'Bold',
    },
});

export default function TopologyData({ data }: { data: HydratedEntity }) {
    const opconData = getSignal(data, 'operatingConditionResponse', {});
    const topologyData = _.get(opconData, 'topology.value', {});
    const evaluatedMileage = getSignal(topologyData, 'evaluatedMileage', 0);
    const mileageFromPerformance = getSignal(data, 'mileage', 0);
    const totalMileage = _.sumBy(_.get(topologyData, 'slopes', []), 'mileage');
    const sumAllValues = (obj: Slope) => _.sum(Object.values(obj));
    const getFlatMileage = (
        totalMileage: number,
        slopeBuckets: {
            up: Slope;
            down: Slope;
        }
    ) => {
        const upMileage = sumAllValues(slopeBuckets.up);
        const downMileage = sumAllValues(slopeBuckets.down);

        return totalMileage - upMileage - downMileage;
    };

    const slopeBuckets = aggregateSlopeInBuckets(_.get(topologyData, 'slopes', []));
    const flatMileage = getFlatMileage(totalMileage, slopeBuckets);

    const slopeUpLow = toPercent(slopeBuckets.up.low, totalMileage);
    const slopeUpMedium = toPercent(slopeBuckets.up.medium, totalMileage);
    const slopeUpSteep = toPercent(slopeBuckets.up.steep, totalMileage);

    const slopeDownLow = toPercent(slopeBuckets.down.low, totalMileage);
    const slopeDownMedium = toPercent(slopeBuckets.down.medium, totalMileage);
    const slopeDownSteep = toPercent(slopeBuckets.down.steep, totalMileage);
    const flatPercentage = toPercent(flatMileage, totalMileage);

    return (
        <DisplayWarningConditionally evaluatedMileage={evaluatedMileage} totalMileage={mileageFromPerformance}>
            <View style={{ flexDirection: 'row', width: '100%' }}>
                <View style={styles.topologyData} wrap={true}>
                    <View style={styles.topologyDataTop}>
                        <TopologyDataTitleItem messageId={'pdfSlopeUp'} />
                        <TopologyDataItem messageId={'slopeUp.low'} value={slopeUpLow} />
                        <TopologyDataItem messageId={'slopeUp.medium'} value={slopeUpMedium} />
                        <TopologyDataItem messageId={'slopeUp.steep'} value={slopeUpSteep} />
                    </View>
                    <View style={styles.topologyDataBottom}>
                        <TopologyDataTitleItem messageId={'pdfSlopeDown'} />
                        <TopologyDataItem messageId={'slopeDown.low'} value={slopeDownLow} />
                        <TopologyDataItem messageId={'slopeDown.medium'} value={slopeDownMedium} />
                        <TopologyDataItem messageId={'slopeDown.steep'} value={slopeDownSteep} />
                    </View>
                </View>
                <View style={{ ...styles.dataItemTitle, fontFamily: 'Regular' }}>
                    <Text style={styles.title}>
                        <FormattedMessage id={'slope.flat'} />
                    </Text>
                    <Text style={{ fontFamily: 'Bold' }}>
                        <WithUnit unit={ValidUnit.PERCENTAGE}>
                            <FormattedNumber value={flatPercentage} maximumFractionDigits={0} />
                        </WithUnit>
                    </Text>
                </View>
            </View>
        </DisplayWarningConditionally>
    );
}

const TopologyDataTitleItem = ({ messageId }: { messageId: string }) => {
    return (
        <View style={styles.dataItemTitle}>
            <Text>
                <FormattedMessage id={messageId} />
            </Text>
        </View>
    );
};

export const TopologyDataItem = ({ messageId, value }: { messageId: string; value: number }) => {
    return (
        <View style={styles.topologyDataItem}>
            <Text data-test="title">
                <FormattedMessage id={`${messageId}.title`} />
            </Text>
            <Text data-test="description">
                <FormattedMessage id={`${messageId}.description`} />
            </Text>
            <Text style={styles.value}>
                <WithUnit unit={ValidUnit.PERCENTAGE}>
                    <FormattedNumber value={value} maximumFractionDigits={0} />
                </WithUnit>
            </Text>
        </View>
    );
};
