import { isWhitelistedPerform3Account } from '@common/login/selectors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { LoginState } from './../../common/login/reducer';

export type Settings = { [key: string]: any };

export type SettingState = {
    settings: Settings;
} & {
    includeCruiseControlInRating: boolean;
    enablePerform3: boolean;
    isCalcParamsDialogVisible: boolean;
    calculationParams: {
        currentPreset: CalculationParamsPreset;
        currentCalcParams: CalculationParams;
        savedPreset: CalculationParamsPreset;
        savedCalcParams: CalculationParams;
        currentOverspeedLimit: number;
        savedOverspeedLimit: number;
    };
};

export type CalculationParamObject = {
    enabled: boolean;
    weight: number;
};

export enum CalculationParamsPreset {
    none = 'none',
    'mixed-fleet' = 'mixed-fleet',
}

export type CalculationParams = {
    coastingRating: CalculationParamObject;
    accelerationPedalRating: CalculationParamObject;
    brakingRating: CalculationParamObject;
    cruiseControlRating: CalculationParamObject;
    overSpeedRating: CalculationParamObject;
    harshAccelerationRating: CalculationParamObject;
    harshBrakingRating: CalculationParamObject;
    excessiveIdlingRating: CalculationParamObject;
};

export const defaultCalculationParamsPreset = CalculationParamsPreset.none;

export const defaultCalculationParams = {
    coastingRating: { enabled: true, weight: 20 },
    accelerationPedalRating: { enabled: true, weight: 20 },
    brakingRating: { enabled: true, weight: 20 },
    cruiseControlRating: { enabled: true, weight: 20 },
    overSpeedRating: { enabled: true, weight: 20 },
    harshAccelerationRating: { enabled: true, weight: 0 },
    harshBrakingRating: { enabled: true, weight: 0 },
    excessiveIdlingRating: { enabled: true, weight: 0 },
};

export const defaultOverspeedLimit = 86;

export const defaultState: SettingState = {
    settings: {},
    includeCruiseControlInRating: true,
    enablePerform3: true,
    isCalcParamsDialogVisible: false,
    calculationParams: {
        currentPreset: CalculationParamsPreset.none,
        currentCalcParams: defaultCalculationParams,
        savedPreset: CalculationParamsPreset.none,
        savedCalcParams: defaultCalculationParams,
        currentOverspeedLimit: defaultOverspeedLimit,
        savedOverspeedLimit: defaultOverspeedLimit,
    },
};

const settingSlice = createSlice({
    name: 'settings',
    initialState: defaultState,
    reducers: {
        toggleCalcParamsDialog(state, { payload }: PayloadAction<boolean>) {
            state.isCalcParamsDialogVisible = payload;
        },
        setCurrentCalculationParams(
            state,
            {
                payload: { updatedCalcParams },
            }: {
                payload: {
                    previousSavedCalcParams: CalculationParams;
                    updatedCalcParams: CalculationParams;
                    previousSavedPreset: CalculationParamsPreset;
                    updatedPreset: CalculationParamsPreset;
                    previousSavedOverspeedLimit: number;
                    updatedOverspeedLimit: number;
                    restored: boolean;
                };
            }
        ) {
            state.calculationParams.currentCalcParams = updatedCalcParams;
        },
        setSavedCalculationParams(state, { payload: { value } }: { payload: { value: CalculationParams } }) {
            state.calculationParams.savedCalcParams = value;
        },
        setCurrentCalculationParamsPreset(
            state,
            { payload: { value } }: { payload: { value: CalculationParamsPreset } }
        ) {
            state.calculationParams.currentPreset = value;
        },
        setSavedCalculationParamsPreset(
            state,
            { payload: { value } }: { payload: { value: CalculationParamsPreset } }
        ) {
            state.calculationParams.savedPreset = value;
        },
        setCurrentOverspeedLimit(state, { payload: { value } }: { payload: { value: number } }) {
            state.calculationParams.currentOverspeedLimit = value;
        },
        setSavedOverspeedLimit(state, { payload: { value } }: { payload: { value: number } }) {
            state.calculationParams.savedOverspeedLimit = value;
        },
        setIncludeCruiseControlInRating(state, { payload: { value } }: { payload: { value: boolean } }) {
            state.includeCruiseControlInRating = value;
        },
        setEnablePerform3(state, { payload }) {
            state.enablePerform3 = payload;
        },
        updateSavingFailed() {},
        loadSettings() {},
        loadSettingsFailed() {},
        loadSettingsSuccess(state, { payload: { settings } }: { payload: { settings: Settings } }) {
            state.settings = settings;
        },
        updateSetting(state, { payload: { key, value } }: { payload: { key: string; value: any } }) {
            state.settings[key] = value;
        },
    },
});

export const {
    updateSetting,
    loadSettings,
    loadSettingsSuccess,
    loadSettingsFailed,
    updateSavingFailed,
    setIncludeCruiseControlInRating,
    setEnablePerform3,
    setCurrentCalculationParams,
    setSavedCalculationParams,
    setCurrentCalculationParamsPreset,
    setSavedCalculationParamsPreset,
    setCurrentOverspeedLimit,
    setSavedOverspeedLimit,
    toggleCalcParamsDialog,
} = settingSlice.actions;
export default settingSlice.reducer;

export const includeCruiseControlInRating = (state: { settings: SettingState }) =>
    _.get(state, 'settings.includeCruiseControlInRating', true);

export const enablePerform3 = (state: { settings: SettingState; login: LoginState }) => {
    if (!isWhitelistedPerform3Account(state)) return true;
    return _.get(state, 'settings.enablePerform3', true);
};

export const isCalcParamsDialogVisible = (state: { settings: SettingState }) =>
    _.get(state, 'settings.isCalcParamsDialogVisible', false);

export const currentCalculationParams = (state: { settings: SettingState }) =>
    _.get(state, 'settings.calculationParams.currentCalcParams');

export const savedCalculationParams = (state: { settings: SettingState }) =>
    _.get(state, 'settings.calculationParams.savedCalcParams');

export const currentCalculationParamsPreset = (state: { settings: SettingState }) =>
    _.get(state, 'settings.calculationParams.currentPreset', CalculationParamsPreset.none);

export const savedCalculationParamsPreset = (state: { settings: SettingState }) =>
    _.get(state, 'settings.calculationParams.savedPreset', CalculationParamsPreset.none);

export const currentOverspeedLimit = (state: { settings: SettingState }) =>
    _.get(state, 'settings.calculationParams.currentOverspeedLimit', defaultOverspeedLimit);

export const savedOverspeedLimit = (state: { settings: SettingState }) =>
    _.get(state, 'settings.calculationParams.savedOverspeedLimit', defaultOverspeedLimit);
