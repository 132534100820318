import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Label: React.FC<{
    icon?: string;
    secondaryIcon?: string;
    id?: string;
    color?: string;
    children?: unknown;
}> = ({ icon, secondaryIcon, id, color = 'dark', children }) => {
    const shouldUsePairIcon = icon !== undefined && secondaryIcon !== undefined;
    if (shouldUsePairIcon) {
        return (
            <div className="display-flex align-items-center">
                <div className="display-flex align-items-center padding-y-5">
                    <span className={cn('rioglyph-icon-pair text-size-20 margin-right-5', 'text-color-' + color)}>
                        <span className={cn('rioglyph', icon)}></span>
                        <span className={cn('rioglyph', secondaryIcon)}></span>
                    </span>
                </div>
                {children ? children : <FormattedMessage id={id} />}
            </div>
        );
    }

    return (
        <div className="display-flex align-items-center">
            <span className={cn('rioglyph text-size-20 margin-right-5', icon, 'text-color-' + color)} />
            {children ? children : <FormattedMessage id={id} />}
        </div>
    );
};

export default Label;
