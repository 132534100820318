import { formatToValueOrZero, plainAverage, withUnit } from '@utils/formatters';
import { formatSecondsToTime } from '@utils/stringFormatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import { SECONDS, TIME } from '../constants/units';
import createColumn from './createColumn';

export const timeSignal = {
    unit: SECONDS,
    displayedUnit: TIME,
    format: compose<ReactNode>(withUnit(TIME), formatSecondsToTime),
    average: plainAverage,
};

export const timeSignalWithDefaultValue = {
    ...timeSignal,
    format: compose<ReactNode>(withUnit(TIME), formatToValueOrZero, formatSecondsToTime),
};

export const drivingTime = createColumn({
    key: 'drivingTime',
    dataField: 'drivingTime',
    labelId: 'drivingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
    dataSort: true,
});

export const idlingTime = createColumn({
    key: 'idlingTime',
    dataField: 'idlingTime',
    labelId: 'idlingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const idlingTimeBev = createColumn({
    key: 'idlingTime',
    dataField: 'idlingTime',
    labelId: 'idlingTimeBev',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const fuelAndElectricIdlingTime = createColumn({
    key: 'idlingTime',
    dataField: 'idlingTime',
    labelId: 'fuelAndElectricIdlingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const operatingTime = createColumn({
    key: 'operatingTime',
    dataField: 'operatingTime',
    labelId: 'operatingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const activeCoastingTime = createColumn({
    key: 'activeCoastingTime',
    dataField: 'activeCoastingTime',
    labelId: 'activeCoastingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const passiveCoastingTime = createColumn({
    key: 'passiveCoastingTime',
    dataField: 'passiveCoastingTime',
    labelId: 'passiveCoastingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const coastingCCTime = createColumn({
    key: 'coastingCcTime',
    dataField: 'coastingCcTime',
    labelId: 'coastingCcTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const kickdownTime = createColumn({
    key: 'kickdownTime',
    dataField: 'kickdownTime',
    labelId: 'kickdownTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const accelerationPedalTimeWithCc = createColumn({
    key: 'accelerationPedalTimeWithCc',
    dataField: 'accelerationPedalTimeWithCc',
    labelId: 'accelerationPedalTimeWithCc',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const serviceBrakeTime = createColumn({
    key: 'serviceBrakeTime',
    dataField: 'serviceBrakeTime',
    labelId: 'serviceBrakeTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const motorBrakeTime = createColumn({
    key: 'motorBrakeTime',
    dataField: 'motorBrakeTime',
    labelId: 'motorBrakeTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const retarderTime = createColumn({
    key: 'retarderTime',
    dataField: 'retarderTime',
    labelId: 'retarderTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const cruiseControlTimeInPotential = createColumn({
    key: 'cruiseControlTimeInPotential',
    dataField: 'cruiseControlTimeInPotential',
    labelId: 'cruiseControlTimeInPotential',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const noCruiseControlTimeInPotential = createColumn({
    key: 'noCruiseControlTimeInPotential',
    dataField: 'noCruiseControlTimeInPotential',
    labelId: 'noCruiseControlTimeInPotential',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const coastingTime = createColumn({
    key: 'coastingTime',
    dataField: 'coastingTime',
    labelId: 'coastingTime',
    formatter: timeSignal.format,
    unit: timeSignal.unit,
});

export const driveProgramActiveTime = createColumn({
    key: 'driveProgramActiveTime',
    dataField: 'driveProgramActiveTime',
    labelId: 'driveProgramActiveTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramEfficiencyTime = createColumn({
    key: 'driveProgramEfficiencyTime',
    dataField: 'driveProgramEfficiencyTime',
    labelId: 'driveProgramEfficiencyTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramEfficiencyPlusTime = createColumn({
    key: 'driveProgramEfficiencyPlusTime',
    dataField: 'driveProgramEfficiencyplusTime',
    labelId: 'driveProgramEfficiencyplusTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramEmergencyTime = createColumn({
    key: 'driveProgramEmergencyTime',
    dataField: 'driveProgramEmergencyTime',
    labelId: 'driveProgramEmergencyTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramFluidTime = createColumn({
    key: 'driveProgramFluidTime',
    dataField: 'driveProgramFluidTime',
    labelId: 'driveProgramFluidTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramHeavyDutyTime = createColumn({
    key: 'driveProgramHeavyDutyTime',
    dataField: 'driveProgramHeavydutyTime',
    labelId: 'driveProgramHeavydutyTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramManoeuverTime = createColumn({
    key: 'driveProgramManoeuverTime',
    dataField: 'driveProgramManeuverTime',
    labelId: 'driveProgramManeuverTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramManualTime = createColumn({
    key: 'driveProgramManualTime',
    dataField: 'driveProgramManualTime',
    labelId: 'driveProgramManualTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramOffroadTime = createColumn({
    key: 'driveProgramOffroadTime',
    dataField: 'driveProgramOffroadTime',
    labelId: 'driveProgramOffroadTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramOffroadWithAssistanceTime = createColumn({
    key: 'driveProgramOffroadWithAssistanceTime',
    dataField: 'driveProgramOffroadwithassistanceTime',
    labelId: 'driveProgramOffroadwithassistanceTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramPerformanceTime = createColumn({
    key: 'driveProgramPerformanceTime',
    dataField: 'driveProgramPerformanceTime',
    labelId: 'driveProgramPerformanceTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const driveProgramTotalTime = createColumn({
    key: 'driveProgramTotalTime',
    dataField: 'driveProgramTotalTime',
    labelId: 'driveProgramTotalTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const accTime = createColumn({
    key: 'accTime',
    dataField: 'accTime',
    labelId: 'accTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const noAccTime = createColumn({
    key: 'noAccTime',
    dataField: 'noAccTime',
    labelId: 'noAccTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const pccaTime = createColumn({
    key: 'pccaTime',
    dataField: 'pccaTime',
    labelId: 'pccaTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const noPccaTime = createColumn({
    key: 'noPccaTime',
    dataField: 'noPccaTime',
    labelId: 'noPccaTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const harshAccelerationMediumTime = createColumn({
    key: 'harshAccelerationMediumTime',
    dataField: 'harshAccelerationMediumTime',
    labelId: 'harshAccelerationMediumTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const harshAccelerationHardTime = createColumn({
    key: 'harshAccelerationHardTime',
    dataField: 'harshAccelerationHardTime',
    labelId: 'harshAccelerationHardTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const harshBrakingMediumTime = createColumn({
    key: 'harshBrakingMediumTime',
    dataField: 'harshBrakingMediumTime',
    labelId: 'harshBrakingMediumTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const harshBrakingHardTime = createColumn({
    key: 'harshBrakingHardTime',
    dataField: 'harshBrakingHardTime',
    labelId: 'harshBrakingHardTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const excessiveIdlingTime = createColumn({
    key: 'excessiveIdlingTime',
    dataField: 'excessiveIdlingTime',
    labelId: 'excessiveIdlingTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});

export const acceptableIdlingTime = createColumn({
    key: 'acceptableIdlingTime',
    dataField: 'acceptableIdlingTime',
    labelId: 'acceptableIdlingTime',
    formatter: timeSignalWithDefaultValue.format,
    unit: timeSignalWithDefaultValue.unit,
});
