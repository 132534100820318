import RioSwitch from '@rio-cloud/rio-uikit/Switch';
import cn from 'classnames';
import { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { Controller, ControllerProps } from 'react-hook-form';

enum InputStatus {
    error,
    touched,
    success,
}

type Props<Form> = {
    errorMessage?: ReactElement;
    status?: InputStatus;
    disabled?: boolean;
    enabledText?: string;
    disabledText?: string;
} & Omit<ControllerProps<Form>, 'render'>;

export default function Switch<Form>(props: Props<Form>) {
    const {
        status = InputStatus.success,
        errorMessage = 'error',
        name,
        disabled = false,
        enabledText = '',
        disabledText = '',
        ...rest
    } = props;
    return (
        <Controller
            name={name}
            {...rest}
            render={({ field }) => (
                <div className={cn({ 'has-error': status === InputStatus.error })} data-test={`create-form-${name}`}>
                    <RioSwitch
                        keyName={name}
                        disabled={disabled}
                        onChange={field.onChange}
                        checked={Boolean(field.value)}
                        enabledText={enabledText}
                        disabledText={disabledText}
                    />
                    {status === InputStatus.error && (
                        <span className="help-block">
                            <span>{errorMessage}</span>
                        </span>
                    )}
                </div>
            )}
        />
    );
}

export function LoadingSwitch() {
    return (
        <ContentLoader
            className="display-block"
            speed={2}
            height={34}
            viewBox="0 0 400 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="35" height="20" />
        </ContentLoader>
    );
}
