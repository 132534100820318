import Day from '@components/graphComponents/Day';
import { HoverInfo } from '@pages/driverAnalysis/types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { State } from '../../setup/types';
import { DateRange } from '../../types';
import { setFilteredSegment } from './reducer';
import { getActiveSegmentIdentifier, getDateRange, getFilteredSegment } from './selectors';
import { segmentInterval } from './utils';

export function SegmentableDay({
    onHover,
    filteredSegment,
    dateRange,
    activeSegmentIdentifier,
    onSegmentFilter,
    useOuterXPosition = false,
    xOffset = 0,
}: {
    filteredSegment?: DateRange;
    dateRange: DateRange;
    activeSegmentIdentifier?: Date;
    onSegmentFilter: (args?: DateRange) => void;
    onHover: (hover: HoverInfo | null) => void;
    useOuterXPosition?: boolean;
    xOffset?: number;
}) {
    const segmenter = segmentInterval(dateRange);
    return (
        <Day
            onHover={onHover}
            onClick={date => {
                const segmentRange = segmenter(date);
                if (_.isEqual(filteredSegment, segmentRange)) {
                    onSegmentFilter();
                } else {
                    onSegmentFilter(segmentRange);
                }
            }}
            activeDay={activeSegmentIdentifier}
            useOuterXPosition={useOuterXPosition}
            xOffset={xOffset}
        />
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSegmentFilter: (dateRange?: DateRange) => dispatch(setFilteredSegment(dateRange)),
});
const mapStateToProps = (state: State) => {
    return {
        filteredSegment: getFilteredSegment(state),
        dateRange: getDateRange(state),
        activeSegmentIdentifier: getActiveSegmentIdentifier(state),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SegmentableDay);
