import { VehicleFuelType } from '@api/models/types';
import { Data, DataEntry } from '@components/graphComponents/types';
import { cleanupYScaleTicks } from '@components/graphComponents/YAxis';
import { Values } from '@components/summary/types';
import { defaultSelectedFuelTypes } from '@features/tree/reducer';
import { steps } from '@features/ui/utils';
import colors from '@rio-cloud/rio-uikit/Colors';
import { dateRangeSegmentedByStep } from '@utils/dateRangeSegmentedByStep';
import { ScaleLinear } from 'd3-scale';
import _ from 'lodash';
import moment from 'moment';

import { DateRange } from '../../types';

export type BarName = 'ICE' | 'BEV_UP' | 'BEV_DOWN';

export const BARS_INFO = {
    ICE: {
        label: 'ICE',
        labelWithConsumption: 'ICE consumption',
        colorValue: colors['color-highlight-dark'],
        colorName: 'highlight-dark',
    },
    BEV_UP: { label: 'BEV', labelWithConsumption: 'BEV consumption', colorValue: '#4b80a6', colorName: undefined }, // TODO: replace with  colors['brand-info'] when updating to rio-uikit v0.16.4
    BEV_DOWN: { label: 'BEV recuperation', colorValue: colors['color-highlight'], colorName: 'highlight' },
};

const findMatchingY = ({
    key,
    data,
    yAxis,
    step,
}: {
    key: Date;
    data?: Data;
    yAxis: string;
    step: moment.unitOfTime.Diff;
}) => {
    const possibleMatch = data?.find(
        (d: DataEntry) =>
            moment(d.x)
                .startOf(step)
                .diff(moment(key).startOf(step), 'days') === 0
    );

    return _.get(possibleMatch, yAxis, undefined);
};

export const convertDataToXYValue = ({
    dieselData,
    electricData,
    selectedFuelTypes = defaultSelectedFuelTypes,
}: {
    dieselData?: Values;
    electricData?: { values?: Values; recuperationValues?: Values };
    selectedFuelTypes?: VehicleFuelType[];
}) => {
    const convertedDieselValues =
        dieselData?.map((e: { date: Date; value: unknown }) => ({
            x: moment(e.date)
                .startOf('day')
                .toDate(),
            leftUpY: _.get(e, 'value.value'),
        })) || [];

    const hasDieselData =
        selectedFuelTypes.includes(VehicleFuelType.DIESEL) ||
        selectedFuelTypes.includes(VehicleFuelType.GAS) ||
        selectedFuelTypes.includes(VehicleFuelType.UNKNOWN);

    const convertedElectricValues =
        electricData?.values?.map((e: { date: Date; value: unknown }) => ({
            x: moment(e.date)
                .startOf('day')
                .toDate(),
            leftUpY: hasDieselData ? undefined : _.get(e, 'value.value', undefined),
            rightUpY: hasDieselData ? _.get(e, 'value.value', undefined) : undefined,
        })) || [];

    const convertedElectricRecuperationValues =
        electricData?.recuperationValues?.map((e: { date: Date; value: unknown }) => ({
            x: moment(e.date)
                .startOf('day')
                .toDate(),
            leftDownY: hasDieselData ? undefined : _.get(e, 'value.value'),
            rightDownY: hasDieselData ? _.get(e, 'value.value') : undefined,
        })) || [];

    const merged = _.merge(
        _.keyBy([], 'x'),
        _.keyBy(convertedDieselValues, 'x'),
        _.keyBy(convertedElectricValues, 'x'),
        _.keyBy(convertedElectricRecuperationValues, 'x')
    );

    return _.values(merged);
};

export const mergeExistingDataWithPlaceholders = ({
    dateRange,
    existingData,
}: {
    dateRange: DateRange;
    existingData: Data;
}) => {
    const step = steps(dateRange);
    const xAxisValues = dateRangeSegmentedByStep({ dateRange, step });

    if (!xAxisValues.length) {
        return existingData;
    }

    return xAxisValues.map(date => {
        return {
            x: date,
            leftUpY: findMatchingY({ key: date, data: existingData, yAxis: 'leftUpY', step }),
            leftDownY: findMatchingY({ key: date, data: existingData, yAxis: 'leftDownY', step }),
            rightUpY: findMatchingY({ key: date, data: existingData, yAxis: 'rightUpY', step }),
            rightDownY: findMatchingY({ key: date, data: existingData, yAxis: 'rightDownY', step }),
        };
    });
};

export const generateTicks = (y: ScaleLinear<number, unknown>, height: number) => {
    const yTicks = y.ticks(5).reverse();
    return cleanupYScaleTicks({
        height,
        yScale: y,
        ticks: yTicks,
        shouldHideLowerBoundTick: false,
        spaceUntilMaxTickAndTop: 12,
    });
};
