import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { SortDirection } from '@utils/sortyByProperty';
import { trackingAttributes } from '@utils/tracking';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../columns/createColumn';
import OverlayTriggerWithInjectedIntl from '../OverlayTriggerWithInjectedIntl';

const TableHead: React.FunctionComponent<{
    column: Column;
    hasSortActive: boolean;
    sortDirection?: SortDirection;
    handleSortChange: (event: React.MouseEvent<HTMLTableHeaderCellElement>) => void;
    sortDisabled: boolean;
    location?: string;
    shouldShowFuelType?: boolean;
}> = ({
    column,
    hasSortActive,
    sortDirection,
    handleSortChange,
    sortDisabled,
    location,
    shouldShowFuelType = false,
}) => {
    const tableHeadClassNames = ['user-select-none', 'sort-column'].join(' ');

    const formattedHeaderLabel = <FormattedMessage id={column.labelId} />;

    const tooltip = (
        <Tooltip id={'tooltip'} width="auto">
            {formattedHeaderLabel}
        </Tooltip>
    );

    const iconComponent = column.icon ? <span className={`${column.icon} text-size-20 margin-right-5`} /> : null;

    return (
        <OverlayTriggerWithInjectedIntl placement={'top'} overlay={tooltip}>
            <th
                key={column.key}
                className={tableHeadClassNames}
                onClick={!sortDisabled ? handleSortChange : _.noop}
                data-test={column.dataField}
                data-sortby={column.key}
                {...trackingAttributes(
                    'click',
                    'perform, summaryTable',
                    'sortTable',
                    `sortTable, ${location}, ${column.key}`
                )}
                style={{ verticalAlign: 'bottom' }}
            >
                {shouldShowFuelType && (
                    <span>
                        {column.key.includes('electric') ? (
                            <FormattedMessage id="vehicleFuelType.electric" />
                        ) : (
                            <FormattedMessage id="vehicleFuelType.fuel" />
                        )}
                    </span>
                )}
                <p className="ellipsis-1 margin-bottom-0 margin-top-5">
                    {hasSortActive ? <SortArrows direction={sortDirection} /> : !sortDisabled && <SortArrows />}
                    {iconComponent}
                    {formattedHeaderLabel}
                </p>
            </th>
        </OverlayTriggerWithInjectedIntl>
    );
};

export default TableHead;
