import { getLocale } from '@common/lang/selectors';
import { isKoreanTenant } from '@common/login/selectors';
import { getAccessToken } from '@common/tokenHandling/selectors';
import { backendConfig, getDrivers } from '@data/selectors';
import { enablePerform3 } from '@features/settings/reducer';
import { extractDriverName } from '@utils/stringFormatters';
import FileSaver from 'file-saver';
import { all, call, delay, put, race, select, takeEvery } from 'redux-saga/effects';

import { MAX_API_RESPONSE_WAIT_TIME, MAX_API_RESPONSE_WAIT_TIME_5 } from '../../constants/api';
import { configureReporting } from '../../setup/errorReporting';
import { Map, RawDriver } from '../../types';
import { errorNotification } from '../ui/notificationSaga';
import { requestExcelDownload, requestExcelDownloadFailed, requestExcelDownloadSuccess } from './actions';
import { fetchPostWithLocationHeaderResponse, pollExcel } from './api';
import { RequestDownloadExcel } from './types';

const { captureException } = configureReporting(window, import.meta.env);

export function* downloadExcel({
    payload: { owner, opConQuery, aggregationQuery, fileName },
}: {
    payload: RequestDownloadExcel;
}) {
    const opConUrl: string = yield select(backendConfig, 'OPCON');
    const excelExportBaseUrl: string = yield select(backendConfig, 'EXCEL_EXPORT_SERVICE');
    const token: string = yield select(getAccessToken);
    const drivers: Map<RawDriver> = yield select(getDrivers);
    const locale: string = yield select(getLocale);
    const isKorean: boolean = yield select(isKoreanTenant);
    const isPerform3Enabled: boolean = yield select(enablePerform3);

    let mergerBaseUrl: ReturnType<typeof backendConfig>;
    mergerBaseUrl = yield select(backendConfig, 'QUERY_SERVICE');

    try {
        const { opConLocationReportLocationRace, aggregationReportLocationRace } = yield all({
            opConLocationReportLocationRace:
                !isKorean &&
                race({
                    data: call(
                        fetchPostWithLocationHeaderResponse,
                        opConUrl,
                        'reports',
                        { ...opConQuery, driver_identification: 'id' },
                        token,
                        true
                    ),
                    timeout: delay(MAX_API_RESPONSE_WAIT_TIME_5),
                }),
            aggregationReportLocationRace: race({
                data: call(
                    fetchPostWithLocationHeaderResponse,
                    mergerBaseUrl,
                    'aggregation-reports',
                    { ...aggregationQuery, use_driver_admin_driver_ids: true },
                    token
                ),
                timeout: delay(MAX_API_RESPONSE_WAIT_TIME),
            }),
        });

        const hasReport = aggregationReportLocationRace.data;

        if (!hasReport) {
            throw new Error('The report was not loaded');
        }

        const aggregationLocation = `${mergerBaseUrl}${aggregationReportLocationRace.data}`;
        const operationalReportId = opConLocationReportLocationRace.data;

        const driverParam = {
            drivers: Object.values(drivers).map(d => ({
                driver_id: d.driverId,
                display_name: extractDriverName(d, { lastNameFirst: true }),
            })),
        };

        const downloadResponse: { data: Blob } = yield race({
            data: call(pollExcel as (...args: unknown[]) => Promise<Blob>, {
                serviceBaseUrl: excelExportBaseUrl,
                aggregationLocation,
                aggregationId: aggregationReportLocationRace.data.replace('/aggregation-reports/', ''),
                operationalReportId,
                authToken: token,
                locale,
                drivers: driverParam,
                isPerform3Enabled: isPerform3Enabled,
            }),
            timeout: delay(MAX_API_RESPONSE_WAIT_TIME),
        });

        if (!downloadResponse.data) {
            throw new Error('The report was not loaded');
        }

        yield call(FileSaver.saveAs, downloadResponse.data, `${fileName}.xlsx`);
        yield put(requestExcelDownloadSuccess({ owner }));
    } catch (e) {
        yield call(errorNotification, 'error.default');
        yield put(requestExcelDownloadFailed({ owner }));
        captureException(e as Error);
    }
}

export default function* root() {
    yield takeEvery(requestExcelDownload, downloadExcel);
}
