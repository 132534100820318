import { ReactNode } from 'react';

import { SignalValue, StatisticsReport } from '../../types';

export type Values = { date: Date; value: SignalValue }[];
export type Data = StatisticsReport & { values: Values };

export type DieselAndElectric<T> = { diesel: T; electric: T };

export type Stats = { average?: number; values?: unknown[] };

export type Formatter = (value: unknown, data?: unknown) => ReactNode;

export type Section = {
    key: string;
    title: React.ReactNode;
    unit: string | DieselAndElectric<string | undefined> | undefined;
    stats: Stats | DieselAndElectric<Stats>;
    tabValue: React.ReactNode;
    formatter: Formatter | DieselAndElectric<Formatter | undefined>;
    values:
        | Values
        | { diesel?: Values }
        | { diesel?: Values; electric: { values?: Values; recuperationValues?: Values } };
};

export enum SummaryStatus {
    LOADING,
    LOADED,
    ERROR,
}

export type Loading = { type: SummaryStatus.LOADING };
export type Loaded = {
    type: SummaryStatus.LOADED;
    data: { dieselData: Data[] | undefined; electricData: Data[] | undefined };
};
export type Errored = { type: SummaryStatus.ERROR; data: unknown };

export type Status = Loading | Loaded | Errored;
