import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../../columns/createColumn';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'column',
    },
    rowIcon: {
        height: 10,
        width: 10,
        marginRight: 3,
    },
    rowHeader: {
        paddingLeft: 10,
        flexWrap: 'nowrap',
        flexDirection: 'row',
        alignItems: 'center',
        maxLines: 2,
        height: 10,
        backgroundColor: '#f6f8f9',
        borderBottomWidth: 1.2,
        borderTopWidth: 1.2,
        borderColor: '#4C5667',
        paddingBottom: 20,
        paddingTop: 20,
    },
    highlighted: {
        fontFamily: 'Bold',
    },
});

export default function TableRow({
    width,
    children,
    column,
    isHighlighted,
}: {
    width: string;
    isHighlighted: boolean;
    column: Column;
    children: ReactNode;
}) {
    const textStyles = {
        ...(isHighlighted ? styles.highlighted : {}),
        maxLines: 2,
        flex: 1,
        textOverflow: 'ellipsis' as const,
    };
    return (
        <View style={{ ...styles.row, width }} data-test="row">
            <View style={styles.rowHeader} wrap={false} fixed data-test="rowHeader">
                {column.icon ? <Image src={column.icon} style={styles.rowIcon} /> : null}
                <Text style={textStyles}>
                    <FormattedMessage id={column.labelId} />
                </Text>
            </View>
            {children}
        </View>
    );
}
