import { convertDataToXYValue, mergeExistingDataWithPlaceholders } from '@components/commonGraphComponents/utils';
import XAxis from '@components/graphComponents/DatedXAxis';
import { DataPoint, Guidelines, ValueText } from '@components/graphComponents/Graph';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import Line from '@components/graphComponents/Line';
import { Overlay } from '@components/graphComponents/Overlay';
import { HOVER_Y_OFFSET } from '@components/graphComponents/utils';
import { generateTicks, generateTicksFromSeconds, YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import { DieselAndElectric, Formatter, Section, Stats, Values } from '@components/summary/types';
import SegmentableDay from '@features/ui/SegmentableDay';
import SegmentableDayOverlay from '@features/ui/SegmentableDayOverlay';
import { formatSecondsToTime } from '@utils/stringFormatters';
import _ from 'lodash';
import { useState } from 'react';
import { FormattedMessage, IntlFormatters } from 'react-intl';

import { SECONDS } from '../../../constants/units';
import { DateRange } from '../../../types';
import { HoverInfo } from '../../driverAnalysis/types';
import { VehicleGraphDimensions } from '.';

const NonTruEGraph = ({
    column,
    dateRange,
    dimensions,
    intl: { formatNumber = (value: number) => value.toString(), formatDate, formatMessage },
    dieselData,
    tickFormatter,
}: {
    column: Section;
    dateRange: DateRange;
    dimensions: VehicleGraphDimensions;
    dieselData: Values | undefined;
    intl: IntlFormatters;
    tickFormatter: (dateRange: DateRange) => (d: Date) => string;
}) => {
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);

    const existingData = convertDataToXYValue({ dieselData });
    const mappedData = mergeExistingDataWithPlaceholders({ dateRange, existingData });

    const average = (column.stats as DieselAndElectric<Stats>)?.diesel?.average;

    const formatter = (column.formatter as DieselAndElectric<Formatter>)?.diesel;

    const unit = (column.unit as DieselAndElectric<string>)?.diesel;

    return (
        <div
            className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
            style={{ height: dimensions.height }}
            data-test="vehicle-non-TruE-graph"
        >
            {hoverInfo && (
                <Overlay
                    hasData={!!hoverInfo.data}
                    x={hoverInfo.leftX}
                    y={
                        hoverInfo.leftUpY +
                        (hoverInfo.leftUpY >= dimensions.height - dimensions.margin * 4
                            ? -HOVER_Y_OFFSET
                            : HOVER_Y_OFFSET)
                    }
                >
                    {!_.isUndefined(_.get(hoverInfo, 'data.leftUpY')) ? (
                        <>{formatter(hoverInfo.data.leftUpY)}</>
                    ) : (
                        <FormattedMessage id="noData" />
                    )}
                </Overlay>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                dimensions={dimensions}
                data={mappedData}
            >
                {!_.isUndefined(average) && (
                    <ValueText
                        show={Boolean(hoverInfo)}
                        value={average}
                        formatter={formatter}
                        className="text-bold"
                        label={<FormattedMessage id="average" />}
                    />
                )}
                <SegmentableDayOverlay leftYScaleFormatter={formatter} />
                <svg
                    width={'100%'}
                    height={'100%'}
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <Guidelines average={average} show={Boolean(hoverInfo)} />
                    <YAxisLines tickGenerator={unit === SECONDS ? generateTicksFromSeconds : generateTicks} />
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                    />
                    <Line />
                    <DataPoint />
                    <YAxisTicks
                        formatter={unit === SECONDS ? formatSecondsToTime : formatNumber}
                        tickGenerator={unit === SECONDS ? generateTicksFromSeconds : generateTicks}
                    />
                    <SegmentableDay onHover={setHover} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default NonTruEGraph;
