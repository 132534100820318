import Select from '@rio-cloud/rio-uikit/Select';
import { trackingAttributes } from '@utils/tracking';
import cn from 'classnames';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

interface Tabs {
    testTag?: string;
    title: React.ReactNode;
    disabled?: boolean;
    content: React.ReactNode;
}

interface TabbableContentI {
    tabs: Tabs[];
    initialTab?: number;
    needsBottomSpacing?: boolean;
    location?: string;
}

const TabbableContent: React.FC<TabbableContentI> = ({
    tabs = [],
    initialTab = 0,
    needsBottomSpacing = false,
    location = '',
}) => {
    const [currentContentBlock, switchContent] = useState(initialTab);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (document && document.documentElement && !document.documentElement.classList.contains('ua-desktop')) {
            setIsMobile(true);
        }
    }, []);

    const options = tabs.map((tab, idx) => ({
        id: idx.toString(),
        selected: currentContentBlock === idx,
        label: tab.title,
    }));

    let selection = (
        <div className="border-color-light">
            <Select
                onChange={(item: { id: string } | undefined) => {
                    item ? switchContent(parseInt(item.id)) : '';
                }}
                options={options}
            />
        </div>
    );
    if (!isMobile) {
        selection = (
            <ul className="nav nav-pills nav-justified nav-justified nav-justified-word-wrap border-color-light">
                {tabs.map((tab, idx) => (
                    <li
                        {...trackingAttributes(
                            'click',
                            'perform, detailsPanel',
                            'selectDataDetailsPanel',
                            `dataSelect, ${location}, ${tab.testTag}`
                        )}
                        data-test={tab.testTag}
                        key={idx}
                        id="side-panel-tab-item"
                        className={cn('', {
                            active: currentContentBlock === idx && tab.disabled !== true,
                            disabled: tab.disabled === true,
                        })}
                        style={{ blockSize: 'fit-content' }}
                    >
                        <div
                            role="tab"
                            className="word-break"
                            data-cy="tabbable-content"
                            style={{ position: 'relative' }}
                            onClick={() => switchContent(idx)}
                        >
                            {tab.title}
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <div>
            {selection}
            <div className={cn('padding-top-15', { 'margin-bottom-15 padding-bottom-15': needsBottomSpacing })}>
                {get(tabs, `${currentContentBlock}.content`)}
            </div>
        </div>
    );
};

export default TabbableContent;
