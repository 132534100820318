import Perf3OperationalDays from './Perf3OperationalDays';

interface Props {
    operatingDays?: string[];
    totalNumberOfDays?: number;
}

const OperationalDays = ({ operatingDays, totalNumberOfDays }: Props) => (
    <Perf3OperationalDays operatingDays={operatingDays} totalNumberOfDays={totalNumberOfDays} />
);

export default OperationalDays;
