import { axisBottom } from 'd3-axis';
import { ScaleBand } from 'd3-scale';
import { select } from 'd3-selection';
import { useContext, useEffect, useRef } from 'react';

import { GraphContext } from './GraphDataProvider';

export default function BandXAxis({ tickFormatter = () => '' }: { tickFormatter: (d: number) => string }) {
    const { xScale: x, dimensions } = useContext(GraphContext);
    const axisRef = useRef<SVGSVGElement>(null);
    const axisLine = useRef(null);

    useEffect(() => {
        if (axisRef && axisRef.current) {
            const xAxisElement = select(axisRef.current);
            xAxisElement.call(axisBottom<number>(x as ScaleBand<number>).tickFormat(tickFormatter));
            xAxisElement.select('.domain').remove();
            xAxisElement
                .selectAll('.tick text')
                .attr('x', 0)
                .attr('dy', 10);
        }

        if (axisLine.current) {
            select(axisLine.current)
                .attr('x0', 0)
                .attr('x1', dimensions.width + dimensions.margin)
                .attr('y0', 0);
        }
    }, [axisRef, dimensions.height, dimensions.margin, dimensions.width, tickFormatter, x]);

    return (
        <g transform={`translate(0, ${dimensions.height - dimensions.margin})`}>
            <g transform={`translate(${dimensions.margin} ${dimensions.margin})`}>
                <g ref={axisRef} className="axis xAxis" />
            </g>
            <g transform={`translate(0 ${dimensions.margin})`}>
                <line ref={axisLine} className="axisLine" />
            </g>
        </g>
    );
}
