import { formatOneDigitNumber, weightAverageByMileage, withUnit } from '@utils/formatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import { KG, TONS } from '../constants/units';
import createColumn from './createColumn';

export const weightSignal = {
    unit: TONS,
    displayedUnit: TONS,
    format: compose<ReactNode>(withUnit(TONS), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const weightSignalInKg = {
    unit: KG,
    displayedUnit: KG,
    format: compose<ReactNode>(withUnit(KG), formatOneDigitNumber),
    average: weightAverageByMileage,
};

export const averageWeight = createColumn({
    key: 'averageWeight',
    dataField: 'averageWeight',
    labelId: 'averageWeight',
    formatter: weightSignal.format,
    unit: weightSignal.unit,
});

export const co2Emission = createColumn({
    key: 'co2Emission',
    dataField: 'co2Emission',
    labelId: 'co2Emission',
    formatter: weightSignalInKg.format,
    unit: weightSignalInKg.unit,
});
