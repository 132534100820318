import Select from '@rio-cloud/rio-uikit/Select';
import { dateRangeSegmentedByStep } from '@utils/dateRangeSegmentedByStep';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { State } from '../../setup/types';
import { DateRange } from '../../types';
import { setFilteredSegment } from './reducer';
import { getActiveSegmentIdentifier, getDateRange } from './selectors';
import { formatSegmentDescriptionLong, segmentInterval, steps } from './utils';

const filterKey = configureGetCreateTimeInterval({
    days: 'filterBySegment.day',
    weeks: 'filterBySegment.week',
    months: 'filterBySegment.month',
    quarters: 'filterBySegment.quarter',
    years: 'filterBySegment.year',
});

type ConnectedProps = {
    dateRange: DateRange;
    activeSegmentIdentifier?: Date;
    onSegmentFilter: (dateRange?: DateRange) => void;
};

export const defaultDateRange = {
    start: new Date(),
    end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
};

export const SegmentFilter: React.FunctionComponent<ConnectedProps & WrappedComponentProps> = ({
    intl,
    dateRange,
    activeSegmentIdentifier,
    onSegmentFilter,
}) => {
    const segmenter = segmentInterval(dateRange);
    const segmentDescription = formatSegmentDescriptionLong(intl.formatDate, intl.formatMessage)(dateRange);

    const options = dateRangeSegmentedByStep({ dateRange, step: steps(dateRange) }).map(step => ({
        id: step.getTime().toString(),
        selected: activeSegmentIdentifier && activeSegmentIdentifier.getTime() === step.getTime(),
        label: (
            <span className="display-flex justify-content-between align-items-center">
                <span className="flex-1">{segmentDescription(step)}</span>
                <span
                    className="rioglyph rioglyph-remove-sign"
                    data-test="segementer-remove-selection"
                    onClick={e => {
                        e.stopPropagation();
                        onSegmentFilter();
                    }}
                />
            </span>
        ),
    }));

    return (
        <span data-test="segment-filter">
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                .dropdown-menu .rioglyph { display: none; }
    `,
                }}
            />
            <Select
                options={options}
                placeholder={<FormattedMessage id={filterKey(dateRange)} />}
                onChange={(item: { id: string } | undefined) => {
                    if (item) {
                        const date = new Date(parseInt(item.id));
                        onSegmentFilter(segmenter(date));
                    } else {
                        onSegmentFilter(defaultDateRange);
                    }
                }}
            />
        </span>
    );
};

const InjectedSegmentFilter = injectIntl(SegmentFilter);

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onSegmentFilter: function(dateRange?: DateRange) {
        dispatch(setFilteredSegment(dateRange));
    },
});

const mapStateToProps = (state: State) => ({
    activeSegmentIdentifier: getActiveSegmentIdentifier(state),
    dateRange: getDateRange(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InjectedSegmentFilter);
