import { Road } from 'src/types';
export const getMileageSplitIntoUrbanNonUrban = (roads: Pick<Road, 'mileage' | 'urban'>[]) => {
    const urban = roads
        .filter(r => {
            return r.urban;
        })
        .reduce((mileage, road) => {
            return mileage + road.mileage;
        }, 0);

    const nonUrban = roads
        .filter(r => {
            return !r.urban;
        })
        .reduce((mileage, road) => {
            return mileage + road.mileage;
        }, 0);

    return { urban, nonUrban };
};
