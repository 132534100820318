import { getFeatureToggles, getPerformVehicles } from '@common/permissions/selectors';
import { getSelectedDriverIds } from '@data/selectors';
import { enablePerform3 } from '@features/settings/reducer';
import { hideSidebar } from '@features/ui/reducer';
import { getActiveDateRange } from '@features/ui/selectors';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { State } from '../../setup/types';
import { DateRange } from '../../types';
import DriverAnalysis from './DriverAnalysis';
import useDriverContentLoader from './hooks/useDriverContentLoader';

const Entry: React.FC<{
    selectedDriverIds: string[];
    vehicles: string[];
    activeDateRange: DateRange;
    hideSidebar: () => void;
    shouldShowHarshKPIs: boolean;
    shouldUseV2Ratings: boolean;
    shouldShowExcessiveKPIs: boolean;
    truE_EEF: boolean;
}> = ({
    selectedDriverIds,
    vehicles,
    activeDateRange,
    hideSidebar,
    shouldShowHarshKPIs,
    shouldUseV2Ratings,
    shouldShowExcessiveKPIs,
    truE_EEF,
}) => {
    useEffect(() => {
        if (selectedDriverIds.length === 0) {
            hideSidebar();
        }
    }, [hideSidebar, selectedDriverIds.length]);

    // TODO: remove hotfix that prevents calling backend with empty driver list
    const parentsWithChildren = useDriverContentLoader({
        selectedDriverIds,
        vehicles,
        dateRange: activeDateRange,
        shouldShowHarshKPIs,
        shouldUseV2Ratings,
        shouldShowExcessiveKPIs,
        truE_EEF,
    });
    return <DriverAnalysis parentsWithChildren={parentsWithChildren} />;
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideSidebar: () => dispatch(hideSidebar()),
});

const mapStateToProps = (state: State) => ({
    selectedDriverIds: getSelectedDriverIds(state),
    vehicles: getPerformVehicles(state),
    activeDateRange: getActiveDateRange(state),
    shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
    shouldUseV2Ratings: enablePerform3(state),
    shouldShowExcessiveKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
    truE_EEF: getFeatureToggles(state).truE_EEF as boolean,
});

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
