import { DRIVER_ANALYSIS_STORAGE_KEYS } from '@pages/driverAnalysis/settingsSaga';
import { VEHICLE_ANALYSIS_STORAGE_KEYS } from '@pages/vehicleAnalysis/settingsSaga';
import { Component } from 'react';

import { SETTINGS } from './features/settings/cruiseControlSettingsSaga';
import { TREE_SETTINGS } from './features/tree/saga';
import { configureReporting } from './setup/errorReporting';

const { captureException } = configureReporting(window, import.meta.env);

function reload() {
    window.location.reload();
}

function clearSavedLocalStorageSettings() {
    try {
        [
            ...Object.values(SETTINGS),
            ...Object.values(VEHICLE_ANALYSIS_STORAGE_KEYS),
            ...Object.values(DRIVER_ANALYSIS_STORAGE_KEYS),
            ...Object.values(TREE_SETTINGS),
            'lastRoute',
        ].forEach(localStorage.removeItem.bind(localStorage));
    } catch (e) {
        captureException(e as Error);
    }
}

export default class RootErrorBoundary extends Component<unknown, { hasError: boolean }> {
    constructor(props: { [key: string]: unknown }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info?: { componentStack: string }) {
        captureException(error, info);
        clearSavedLocalStorageSettings();
    }

    render() {
        return this.state.hasError ? (
            <div style={{ height: '100vh' }} className="display-flex flex-column">
                <div
                    style={{ fontSize: 300, height: '100vh' }}
                    className="display-flex justify-content-center align-items-center flex-1-1"
                >
                    <span className="rioglyph text-color-light rioglyph-truck-breakdown text-size-300pct" />
                </div>
                <div className="text-size-20 margin-20">
                    <div>
                        <h3>Sorry, something seems to be wrong</h3>
                        <p>
                            Please try again later. If the problem persists, please contact the RIO Support Hotline
                            00800/22550746.
                        </p>
                    </div>
                    <div>
                        <h3>Entschuldigung, etwas scheint nicht zu klappen</h3>
                        <p>
                            Bitte versuchen Sie es später nochmal. Wenn das Problem weiterhin besteht, kontaktieren Sie
                            bitte die RIO Support Hotline 00800/22550746.
                        </p>
                    </div>
                    <div className="text-bold">Error Code: 1432 </div>
                    <span
                        onClick={reload}
                        className="text-color-highlight text-size-20 rioglyph rioglyph-refresh cursor-pointer"
                    />
                </div>
            </div>
        ) : (
            this.props.children
        );
    }
}
