import WithUnit from '@components/WithUnit';
import toPercent from '@utils/toPercent';
import cn from 'classnames';
import React from 'react';

import { ValidUnit } from '../../constants/units';
import { Sign } from './types';

interface Props {
    name: React.ReactElement | string;
    totalMileage: number;
    mileage: number;
    sign?: Sign;
}

const getIconClass = (sign: Sign) => {
    switch (sign) {
        case Sign.MINUS:
            return 'rioglyph rioglyph-slope-down-max';
        case Sign.PLUS:
            return 'rioglyph rioglyph-slope-up-max';
        default:
            return '';
    }
};

export const Icon = ({ sign }: { sign?: Sign }): React.ReactElement =>
    sign ? (
        <div className={cn(getIconClass(sign), 'rioglyph text-size-18 text-color-highlight margin-right-10')} />
    ) : (
        <>{''}</>
    );

const SlopeRow = ({ mileage, sign, name, totalMileage = 0 }: Props) => {
    const percentage = toPercent(mileage, totalMileage, 0);

    return (
        <tr>
            <td className="border-top-0 padding-top-10 padding-bottom-10 max-width-250">
                <div className="display-flex align-items-center">
                    <Icon sign={sign} />
                    <div className="margin-right-10 text-color-dark ellipsis-1">{name}</div>
                </div>
            </td>
            <td className="border-top-0 padding-top-10 padding-bottom-10">
                <div className="width-100pct display-flex align-items-center">
                    <div className="StatusBar width-100pct display-flex align-items-center">
                        <div className="display-flex flex-1-0 align-items-center">
                            <div className="flex-order-2 width-45 margin-left-10">
                                <span className="display-flex flex-1-0 justify-content-end text-color-highlight text-size-16 text-bold">
                                    <WithUnit unit={ValidUnit.PERCENTAGE}>{percentage}</WithUnit>
                                </span>
                            </div>
                            <div className="display-flex flex-1-0 flex-order-1">
                                <div className="progress margin-0 flex-1-0 height-5">
                                    <div
                                        className="progress-bar progress-bar-highlight progress-divider"
                                        style={{ width: percentage + '%' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default SlopeRow;
