import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';

import ResetToDefaultButton from './ResetToDefaultButton';

const CalculationParamsDialogFooter = ({
    applyButtonOnClick,
    applyButtonDisabled,
    cancelButtonOnClick,
    resetButtonDisabled,
    resetButtonOnClick,
}: {
    applyButtonDisabled: boolean;
    applyButtonOnClick: () => void;
    cancelButtonOnClick: () => void;
    resetButtonDisabled: boolean;
    resetButtonOnClick: () => void;
}) => (
    <div className="display-flex justify-content-between">
        <ResetToDefaultButton disabled={resetButtonDisabled} resetToDefault={resetButtonOnClick} />
        <div>
            <Button
                variant="outline"
                className="btn-default padding-left-15 padding-right-15 margin-right-5"
                onClick={cancelButtonOnClick}
            >
                <span>
                    <FormattedMessage id="cancel" />
                </span>
            </Button>
            <Button
                disabled={applyButtonDisabled}
                className="padding-left-20 padding-right-20"
                bsStyle="primary"
                onClick={applyButtonOnClick}
            >
                <span>
                    <FormattedMessage id="apply" />
                </span>
            </Button>
        </div>
    </div>
);

export default CalculationParamsDialogFooter;
