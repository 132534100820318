import { FormatDateOptions } from 'react-intl';

export const PADDED_DATE_FORMAT = { year: 'numeric', month: '2-digit', day: '2-digit' } as FormatDateOptions & {
    calendar?:
        | 'buddhist'
        | 'chinese'
        | 'coptic'
        | 'ethiopia'
        | 'ethiopic'
        | 'gregory'
        | 'hebrew'
        | 'indian'
        | 'islamic'
        | 'iso8601'
        | 'japanese'
        | 'persian'
        | 'roc';
};
