import { Text } from '@react-pdf/renderer';
import { SourceObject } from '@react-pdf/types';
import { extractVehicleName } from '@utils/stringFormatters';
import { get } from 'lodash';

import { Vehicle, VehicleType } from '../../../types';
import dieselBusImage from '../assets/bus_diesel.png';
import electricBusImage from '../assets/bus_electric.png';
import busImage from '../assets/bus.png';
import dieselTruckImage from '../assets/truck_diesel.png';
import electricTruckImage from '../assets/truck_electric.png';
import truckImage from '../assets/truck.png';
import dieselVanImage from '../assets/van_diesel.png';
import electricVanImage from '../assets/van_electric.png';
import vanImage from '../assets/van.png';
import Label from './Label';
import SeparatedList from './SeparatedList';

const imageMappings: Record<string, SourceObject> = {
    dieselBusImage: dieselBusImage,
    dieselTruckImage: dieselTruckImage,
    dieselVanImage: dieselVanImage,
    electricBusImage: electricBusImage,
    electricTruckImage: electricTruckImage,
    electricVanImage: electricVanImage,
    unknownBusImage: dieselBusImage,
    unknownTruckImage: dieselTruckImage,
    unknownVanImage: dieselVanImage,
};

export function SingleVehicleLabel({
    vehicle,
    singleLine,
    shouldShowFuelType = false,
}: {
    vehicle?: Vehicle;
    singleLine: boolean;
    shouldShowFuelType?: boolean;
}) {
    if (!vehicle) {
        return <Label textId="unknown" image={truckImage} />;
    }

    const style = singleLine ? { maxLines: 1 } : { maxLines: 2, flex: 1, textOverflow: 'ellipsis' as const };
    const name = <Text style={style}>{extractVehicleName(vehicle)}</Text>;

    const allowedFuelTypes = ['DIESEL', 'ELECTRIC', 'UNKNOWN'];

    const fuelType = allowedFuelTypes.includes(vehicle.fuelType) ? vehicle.fuelType : 'unknown';

    switch (vehicle.type) {
        case VehicleType.TRUCK:
            return (
                <Label
                    textId="truck"
                    image={shouldShowFuelType ? imageMappings[`${fuelType.toLowerCase()}TruckImage`] : truckImage}
                >
                    {name}
                </Label>
            );
        case VehicleType.VAN:
            return (
                <Label
                    textId="van"
                    image={shouldShowFuelType ? imageMappings[`${fuelType.toLowerCase()}VanImage`] : vanImage}
                >
                    {name}
                </Label>
            );
        case VehicleType.BUS:
            return (
                <Label
                    textId="bus"
                    image={shouldShowFuelType ? imageMappings[`${fuelType.toLowerCase()}BusImage`] : busImage}
                >
                    {name}
                </Label>
            );
        default:
            return <Label textId="unknown" image={truckImage} />;
    }
}

export default function VehicleLabel({
    vehicles = [],
    expanded = false,
    shouldShowFuelType = false,
}: {
    vehicles: Vehicle[];
    expanded?: boolean;
    shouldShowFuelType?: boolean;
}) {
    if (expanded) {
        const vehicleLabels = vehicles.map((vehicle, index) => (
            <SingleVehicleLabel
                key={`vehicle_${index}`}
                vehicle={vehicle}
                singleLine={true}
                shouldShowFuelType={shouldShowFuelType}
            />
        ));

        return <SeparatedList>{vehicleLabels}</SeparatedList>;
    }

    const first = get(vehicles, [0]);

    if (vehicles.length === 1) {
        return <SingleVehicleLabel vehicle={first} singleLine={false} shouldShowFuelType={shouldShowFuelType} />;
    }
    return (
        <Label textId="truck" image={truckImage}>
            <Text>{vehicles.length}</Text>
        </Label>
    );
}
