import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import { Vehicle } from '../../../types';

export default function Systems({ vehicles = [] }: { vehicles: Vehicle[] | undefined }) {
    const systems = vehicles.map(vehicle => _.get(vehicle, 'system')).filter(Boolean);
    const formattedSystems = systems.length ? systems : ['-'];

    return (
        <View>
            <Text style={{ maxLines: 1 }}>{formattedSystems.join(', ').toUpperCase()}</Text>
        </View>
    );
}
