import { getSignal } from '@api/index';
import { intersection, isEmpty } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HydratedEntity, HydratedEntityWithChildren, Vehicle } from '../../types';

export function getLimitedFunctionalityWarning(
    row: HydratedEntityWithChildren | HydratedEntity,
    performReducedBusIds?: string[]
): JSX.Element | null {
    if (isEmpty(performReducedBusIds)) {
        return null;
    }

    const vehicles = getSignal<Vehicle[]>(row, 'vehicles', []);
    const vehicleIds = vehicles.map(vehicle => vehicle.vehicleId);

    const cityInterCityBuses = intersection(vehicleIds, performReducedBusIds);
    return !isEmpty(cityInterCityBuses) ? <LimitedFunctionalityWarning key="containsEssentialsBusWarning" /> : null;
}

export function LimitedFunctionalityWarning(): JSX.Element {
    return (
        <React.Fragment>
            <dt data-test="essential-bus-warning">
                <FormattedMessage id="containsEssentialsVehicleWarning" />
            </dt>
            <dd>
                <FormattedMessage id="containsEssentialsBusWarningDetail" />
            </dd>
        </React.Fragment>
    );
}
