import { isWhitelistedPerform3Account } from '@common/login/selectors';
import { enablePerform3, setEnablePerform3 } from '@features/settings/reducer';
import { Dispatch } from '@reduxjs/toolkit';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { connect } from 'react-redux';

import { LoginStatePart, SettingStatePart } from '../../setup/types';
const Perform3Toggle: React.FC<{
    setEnablePerform3: (setting: boolean) => void;
    enablePerform3: boolean;
    isWhitelistedAccount: boolean;
}> = ({ setEnablePerform3, enablePerform3, isWhitelistedAccount }) => {
    return (
        <div>
            <div className="line-height-20">
                {isWhitelistedAccount && (
                    <Switch
                        keyName="perform3Toggle"
                        onChange={setEnablePerform3}
                        checked={enablePerform3}
                        enabledText={'Perform3'}
                        disabledText={'Perform2'}
                    />
                )}
            </div>
        </div>
    );
};

export const mapStateToProps = (state: SettingStatePart & LoginStatePart) => ({
    enablePerform3: enablePerform3(state),
    isWhitelistedAccount: isWhitelistedPerform3Account(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setEnablePerform3: (setting: boolean) => dispatch(setEnablePerform3(setting)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Perform3Toggle);
