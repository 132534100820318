import { View } from '@react-pdf/renderer';
import React, { ReactNode } from 'react';
export default function SeparatedList({ children }: { children: ReactNode }) {
    const childrenAsArray = React.Children.toArray(children);
    return (
        <>
            {childrenAsArray.map((child, index) => (
                <View style={{ flexDirection: 'row', paddingRight: 5 }} key={index}>
                    {child}
                </View>
            ))}
        </>
    );
}
