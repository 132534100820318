import FileSaver from 'file-saver';
import { FormattedMessage } from 'react-intl';

import apiJSON from '../static/API_customer_postman_collection.json';
import europeanFlag from '../static/european-flag.png';

const DE_FORM_URL = 'https://rio.cloud/de/kontakt/formular';
const DE_MARKETPLACE_URL = 'https://rio.cloud/de/marketplace/partner';
const DE_PARTNER_MANAGEMENT_URL = 'https://rio.cloud/de/kontakt/formular';
const DE_API_URL = 'https://rio.cloud/de/apis/performance-analysis-data';
const DE_PERFORM_PRODUCT_PAGE = 'https://rio.cloud/en/apis/performance-analysis-data';

const EN_FORM_URL = 'https://rio.cloud/en/contact/formular';
const EN_MARKETPLACE_URL = 'https://rio.cloud/en/marketplace/partner';
const EN_PARTNER_MANAGEMENT_URL = 'https://rio.cloud/en/contact/formular';
const EN_API_URL = 'https://rio.cloud/en/apis/performance-analysis-data';
const EN_PERFORM_PRODUCT_PAGE = 'https://rio.cloud/en/apis/performance-analysis-data';

const OPEN_API_YAML = 'https://rio.cloud/fileadmin/apis/api-perform.yaml';
const RIO_SECURITY_PDF =
    'https://rio.cloud/fileadmin/Region/Europe/Language_Specific_PDF_Documents/Partner_PDFs/pdf-rioiam-partner-authentication.pdf';
const ASSETS_IDS_YAML = 'https://company.rio.cloud/fileadmin/Region/Europe/Apis/api-assets.yaml';
const DRIVERS_IDS_YAML = 'https://company.rio.cloud/fileadmin/Region/Europe/Apis/api-drivers.yaml';
const SWAGGER_URL = 'https://editor.swagger.io/';

const LinkGenerator = ({
    href,
    displayText,
    messageId,
    openInNewTab = true,
}: {
    href: string;
    displayText?: string;
    messageId?: string;
    openInNewTab?: boolean;
}) => {
    return (
        <a href={href} target={openInNewTab ? '_blank' : ''} rel="noreferrer">
            {displayText}
            {messageId && <FormattedMessage id={messageId} />}
        </a>
    );
};

export default function PerformAPIOverview({ locale }: { locale: string }) {
    const formLink = <LinkGenerator messageId="help.form" href={locale.includes('de') ? DE_FORM_URL : EN_FORM_URL} />;

    const marketplaceLink = (
        <LinkGenerator
            messageId="help.marketplace"
            href={locale.includes('de') ? DE_MARKETPLACE_URL : EN_MARKETPLACE_URL}
        />
    );

    const partnerManagementLink = (
        <LinkGenerator
            messageId="help.partnerManagement"
            href={locale.includes('de') ? DE_PARTNER_MANAGEMENT_URL : EN_PARTNER_MANAGEMENT_URL}
        />
    );

    const apiLink = <LinkGenerator messageId="help.here" href={locale.includes('de') ? DE_API_URL : EN_API_URL} />;

    const contactForm = locale.includes('de') ? (
        <LinkGenerator displayText={DE_FORM_URL} href={DE_FORM_URL} />
    ) : (
        <LinkGenerator displayText={EN_FORM_URL} href={EN_FORM_URL} />
    );

    const securityPdfLink = <LinkGenerator displayText={RIO_SECURITY_PDF} href={RIO_SECURITY_PDF} />;

    const openAPIYamlLink = <LinkGenerator messageId="openapi.yaml" href={OPEN_API_YAML} />;

    const performProductLink = (
        <LinkGenerator
            displayText="Perform (rio.cloud)"
            href={locale.includes('de') ? DE_PERFORM_PRODUCT_PAGE : EN_PERFORM_PRODUCT_PAGE}
        />
    );

    const assetsIdsYamlLink = <LinkGenerator displayText={ASSETS_IDS_YAML} href={ASSETS_IDS_YAML} />;

    const driversIdsYamlLink = <LinkGenerator displayText={DRIVERS_IDS_YAML} href={DRIVERS_IDS_YAML} />;

    const swaggerLink = <LinkGenerator displayText={SWAGGER_URL} href={SWAGGER_URL} />;

    const onDownload = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        const json = JSON.stringify(apiJSON);
        const blob = new Blob([json], { type: 'application/json' });
        FileSaver.saveAs(blob, 'API_customer_postman_collection.json');
    };

    return (
        <>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.performAPI.overviewTitle" />
            </h3>
            <div className="display-flex margin-top-15 margin-bottom-10 gap-10 align-items-center">
                <img alt="european-flag" src={europeanFlag} style={{ maxWidth: '45px', objectFit: 'contain' }} />
                <p className="margin-0 text-medium">
                    <FormattedMessage id="help.performAPI.europeanNote" />
                </p>
            </div>
            <h4 className="margin-top-15">
                <FormattedMessage id="help.performAPI.introduction.title" />
            </h4>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.introduction.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        line_break: <br />,
                    }}
                />
            </div>
            <h4>
                <FormattedMessage id="help.performAPI.howItWorks.title" />
            </h4>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.howItWorks.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        // eslint-disable-next-line react/display-name
                        ol: (chunks: unknown[]) => <ol>{chunks}</ol>,
                        // eslint-disable-next-line react/display-name
                        li: (chunks: unknown[]) => <li className="margin-bottom-5">{chunks}</li>,
                        marketplace: marketplaceLink,
                        partnerManagement: partnerManagementLink,
                        api: apiLink,
                        form: formLink,
                    }}
                />
            </div>
            <h4>
                <FormattedMessage id="help.performAPI.securityModel.title" />
            </h4>
            <h5>
                <FormattedMessage id="help.performAPI.securityModel.tecnologyUsed.title" />
            </h5>
            <p>
                <FormattedMessage id="help.performAPI.securityModel.tecnologyUsed.description" />
            </p>
            <h5>
                <FormattedMessage id="help.performAPI.securityModel.steps.title" />
            </h5>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.securityModel.steps.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        // eslint-disable-next-line react/display-name
                        specialParagraph: (chunks: unknown[]) => (
                            <p className="margin-bottom-5 margin-top-15">{chunks}</p>
                        ),
                        // eslint-disable-next-line react/display-name
                        code: (chunks: unknown[]) => (
                            <p
                                style={{ fontFamily: "'Lucida Console', monospace" }}
                                className="margin-bottom-15 margin-top-5"
                            >
                                {chunks}
                            </p>
                        ),
                        // eslint-disable-next-line react/display-name
                        lessThanSymbol: () => <span>&lt;</span>,
                        // eslint-disable-next-line react/display-name
                        biggerThanSymbol: () => <span>&gt;</span>,
                        form: contactForm,
                        line_break: <br className="" />,
                    }}
                />
            </div>
            <h5>
                <FormattedMessage id="help.performAPI.securityModel.rioDocumentation.title" />
            </h5>
            <p>
                <FormattedMessage
                    id="help.performAPI.securityModel.rioDocumentation.description"
                    values={{ securityPdf: securityPdfLink }}
                />
            </p>
            <h4>
                <FormattedMessage id="help.performAPI.apiDescription.title" />
            </h4>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.apiDescription.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        // eslint-disable-next-line react/display-name
                        specialParagraph: (chunks: unknown[]) => (
                            <p className="margin-bottom-5 margin-top-15">{chunks}</p>
                        ),
                        line_break: <br />,
                        openAPIYaml: openAPIYamlLink,
                        performProduct: performProductLink,
                    }}
                />
            </div>
            <h5>
                <FormattedMessage id="help.performAPI.apiDescription.apiRequest.title" />
            </h5>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.apiDescription.apiRequest.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        assetsIdsAPI: assetsIdsYamlLink,
                        driversIdsAPI: driversIdsYamlLink,
                    }}
                />
            </div>
            <h5>
                <FormattedMessage id="help.performAPI.apiDescription.typesOfRequests.title" />
            </h5>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.apiDescription.typesOfRequests.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        ul: (chunks: unknown[]) => <ul>{chunks}</ul>,
                        // eslint-disable-next-line react/display-name
                        li: (chunks: unknown[]) => <li className="margin-bottom-5">{chunks}</li>,
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                    }}
                />
            </div>
            <h5>
                <FormattedMessage id="help.performAPI.apiDescription.taxonomy.title" />
            </h5>
            <p>
                <FormattedMessage
                    id="help.performAPI.apiDescription.taxonomy.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        ul: (chunks: unknown[]) => <ul>{chunks}</ul>,
                        // eslint-disable-next-line react/display-name
                        li: (chunks: unknown[]) => <li className="margin-bottom-5">{chunks}</li>,
                        // eslint-disable-next-line react/display-name
                        code: (chunks: unknown[]) => (
                            <span style={{ fontFamily: "'Lucida Console', monospace" }}>{chunks}</span>
                        ),
                    }}
                />
            </p>
            <h5>
                <FormattedMessage id="help.performAPI.apiDescription.typicalUseCases.title" />
            </h5>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.apiDescription.typicalUseCases.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        ul: (chunks: unknown[]) => <ul>{chunks}</ul>,
                        // eslint-disable-next-line react/display-name
                        li: (chunks: unknown[]) => <li className="margin-bottom-5">{chunks}</li>,
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        // eslint-disable-next-line react/display-name
                        code: (chunks: unknown[]) => (
                            <span style={{ fontFamily: "'Lucida Console', monospace" }}>{chunks}</span>
                        ),
                    }}
                />
            </div>
            <h5>
                <FormattedMessage id="help.performAPI.apiDescription.exampleQueries.title" />
            </h5>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.apiDescription.exampleQueries.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        // eslint-disable-next-line react/display-name
                        code: (chunks: unknown[]) => (
                            <p
                                style={{ fontFamily: "'Lucida Console', monospace" }}
                                className="margin-bottom-15 margin-top-5"
                            >
                                {chunks}
                            </p>
                        ),
                    }}
                />
            </div>
            <h4 className="margin-top-15">
                <FormattedMessage id="help.performAPI.specificiations.title" />
            </h4>
            <p>
                <FormattedMessage
                    id="help.performAPI.specificiations.description"
                    values={{
                        swagger: swaggerLink,
                    }}
                />
            </p>
            <LinkGenerator displayText={OPEN_API_YAML} href={OPEN_API_YAML} openInNewTab={false} />
            <h4 className="margin-top-15">
                <FormattedMessage id="help.performAPI.postmanCollection.title" />
            </h4>
            <div className="margin-bottom-15">
                <FormattedMessage
                    id="help.performAPI.postmanCollection.description"
                    values={{
                        // eslint-disable-next-line react/display-name
                        p: (chunks: unknown[]) => <p className="margin-bottom-5 margin-top-5">{chunks}</p>,
                        // eslint-disable-next-line react/display-name
                        b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                        // eslint-disable-next-line react/display-name
                        i: (chunks: unknown[]) => <i>{chunks}</i>,
                    }}
                />
                <a href="#" onClick={onDownload}>
                    API_customer_postman_collection.json
                </a>
            </div>
        </>
    );
}
