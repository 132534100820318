import { getAccount } from '@common/login/selectors';
import { getTableViewType, setTableViewType } from '@features/ui/reducer';
import SegmentFilter from '@features/ui/SegmentFilter';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { generatePushToDataLayer } from '@utils/tracking';
import { camelCase } from 'lodash';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../setup/store';

export const SettingsToolbar: React.FunctionComponent<{
    disabled?: boolean;
    toggleSettings: (visible: boolean) => void;
    location?: string;
}> = ({ disabled, toggleSettings, location }) => {
    const dispatch = useAppDispatch();
    const viewType = useSelector(getTableViewType);
    const account: string | undefined = useSelector(getAccount);
    const pushToDataLayer = generatePushToDataLayer(account);

    const onViewTypeChange = (viewType: string) => {
        pushToDataLayer({
            eventTrigger: 'click',
            eventCategory: 'perform, tableSettings',
            eventAction: 'toggleTableView',
            eventLabel: `${camelCase(viewType)}View, ${location}`,
        });

        dispatch(setTableViewType(viewType));
    };

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column min-width-200">
                        <SegmentFilter />
                    </div>
                </div>

                <div className="table-toolbar-group-right display-none-xs display-flex-sm">
                    <div className="table-toolbar-column table-toolbar-column-spacer">
                        <TableViewToggles
                            initialViewType={viewType}
                            viewType={viewType}
                            onViewTypeChange={onViewTypeChange}
                        />
                    </div>
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar">
                            <button
                                disabled={disabled}
                                className="btn btn-default btn-icon-only"
                                data-test="table-settings-toggler"
                                onClick={() => toggleSettings(true)}
                            >
                                <span className="rioglyph rioglyph-cog" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};

export default SettingsToolbar;
