import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { ReactElement } from 'react';
import { FormattedDate } from 'react-intl';

const DateRangeSection = ({
    title,
    start,
    end,
}: {
    title?: ReactElement;
    start: Date | undefined;
    end: Date | undefined;
}) => (
    <View style={{ flexDirection: 'row' }}>
        {title && (
            <View style={{ minWidth: 80 }}>
                <Text>
                    {title}
                    <Text>:</Text>
                </Text>
            </View>
        )}
        <Text>
            <FormattedDate value={start} />
            <Text> - </Text>
            <FormattedDate value={end} />
        </Text>
    </View>
);

DateRangeSection.prototype = {
    title: PropTypes.node,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
};

export default DateRangeSection;
