import { getSignal } from '@api/helpers/transformer';
import { getAccuracyWarning } from '@components/warnings/AccuracyWarning';
import { getBelowMileageWarning } from '@components/warnings/BelowMileageWarning';
import { getBusOrVanWarning } from '@components/warnings/BusOrVanWarning';
import { getLimitedFunctionalityWarning } from '@components/warnings/LimitedFunctionalityWarning';
import { getTbm2AndTbm3Warning } from '@components/warnings/Tbm2AndTbm3Warning';

import { HydratedEntity, HydratedEntityWithChildren } from '../../types';

export type EntityDecoratedWithWarnings = Omit<HydratedEntityWithChildren, 'level' | 'children'> &
    Partial<Pick<HydratedEntityWithChildren, 'level' | 'children'>>;

export const decorateWithAdditionalInformation = ({
    entity,
    advanceVehicleIds,
    performVehicleIds,
    performReducedBus,
    shouldShowFuelType = false,
}: {
    entity: HydratedEntityWithChildren | HydratedEntity;
    advanceVehicleIds?: string[];
    performVehicleIds?: string[];
    performReducedBus?: string[];
    shouldShowFuelType?: boolean;
}): EntityDecoratedWithWarnings => ({
    ...entity,
    shouldShowFuelType,
    warnings: [
        getAccuracyWarning(entity),
        getTbm2AndTbm3Warning(entity),
        getBelowMileageWarning(entity),
        getBusOrVanWarning(entity),
        getLimitedFunctionalityWarning(entity, performReducedBus),
    ].filter((warning): warning is JSX.Element => Boolean(warning)),
    ...('children' in entity
        ? {
              children: (entity as HydratedEntityWithChildren).children.map(entity =>
                  decorateWithAdditionalInformation({
                      entity,
                      advanceVehicleIds,
                      performVehicleIds,
                      performReducedBus,
                      shouldShowFuelType,
                  })
              ),
          }
        : {}),
});

export const get = <T>(field: string) => (entity: HydratedEntity) => getSignal<T | null>(entity, field, null);
