import { Vehicle, VehicleFuelType } from '@api/models/types';
import { extractVehicleName } from '@utils/stringFormatters';
import { get } from 'lodash';
import React from 'react';

import { VehicleType } from '../types';
import Label from './Label';

const FUEL_ICONS: Record<VehicleFuelType, string> = {
    DIESEL: 'rioglyph-fuel-diesel',
    ELECTRIC: 'rioglyph-fuel-electric',
    GAS: 'rioglyph-fuel-gas',
    HYDROGEN: 'rioglyph-fuel-hydrogen',
    UNKNOWN: 'rioglyph-fuel-diesel', // for now, we will treat UNKNOWN fuel type as DIESEL
};

export const SingleVehicleLabel: React.FC<{ vehicle: Vehicle; shouldShowFuelType: boolean }> = ({
    vehicle,
    shouldShowFuelType,
}) => {
    const name = extractVehicleName(vehicle);
    const fuelIconName = FUEL_ICONS[vehicle.fuelType];

    switch (vehicle.type) {
        case VehicleType.TRUCK:
            return (
                <Label id="truck" icon="rioglyph-truck" secondaryIcon={shouldShowFuelType ? fuelIconName : undefined}>
                    {name}
                </Label>
            );

        case VehicleType.VAN:
            return (
                <Label id="van" icon="rioglyph-van" secondaryIcon={shouldShowFuelType ? fuelIconName : undefined}>
                    {name}
                </Label>
            );
        case VehicleType.BUS:
            return (
                <Label id="bus" icon="rioglyph-bus" secondaryIcon={shouldShowFuelType ? fuelIconName : undefined}>
                    {name}
                </Label>
            );
        default:
            return <Label id="unknown" icon="" />;
    }
};

const VehicleLabel: React.FC<{ vehicles: Vehicle[]; explicit?: boolean; shouldShowFuelType?: boolean }> = ({
    vehicles = [],
    explicit = false,
    shouldShowFuelType = false,
}) => {
    const first = get(vehicles, [0]);
    const total = vehicles.length || '-';
    const isSingle = vehicles.length === 1;

    if (explicit) {
        return vehicles.length ? (
            <ul style={{ listStyle: 'none' }} className="padding-0">
                {vehicles.map(vehicle => (
                    <li key={vehicle.vin || vehicle.vehicleId} className="margin-0 ellipsis-1">
                        <SingleVehicleLabel vehicle={vehicle} shouldShowFuelType={shouldShowFuelType} />
                    </li>
                ))}
            </ul>
        ) : (
            <span>-</span>
        );
    }

    return isSingle ? <SingleVehicleLabel vehicle={first} shouldShowFuelType={shouldShowFuelType} /> : <>{total}</>;
};

export default React.memo(VehicleLabel);
