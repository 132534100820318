import langReducer from '@common/lang/reducer';
import loginReducer from '@common/login/reducer';
import permissionsReducer from '@common/permissions/reducer';
import tokenHandlingReducer from '@common/tokenHandling/reducer';
import appReducer from '@features/app/reducer';
import downloadReducer from '@features/download/reducer';
import serviceInfoReducer from '@features/serviceInfo/reducer';
import settingsReducer from '@features/settings/reducer';
import treeReducer from '@features/tree/reducer';
import uiReducer from '@features/ui/reducer';
import coDriverReducer from '@pages/coDriver/reducer';
import driverAnalysisReducer from '@pages/driverAnalysis/driverAnalysisReducer';
import reportReducer from '@pages/reports/reducer';
import vehicleAnalysisReducer from '@pages/vehicleAnalysis/reducer';

import dataReducer from '../data/reducer';
import configReducer from './configReducer';

export default {
    app: appReducer,
    config: configReducer,
    lang: langReducer,
    login: loginReducer,
    ui: uiReducer,
    data: dataReducer,
    tree: treeReducer,
    reports: reportReducer,
    tokenHandling: tokenHandlingReducer,
    driverAnalysis: driverAnalysisReducer,
    vehicleAnalysis: vehicleAnalysisReducer,
    download: downloadReducer,
    serviceInfo: serviceInfoReducer,
    permissions: permissionsReducer,
    coDriver: coDriverReducer,
    settings: settingsReducer,
};
