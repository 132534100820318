import { getSignal, Id } from '@api/index';
import AccessControl from '@common/permissions/AccessControl';
import { SEE_MAP } from '@common/permissions/hasPermissions';
import ErrorBoundary from '@components/ErrorBoundry';
import SimpleErrorState from '@components/SimpleErrorState';
import TemperatureHistogram from '@features/environmentConditions/TemperatureHistogram';
import EmptyOpConState from '@features/opcon/EmptyOpConState';
import isBelowMileageThreshold from '@features/opcon/isBelowMileageThreshold';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import _ from 'lodash';
import { useContext } from 'react';

import SectionTitle from '../../components/SectionTitle';
import { DateRange } from '../../types';
import { OpCon } from '../opcon/types';
import DrivenElevation from './DrivenElevation';
import ElevationGraph from './ElevationGraph';
import EnvironmentConditionsProvider, { EnvironmentConditionsContext } from './EnvironmentConditionsProvider';
import { Status } from './types';

const ElevationInfo = ({
    dateRange,
    driverIds,
    hasOpconRequestFailed,
    hasElevationInfo,
    opCon,
    vehicleIds,
}: {
    hasOpconRequestFailed: boolean;
    hasElevationInfo: boolean;
    opCon: OpCon | undefined;
} & Pick<Props, 'dateRange' | 'vehicleIds' | 'driverIds'>) => {
    if (hasOpconRequestFailed) {
        return (
            <SimpleErrorState fullWidth className="padding-top-10 padding-bottom-10" data-test="opcon-error-state" />
        );
    }

    if (!hasElevationInfo || opCon === undefined) {
        return <EmptyOpConState className="padding-top-10 padding-bottom-10" />;
    }

    return (
        <>
            <AccessControl requiredPermissions={[SEE_MAP]}>
                <ElevationGraph dateRange={dateRange} vehicleIds={vehicleIds} driverIds={driverIds} />
            </AccessControl>
            <DrivenElevation opCon={opCon} />
        </>
    );
};

const AmbientTemperatureInfo = ({
    averageAmbientTemperatures,
    hasAmbientTemperatureRequestFailed,
    hasAmbientTemperatureInfo,
}: {
    averageAmbientTemperatures: number[] | undefined;
    hasAmbientTemperatureRequestFailed: boolean;
    hasAmbientTemperatureInfo: boolean;
}) => {
    if (hasAmbientTemperatureRequestFailed) {
        return (
            <SimpleErrorState
                fullWidth
                className="padding-top-10 padding-bottom-20"
                data-test="temperature-error-state"
            />
        );
    }

    if (!hasAmbientTemperatureInfo || averageAmbientTemperatures === undefined) {
        return <EmptyOpConState className="padding-top-10 padding-bottom-0" />;
    }

    return <TemperatureHistogram temperatureData={averageAmbientTemperatures} />;
};

interface Props {
    dateRange: DateRange;
    driverIds: (Id | null)[];
    mileageFromPerformance: number;
    vehicleIds: Id[];
    showTemperatureHistogram?: boolean;
}

export const EnvironmentConditions = ({
    mileageFromPerformance,
    dateRange,
    vehicleIds,
    driverIds,
    showTemperatureHistogram,
}: Props) => {
    const { ambientTemperature, opCon } = useContext(EnvironmentConditionsContext);

    const hasOpconRequestFailed = opCon.status === Status.FAILED;
    const operationRange = opCon.status === Status.SUCCESS && getSignal(opCon.data || {}, 'range', {});
    const topology = opCon.status === Status.SUCCESS && getSignal(opCon.data || {}, 'topology', {});
    const hasElevationInfo =
        opCon.status === Status.SUCCESS &&
        !isBelowMileageThreshold(_.get(topology, 'evaluatedMileage', 0), mileageFromPerformance) &&
        !isBelowMileageThreshold(_.get(operationRange, 'evaluatedMileage', 0), mileageFromPerformance);

    const hasAmbientTemperatureRequestFailed = ambientTemperature.status === Status.FAILED;
    const hasAmbientTemperatureInfo =
        ambientTemperature.status === Status.SUCCESS && (ambientTemperature.data || []).length > 0;

    if (opCon.status === Status.LOADING || ambientTemperature.status === Status.LOADING) {
        return <Spinner />;
    }

    if (hasOpconRequestFailed && hasAmbientTemperatureRequestFailed) {
        return <SimpleErrorState fullWidth className="padding-bottom-20" data-test="general-error-state" />;
    }

    if (
        !hasOpconRequestFailed &&
        !hasElevationInfo &&
        !hasAmbientTemperatureRequestFailed &&
        !hasAmbientTemperatureInfo
    ) {
        return <EmptyOpConState className="padding-bottom-20" />;
    }

    return (
        <div data-test="environmentConditions">
            <ErrorBoundary>
                <SectionTitle title="heightProfile" icon="rioglyph-slope-up-max" />
                <ElevationInfo
                    hasOpconRequestFailed={hasOpconRequestFailed}
                    hasElevationInfo={hasElevationInfo}
                    driverIds={driverIds}
                    vehicleIds={vehicleIds}
                    dateRange={dateRange}
                    opCon={opCon.data}
                />
                {showTemperatureHistogram && (
                    <div style={{ position: 'relative' }}>
                        <SectionTitle title="ambientTemperature" icon="rioglyph-temperature" />
                        <AmbientTemperatureInfo
                            hasAmbientTemperatureRequestFailed={hasAmbientTemperatureRequestFailed}
                            hasAmbientTemperatureInfo={hasAmbientTemperatureInfo}
                            averageAmbientTemperatures={ambientTemperature.data}
                        />
                    </div>
                )}
            </ErrorBoundary>
        </div>
    );
};

const SelfLoadingEnvironmentConditions = ({
    dateRange,
    driverIds,
    vehicleIds,
    allowedVehicles = [],
    showTemperatureHistogram = false,
    ...props
}: {
    allowedVehicles?: Id[];
    dateRange: DateRange;
    driverIds: (Id | null)[];
    mileageFromPerformance: number;
    vehicleIds: Id[];
    showTemperatureHistogram?: boolean;
} & unknown) => (
    <EnvironmentConditionsProvider
        allowedVehicles={allowedVehicles}
        dateRange={dateRange}
        driverIds={driverIds}
        shouldFetchTemperatureInfo={showTemperatureHistogram}
        vehicleIds={vehicleIds}
    >
        <EnvironmentConditions
            {...props}
            showTemperatureHistogram={showTemperatureHistogram}
            dateRange={dateRange}
            driverIds={driverIds}
            vehicleIds={vehicleIds}
        />
    </EnvironmentConditionsProvider>
);

export default SelfLoadingEnvironmentConditions;
