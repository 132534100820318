import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';

import { configureReporting } from '../../setup/errorReporting';
import { loadSettingsSuccess as settingsLoaded } from '../settings/reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from '../settings/saga';
import { setDateRange, setIsGraphCollapsed } from './reducer';

const { captureException } = configureReporting(window, import.meta.env);

export const UI_STORAGE_KEYS = { GRAPH_COLLPASED: 'graphCollapsed', DATE_RANGE: 'dateRange' };

export function* saveDateRange({ payload }: { payload: { start: Date; end: Date } }) {
    try {
        yield saveSettingsToStorage(UI_STORAGE_KEYS.DATE_RANGE, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveIsGraphCollapsed({ payload }: { payload: boolean }) {
    try {
        yield saveSettingsToStorage(UI_STORAGE_KEYS.GRAPH_COLLPASED, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const dateRange: { start: Date; end: Date } = yield loadSettingsFromStorage(UI_STORAGE_KEYS.DATE_RANGE, {});
        const isGraphCollapsed: boolean = yield loadSettingsFromStorage(UI_STORAGE_KEYS.GRAPH_COLLPASED, false);
        const start = moment(dateRange.start);
        const end = moment(dateRange.end);
        if (dateRange.start && start.isValid() && dateRange.end && end.isValid()) {
            yield put(setDateRange({ start: start.toDate(), end: end.toDate() }));
        }
        yield put(setIsGraphCollapsed(isGraphCollapsed));
    } catch (e) {
        yield saveSettingsToStorage(UI_STORAGE_KEYS.DATE_RANGE, {});
        yield saveSettingsToStorage(UI_STORAGE_KEYS.GRAPH_COLLPASED, false);
        yield call(captureException, e);
    }
}

export default function* root() {
    yield takeLatest(setDateRange, saveDateRange);
    yield takeLatest(setIsGraphCollapsed, saveIsGraphCollapsed);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
