import { Image, View } from '@react-pdf/renderer';
import { SourceObject } from '@react-pdf/types';

import brakeImage from '../assets/brake.png';
import coastingImage from '../assets/coasting.png';
import cruiseControlImage from '../assets/cruise-control.png';
import kickdownImage from '../assets/kickdown.png';
import speedImage from '../assets/speed.png';

/**
 * There is a miss match with the icon names. Temporarily place this here, till we get around fixing that issue
 * With foresightedDriving and column names.
 */
const imageMappings: Record<string, SourceObject> = {
    coastingRating: coastingImage,
    brakingRating: brakeImage,
    cruiseControlRating: cruiseControlImage,
    cruiseControlRatingV2: cruiseControlImage,
    accelerationPedalRating: kickdownImage,
    accelerationPedalRatingV2: kickdownImage,
    overspeedRating: speedImage,
};

const ForesightedDrivingIcon = ({ id }: { id: string }) => {
    const mappedId = imageMappings[id];
    if (mappedId) {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Image src={mappedId} style={{ width: 14.25, marginRight: 5 }} />
            </View>
        );
    }
    return <View />;
};
export default ForesightedDrivingIcon;
