import { StyleSheet, Text, View } from '@react-pdf/renderer';

import { Column as ColumnType } from '../../../columns/createColumn';
import { HydratedEntity } from '../../../types';
import isNotAReactComponent from '../../../utils/isNotAReactComponent';

const styles = StyleSheet.create({
    column: {
        paddingTop: 5,
        height: 35,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderBottomWidth: 1.2,
        borderColor: '#4C5667',
        justifyContent: 'center',
    },
    highlighted: {
        fontFamily: 'Bold',
    },
});

export default function Column({
    entity,
    column,
    isHighlighted,
    columnData,
}: {
    entity: HydratedEntity;
    column: ColumnType;
    isHighlighted: boolean;
    columnData?: Record<string, unknown>;
}) {
    const textStyles = isHighlighted ? styles.highlighted : {};
    const content = column.formatter(column.valueExtractor(entity), { ...entity, ...columnData });
    const wrappedContent = isNotAReactComponent(content) ? <Text style={{ maxLines: 1 }}>{content}</Text> : content;
    return (
        <View style={{ ...styles.column, ...textStyles }} wrap={false}>
            {wrappedContent}
        </View>
    );
}
