import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { FormattedMessage } from 'react-intl';

export default function VehicleState({ headlineId, messageId }: { headlineId: string; messageId: string }) {
    return (
        <div data-test="VehicleState">
            <CustomState
                headline={<FormattedMessage id={headlineId} />}
                message={<FormattedMessage id={messageId} />}
                icons={[
                    {
                        name: 'rioglyph-van',
                        className: 'text-size-200pct',
                    },
                    {
                        name: 'rioglyph-truck',
                        className: 'text-size-300pct',
                    },
                    {
                        name: 'rioglyph-bus',
                        className: 'text-size-200pct',
                    },
                ]}
            />
        </div>
    );
}
