import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import toPercent from '@utils/toPercent';
import cn from 'classnames';
import _ from 'lodash';

import OverlayTriggerWithInjectedIntl from './OverlayTriggerWithInjectedIntl';

const COLORS = ['bg-light', 'bg-gray', 'bg-dark', 'bg-darker', 'bg-darkest'];

const SimpleBarChart: React.FC<{ activeIndex: number; total?: number; tooltip?: React.ReactNode }> = ({
    activeIndex,
    total = 5,
    tooltip,
}) => {
    const bars = _.range(total)
        .map(step => toPercent(step + 1, total))
        .map((bar, index) => (
            <div
                key={index}
                style={{
                    height: `${bar}%`,
                    width: `${90 / total}%`,
                }}
                className={cn('bar', index <= activeIndex ? COLORS[activeIndex] : 'bg-lighter')}
            />
        ));
    return (
        <OverlayTriggerWithInjectedIntl
            placement="top"
            overlay={
                <Tooltip id="tooltip" className="top" width="auto">
                    {tooltip}
                </Tooltip>
            }
        >
            <div
                data-test="SimpleBarChart"
                className="width-100pct height-100pct display-flex align-items-end justify-content-between"
            >
                {bars}
            </div>
        </OverlayTriggerWithInjectedIntl>
    );
};

export default SimpleBarChart;
