import { useEffect } from 'react';

const NotFoundState = ({ onClose }: { onClose: () => void }) => {
    useEffect(() => {
        onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return null;
};

export default NotFoundState;
