import { FormattedMessage } from 'react-intl';

const EN_URL = 'https://rio.cloud/en/apis/performance-analysis-data';
const DE_URL = 'https://rio.cloud/de/apis/performance-analysis-data';

export default function PerformAPISpecification({ locale }: { locale: string }) {
    const iframeUrl = locale.includes('de') ? DE_URL : EN_URL;

    return (
        <>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.performAPI.specificationTitle" />
            </h3>
            <iframe
                src={iframeUrl}
                title="Perform API Specification page"
                frameBorder="0"
                style={{ width: '100%', height: '75vh' }}
            ></iframe>
        </>
    );
}
