import { Font } from '@react-pdf/renderer';
import { hyphenateSync } from 'hyphen/en-us';
import React from 'react';
const SOFT_HYPHEN = '\u00ad';
const cache: Record<string, string[]> = {};

const wordHyphenation = (word: string | undefined) => {
    if (!word) return [];
    if (word.length < 10) {
        return [word];
    }
    if (cache[word]) return cache[word];
    cache[word] = hyphenateSync(word, { hyphenChar: SOFT_HYPHEN, minWordLength: 10 }).split(SOFT_HYPHEN);
    return cache[word];
};

const FontProvider: React.FC<{ locale: string }> = ({ locale, children }) => {
    let bold = '/fonts/SourceSansPro-Bold.ttf';
    let regular = '/fonts/SourceSansPro-Regular.ttf';
    if (['ko-KR', 'ko'].includes(locale)) {
        regular = '/fonts/NanumGothic-Regular.ttf';
        bold = '/fonts/NanumGothic-Bold.ttf';
    }

    Font.registerHyphenationCallback(wordHyphenation);
    Font.register({
        family: 'Regular',
        src: regular,
    });

    Font.register({
        family: 'Bold',
        src: bold,
    });

    return <>{children}</>;
};

export default FontProvider;
