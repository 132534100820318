import { HoverInfo } from '@pages/driverAnalysis/types';
import { NumberValue } from 'd3-scale';
import _ from 'lodash';
import { useContext, useState } from 'react';

import { WITH_DATA_COLOR, WITHOUT_DATA_COLOR } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';
import { hasValue, HOVER_INFO_HEIGHT, NO_DATA_Y_OFFSET } from './utils';

//TODO : Rename this Component since it renders more than just days.
export default function Day({
    onHover,
    onClick,
    activeDay,
    useOuterXPosition = false,
    xOffset = 0,
}: {
    onClick: (start: Date) => void;
    activeDay?: Date;
    onHover: (hover: HoverInfo | null) => void;
    useOuterXPosition?: boolean;
    xOffset?: number;
}) {
    const {
        xScale: x,
        leftYScale,
        rightYScale,
        data,
        dimensions: { height, margin, horizontalMargin },
    } = useContext(GraphContext);
    const [hovered, setHovered] = useState<Date | undefined>();

    const getXValue = (value: NumberValue | Date) => x(value as number) || 0;

    const calculateWidth = (index: number) => {
        let dayWidth = margin * 3;

        if (data.length > 1) {
            dayWidth = getXValue(data[1].x) - getXValue(data[0].x);

            switch (index) {
                case 0:
                case data.length - 1:
                    return dayWidth / 2 + margin;
            }
        }
        return dayWidth;
    };

    const transform = (d: DataEntry, index: number) => {
        let dayWidth = margin * 3;
        if (data.length > 1) {
            dayWidth = getXValue(data[1].x) - getXValue(data[0].x);
            if (index === 0 && !horizontalMargin) {
                return 'translate(0 0)';
            }
        }

        return `translate(${getXValue(d.x) - dayWidth / 2} 0)`;
    };

    const getHoverInfo = (d: DataEntry) => {
        const leftX = useOuterXPosition ? getXValue(d.x) - xOffset : getXValue(d.x);
        const rightX = useOuterXPosition ? getXValue(d.x) + xOffset : getXValue(d.x);
        const leftUpY = !_.isUndefined(d.leftUpY) ? leftYScale(d.leftUpY) : 0;
        const leftDownY =
            !_.isUndefined(d.leftDownY) && leftYScale
                ? leftYScale(-Math.abs(d.leftDownY)) - HOVER_INFO_HEIGHT
                : NO_DATA_Y_OFFSET;
        const rightUpY = !_.isUndefined(d.rightUpY) && rightYScale ? rightYScale(d.rightUpY) : NO_DATA_Y_OFFSET;
        const rightDownY =
            !_.isUndefined(d.rightDownY) && rightYScale
                ? rightYScale(-Math.abs(d.rightDownY)) - HOVER_INFO_HEIGHT
                : NO_DATA_Y_OFFSET;
        const size = data.length > 1 ? getXValue(data[1].x) - getXValue(data[0].x) : margin * 3;

        return {
            leftX,
            rightX,
            leftUpY,
            leftDownY,
            rightUpY,
            rightDownY,
            size,
            data: d,
        };
    };

    return (
        <g>
            {data.map((d, idx) => {
                const isHovered = hovered && hovered.getTime() === (d.x as Date).getTime();
                const isActive = activeDay && activeDay.getTime() === (d.x as Date).getTime();

                return (
                    <g key={idx} className="day cursor-pointer">
                        <rect
                            height={height}
                            stroke={hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR}
                            fill={hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR}
                            fillOpacity={0.1}
                            strokeWidth={1}
                            opacity={isActive || isHovered ? 1 : 0}
                            width={calculateWidth(idx)}
                            transform={transform(d, idx)}
                            onMouseOut={() => {
                                setHovered(undefined);
                                onHover(null);
                            }}
                            onMouseOver={() => {
                                setHovered(d.x as Date);
                                onHover(getHoverInfo(d));
                            }}
                            onClick={() => onClick(d.x as Date)}
                        />
                    </g>
                );
            })}
        </g>
    );
}
