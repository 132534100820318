import { Image, View } from '@react-pdf/renderer';
import { SourceObject } from '@react-pdf/types';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export default function Label({
    textId,
    image,
    children,
}: {
    textId: string;
    image: SourceObject;
    children?: ReactNode;
}) {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image src={image} style={{ width: 10, height: 10, marginRight: 6 }} />
            {children ? children : <FormattedMessage id={textId} />}
        </View>
    );
}
