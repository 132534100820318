import sha1 from 'js-sha1';
import * as _ from 'lodash';

import { EntityType } from '../../features/foresightedDriving/types';
import { OpCon } from '../../features/opcon/types';
import { HydratedEntity, OpconSignals } from '../../types';
import { Id, Signal, Signals } from '../models/types';

export const createId = (a: unknown): Id => sha1(JSON.stringify(a));

//TODO: investigate the right type here.
export const createSignal = <T>(value: T): Signal<T> => ({
    value,
    unit: 'noUnit',
    accuracy: 'high',
});

export const extractValueFromSignal = <T>(signal: Signal<T>): T => signal.value;

export const getSignal = <T>(
    entity: HydratedEntity | EntityType | OpconSignals | OpCon | Record<string, unknown>,
    key: string,
    defaultValue: T | '-'
): T => {
    if (key === 'drivers' && 'drivers' in entity) {
        return (entity.drivers || []) as T;
    }

    if (key === 'vehicles' && 'vehicles' in entity) {
        return (entity.vehicles || []) as T;
    }

    if ('signals' in entity && entity.signals) {
        return _.get(entity, `signals.${key}.value`, defaultValue) as T;
    }

    return _.get(entity, key, defaultValue) as T;
};

const mapSignals = (rawInput: unknown) => _.mapValues(rawInput as Signals, createSignal);

export const TBM3ReportEntityFactory = <T>(
    transformed: T,
    rawInput: T,
    reportId: string
): { id: string; signals: { [key: string]: Signals } } => ({
    id: createId({ ...rawInput, reportId }),
    signals: mapSignals(transformed),
});
