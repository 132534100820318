import { max, min } from 'd3-array';
import { ScaleBand, ScaleLinear, scaleLinear, ScaleTime, scaleTime } from 'd3-scale';
import { get, head, isNumber, noop, sortBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';

import { Data, Dimensions, formatDate, formatMessage, GraphType } from './types';

export const GraphContext = React.createContext<{
    data: Data;
    graphType: GraphType;
    xScale: ScaleTime<number, number> | ScaleBand<number>;
    //eslint-disable-next-line  @typescript-eslint/no-explicit-any
    leftYScale: ScaleLinear<any, any>;
    //eslint-disable-next-line  @typescript-eslint/no-explicit-any
    rightYScale?: ScaleLinear<any, any>;
    formatDate: formatDate;
    formatMessage: formatMessage;
    dimensions: Dimensions;
    yZeroPosition: number;
    hoverInfoXOffset?: number;
    setHoverInfoXOffset?: (hoverInfoXOffset: number) => void;
}>({
    data: [],
    graphType: GraphType.SINGLE_UP_BAR,
    xScale: scaleTime<number, number>(),
    leftYScale: scaleLinear(),
    rightYScale: scaleLinear(),
    formatDate: (_: Date) => '',
    formatMessage: () => '',
    dimensions: {
        height: 0,
        width: 0,
        margin: 0,
        horizontalMargin: undefined,
        widthWithoutMargin: 0,
        heightWithoutMargin: 0,
        legendHeight: 0,
        legendLeftMargin: 0,
        legendTopMargin: 0,
    },
    yZeroPosition: 0,
    hoverInfoXOffset: 0,
    setHoverInfoXOffset: () => {},
});

type props = {
    data: Data;
    dimensions: {
        height: number;
        width: number;
        margin: number;
        horizontalMargin?: number;
        legendHeight?: number;
        legendLeftMargin?: number;
        legendTopMargin?: number;
    };
    formatMessage: ({ id }: { id: string }) => string;
    formatDate?: (date: Date) => string;
    xScale?: ScaleTime<number, number> | ScaleBand<number>;
    //eslint-disable-next-line  @typescript-eslint/no-explicit-any
    leftYScale?: ScaleLinear<any, any>;
    //eslint-disable-next-line  @typescript-eslint/no-explicit-any
    rightYScale?: ScaleLinear<any, any>;
    hoverInfoXOffset?: number;
    setHoverInfoXOffset?: (hoverInfoXOffset: number) => void;
    setYZeroPosition?: (yZeroPosition: number) => void;
    yScaleShouldStartAtZero?: boolean;
    graphType?: GraphType;
    shouldRoundYScales?: boolean;
};

const GraphDataProvider: React.FC<props> = ({
    data,
    dimensions: {
        height,
        width,
        margin,
        horizontalMargin,
        legendLeftMargin = 0,
        legendTopMargin = 0,
        legendHeight = 0,
    },
    formatMessage = () => '',
    formatDate = _ => '',
    children,
    xScale,
    leftYScale,
    rightYScale,
    hoverInfoXOffset = 0,
    setHoverInfoXOffset = noop,
    setYZeroPosition = noop,
    yScaleShouldStartAtZero = false,
    graphType = GraphType.SINGLE_UP_BAR,
    shouldRoundYScales = false,
}) => {
    const largestXData = get(head(sortBy(data, 'x').reverse()), 'x', new Date());
    const smallestXData = get(head(sortBy(data, 'x')), 'x', new Date());
    const xDomain = [moment(smallestXData).toDate(), moment(largestXData).toDate()];
    xScale =
        xScale ||
        scaleTime()
            .domain(xDomain)
            .range([horizontalMargin || margin, width - (horizontalMargin || margin)]);

    let leftYData: number[] | undefined = undefined,
        leftLargestYDataPoint = 0,
        leftSmallestYDataPointMinus10Percent = 0,
        rightYData: number[] | undefined = undefined,
        rightLargestYDataPoint = 0,
        rightSmallestYDataPointMinus10Percent = 0,
        yZeroPosition: number | undefined = undefined;

    switch (graphType) {
        case GraphType.SINGLE_UP_BAR: {
            leftYData = data.map(d => d.leftUpY).filter(isNumber);
            leftLargestYDataPoint = max<number>(leftYData) || 0;
            const leftSmallestYDataPoint = min<number>(leftYData) || 0;
            leftSmallestYDataPointMinus10Percent = Math.max(
                leftSmallestYDataPoint - (leftLargestYDataPoint - leftSmallestYDataPoint) * 0.1,
                0
            );
            yZeroPosition = height - margin;
            break;
        }
        case GraphType.TWO_UP_BARS: {
            leftYData = data.map(d => d.leftUpY).filter(isNumber);
            leftLargestYDataPoint = max<number>(leftYData) || 0;

            rightYData = data.map(d => d.rightUpY).filter(isNumber);
            rightLargestYDataPoint = max<number>(rightYData) || 0;

            const biggestRange = leftLargestYDataPoint >= rightLargestYDataPoint ? 'leftYData' : 'rightYData';

            if (biggestRange === 'leftYData') {
                rightYData = leftYData;
                rightLargestYDataPoint = leftLargestYDataPoint;
            } else {
                leftYData = rightYData;
                leftLargestYDataPoint = rightLargestYDataPoint;
            }

            yZeroPosition = height - margin;
            break;
        }
        case GraphType.ONE_UP_AND_ONE_DOWN_BARS: {
            const leftUpYData = data.map(d => d.leftUpY).filter(isNumber);
            const leftDownYData = data.map(d => -Math.abs(d.leftDownY)).filter(isNumber);
            leftYData = leftUpYData.concat(leftDownYData);
            leftLargestYDataPoint = max<number>(leftYData) || 0;
            const leftSmallestDataPoint = min<number>(leftYData) || 0;
            leftSmallestYDataPointMinus10Percent =
                leftSmallestDataPoint > 0
                    ? Math.max(leftSmallestDataPoint - (leftLargestYDataPoint - leftSmallestDataPoint) * 0.1, 0)
                    : Math.min(leftSmallestDataPoint - (leftLargestYDataPoint - leftSmallestDataPoint) * 0.1, 0);
            break;
        }
        case GraphType.TWO_UP_AND_ONE_DOWN_BARS: {
            leftYData = data.map(d => d.leftUpY).filter(isNumber);
            leftLargestYDataPoint = max<number>(leftYData) || 0;

            const rightUpYData = data.map(d => d.rightUpY).filter(isNumber);
            const rightDownYData = data.map(d => isNumber(d.rightDownY) && -Math.abs(d.rightDownY)).filter(isNumber);
            rightYData = rightUpYData.concat(rightDownYData);
            rightLargestYDataPoint = max<number>(rightYData) || 0;
            const rightSmallestDataPoint = min<number>(rightYData) || 0;
            rightSmallestYDataPointMinus10Percent =
                rightSmallestDataPoint > 0
                    ? Math.max(rightSmallestDataPoint - (rightLargestYDataPoint - rightSmallestDataPoint) * 0.1, 0)
                    : Math.min(rightSmallestDataPoint - (rightLargestYDataPoint - rightSmallestDataPoint) * 0.1, 0);

            if (leftLargestYDataPoint === 0 && rightYData.length > 0) {
                leftLargestYDataPoint = rightLargestYDataPoint;
            }

            if (rightSmallestYDataPointMinus10Percent === rightLargestYDataPoint && leftYData.length > 0) {
                rightLargestYDataPoint = leftLargestYDataPoint;
            }

            break;
        }
    }

    let rightYDomain = useMemo(() => {
        if (graphType !== GraphType.TWO_UP_AND_ONE_DOWN_BARS) {
            return [0, 0];
        }

        return rightSmallestYDataPointMinus10Percent > 0
            ? [rightLargestYDataPoint, 0]
            : [rightLargestYDataPoint, rightSmallestYDataPointMinus10Percent];
    }, [graphType, rightLargestYDataPoint, rightSmallestYDataPointMinus10Percent]);

    rightYScale = useMemo(() => {
        const scale =
            rightYScale ||
            scaleLinear()
                .domain(rightYDomain)
                .range([margin, height - margin]);

        return shouldRoundYScales ? scale.nice() : scale;
    }, [height, margin, rightYDomain, rightYScale, shouldRoundYScales]);

    let leftYDomain = useMemo(() => {
        return yScaleShouldStartAtZero
            ? [leftLargestYDataPoint, 0]
            : [leftLargestYDataPoint, leftSmallestYDataPointMinus10Percent];
    }, [leftLargestYDataPoint, leftSmallestYDataPointMinus10Percent, yScaleShouldStartAtZero]);

    leftYScale = useMemo(() => {
        let leftZeroPosition = height - margin;
        if (graphType === GraphType.TWO_UP_AND_ONE_DOWN_BARS && rightYData && rightYData.length > 0) {
            leftZeroPosition = rightYScale!(0);
        }

        const scale =
            leftYScale ||
            scaleLinear()
                .domain(leftYDomain)
                .range([margin, leftZeroPosition]);

        return shouldRoundYScales ? scale.nice() : scale;
    }, [height, margin, graphType, rightYData, leftYScale, leftYDomain, shouldRoundYScales, rightYScale]);

    yZeroPosition = yZeroPosition ?? graphType === GraphType.ONE_UP_AND_ONE_DOWN_BARS ? leftYScale(0) : rightYScale!(0);

    useEffect(() => {
        if (leftYScale && rightYScale) {
            setYZeroPosition(yZeroPosition);
        }
    }, [leftYScale, rightYScale, setYZeroPosition, yZeroPosition]);

    const config = {
        data,
        xScale,
        leftYScale,
        rightYScale,
        formatDate,
        formatMessage,
        dimensions: {
            margin,
            horizontalMargin,
            height: height - margin,
            width: width - margin,
            widthWithoutMargin: width,
            heightWithoutMargin: height,
            legendHeight,
            legendLeftMargin,
            legendTopMargin,
        },
        hoverInfoXOffset,
        setHoverInfoXOffset,
        graphType,
        yZeroPosition: yZeroPosition ?? height - margin,
    };

    return <GraphContext.Provider value={config}>{children}</GraphContext.Provider>;
};

export default GraphDataProvider;
