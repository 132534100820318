import { Loadable, LoadableType } from '@data/loadable';
import { Data, DataType, FlattenedRow } from '@pages/driverAnalysis/types';
import { findValueExtractor, sortByProperty, SortDirection } from '@utils/sortyByProperty';
import { useMemo } from 'react';
import { compose } from 'redux';

import { Column } from '../../../columns/createColumn';

const findColumnByKey = (columns: Column[], key: string): Column | undefined =>
    columns.find(column => column.key === key);

const createSortAndFlattenFunction = (openRows: string[], sortColumn: Column | undefined, sortOrder: SortDirection) => {
    const { key, dataField } = sortColumn || {};

    const sortChildrenBy = (sortFn: { (children: DataType): Data[] }) => (data: Data[]) =>
        data.map(d => ({
            ...d,
            children: sortFn(d.children),
        }));

    const dataSort: (arg0: Data[]) => Data[] = compose(
        sortChildrenBy((children: DataType) => sortByProperty(children, dataField, sortOrder, findValueExtractor(key))),
        sortChildrenBy((children: DataType) =>
            sortByProperty(children, 'vehicles', SortDirection.ASCENDING, findValueExtractor('vehicles'))
        ),
        (data: Data[]) => sortByProperty(data, dataField, sortOrder, findValueExtractor(key))
    );

    const flattenChildren = (data: Data[]): FlattenedRow[] =>
        data.reduce((result: FlattenedRow[], row: Data) => {
            const isRowOpen = openRows.includes(row.id);
            return [...result, row, ...((isRowOpen && row.children) || [])];
        }, [] as FlattenedRow[]);

    return compose(flattenChildren, dataSort);
};

export default function useFlattenAndSortTableRows({
    collapsedRow,
    key,
    order,
    openRows,
    columnOrder,
}: {
    collapsedRow: LoadableType<Data[]>;
    key: string;
    order: SortDirection;
    openRows: string[];
    columnOrder: Column[];
}) {
    return useMemo(() => {
        const sortAndFlatten = createSortAndFlattenFunction(openRows, findColumnByKey(columnOrder, key), order);
        return Loadable.map(collapsedRow, sortAndFlatten);
    }, [collapsedRow, key, order, openRows, columnOrder]);
}
