import { ReportConfiguration } from '@api/index';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { REPORT_ROUTE } from '../../../constants/routes';
import { ReportStatePart } from '../../../setup/types';
import { getReportById, getStatus } from '../reducer';
import { Status } from '../types';
import EditingForm from './EditingForm';
import LoadingState from './LoadingState';
import NotFoundState from './NotFoundState';

const EditReportSidebar = () => {
    const reportId = useParams<{ id: string }>().id;
    const history = useHistory();
    const onCloseSidebar = () => history.replace(REPORT_ROUTE);

    const report: ReportConfiguration = useSelector((state: ReportStatePart) => getReportById(state, reportId));
    const status = useSelector(getStatus);

    if ([Status.Loading, Status.Ready].includes(status)) {
        return <LoadingState onClose={onCloseSidebar} />;
    }

    if (report) {
        return <EditingForm report={report} onClose={onCloseSidebar} />;
    }
    return <NotFoundState onClose={onCloseSidebar} />;
};

export default EditReportSidebar;
