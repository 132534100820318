import _ from 'lodash';

import { Driver, System, Vehicle, VehicleFuelType, VehicleGroup, VehicleSegment, VehicleType } from '../models/types';
import { TagApiResponseType } from './decodeGroups';
import { DriverApiResponseType } from './driverDecoder';
import {
    AssetApiResponseType,
    AssetType,
    AssociationApiResponseType,
    PlatformInfoVehicleApiResponseType,
} from './vehicleDecoder';

export const unassignedGroup: { id: string; name: string; position: 'last' | undefined } = {
    id: 'static/unassignedTrucks',
    name: 'All my unassigned Trucks',
    position: 'last',
};

function deviceImportance(type: System | string) {
    switch (type) {
        case System.TBM3:
            return 2;
        case System.TBM2:
            return 1;
        default:
            return 0;
    }
}

export function getGroups(tags: TagApiResponseType) {
    return tags.items
        .map<VehicleGroup>(item => ({
            entityIds: [],
            id: item.id,
            name: item.name,
            type: item.type,
        }))
        .concat([unassignedGroup]);
}

export function getVehicles(
    assets: AssetApiResponseType,
    associations: AssociationApiResponseType,
    additionalVehiclesInfos: PlatformInfoVehicleApiResponseType
): Vehicle[] {
    const devices = associations.items
        .map(function(association) {
            return {
                asset_id: association.asset_id,
                type: _.get(association, '_embedded.device.type', '').toLowerCase(),
            };
        })
        .sort(function(a, b) {
            return deviceImportance(b.type) - deviceImportance(a.type);
        });

    interface WithId extends AssetType {
        identification: string;
    }

    const busSegments: { [key: string]: VehicleSegment } = additionalVehiclesInfos.items
        .filter(vehicle => vehicle.type === VehicleType.BUS)
        .reduce((acc, bus) => ({ ...acc, [bus.assetId]: bus.segment }), {});

    const vehiclesFuelType: { [key: string]: VehicleFuelType } = additionalVehiclesInfos.items.reduce(
        (acc, vehicle) => ({ ...acc, [vehicle.assetId]: vehicle.fuelType || VehicleFuelType.UNKNOWN }),
        {}
    );

    const vehicles = assets.items
        .filter((vehicle): vehicle is WithId => vehicle.identification !== null)
        .filter(function(vehicle) {
            return vehicle.identification_type === 'vin';
        })
        .map(function(vehicle) {
            const device = devices.find(function(device) {
                return device.asset_id === vehicle.id;
            });

            const groupIds = _.map(_.get(vehicle, '_embedded.tags.items', []), 'id');

            const segment = busSegments[vehicle.id] || VehicleSegment.UNKNOWN;

            return {
                vehicleId: vehicle.id,
                id: vehicle.id,
                fleetId: vehicle.account_id,
                name: vehicle.name,
                vin: vehicle.identification,
                type: vehicle.type.toUpperCase() as VehicleType,
                segment,
                fuelType: vehiclesFuelType[vehicle.id],
                brand: vehicle.brand,
                system: (device ? device.type : 'NO_SYSTEM') as System,
                status: vehicle.status,
                groupIds: _.isEmpty(groupIds) ? [unassignedGroup.id] : groupIds,
            };
        });
    return vehicles;
}

function isValidDriver(driver: Driver) {
    return driver.displayName;
}

export function getDrivers(drivers: DriverApiResponseType) {
    return drivers.items
        .map<Driver>(item => {
            // lodash get typings are broken.
            const identifications = _.get(item, '_embedded.identifications', []) as {
                identification_type: string;
                identification: string;
            }[];

            const groupIds = _.map(_.get(item, '_embedded.tags', []), 'id');
            const rioDriverId = identifications.find(
                identification => identification.identification_type === 'rio-driver-identification'
            );
            const euDriverCardId = identifications.find(
                identification => identification.identification_type === 'eu-tachograph-card'
            );

            return {
                name: item.last_name,
                displayName: item.display_name,
                fleetId: item.account_id,
                driverId: item.id,
                id: item.id,
                groupIds: _.isEmpty(groupIds) ? [unassignedGroup.id] : groupIds,
                legacyRioId: _.get(rioDriverId, 'identification', null),
                firstName: item.first_name,
                lastName: item.last_name,
                cardNumber: _.get(euDriverCardId, 'identification', null),
                status: item.status.toUpperCase(),
            };
        })
        .filter(isValidDriver);
}
