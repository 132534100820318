import {
    ReportConfiguration,
    ReportConfigurationResponse,
    ReportConfigurationResponseType,
    ReportConfigurationSingleResponse,
    ReportConfigurationSingleResponseType,
    ReportType,
    TargetType,
} from '../models/reportConfiguration';
import { decodeJson } from './decoder';

export function decodeSingleReportConfiguration(raw: ReportConfigurationSingleResponseType): ReportConfiguration {
    const decoded = decodeJson(ReportConfigurationSingleResponse, raw);
    return transformReportConfiguration(decoded);
}

export function decodeReportConfigurations(raw: ReportConfigurationResponseType): ReportConfiguration[] {
    const decoded: ReportConfigurationResponseType = decodeJson(ReportConfigurationResponse, raw);
    return decoded.items.map(item => transformReportConfiguration(item));
}

function transformReportConfiguration(decoded: ReportConfigurationSingleResponseType): ReportConfiguration {
    const { targets: _, ...decodedWithouthTargets } = decoded;

    const defaults = { ...decodedWithouthTargets, reportType: findReportType(decoded.reportType) };

    let { types, ...targets } = decoded.targets.reduce(
        (acc: { types: TargetType[] }, target) => {
            if ('assets' in target) {
                return {
                    ...acc,
                    assets: target.assets,
                    types: [...acc.types, TargetType.assets],
                };
            }
            if ('drivers' in target) {
                return {
                    ...acc,
                    drivers: target.drivers,
                    types: [...acc.types, TargetType.drivers],
                };
            }
            if ('groups' in target) {
                return {
                    ...acc,
                    groups: target.groups,
                    types: [...acc.types, TargetType.groups],
                };
            }

            return { ...acc, types: [...acc.types, TargetType.fleet] };
        },
        { types: [] }
    );

    let type: TargetType;
    if (types.length > 1) {
        type = types.includes(TargetType.drivers) ? TargetType.driversAndGroups : TargetType.assetsAndGroups;
    } else {
        type = types[0];
    }

    return { ...defaults, groups: [], drivers: [], assets: [], ...targets, type };
}

function findReportType(reportType: string): ReportType {
    const { unknown, ...acceptedReportTypes } = ReportType;
    return Object.values(acceptedReportTypes).includes(reportType) ? (reportType as ReportType) : ReportType.unknown;
}
