import { Loadable } from '@data/loadable';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import _ from 'lodash';
import always from 'lodash/fp/always';
import { cloneElement, ReactElement } from 'react';

import useOpconData from '../../data/hooks/useOpconData';

export default function GetBasicOpConData({
    start,
    end,
    driverIds,
    vehicleIds,
    children,
}: {
    start: Date;
    end: Date;
    driverIds: (string | null)[];
    vehicleIds: string[];
    children: ReactElement;
}) {
    const groupBy = [
        driverIds.filter(Boolean).length && 'driver-id',
        vehicleIds.filter(Boolean).length && 'asset-id',
    ].filter((group): group is string => Boolean(group));

    const opConDataRequestForSelectedVehicle = useOpconData({
        dateRange: { start, end },
        vehicleIds,
        driverIds,
        groupBy,
        debounced: false,
    });

    return Loadable.cata(
        opConDataRequestForSelectedVehicle,
        data => {
            const props = { opCon: _.get(data, [0], {}) };
            return cloneElement(children, props);
        },
        always(null),
        always(<Spinner />),
        always(<Spinner />)
    );
}
