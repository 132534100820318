import { getFeatureToggles, getPerformVehicles } from '@common/permissions/selectors';
import { requestExcelDownload } from '@features/download/actions';
import DownloadMenu from '@features/download/DownloadMenu';
import fileName from '@features/download/fileName';
import { TEMPLATE_EXCEL_DOWNLOAD, TEMPLATE_OPCON_REQUEST } from '@features/download/queries';
import { RequestDownloadExcel } from '@features/download/types';
import { Payload as PdfPayload, requestPrint } from '@features/pdf/actions';
import {
    TEMPLATE_DRIVER_ELECTRIC_REQUEST_BY_TIME,
    TEMPLATE_DRIVER_REQUEST,
    TEMPLATE_DRIVER_REQUEST_BY_TIME,
} from '@features/pdf/queries';
import { CalculationParams, savedCalculationParams } from '@features/settings/reducer';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import { trackingAttributes } from '@utils/tracking';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AVAILABLE_CONFIGURATIONS } from '../../constants/pdf';
import { State } from '../../setup/types';
import { DateRange, IntervalTypes, OpconQueryPayload, PerformSegmentBy } from '../../types';
import { Sorting, UseCaseKey, UseCaseSettingsType } from './driverAnalysisReducer';
import { getSortingBy, getUseCaseSettings } from './driverAnalysisSelectors';

const timeIntervalType = configureGetCreateTimeInterval({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'quarter',
    years: 'year',
});

export function DriverDownloadMenu({
    dateRange,
    drivers,
    vehicles,
    columnSettings,
    onDownloadRequested,
    disabled,
    useCaseKey,
    onPDFRequested,
    sortBy,
    savedCalculationParameters,
    isTruEEnabled,
}: {
    dateRange: DateRange;
    drivers: string[];
    vehicles: string[];
    columnSettings: UseCaseSettingsType;
    onDownloadRequested: (payload: RequestDownloadExcel) => void;
    disabled: boolean;
    useCaseKey: UseCaseKey;
    onPDFRequested: (payload: PdfPayload) => void;
    sortBy: Sorting;
    savedCalculationParameters: CalculationParams;
    isTruEEnabled: boolean;
}) {
    const downloadOptions = [
        { header: true, value: <FormattedMessage id="download.excelReport" /> },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'perform, downloadButton',
                        'downloadDrivingAnalysisExcel',
                        'downloadExcel, drivingAnalysis, downloadByDrivers'
                    )}
                >
                    <FormattedMessage id="download.excelByDriver" />
                </div>
            ),
            onSelect: () => {
                onDownloadRequested({
                    aggregationQuery: TEMPLATE_EXCEL_DOWNLOAD({
                        start: dateRange.start,
                        end: dateRange.end,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                        segmentBy: PerformSegmentBy.driver,
                    }),
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        start: dateRange.start,
                        end: dateRange.end,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                        groupBy: ['driver-id'],
                    }),
                    fileName: fileName({ dateRange, value: 'driver' }),
                });
            },
        },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'perform, downloadButton',
                        'downloadDrivingAnalysisExcel',
                        'downloadExcel, drivingAnalysis, downloadByDriversAndVehicles'
                    )}
                >
                    <FormattedMessage id="download.excelByDriverAndVehicle" />
                </div>
            ),
            onSelect: () => {
                onDownloadRequested({
                    aggregationQuery: TEMPLATE_EXCEL_DOWNLOAD({
                        start: dateRange.start,
                        end: dateRange.end,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                        segmentBy: PerformSegmentBy.driver_and_vehicle,
                    }),
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        start: dateRange.start,
                        end: dateRange.end,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                        groupBy: ['asset-id', 'driver-id'],
                    }),
                    fileName: fileName({ dateRange, value: 'driver-and-vehicle' }),
                });
            },
        },
    ];

    const pdfExportOptions = [
        { header: true, value: <FormattedMessage id="download.pdfReport" /> },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'perform, downloadButton',
                        'downloadDrivingAnalysisPdf',
                        'downloadPdf, drivingAnalysis, downloadByDrivers'
                    )}
                >
                    <FormattedMessage id="download.pdfReportByDriver" />
                </div>
            ),
            onSelect: () =>
                onPDFRequested({
                    id: AVAILABLE_CONFIGURATIONS.DRIVER,
                    useCase: useCaseKey === 'overview' ? 'driver-overview' : useCaseKey,
                    dateRange,
                    columnSettings,
                    sortBy,
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        groupBy: ['driver-id'],
                        start: dateRange.start,
                        end: dateRange.end,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                    }) as OpconQueryPayload,
                    aggregationQuery: TEMPLATE_DRIVER_REQUEST({
                        start: dateRange.start,
                        end: dateRange.end,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                    }),
                    summaryQuery: TEMPLATE_DRIVER_REQUEST_BY_TIME({
                        start: dateRange.start,
                        end: dateRange.end,
                        intervalType: timeIntervalType(dateRange) as IntervalTypes,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                    }),
                    summaryElectricQuery: TEMPLATE_DRIVER_ELECTRIC_REQUEST_BY_TIME({
                        start: dateRange.start,
                        end: dateRange.end,
                        intervalType: timeIntervalType(dateRange) as IntervalTypes,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                    }),
                    calculationParameters: savedCalculationParameters,
                    isTruEEnabled,
                }),
        },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'perform, downloadButton',
                        'downloadDrivingAnalysisPdf',
                        'downloadPdf, drivingAnalysis, downloadByDriversAndVehicles'
                    )}
                >
                    <FormattedMessage id="download.pdfReportByDriverAndVehicles" />
                </div>
            ),
            onSelect: () =>
                onPDFRequested({
                    id: AVAILABLE_CONFIGURATIONS.DRIVER_AND_VEHICLE,
                    useCase: useCaseKey === 'overview' ? 'driver-overview' : useCaseKey,
                    dateRange,
                    columnSettings,
                    sortBy,
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        groupBy: ['driver-id', 'asset-id'],
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        driverIds: drivers,
                    }) as OpconQueryPayload,
                    aggregationQuery: TEMPLATE_DRIVER_REQUEST({
                        ...dateRange,
                        driverIds: drivers,
                        vehicleIds: vehicles,
                        segmentBy: PerformSegmentBy.driver_and_vehicle,
                    }),
                    summaryQuery: TEMPLATE_DRIVER_REQUEST_BY_TIME({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        intervalType: timeIntervalType(dateRange) as IntervalTypes,
                        driverIds: drivers,
                    }),
                    summaryElectricQuery: TEMPLATE_DRIVER_ELECTRIC_REQUEST_BY_TIME({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        intervalType: timeIntervalType(dateRange) as IntervalTypes,
                        driverIds: drivers,
                    }),
                    calculationParameters: savedCalculationParameters,
                    isTruEEnabled,
                }),
        },
    ];

    return <DownloadMenu disabled={disabled} options={downloadOptions.concat(pdfExportOptions)} owner="driver" />;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onPDFRequested: (args: PdfPayload) => dispatch(requestPrint(args)),
    onDownloadRequested: ({ aggregationQuery, opConQuery, fileName }: RequestDownloadExcel) =>
        dispatch(requestExcelDownload({ owner: 'driver', opConQuery, aggregationQuery, fileName })),
});

const mapStateToProps = (state: State) => {
    const useCaseSettings = getUseCaseSettings(state);
    return {
        columnSettings: {
            columnOrder: useCaseSettings.columnOrder,
            filteredColumnNames: useCaseSettings.filteredColumnNames,
        },
        sortBy: getSortingBy(state),
        vehicles: getPerformVehicles(state),
        savedCalculationParameters: savedCalculationParams(state),
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverDownloadMenu);
