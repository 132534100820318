import { FormattedMessage } from 'react-intl';

const Release500 = () => (
    <div className="display-flex gap-20 align-items-start" style={{ marginLeft: '110px' }}>
        <div style={{ flexBasis: '50%' }}>
            <p className="text-bold text-size-18">
                <FormattedMessage id="help.changelog.changedKPIs" />
            </p>
            <ul className="padding-left-20">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedKPIs.cruiseControlPotential" />
                    </b>
                </li>
                <p className="padding-left-10">
                    <FormattedMessage
                        id="help.changelog.5.0.0.changedKPIs.cruiseControlPotential.description"
                        values={{ line_break: <br /> }}
                    />
                </p>
            </ul>
        </div>
        <div style={{ flexBasis: '50%' }}>
            <p className="text-bold text-size-18">
                <FormattedMessage id="help.changelog.changedRatings" />
            </p>
            <ul className="padding-left-18">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedRatings.acceleratorPedalAndCruiseControl" />
                    </b>
                </li>
                <p className="padding-left-10">
                    <FormattedMessage
                        id="help.changelog.5.0.0.changedRatings.acceleratorPedalAndCruiseControl.description"
                        values={{ line_break: <br /> }}
                    />
                </p>
            </ul>
        </div>
    </div>
);

export default Release500;
