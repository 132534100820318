import './styles.less';

import { VehicleFuelType } from '@api/models/types';
import { getAccount } from '@common/login/selectors';
import { getFeatureToggles, getPerformVehicles } from '@common/permissions/selectors';
import { getAccessToken } from '@common/tokenHandling/selectors';
import SimpleErrorState from '@components/SimpleErrorState';
import SettingsDialog from '@components/table/SettingsDialog';
import SettingsToolbar from '@components/table/SettingsToolbar';
import Table from '@components/table/Table';
import UseCases from '@components/UseCases';
import { Loadable, LoadableType } from '@data/loadable';
import { getDrivers, getSelectedDriverIds, getVehicles } from '@data/selectors';
import AdministrationSettingsFooter from '@features/settings/AdministrationSettingsFooter';
import {
    enablePerform3,
    includeCruiseControlInRating as includeCruiseControlInRatingSelector,
    savedCalculationParams,
} from '@features/settings/reducer';
import { getTableViewType, hideSidebar, isMobile, showSidebar, sidebarData } from '@features/ui/reducer';
import { getDateRange, isFilteringOnSegment } from '@features/ui/selectors';
import Toolbar from '@features/ui/Toolbar';
import useDriverTableColumns from '@pages/driverAnalysis/hooks/useDriverTableColumns';
import useTableSettings from '@pages/driverAnalysis/hooks/useTableSettings';
import _ from 'lodash';
import always from 'lodash/fp/always';
import identity from 'lodash/fp/identity';
import React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { loadingFormatter } from '../../columns/DOM';
import { DRIVER_ROUTE } from '../../constants/routes';
import { State } from '../../setup/types';
import { DateRange, Map, RawDriver, Vehicle } from '../../types';
import {
    closeRow,
    openRow,
    setSortKeyAndDirection as setSortKeyAndDirectionAction,
    setUseCase,
} from './driverAnalysisActions';
import { Sorting, UseCaseKey } from './driverAnalysisReducer';
import { getOpenRows, getSortingBy, getUseCase } from './driverAnalysisSelectors';
import DriverDownloadMenu from './DriverDownloadMenu';
import DriverState from './DriverState';
import DriverSummary from './DriverSummary';
import useFlattenAndSortTableRows from './hooks/useFlattenAndSortTableRows';
import { Data, FlattenedRow } from './types';
import { getUseCaseConfigForKey, UseCaseConfig, useCaseConfig } from './useCaseConfig';

const NON_BEV_RATINGS_KPIS = ['discipline.coasting', 'discipline.accelerationPedal', 'discipline.braking'];

const hasData = (loadable: LoadableType<unknown[]>) => Loadable.map(loadable, data => Boolean(data.length));

const getShouldShowGraph = compose(
    loadable => Loadable.cata(loadable, identity, always(true), always(true), always(true)),
    hasData
);

const getAreSettingsEnabled = compose(
    loadable => Loadable.cata(loadable, identity, always(false), always(false), always(false)),
    hasData
);

const openExpanderRow = ({
    openRows,
    openRow,
    closeRow,
}: {
    openRows: string[];
    openRow: (id: string) => void;
    closeRow: (id: string) => void;
}) => (row: FlattenedRow) => {
    const id = row.id;
    if (openRows.includes(id)) {
        closeRow(id);
    } else {
        openRow(id);
    }
};

const DriverAnalysis: React.FunctionComponent<{
    driverIds: string[];
    openSidebar: (sideBarData: FlattenedRow) => void;
    sortBy: Sorting;
    setSortKeyAndDirection: (sortBy: Sorting) => void;
    setUseCase: (useCase: UseCaseKey) => void;
    requestConfiguration: {
        accessToken: string;
        useCase: UseCaseKey;
        dateRange: DateRange;
        drivers: Map<RawDriver>;
        vehicles: Map<Vehicle>;
    };
    closeSidebar: () => void;
    useCase: UseCaseKey;
    useCaseConfig: UseCaseConfig[];
    selectedUseCase?: UseCaseConfig;
    allowedVehicles: string[];
    isFilteringOnSegment: boolean;
    parentsWithChildren: LoadableType<Data[]>;
    selectedDriver?: string;
    openRow: (id: string) => void;
    closeRow: (id: string) => void;
    openRows: string[];
    viewType?: string;
    isMobile?: boolean;
    account?: string;
    isTruEEnabled: boolean;
}> = ({
    driverIds,
    openSidebar,
    sortBy,
    setSortKeyAndDirection,
    setUseCase,
    requestConfiguration,
    closeSidebar,
    useCase,
    useCaseConfig,
    selectedUseCase,
    allowedVehicles,
    isFilteringOnSegment,
    parentsWithChildren,
    selectedDriver,
    openRow,
    closeRow,
    openRows = [],
    viewType,
    isMobile = false,
    account = '',
    isTruEEnabled,
}) => {
    const dateRange = requestConfiguration.dateRange;
    const { areSettingsVisible, toggleSettings } = useTableSettings();
    const {
        selectedColumns,
        hiddenColumns,
        setColumnOrder,
        setFilteredColumnNames,
        columnOrder,
    } = useDriverTableColumns(selectedUseCase);

    const expandedChildren = useFlattenAndSortTableRows({
        collapsedRow: parentsWithChildren,
        key: sortBy.key,
        order: sortBy.order,
        openRows,
        columnOrder: columnOrder.sorted,
    });

    const areResultsEmpty = Loadable.cata(
        expandedChildren,
        data => _.isEmpty(data),
        always(true),
        always(true),
        always(true)
    );

    const shouldShowGraph = isFilteringOnSegment || (getShouldShowGraph(expandedChildren) && Boolean(driverIds.length));
    const areSettingsEnabled = getAreSettingsEnabled(expandedChildren);

    const table = Loadable.cata(
        expandedChildren,
        data => {
            if (!data.length) {
                return (
                    <div className={!isFilteringOnSegment ? 'margin-top-20pct' : ''}>
                        <DriverState
                            headlineId="driverAnalysisNoDriversReturned"
                            messageId="driverAnalysisNoDriversReturnedExplanation"
                        />
                    </div>
                );
            }

            const haveDriversDrivenAnyEletricVehicle = data.some(driver =>
                (driver.vehicles as Vehicle[]).some(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC)
            );
            const haveDriversDrivenOnlyEletricVehicle = data.every(driver =>
                (driver.vehicles as Vehicle[]).every(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC)
            );
            const shouldShowFuelType = isTruEEnabled && haveDriversDrivenAnyEletricVehicle;

            const dataWithIfShouldShowEngineType = data.map(driver => {
                const newVehicles = (driver.vehicles as Vehicle[]).map(vehicle => ({
                    ...vehicle,
                    shouldShowFuelType,
                }));
                const newChildren = (driver.children as any[])?.map(child => {
                    return {
                        ...child,
                        vehicles: child.vehicles.map((vehicle: Vehicle) => ({ ...vehicle, shouldShowFuelType })),
                    };
                });

                return { ...driver, vehicles: newVehicles, children: newChildren, shouldShowFuelType };
            });

            const filteredColumns = !(haveDriversDrivenOnlyEletricVehicle && isTruEEnabled)
                ? selectedColumns
                : selectedColumns.filter(column => !NON_BEV_RATINGS_KPIS.includes(column.labelId));

            return (
                <Table
                    key={useCase}
                    filteredColumns={filteredColumns}
                    onOpenChildren={openExpanderRow({ openRows, openRow, closeRow })}
                    sortBy={sortBy}
                    onSort={setSortKeyAndDirection}
                    selectedElements={selectedDriver ? [selectedDriver] : undefined}
                    openRows={openRows}
                    data={dataWithIfShouldShowEngineType}
                    onRowClicked={(row, active) => (active || !row ? closeSidebar() : openSidebar(row))}
                    viewType={viewType}
                    location="drivingAnalysis"
                    shouldShowFuelType={shouldShowFuelType}
                />
            );
        },
        () => (
            <div className="margin-top-20">
                <SimpleErrorState headlineId="error.default" messageId="error.server" />
            </div>
        ),
        () => (
            <Table
                filteredColumns={selectedColumns.map(c => ({ ...c, formatter: loadingFormatter }))}
                sortBy={sortBy}
                onSort={setSortKeyAndDirection}
                data={_.fill(Array(3), { level: 1, id: '' })}
                viewType={viewType}
                shouldShowFuelType={false}
            />
        ),
        () => (
            <div className="margin-top-20pct">
                <DriverState
                    headlineId="driverAnalysisNoDriversSelected"
                    messageId="driverAnalysisNoDriversSelectedExplanation"
                />
            </div>
        )
    );

    return (
        <div className="driver-section" data-test="DriverAnalysis">
            <Toolbar>
                <div className="table-toolbar-column table-toolbar-column-spacer">
                    <UseCases
                        onSelect={setUseCase}
                        active={requestConfiguration.useCase}
                        useCaseConfig={useCaseConfig}
                        location="drivingAnalysis"
                    />
                </div>
                <div className="table-toolbar-column">
                    <DriverDownloadMenu
                        drivers={driverIds}
                        disabled={!areSettingsEnabled}
                        dateRange={dateRange}
                        useCaseKey={useCase}
                    />
                </div>
            </Toolbar>
            <SettingsDialog
                areSettingsVisible={areSettingsVisible}
                hiddenColumns={hiddenColumns.map(column => column.key)}
                toggleSettings={toggleSettings}
                columnOrder={columnOrder.sorted.map(column => column.key)}
                setColumnOrder={order => setColumnOrder(useCase, order)}
                columns={columnOrder.default}
                defaultColumnOrder={columnOrder.default.map(column => column.key)}
                setColumns={filteredColumnNames => setFilteredColumnNames(useCase, filteredColumnNames)}
                account={account}
                location="drivingAnalysis"
                useCase={`Driver${_.upperFirst(useCase)}`}
            />
            {!isMobile && shouldShowGraph && (
                <DriverSummary
                    dateRange={dateRange}
                    driverIds={driverIds}
                    allowedVehicles={allowedVehicles}
                    parentsWithChildren={parentsWithChildren}
                />
            )}
            {driverIds.length ? (
                <React.Fragment>
                    <SettingsToolbar
                        disabled={areResultsEmpty}
                        toggleSettings={toggleSettings}
                        location="drivingAnalysis"
                    />
                    {table}
                </React.Fragment>
            ) : (
                <div className="margin-top-20pct">
                    <DriverState
                        headlineId="driverAnalysisNoDriversSelected"
                        messageId="driverAnalysisNoDriversSelectedExplanation"
                    />
                </div>
            )}
            <AdministrationSettingsFooter />
        </div>
    );
};

const useCaseConfigForKey = createSelector(
    [getUseCase, includeCruiseControlInRatingSelector, enablePerform3, savedCalculationParams, getFeatureToggles],
    (useCase, includeCruiseControlInRating, isPerform3Enabled, calculationParams, featureToggles) => {
        return getUseCaseConfigForKey(useCase, {
            includeCruiseControlInRating,
            shouldUseV2Ratings: isPerform3Enabled,
            calculationParams,
            shouldShowHarshKPIs: featureToggles.showHarshKPIs as boolean,
            shouldShowExcessiveKPIs: featureToggles.showExcessiveKPIs as boolean,
        });
    }
);

const mapStateToProps = (state: State) => {
    const useCase = getUseCase(state);

    return {
        driverIds: getSelectedDriverIds(state),
        useCase,
        useCaseConfig,
        // eslint-disable-next-line react-hooks/rules-of-hooks
        selectedUseCase: useCaseConfigForKey(state),
        openRows: getOpenRows(state),
        sortBy: getSortingBy(state),
        selectedDriver: _.get(sidebarData(state, DRIVER_ROUTE), 'id'),
        allowedVehicles: getPerformVehicles(state),
        requestConfiguration: {
            accessToken: getAccessToken(state),
            useCase,
            dateRange: getDateRange(state),
            drivers: getDrivers(state),
            vehicles: getVehicles(state),
        },
        isFilteringOnSegment: isFilteringOnSegment(state),
        viewType: getTableViewType(state),
        isMobile: isMobile(state),
        account: getAccount(state),
        shouldUseV2Ratings: enablePerform3(state),
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        openSidebar: function(sideBarData: FlattenedRow) {
            dispatch(showSidebar({ data: sideBarData, type: DRIVER_ROUTE }));
        },
        closeSidebar: function() {
            dispatch(hideSidebar());
        },
        setSortKeyAndDirection: function(sortBy: Sorting) {
            dispatch(setSortKeyAndDirectionAction(sortBy));
        },
        setUseCase: function(useCase: UseCaseKey) {
            dispatch(setUseCase(useCase));
        },
        openRow: function(id: string) {
            dispatch(openRow({ id }));
        },
        closeRow: function(id: string) {
            dispatch(closeRow({ id }));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverAnalysis);
