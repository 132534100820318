import cn from 'classnames';
import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export enum InputStatus {
    error,
    touched,
    success,
}

export default function Input(
    props: {
        register: UseFormRegisterReturn;
        errorMessage: ReactElement;
        status: InputStatus;
    } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
) {
    const { register, status, errorMessage, ...rest } = props;

    return (
        <div className={cn('form-group', { 'has-error': status === InputStatus.error })} {...rest}>
            <input type="text" {...register} className="form-control" />

            {status === InputStatus.error && (
                <span className="text-color-danger help-block">
                    <span>{errorMessage}</span>
                </span>
            )}
        </div>
    );
}

export function LoadingInput() {
    return (
        <ContentLoader
            className="display-block"
            speed={2}
            height={34}
            viewBox="0 0 400 30"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="300" height="30" />
        </ContentLoader>
    );
}
