import colors from '@rio-cloud/rio-uikit/Colors';
import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import { area } from 'd3-shape';
import last from 'lodash/last';
import { useContext, useEffect, useRef } from 'react';

import { ANIMATION_DURATION } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';
const COLOR = colors['color-highlight-light'];
const INTERPOLATED_COLOR = colors['gray-lightest'];
const INTERPOLATED_STRIPE_COLOR = colors['gray-lighter'];

enum DATA_TYPE {
    INTERPOLATED,
    WITH_DATA,
}

// The data comes with interpolated data, in this case we want to slice it again.
// And gray out certain parts of the segment
// We start out with a stream of data and we slice it into buckets. Where
// the first last element is always the first element of the new bucket.
// slice([i,i,d,d,d,i,i]) = [[i,i],[i,d,d,d],[d,i,i]]
// This allows us to create areas, which are nicely formatting.
// An improvement, could would be to have just 1 area but segment days with masks!
// This case slice([i,i,i,d]) == [[i,i,i,d][d]]
// will yield 2 areas, but since the last one only contains 1 point i'll be invisible
const sliceDataIntoSegments = (data: DataEntry[]) =>
    data.reduce((acc: DataEntry[][], entry) => {
        const lastBucket = last(acc);
        const lastItem = last(lastBucket);
        if (lastBucket) {
            if (lastItem?.interpolated === entry.interpolated) {
                lastBucket.push(entry);
            } else {
                lastBucket.push(entry);
                acc.push([entry]);
            }
        } else {
            acc.push([entry]);
        }
        return acc;
    }, []);

export default function Area({ fill = COLOR }: { fill?: string }) {
    const { xScale: x, leftYScale: y, data } = useContext(GraphContext);
    const container = useRef<SVGGElement>(null);

    useEffect(() => {
        const areaGenerator = area<DataEntry>()
            .x(d => x(d.x as number) || 0)
            .y1(d => y(d.leftUpY))
            .y0(y.range()[1]);

        const sliced = sliceDataIntoSegments(data).map(slice => {
            const withoutStart = [...slice].splice(0, 1);
            const type =
                withoutStart.filter(s => s.interpolated).length > withoutStart.length / 2
                    ? DATA_TYPE.INTERPOLATED
                    : DATA_TYPE.WITH_DATA;
            return { area: areaGenerator(slice), type };
        });
        if (container?.current) {
            const areasUpdate = select(container.current)
                .selectAll<SVGPathElement, unknown>('.area')
                .data(sliced);

            const areasEnter = areasUpdate
                .enter()
                .append('path')
                .attr('class', 'area');
            areasUpdate.exit().remove();

            areasEnter
                .merge(areasUpdate)
                .transition()
                .ease(easeLinear)
                .duration(ANIMATION_DURATION)
                .attr('fill-opacity', '1')
                .attr('fill', ({ type }) => (type === DATA_TYPE.INTERPOLATED ? 'url(#grayed-out-pattern)' : fill))
                .attr('d', ({ area }) => area);
        }
    }, [data, x, y, fill]);

    return (
        <g ref={container}>
            <defs>
                <pattern
                    id="grayed-out-pattern"
                    patternUnits="userSpaceOnUse"
                    width="10"
                    height="10"
                    patternTransform="rotate(45)"
                    fill="red"
                >
                    <rect x="0" y="0" height="10" width="10" stroke={INTERPOLATED_COLOR} strokeWidth="400" />
                    <line x1="0" y="0" x2="0" y2="10" stroke={INTERPOLATED_STRIPE_COLOR} strokeWidth="1" />
                </pattern>
            </defs>
        </g>
    );
}
