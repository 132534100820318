import { getSignal } from '@api/index';
import WithUnit from '@components/WithUnit';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { formatIntegerNumber, withUnit } from '@utils/formatters';
import { getMileageSplitIntoUrbanNonUrban } from '@utils/getMileageSplitIntoUrbanNonUrban';
import { groupRoadsByCountry } from '@utils/groupRoadsByCountry';
import toPercent from '@utils/toPercent';
import _ from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { compose } from 'redux';

import { KILOMETERS, ValidUnit } from '../../../constants/units';
import { HydratedEntity } from '../../../types';
import stopOverImage from '../assets/stopover.png';
import DisplayWarningConditionally from '../components/DisplayOpconWarningConditionally';
import { FONT_SIZES } from './styles';

const styles = StyleSheet.create({
    title: {
        fontSize: FONT_SIZES.SUB_TITLE,
    },
    operationalRange: {
        width: '100%',
        flexDirection: 'row',
    },
    operationalRangeWithBorder: {
        width: '100%',
        borderBottom: '#2a3740',
        borderBottomWidth: 1.2,
        flexDirection: 'row',
    },
    dataItemTitle: {
        fontSize: FONT_SIZES.TEXT,
        width: '25%',
        padding: 10,
        paddingLeft: 20,
        justifyContent: 'center',
        fontFamily: 'Bold',
    },
    operatingRadius: {
        padding: 10,
        paddingLeft: 20,
        fontFamily: 'Bold',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

const kmFormatter = (n: number | undefined) => compose<number>(withUnit(KILOMETERS), formatIntegerNumber)(n);

export default function OperationalRange({ data }: { data: HydratedEntity }) {
    const opconData = getSignal(data, 'operatingConditionResponse', {});
    const route = _.get(opconData, 'route.value', {});
    const range = _.get(opconData, 'range.value', {});
    const evaluatedMileage = getSignal(range, 'evaluatedMileage', 0);
    const mileageFromPerformance = getSignal(data, 'mileage', 0);
    const diameter = _.get(range, 'diameter');
    const formattedDiameter = kmFormatter(diameter);
    const roads = _.get(route, 'roads', []);
    const totalMileage = _.sumBy(roads, 'mileage');
    const roadsByCountryAndType = groupRoadsByCountry(roads);

    return (
        <DisplayWarningConditionally evaluatedMileage={evaluatedMileage} totalMileage={mileageFromPerformance}>
            <View>
                {roadsByCountryAndType.map((entry, idx) => (
                    <View
                        key={idx}
                        style={
                            idx % 2 === 0 || idx === roadsByCountryAndType.length - 1
                                ? styles.operationalRangeWithBorder
                                : styles.operationalRange
                        }
                        data-test="country"
                    >
                        <View style={styles.dataItemTitle}>
                            <Text>{entry.country.toUpperCase()}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', flex: 1, flexWrap: 'wrap' }} wrap={true} data-test="roads">
                            {entry.roads.map((road, idx) => {
                                const { urban, nonUrban } = getMileageSplitIntoUrbanNonUrban(road.roads);
                                const urbanPercent = toPercent(urban, totalMileage, 0);
                                const nonUrbanPercent = toPercent(nonUrban, totalMileage, 0);
                                const combinedPercentage = urbanPercent + nonUrbanPercent;
                                return (
                                    <View key={idx} style={{ margin: 10 }}>
                                        <Text style={styles.title}>
                                            {road.name || (
                                                <FormattedMessage id={'sidepanel.circulationType.unknownRoadType'} />
                                            )}
                                        </Text>
                                        <Text style={{ fontFamily: 'Bold' }}>
                                            <WithUnit unit={ValidUnit.PERCENTAGE}>
                                                {combinedPercentage < 1 ? (
                                                    '<1'
                                                ) : (
                                                    <FormattedNumber
                                                        value={combinedPercentage}
                                                        maximumFractionDigits={0}
                                                    />
                                                )}
                                            </WithUnit>
                                        </Text>
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                ))}
                <View data-test="operatingRadius" style={styles.operatingRadius}>
                    <Text style={{ paddingRight: 10 }}>
                        <FormattedMessage id="operatingRadius" />
                    </Text>

                    <Image src={stopOverImage} style={{ width: 10, height: 10, marginRight: 4 }} />
                    <Text>{formattedDiameter}</Text>
                </View>
            </View>
        </DisplayWarningConditionally>
    );
}
