import { Text, View } from '@react-pdf/renderer';
import { getRatingCSSColor } from '@utils/rating';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../../columns/createColumn';
import ProgressBar from '../components/ProgressBar';
import ProgressTitle from '../components/ProgressTitle';
import ForesightedDrivingIcon from './ForesightedDrivingIcon';

const ForesightedDrivingTitleBar = ({ title, value }: { title: Column; value: number | null | undefined }) => {
    const ratingColor = getRatingCSSColor(value).hexColor;
    return (
        <View style={{ flexDirection: 'row' }}>
            <View
                style={{
                    textAlign: 'left',
                    flexDirection: 'row',
                    width: '25%',
                }}
            >
                <ForesightedDrivingIcon id={title.key} />
                <Text style={{ fontFamily: 'Bold' }}>
                    <FormattedMessage id={title.labelId} />
                </Text>
            </View>

            <View
                style={{
                    height: 10,
                    width: '60%',
                    marginTop: 1,
                    marginRight: 10,
                }}
            >
                <ProgressBar color={ratingColor} progress={value || 0} />
            </View>
            <ProgressTitle color={ratingColor} value={title.formatter(typeof value === 'number' ? value : '-')} />
        </View>
    );
};

export default ForesightedDrivingTitleBar;
