const roadTypes = [
    'ALB 1 Europian',
    'ALB 2 Autostraden',
    'ALB 3 Rruge Shteterore',
    'ALB 4 Rruge te Rrethi',
    'ALB 5 Rruge Komunale',
    'AND 3 Carretera General',
    'AND 4 Carretera Secundaria',
    'ARE 1 Mashreq Roads, Emirates Roads',
    'ARE 2 Dubai Roads, Sharjah Roads, Fujairah Roads',
    'ARG 1 Ruta National',
    'ARG 2 Ruta Provincial',
    'AUS 1 National',
    'AUS 2 State',
    'AUS 3 Metroad',
    'AUS 4 Tourist Drive',
    'AUS 5 National Highway',
    'AUS 6 State Road Network',
    'AUT 1 Europastrassen',
    'AUT 2 Autobahnen',
    'AUT 3 Schnellstrassen',
    'AUT 4 Bundesstrassen, Landesstrassen',
    'AUT 5 Landesstrassen, Bezirkstrassen, Privatstrassen des Bundes',
    'AZE 1 Avropa, Asiya',
    'AZE 2 Magistral',
    'AZE 3 Respublika',
    'AZE 4 Yerli',
    'BDI 1 National',
    'BDI 3 Provincial',
    'BEL 1 European',
    'BEL 2 Autosnelwegen',
    'BEL 3 Gewestwegen',
    'BEL 6 Bretelle',
    'BEN 1 Route Nationale Inter-Etat',
    'BEN 2 Route Nationale',
    'BGD 1 National',
    'BGD 2 Regional',
    'BGD 3 Zilla',
    'BGR 1 Evropeyski pat',
    'BGR 2 Magistrala',
    'BGR 3 Parvoklasen pat, Vtoroklasen pat',
    'BGR 4 Tretoklasen pat',
    'BHR 1 Mashreq Roads',
    'BIH 1 European',
    'BIH 2 Autocesta',
    'BIH 3 Magistralni put',
    'BIH 4 Regionalni put',
    'BLM 4 Route Departementale',
    'BLR 1 European',
    'BLR 2 Magistrale',
    'BLR 3 Republic Road',
    'BRA 1 Federal',
    'BRA 2 Estadual',
    'BWA 1 National',
    'BWA 2 National',
    'CAN 1 Primary Provincial Route/Autoroute',
    'CAN 2 Secondary Provincial Route/Autoroute',
    'CAN 3 Regional, County, District',
    'CHE 1 European',
    'CHE 2 Autobahnen, Autostrassen, Autoroutes, Semi-Autoroutes, Autostrade, Semiautostrade',
    'CHE 3 Hauptstrassen, Routes Principales, Strade Principali',
    'CHL 1 Primary National',
    'CHL 2 Secondary National',
    'CHL 3 Primary Regional',
    'CHL 4 Secondary Regional',
    'CHN 1 Highways, Urban Highways',
    'CHN 2 National Road (GuoDao)',
    'CHN 3 Provincial Road',
    'CHN 4 County Road',
    'CHN 5 Village Road',
    'COL 1 Carreteras Nacionales',
    'COL 2 Carreteras Departamentales',
    'CRI 1 Ruta Primaria',
    'CRI 2 Ruta Secundaria',
    'CZE 1 European',
    'CZE 2 Dalnice, Rychlostni komunikace',
    'CZE 3 Silnice I.tridy',
    'CZE 4 Silnice II.tridy',
    'CZE 5 Silnice III.tridy',
    'DNK 1 Europavej',
    'DNK 2 Primaerrute',
    'DNK 3 Sekendaerrute',
    'DZA 1 Autoroute',
    'DZA 2 Route Nationale',
    'DZA 3 Chemin de Wilaya',
    'EGY 1 Mashreq roads',
    'ESP 1 European',
    'ESP 2 Autopista',
    'ESP 3 Carretera Nacional',
    'ESP 4 Autonomica 1st order',
    'ESP 5 Autonomica 2nd order',
    'ESP 6 Autonomica Local',
    'EST 1 European',
    'EST 2 Pohimaantee',
    'EST 3 Tugimaantee',
    'EST 4 Korvalmaantee',
    'FIN 1 Eurooppatie',
    'FIN 2 Valtatie',
    'FIN 3 Kantatie',
    'FIN 4 Seututie',
    'FIN 5 Muu Yleinen Tie',
    'FRA 1 European',
    'FRA 2 Autoroute',
    'FRA 3 Route Territoriale',
    'FRA 4 Route Departementale, Departementale Strategique, Route Métropolitaine',
    'FRA 5 Route Communale, Route Vicinale, Voie Rurale, Voie Communale, Chemin Rural, Chemin Vicinal, Chemin Communal, Sentier Rural or Sente Rurale',
    'GBR 1 Motorways',
    'GBR 2 A-Roads',
    'GBR 3 B-Roads',
    'GBR 4 C-Roads',
    'DEU 1 European',
    'DEU 2 Autobahn',
    'DEU 3 Bundesstrasse',
    'DEU 4 Landesstrassen, Staatsstrassen',
    'DEU 5 Kreisstrassen',
    'DEU 6 Forststrassen',
    'GIN 1 Route Nationale',
    'GLP 3 Route Nationale',
    'GLP 4 Route Departementale',
    'GRC 1 European',
    'GRC 2 National Roads',
    'GTM 1 Ruta Centroamericana',
    'GTM 2 Ruta Nacional',
    'GTM 3 Ruta Departamentall',
    'GTM 4 Camino Rural',
    'GUF 3 Route Nationale',
    'GUF 4 Route Departementale',
    'HKG 1 Federal',
    'HRV 1 European',
    'HRV 2 Autocesta',
    'HRV 3 Drzavna Cesta',
    'HRV 4 Zupanijska Cesta',
    'HRV 5 Lokalna cesta',
    'HUN 1 European',
    'HUN 2 Autopalya',
    'HUN 3 Orszagut',
    'HUN 4 Orszagut',
    'IMN 2 A-Roads',
    'IMN 3 B-Roads',
    'IMN 4 C-Roads',
    'IND 1 National Highway, Asian Highway, National Expressways',
    'IND 2 State',
    'IND 3 Major District Road',
    'IRL 1 Motorways',
    'IRL 2 National',
    'IRL 3 Regional',
    'IRL 4 Local',
    'ISR 1 National Roads',
    'ISR 2 Inter-City Roads',
    'ISR 3 Regional Roads',
    'ISR 4 Local Roads',
    'ITA 1 European',
    'ITA 2 Autostrada',
    'ITA 3 Strada Statale',
    'ITA 4 Strada Regionale',
    'KAZ 1 European Road',
    'KAZ 2 International Road',
    'KAZ 3 The Republican Road',
    'KAZ 4 Regional Road',
    'KEN 1 International Trunk Roads',
    'KEN 2 National Trunk Roads',
    'KEN 3 Primary Roads',
    'KEN 4 Secondary Roads',
    'KEN 5 Minor Roads',
    'KOR 1 Express Highway',
    'KOR 2 Urban Expressway',
    'KOR 3 National Road',
    'KOR 4 Regional Road',
    'KOS 1 European',
    'KOS 2 Autoudha',
    'KOS 3 Magistralni put',
    'KOS 4 Regionalni put',
    'KWT 1 Mashreq Roads',
    'KWT 3 Ring Road, State Route',
    'LBN 1 Mashreq Road',
    'LIE 3 Hauptstrassen',
    'LKA 1 Expressway',
    'LSO 1 National',
    'LTU 1 European',
    'LTU 2 Magistraliniai Keliai',
    'LTU 3 Krasto Keliai',
    'LTU 4 Rajoniniai Keliai',
    'LUX 1 European',
    'LUX 2 Autoroutes, Voies Express',
    'LUX 3 Routes Nationales',
    'LUX 4 Chemins Repris',
    'LVA 1 European',
    'LVA 2 Galvenie autoceli',
    'LVA 3 Skiras autoceli (regionalie autoceli)',
    'LVA 4 Skiras autoceli (vietejie autoceli)',
    'MAR 1 Autoroute',
    'MAR 2 Route Nationale',
    'MAR 3 Route Regionale',
    'MAR 4 Route Provinciale',
    'MCO 4 Routes Departementale',
    'MDA 1 Drumuri Europene',
    'MDA 2 Drumuri Magistrale',
    'MDA 3 Drumuri Republicane',
    'MDA 4 Drumuri Locale',
    'MEX 1 Freeway, Tollway',
    'MEX 2 Freeway, Tollway',
    'MKD 1 International Road',
    'MKD 2 Highway, Express Road',
    'MKD 3 Regional Road',
    'MLI 1 National',
    'MLI 2 Regional',
    'MLI 3 Local',
    'MLI 4 Comunal',
    'MNE 1 European',
    'MNE 3 Magistralni Put',
    'MNE 4 Regionalni Put',
    'MOZ 1 National Road',
    'MOZ 2 Regional',
    'MTQ 3 Autoroute',
    'MTQ 4 Route Departementale',
    'MYS 1 National Toll Highway',
    'MYS 2 Federal',
    'MYS 3 Local',
    'NAM 1 National',
    'NAM 2 National',
    'NAM 3 District',
    'NGA 1 National Roads',
    'NLD 1 European',
    'NLD 2 Autosnelwegen',
    'NLD 3 Nationalewegen',
    'NLD 6 Stadsroutenummers',
    'NOR 1 European',
    'NOR 2 National Roads',
    'NOR 3 County Roads Primary',
    'NOR 4 County Road Secondary',
    'NPL 1 National',
    'NPL 2 Feeder Road',
    'NZL 1 State Highway',
    'NZL 2 Urban Route',
    'OMN 1 Mashreq Road',
    'PAK 1 Motorways',
    'PAK 2 Highways',
    'PAN 1 Ruta Nacional Principal',
    'PAN 2 Ruta Nacional Secundaria',
    'PER 1 Carretera Nacional',
    'PER 2 Carretera Departamental',
    'POL 1 Trasa Europejska',
    'POL 2 Autostrada',
    'POL 3 Droga Ekspresowa',
    'POL 4 Droga Krajowa',
    'POL 5 Droga Wojewodzka',
    'POL 6 Droga Powiatowa',
    'PRI 1 Autopista',
    'PRI 2 Carretera Nacional',
    'PRI 3 Carretera Nacional',
    'PRI 4 Carretera Nacional',
    'PRT 1 European',
    'PRT 2 Auto-Estrada',
    'PRT 3 Itinerario Principal, Via Rapida, Via Expresso',
    'PRT 4 Itinerario Complementar',
    'PRT 5 Estrada Nacional, Estrada Regional',
    'PRT 6 Estrada Municipal',
    'PRY 1 National Roads',
    'PSE 1 National Network',
    'PSE 2 Intercity Roads',
    'PSE 3 Regional Roads',
    'PSE 4 Local Roads',
    'QAT 1 Mashreq Roads',
    'REU 3 Route Nationale',
    'REU 4 Route Departementale',
    'ROU 1 European',
    'ROU 2 Autostrada',
    'ROU 3 Drum National',
    'ROU 4 Drum Judetean',
    'ROU 5 Drum Comunal',
    'RUS 1 Asian Highway',
    'RUS 2 Federal Road',
    'RUS 3 Other Federal Road, Regional Road (Crimea)',
    'RUS 4 Regional Road',
    'RUS 5 Regional Road (only Crimea)',
    'RWA 1 National',
    'RWA 3 District',
    'SEN 1 Route Nationale',
    'SEN 2 Route Regionale',
    'SGP 1 Expressway',
    'SRB 1 European',
    'SRB 2 State roads Iа category (highway)',
    'SRB 3 Magistralni put / State roads Ib category',
    'SRB 4 Regionalni put / State roads IIа category',
    'SRB 5 State roads IIb category',
    'SVK 1 European',
    'SVK 2 Dialnice, Rychlostne komunikacie',
    'SVK 3 Cesty I.triedy',
    'SVK 4 Cesty II.triedy',
    'SVK 5 Cesty III.triedy',
    'SVN 1 Evropa Cesta',
    'SVN 2 Avtocesta',
    'SVN 3 Hitra Cesta',
    'SVN 4 Glavna Cesta',
    'SVN 5 Regionalna',
    'SWE 1 Europavaeg',
    'SWE 2 Riksvaeg',
    'SWE 3 Laensvaeg',
    'SWZ 1 National',
    'SWZ 2 National',
    'TGO 1 Route Nationale',
    'THA 1 Major/National/Special Highway',
    'THA 2 Regional/Special Highway',
    'THA 3 Secondary Highway',
    'THA 4 Intra-province Highway',
    'TUN 1 Autoroute',
    'TUN 2 Route Nationale',
    'TUN 3 Route Regionale',
    'TUN 4 Route Locale',
    'TUR 1 European',
    'TUR 2 Otoyol',
    'TUR 3 Devlet Yolu',
    'TUR 4 Il Yolu',
    'TWN 1 National',
    'TWN 2 Provincial Highway',
    'TWN 3 Provincial',
    'TWN 4 County Highway',
    'TWN 5 Village Highway',
    'TZA 1 National',
    'TZA 2 National',
    'UKR 1 European Road',
    'UKR 2 International Road',
    'UKR 3 National Road',
    'UKR 4 Regional Road',
    'UKR 5 Territorial Road',
    'USA 1 Interstate',
    'USA 2 Federal',
    'USA 3 State',
    'USA 4 County',
    'VAT 4 Strada Provinciale',
    'VIR 4 County',
    'VNM 1 Quoc Lo',
    'VNM 2 Tinh Lo',
    'WEB 1 National Network',
    'WEB 2 Intercity Roads',
    'WEB 3 Regional Roads',
    'WEB 4 Local Roads',
    'ZAF 1 National',
    'ZAF 2 Provincial',
    'ZAF 3 Metropolitan',
].reduce((acc, el) => {
    const [, country, type, name] = el.match(/([A-Z]{3})\s*(\d*)\s*(.*)/) || [];
    return { ...acc, [`${country}-${type}`]: name };
}, {} as Record<string, string>);

export const getRoadTypeLabel = (roadType: string) => roadTypes[`${roadType.toUpperCase()}`];
