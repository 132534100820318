import { Report } from '@api/index';
import { getLocale } from '@common/lang/selectors';
import cn from 'classnames';
import { SyntheticEvent, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../setup/store';
import { downloadReport } from './reducer';

function DownloadButton({ name, generatedReport }: { name: string; generatedReport?: Report }) {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const locale: string = useSelector(getLocale);

    const onDownloadStart = (e: SyntheticEvent<HTMLButtonElement>) => {
        setIsLoading(true);
        e.stopPropagation();
        try {
            (document?.activeElement as HTMLElement).blur();
        } catch (e) {
            /* There seems to be a problem when button has focus & spinnner */
        }

        if (generatedReport && generatedReport.generatedAt) {
            dispatch(downloadReport(generatedReport.reportId)).then(res => {
                const url = URL.createObjectURL(res.payload as Blob | MediaSource);
                const element = document.createElement('a');
                element.setAttribute('href', url);
                element.setAttribute(
                    'download',
                    `${name}-${generatedReport.generatedAt?.toLocaleDateString(locale)}.pdf`
                );
                element.click();
                setIsLoading(false);
            });
        }
    };

    return generatedReport && generatedReport.generatedAt ? (
        <button
            type="button"
            className={cn('btn btn-primary btn-xs', isLoading ? 'btn-loading-overlay' : '')}
            data-test="download-report"
            onClick={onDownloadStart}
        >
            <span className="rioglyph rioglyph-download" aria-hidden="true" />
            <FormattedMessage
                id="reports.reportDownloadFrom"
                values={{ date: <FormattedDate value={generatedReport.generatedAt} /> }}
            />
        </button>
    ) : (
        <FormattedMessage id="reports.noNewReports" />
    );
}
export default DownloadButton;
