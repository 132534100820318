import TabbableContent from '@components/TabbableContent';
import { FormattedMessage } from 'react-intl';

import CalculationParamsSettings from './calculationParamsSettings/CalculationParams';

const AdministrationEntry = () => {
    const tabs = [
        {
            title: (
                <span className="text-size-18">
                    <FormattedMessage id="administration.rating" />
                </span>
            ),
            content: <CalculationParamsSettings key="cp" />,
        },
    ];

    return (
        <div style={{ margin: '0 auto' }} className="container-fluid fluid-default" data-test="AdministrationEntry">
            <TabbableContent tabs={tabs} />
        </div>
    );
};

export default AdministrationEntry;
