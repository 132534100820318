import { getSignal } from '@api/helpers/transformer';
import SimpleErrorState from '@components/SimpleErrorState';
import usePerformData from '@data/hooks/usePerformData';
import { Loadable, LoadableType } from '@data/loadable';
import { EntityType } from '@features/foresightedDriving/types';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import StatusBar from '@rio-cloud/rio-uikit/StatusBar';
import toPercent from '@utils/toPercent';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../columns/createColumn';
import { accDistance, noAccDistance, noPccaDistance, pccaDistance } from '../../columns/distance';
import { accTime, noAccTime, noPccaTime, pccaTime } from '../../columns/time';
import { HydratedEntity, PerformSegmentBy, RawDriver } from '../../types';
import { ASSISTANCE_SYSTEMS_REQUEST_ATTRIBUTES } from './queries';

const AssistanceSystem = ({
    entity,
    headerKey,
    distanceColumn,
    noDistanceColumn,
    timeColumn,
    noTimeColumn,
}: {
    entity: HydratedEntity;
    headerKey: string;
    distanceColumn: Column;
    noDistanceColumn: Column;
    timeColumn: Column;
    noTimeColumn: Column;
}) => {
    const formatColumn = (column: Column) => {
        return column.formatter(column.valueExtractor(entity), entity);
    };

    const distanceColValue = distanceColumn.valueExtractor(entity) as number;
    const noDistanceColValue = noDistanceColumn.valueExtractor(entity) as number;
    const totalDistance = distanceColValue + noDistanceColValue;
    const percentage = toPercent(distanceColValue, totalDistance, 0);

    const progressWithValues = (
        <div className="width-70pct">
            <div className="display-flex justify-content-between padding-bottom-5">
                <div className="display-flex flex-column">
                    <span className="line-height-16 text-medium">
                        <FormattedMessage id="assistanceSystems.enabled" />
                    </span>
                    <span className="line-height-16">
                        <span>{formatColumn(distanceColumn)}</span> <span>{formatColumn(timeColumn)}</span>
                    </span>
                </div>
                <div className="display-flex flex-column text-right">
                    <span className="line-height-16 text-medium">
                        <FormattedMessage id="assistanceSystems.disabled" />
                    </span>
                    <span className="line-height-16">
                        <span>{formatColumn(noDistanceColumn)}</span> <span>{formatColumn(noTimeColumn)}</span>
                    </span>
                </div>
            </div>
            <StatusBar size="small" progress={[{ percentage: percentage, color: 'text-color-highlight' }]} />
        </div>
    );

    return (
        <div className="padding-bottom-10" data-test={headerKey}>
            <div className="panel panel-default padding-10 display-flex align-items-center justify-content-between gap-15">
                <span className="text-size-h5 text-bold width-20pct padding-right-5">
                    <FormattedMessage id={headerKey} />
                </span>
                <span className="text-size-h4 text-color-highlight text-bold">{percentage}%</span>
                {progressWithValues}
            </div>
        </div>
    );
};

export const AssistanceSystems = ({
    data,
    enablePCCA = false,
}: {
    data: LoadableType<HydratedEntity>;
    enablePCCA?: boolean;
}) => {
    const mappedData = Loadable.map(data, entity => {
        const hasACCInfo = () =>
            !_.isUndefined(getSignal(entity, 'accDistance', undefined)) ||
            !_.isUndefined(getSignal(entity, 'noAccDistance', undefined));
        const hasPCCAInfo = () =>
            !_.isUndefined(getSignal(entity, 'pccaDistance', undefined)) ||
            !_.isUndefined(getSignal(entity, 'noPccaDistance', undefined));
        const hasAssistanceSystemsInfo = !_.isEmpty(entity) && (hasACCInfo() || (enablePCCA && hasPCCAInfo()));

        if (!entity || !hasAssistanceSystemsInfo)
            return <EmptyState headline={<FormattedMessage id="noData" />} fullWidth />;

        return (
            <div>
                <AssistanceSystem
                    entity={entity}
                    headerKey="assistanceSystems.accUsage"
                    distanceColumn={accDistance}
                    noDistanceColumn={noAccDistance}
                    timeColumn={accTime}
                    noTimeColumn={noAccTime}
                />
                {enablePCCA && (
                    <AssistanceSystem
                        entity={entity}
                        headerKey="assistanceSystems.pccaUsage"
                        distanceColumn={pccaDistance}
                        noDistanceColumn={noPccaDistance}
                        timeColumn={pccaTime}
                        noTimeColumn={noPccaTime}
                    />
                )}
            </div>
        );
    });

    return Loadable.cata(mappedData, success, failure, loading, unknown);
};

const success = (d: LoadableType<EntityType>) => <>{d}</>;
const failure = () => <SimpleErrorState fullWidth />;
const loading = () => <Spinner />;
const unknown = () => null;

const isMatchingEntityForDriver = (driverId: string | null) => (entity: EntityType) =>
    (entity?.drivers?.length === 0 && driverId === null) ||
    (entity?.drivers?.length === 1 && driverId === (entity.drivers as RawDriver[])?.[0]?.driverId);

const isMatchingEntityForVehicle = (vehicleId: string) => (entity: EntityType) =>
    entity?.vehicles?.length === 1 && vehicleId === entity.vehicles[0].vehicleId;

export const AssistanceSystemsByVehicle = ({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    enablePCCA = false,
}: {
    vehicleIds?: string[];
    driverIds?: (string | null)[];
    start: Date;
    end: Date;
    enablePCCA?: boolean;
}) => {
    const requestDataForOneDriver = driverIds.length === 1;
    const segmentBy = requestDataForOneDriver ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.vehicle;

    const assistanceSystemsData = usePerformData(ASSISTANCE_SYSTEMS_REQUEST_ATTRIBUTES, {
        variables: { start, end, vehicleIds, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<EntityType[]>;

    const desiredData = Loadable.map(assistanceSystemsData, (entities: EntityType[]) =>
        requestDataForOneDriver ? entities.find(isMatchingEntityForDriver(driverIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return <AssistanceSystems data={desiredData} enablePCCA={enablePCCA} />;
};

export const AssistanceSystemsByDriver = ({
    vehicleIds = [],
    driverIds = [],
    allowedVehicles,
    start,
    end,
    enablePCCA = false,
}: {
    vehicleIds?: string[];
    driverIds: string[];
    allowedVehicles: string[];
    start: Date;
    end: Date;
    enablePCCA?: boolean;
}) => {
    const requestDataForOneVehicle = vehicleIds.length === 1;
    const segmentBy = requestDataForOneVehicle ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.driver;

    const assistanceSystemsData = usePerformData(ASSISTANCE_SYSTEMS_REQUEST_ATTRIBUTES, {
        variables: { start, end, driverIds, vehicleIds: allowedVehicles, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<EntityType[]>;

    const desiredData = Loadable.map(assistanceSystemsData, entities =>
        requestDataForOneVehicle ? entities.find(isMatchingEntityForVehicle(vehicleIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return <AssistanceSystems data={desiredData} enablePCCA={enablePCCA} />;
};
