import _ from 'lodash';
import moment from 'moment';

import { AuthToken, PerformQueryPayload, StatisticsReport } from '../types';
import { fetchEntities } from './api';
import { decodePerformRequest, PerformApiResponseType } from './decoder/performDecoder';
import calculateAverage from './helpers/calculateAverage';

// TODO: move to decoder.
const calculateStatistics = (rawResponse: PerformApiResponseType): StatisticsReport[] => {
    const preCalculatedAverages = _.get(rawResponse, 'values.total_segment', []);
    const groupedValues = rawResponse.values.attributes
        .map(attribute => ({
            key: attribute.key,
            values: rawResponse.values.segments.map(segment => segment[attribute.key]),
        }))
        .filter(response => response.values.length);

    return groupedValues.map(({ key, values }) => {
        const average = _.get(preCalculatedAverages, key, calculateAverage(key, groupedValues, values));
        return {
            attribute: _.camelCase(key),
            min: _.min(values),
            max: _.max(values),
            average,
        };
    });
};

export async function fetchPerform(baseUrl: string, payload: PerformQueryPayload, authToken: AuthToken) {
    const rawResponse = ((await fetchEntities(
        baseUrl,
        'aggregation-reports',
        {
            ...payload,
            from: regionZoneDateTime(payload.from),
            to: regionZoneDateTime(payload.to),
        },
        authToken
    )) as unknown) as PerformApiResponseType; //TODO: change api response to correctly assume type.
    const entities = decodePerformRequest(rawResponse);

    return {
        entities,
        statistics: calculateStatistics(rawResponse),
    };
}

export const regionZoneDateTime = (date: Date) => {
    const timeZoneRegion = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return `${moment(date).toISOString(true)}[${timeZoneRegion}]`;
};
