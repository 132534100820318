import { NumberValue } from 'd3-scale';
import { useContext } from 'react';

import AnimatedRect from './AnimatedRect';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';

const BAR_MAX_WIDTH = 40;

export function Bar({
    colorSchema,
    minHeight = 0,
    width,
    xOffsetWithMargin = false,
    saveBarWidth,
}: {
    colorSchema: (d: DataEntry) => string;
    minHeight?: number;
    xOffsetWithMargin?: boolean;
    width?: number;
    saveBarWidth?: (barWidth: number) => void;
}): JSX.Element {
    const {
        xScale: x,
        leftYScale: y,
        data,
        dimensions: { height, margin },
    } = useContext(GraphContext);

    const getXValue = (value: NumberValue) => x(value as number) || 0;

    let barWidth: number;
    if (width === undefined) {
        barWidth = BAR_MAX_WIDTH;
        if (data.length > 1) {
            barWidth = Math.min((getXValue(data[1].x) - getXValue(data[0].x)) * 0.7, BAR_MAX_WIDTH);
        }
    } else {
        barWidth = width;
    }

    if (saveBarWidth) {
        saveBarWidth(barWidth);
    }

    return (
        <g>
            {(data as DataEntry[]).reduce((acc: JSX.Element[], d, index) => {
                if (isNaN(d.leftUpY)) {
                    return acc;
                }

                const hasValue = d.leftUpY > 0;
                const barHeight = hasValue ? height - y(d.leftUpY) : minHeight;
                const fill = colorSchema(d);
                const yOffset = hasValue ? y(d.leftUpY) : height - minHeight;
                const xOffset = xOffsetWithMargin ? getXValue(d.x) + margin : getXValue(d.x) - barWidth / 2;

                acc.push(
                    <AnimatedRect
                        key={d.x.toString()}
                        fill={fill as string}
                        width={barWidth}
                        x={xOffset}
                        y={yOffset}
                        height={barHeight}
                        dataTest={`bar-${index}`}
                    />
                );
                return acc;
            }, [])}
        </g>
    );
}
