import { CalculationParams } from '@features/settings/reducer';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import Brake from '../assets/brake.png';
import Coasting from '../assets/coasting.png';
import CruiseControl from '../assets/cruise-control.png';
import ExclamationSign from '../assets/exclamation-sign.png';
import AccelerationPedal from '../assets/kickdown.png';
import Overspeed from '../assets/speed.png';

const styles = StyleSheet.create({
    calculationRatingsWrap: {
        position: 'absolute',
        bottom: 60,
        left: 20,
        right: 20,
        textAlign: 'center',
    },
    calculationRatings: {
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        display: 'flex',
        backgroundColor: '#DADADA',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        height: 60,
    },
    weightValue: {
        fontFamily: 'Bold',
        fontSize: 8,
    },
    weightItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 5,
        marginRight: 20,
    },
    overspeedWarning: { flexDirection: 'row', alignItems: 'center' },
    weightTitle: {
        fontSize: 8,
    },
    icon: {
        marginRight: 2,
        width: 10,
    },
    exclamationSign: {
        width: 9,
        height: 9,
        marginRight: 3,
        marginLeft: 5,
    },
    noteLabel: {
        fontSize: 8,
        fontFamily: 'Bold',
    },
});

const checkWeight = (weight: number) => {
    if (weight === 0) return <FormattedMessage id="calculationParams.off" />;
    return `${weight}%`;
};

export default function CalculationParameters({
    calculationParams,
    isOverspeedThresholdChanged,
    isDetail = false,
    isElectric = false,
}: {
    calculationParams: CalculationParams;
    isOverspeedThresholdChanged: boolean;
    isDetail: boolean;
    isElectric?: boolean;
}) {
    const calculationParamsIcons = (
        <>
            {!isElectric && (
                <View style={styles.weightItem}>
                    <View style={styles.icon}>
                        <Image src={Coasting} />
                    </View>
                    <Text style={styles.weightTitle}>
                        {!isDetail && (
                            <>
                                <FormattedMessage id="discipline.coasting" />
                                {': '}
                            </>
                        )}
                        <Text data-test="weight-value" style={styles.weightValue}>
                            {checkWeight(calculationParams.coastingRating.weight)}
                        </Text>
                    </Text>
                </View>
            )}
            {!isElectric && (
                <View style={styles.weightItem}>
                    <View style={styles.icon}>
                        <Image src={AccelerationPedal} />
                    </View>
                    <Text style={styles.weightTitle}>
                        {!isDetail && (
                            <>
                                <FormattedMessage id="discipline.accelerationPedal" />
                                {': '}
                            </>
                        )}
                        <Text data-test="weight-value" style={styles.weightValue}>
                            {checkWeight(calculationParams.accelerationPedalRating.weight)}
                        </Text>
                    </Text>
                </View>
            )}
            {!isElectric && (
                <View style={styles.weightItem}>
                    <View style={styles.icon}>
                        <Image src={Brake} />
                    </View>
                    <Text style={styles.weightTitle}>
                        {!isDetail && (
                            <>
                                <FormattedMessage id="discipline.braking" />
                                {': '}
                            </>
                        )}
                        <Text data-test="weight-value" style={styles.weightValue}>
                            {checkWeight(calculationParams.brakingRating.weight)}
                        </Text>
                    </Text>
                </View>
            )}
            <View style={styles.weightItem}>
                <View style={styles.icon}>
                    <Image src={CruiseControl} />
                </View>
                <Text style={styles.weightTitle}>
                    {!isDetail && (
                        <>
                            <FormattedMessage id="discipline.cruiseControl" />
                            {': '}
                        </>
                    )}
                    <Text data-test="weight-value" style={styles.weightValue}>
                        {checkWeight(calculationParams.cruiseControlRating.weight)}
                    </Text>
                </Text>
            </View>
            <View style={styles.weightItem}>
                <View style={styles.icon}>
                    <Image src={Overspeed} />
                </View>
                <Text style={styles.weightTitle}>
                    {!isDetail && (
                        <>
                            <FormattedMessage id="discipline.overspeed" />
                            {': '}
                        </>
                    )}
                    <Text data-test="weight-value" style={styles.weightValue}>
                        {checkWeight(calculationParams.overSpeedRating.weight)}
                    </Text>
                </Text>
            </View>
        </>
    );

    const calculationParamsNote = (
        <>
            <Text style={styles.noteLabel}>
                <FormattedMessage id="calculationParams.note.label" />
            </Text>
            <Text style={styles.weightTitle}>
                {!isDetail && <FormattedMessage id="calculationParams.note.newDescription" />}
                {isDetail && <FormattedMessage id="calculationParams.note.detail.newDescription" />}
            </Text>
            {isDetail && calculationParamsIcons}
        </>
    );

    const calculationParamsDescription = (
        <>
            <Text style={styles.noteLabel}>
                <FormattedMessage id="calculationParams.overspeed.label" />
            </Text>
            <Text style={styles.weightTitle}>
                <FormattedMessage id="calculationParams.overspeed.newDescription" />
            </Text>
        </>
    );

    const overspeedThresholdChanged = (
        <View data-test="overspeed-threshold" style={styles.overspeedWarning}>
            <View style={styles.exclamationSign}>
                <Image src={ExclamationSign} />
            </View>
            <Text style={styles.weightTitle}>
                <Text style={styles.weightValue}>
                    <FormattedMessage id="multipleOverspeedThresholds.note.label" />
                </Text>{' '}
                <FormattedMessage id="multipleOverspeedThresholds.note.message" />
            </Text>
        </View>
    );

    return (
        <View style={styles.calculationRatingsWrap}>
            <View style={styles.calculationRatings}>
                <View fixed style={styles.calculationRatings}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>{calculationParamsNote}</View>
                    {!isDetail && (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>{calculationParamsIcons}</View>
                    )}
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        {isOverspeedThresholdChanged && overspeedThresholdChanged}
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        {!isOverspeedThresholdChanged && calculationParamsDescription}
                    </View>
                </View>
            </View>
        </View>
    );
}
