import { StatisticsReport } from '@api/models/types';
import { getColumn } from '@utils/columns';
import { getSummaryDieselAndElectricValues, getSummaryValue } from '@utils/getSummaryValue';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { fuelAndElectricAverageIdlingConsumption, fuelEfficiency, idlingConsumption } from '../../columns/consumption';
import { Column } from '../../columns/createColumn';
import { fuelAndElectricIdlingTime, idlingTime, idlingTimeBev } from '../../columns/time';
import { FUEL_ELECTRIC_EFFICIENCY } from '../../constants/units';
import Chevron from '../Chevron';
import Panel, { PanelContent, PanelHeader } from '../Panel';
import SkeletonLoader from '../SkeletonLoader';
import Content from './Content';
import {
    Data,
    DieselAndElectric,
    Errored,
    Formatter,
    Loaded,
    Loading,
    Section,
    Status,
    SummaryStatus,
    Values,
} from './types';

type ElectricStats =
    | (StatisticsReport & { values?: Values; recuperationValues?: Values })
    | { values?: Values; recuperationValues?: Values };

const createDefaultSection = (hasDieselAndElectricData: boolean) => (column: Column): Section => ({
    key: column.dataField,
    title: (
        <React.Fragment>
            {column.icon ? <span className={`${column.icon} text-size-20 margin-right-5`} /> : null}
            <FormattedMessage id={column.labelId} />
        </React.Fragment>
    ),
    unit: hasDieselAndElectricData
        ? {
              diesel: column.unit,
              electric: undefined,
          }
        : column.unit,
    stats: {},
    tabValue: (
        <SkeletonLoader height={'50%'} yOffset={5}>
            <span>100 kmh</span>
        </SkeletonLoader>
    ),
    formatter: hasDieselAndElectricData
        ? {
              diesel: column.formatter,
              electric: undefined,
          }
        : column.formatter,
    values: hasDieselAndElectricData
        ? {
              diesel: undefined,
              electric: {
                  values: undefined,
                  recuperationValues: undefined,
              },
          }
        : [],
});

const decorateWithValues = ({ dieselData }: { dieselData?: Data[] }) => (section: Section): Section => {
    const stats = dieselData?.find(d => d.attribute === section.key) || { values: [] };

    return {
        ...section,
        stats,
        tabValue: getSummaryValue(section, stats),
        values: {
            diesel: stats.values,
        },
    };
};

const MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS: Record<string, string> = {
    drivingConsumption: 'electricAverageDrivingConsumption',
    idlingConsumption: 'electricAverageIdlingConsumption',
    fuelConsumption: 'electricOperatingConsumption',
    operatingFuelConsumption: 'electricAverageOperatingConsumption',
    distanceFuelConsumption: 'electricAverageOperatingConsumptionRange',
    fuelEfficiency: 'electricEfficiency',
};

const RECUPERATION_MAPPING: Record<string, string> = {
    drivingConsumption: 'electricRecuperationPerKm',
    idlingConsumption: 'electricRecuperationPerHour',
    fuelConsumption: 'electricRecuperation',
    operatingFuelConsumption: 'electricRecuperationPerKm',
    distanceFuelConsumption: 'electricRecuperationKmPerKwh',
};

const GENERAL_KPIS = [
    'foresightedDrivingPercentageV2',
    'mileage',
    'averageWeight',
    'coastingRating',
    'accelerationPedalRatingV2',
    'brakingRating',
    'cruiseControlRatingV2',
    'overspeedRating',
];

const decorateWithDieselAndElectricValues = (
    {
        dieselData,
        electricData,
    }: {
        dieselData?: Data[];
        electricData?: Data[];
    },
    shouldShowBothValues: boolean,
    isDriver: boolean
) => (section: Section): Section => {
    const mappedDieselAndElectricSections = Object.keys(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS);
    const existsMappingBetweenDieselAndElectricSection = mappedDieselAndElectricSections.includes(section.key);

    const dieselStats = dieselData?.find(d => d.attribute === section.key) || {
        values: undefined,
    };

    let electricStats: ElectricStats = electricData?.find(d => {
        return existsMappingBetweenDieselAndElectricSection
            ? d.attribute === MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS[section.key]
            : d.attribute === section.key;
    }) || { values: undefined };

    if (section.key.toLowerCase().includes('consumption')) {
        const recuperationValues =
            electricData?.find(d => d.attribute === RECUPERATION_MAPPING[section.key])?.values || undefined;
        electricStats = { ...electricStats, recuperationValues };
    }

    const dieselUnit = (section.unit as DieselAndElectric<string | undefined>).diesel;
    const dieselFormatter = (section.formatter as DieselAndElectric<Formatter>).diesel;

    let electricUnit, electricFormatter;
    if (existsMappingBetweenDieselAndElectricSection) {
        electricUnit = getColumn(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS[section.key])?.unit;
        electricFormatter = getColumn(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS[section.key])?.formatter;
    } else {
        electricUnit = dieselUnit;
        electricFormatter = dieselFormatter;
    }

    let newTitle = section.title;

    const hasDieselData = dieselData?.length !== undefined;
    const hasElectricData = electricData?.length !== undefined;

    if (hasElectricData) {
        const labelIdMapping = hasDieselData
            ? {
                  [fuelEfficiency.key]: FUEL_ELECTRIC_EFFICIENCY,
                  [idlingConsumption.key]: fuelAndElectricAverageIdlingConsumption.labelId,
                  [idlingTime.key]: fuelAndElectricIdlingTime.labelId,
              }
            : {
                  [fuelEfficiency.key]: getColumn(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS[section.key])?.labelId,
                  [idlingConsumption.key]: getColumn(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_COLUMNS[section.key])?.labelId,
                  [idlingTime.key]: idlingTimeBev.labelId,
              };

        if (labelIdMapping[section.key]) {
            newTitle = (
                <React.Fragment>
                    <FormattedMessage id={labelIdMapping[section.key]} />
                </React.Fragment>
            );
        }
    }

    const isGeneralKPI = GENERAL_KPIS.includes(section.key);
    let showBothValues = shouldShowBothValues && (!isDriver || !isGeneralKPI);

    return {
        ...section,
        title: newTitle,
        unit: {
            diesel: dieselUnit,
            electric: electricUnit,
        },
        stats: { diesel: dieselStats, electric: electricStats },
        tabValue: getSummaryDieselAndElectricValues({
            diesel: {
                stats: dieselStats,
                unit: dieselUnit,
                formatter: dieselFormatter,
            },
            electric: { stats: electricStats, unit: electricUnit, formatter: electricFormatter },
            shouldShowBothValues: showBothValues,
        }),
        formatter: { diesel: dieselFormatter, electric: electricFormatter },
        values: {
            diesel: dieselStats.values,
            electric: {
                values: electricStats.values,
                recuperationValues: electricStats.recuperationValues,
            },
        },
    };
};

export const createLoading = (): Loading => ({ type: SummaryStatus.LOADING });
export const createLoadedWithDieselAndElectricData = ({
    dieselData,
    electricData,
}: {
    dieselData?: Data[];
    electricData?: Data[];
}): Loaded => ({
    type: SummaryStatus.LOADED,
    data: { dieselData, electricData },
});
export const createLoaded = ({ dieselData, electricData }: { dieselData?: Data[]; electricData?: Data[] }): Loaded => ({
    type: SummaryStatus.LOADED,
    data: { dieselData, electricData },
});
export const createError = (data?: React.ReactNode): Errored => ({ type: SummaryStatus.ERROR, data });

type Props = {
    header?: React.ReactNode;
    columns: Column[];
    entities: unknown[];
    children?: ({ activeSection }: { activeSection: Section }) => React.ReactNode;
    status: Status;
    isExpanded?: boolean;
    onHeaderClick?: () => void;
    activeTab: string | null;
    onTabChange: (s: string | null) => void;
    account?: string;
    location: string;
    hasDieselAndElectricData?: boolean;
    shouldShowBothValues?: boolean;
    highlightColor?: string;
};

// Exposed to simplify testing.
export const LoadedContent: React.FC<{
    entities: unknown[];
    children: ({ activeSection }: { activeSection: Section }) => React.ReactNode;
    isExpanded: boolean;
    onHeaderClick?: () => void;
    data: Data[] | { dieselData?: Data[]; electricData?: Data[] };
    activeTab: string | null;
    onTabChange: (s: string | null) => void;
    rawSections: Section[];
    account?: string;
    location: string;
    hasDieselAndElectricData?: boolean;
    shouldShowBothValues: boolean;
    highlightColor?: string;
}> = ({
    entities,
    children,
    rawSections,
    isExpanded,
    data,
    onTabChange,
    activeTab,
    account,
    location,
    hasDieselAndElectricData,
    shouldShowBothValues,
    highlightColor,
}) => {
    const isDriver = location === 'drivingAnalysis';

    const sections = useMemo(() => {
        return hasDieselAndElectricData
            ? rawSections.map(
                  decorateWithDieselAndElectricValues(
                      data as { dieselData: Data[]; electricData: Data[] },
                      shouldShowBothValues,
                      isDriver
                  )
              )
            : rawSections.map(decorateWithValues(data as { dieselData: Data[] }));
    }, [hasDieselAndElectricData, rawSections, data, shouldShowBothValues]);

    const activeSection = sections.find(s => s.key === activeTab)!;

    return (
        <Content
            account={account}
            location={location}
            activeTab={activeTab}
            entities={entities}
            sections={sections}
            isExpanded={isExpanded}
            setActiveTab={onTabChange}
            highlightColor={highlightColor}
        >
            {activeSection ? children({ activeSection }) : null}
        </Content>
    );
};
const Summary = ({
    header = null,
    columns = [],
    entities = [],
    children = () => null,
    status = createLoading(),
    isExpanded = true,
    onHeaderClick,
    activeTab,
    onTabChange,
    account,
    location,
    hasDieselAndElectricData = false,
    shouldShowBothValues = false,
    highlightColor,
}: Props) => {
    const defaultSections = useMemo(() => columns.map(createDefaultSection(hasDieselAndElectricData)), [
        columns,
        hasDieselAndElectricData,
    ]);
    if (!activeTab || (defaultSections.length && !_.find(defaultSections, ['key', activeTab]))) {
        activeTab = _.get(defaultSections, '[0].key', null);
    }

    let content = null;
    switch (status.type) {
        case SummaryStatus.LOADED: {
            content = (
                <LoadedContent
                    entities={entities}
                    onTabChange={onTabChange}
                    data={status.data}
                    rawSections={defaultSections}
                    activeTab={activeTab}
                    isExpanded={isExpanded}
                    account={account}
                    location={location}
                    hasDieselAndElectricData={hasDieselAndElectricData}
                    shouldShowBothValues={shouldShowBothValues}
                    highlightColor={highlightColor}
                >
                    {children}
                </LoadedContent>
            );
            break;
        }
        case SummaryStatus.LOADING: {
            const activeSection = defaultSections.find(s => s.key === activeTab);
            content = (
                <Content
                    isLoading={true}
                    activeTab={activeTab}
                    entities={entities}
                    sections={defaultSections}
                    isExpanded={isExpanded}
                    setActiveTab={onTabChange}
                    account={account}
                    location={location}
                >
                    {activeSection ? children({ activeSection }) : null}
                </Content>
            );
            break;
        }
        case SummaryStatus.ERROR:
            content = status.data as Error;
            break;
    }

    return (
        <Panel>
            <PanelHeader>
                <div
                    onClick={onHeaderClick}
                    className="display-flex justify-content-center align-items-center cursor-pointer"
                    data-test="summary-header"
                >
                    <div className="flex-1-1">{header}</div>
                    <Chevron isOpen={isExpanded} />
                </div>
            </PanelHeader>
            <PanelContent>{content}</PanelContent>
        </Panel>
    );
};

export default Summary;
