import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { configureReporting } from '../../setup/errorReporting';
import { loadSettingsSuccess as settingsLoaded, setEnablePerform3 } from '../settings/reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from '../settings/saga';
import { infoNotification } from '../ui/notificationSaga';

const { captureException } = configureReporting(window, import.meta.env);

export const SETTINGS = {
    enablePerform3: 'enablePerform3',
};

export function* clearAllSettings() {
    yield saveSettingsToStorage(SETTINGS.enablePerform3, true);
}

export function* saveEnablePerform3Setting({ payload }: { payload: boolean }) {
    try {
        yield saveSettingsToStorage(SETTINGS.enablePerform3, Boolean(payload));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const enablePerform3: boolean = yield loadSettingsFromStorage(SETTINGS.enablePerform3, true);

        yield put(setEnablePerform3(enablePerform3));
    } catch (e) {
        yield call(captureException, e);
        yield clearAllSettings();
        yield delay(1000);
        yield call(infoNotification, 'info.settingsCouldNotBeRestored');
    }
}

export default function* root() {
    yield takeLatest(setEnablePerform3, saveEnablePerform3Setting);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
