import { GraphContext } from '@components/graphComponents/GraphDataProvider';
import { Alignments, Overlay } from '@components/graphComponents/Overlay';
import { GraphType } from '@components/graphComponents/types';
import { HOVER_INFO_HEIGHT, HOVER_Y_OFFSET, NO_DATA_Y_OFFSET } from '@components/graphComponents/utils';
import { Formatter } from '@components/summary/types';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { State } from '../../setup/types';
import { getActiveSegmentIdentifier } from './selectors';

type SingleUpBarProperties = {
    graphHeight: number;
    graphMargin: number;
    leftUpYOriginalPosition: number;
    leftDownYOriginalPosition: undefined;
    rightUpYOriginalPosition: undefined;
    rightDownYOriginalPosition: undefined;
    yZeroPosition: undefined;
};

type TwoUpBarProperties = {
    graphHeight: number;
    graphMargin: number;
    leftUpYOriginalPosition: number;
    leftDownYOriginalPosition: undefined;
    rightUpYOriginalPosition: number;
    rightDownYOriginalPosition: undefined;
    yZeroPosition: undefined;
};

type OneUpAndOneDownBarProperties = {
    graphHeight: number;
    graphMargin: number;
    leftUpYOriginalPosition: number;
    leftDownYOriginalPosition: number;
    rightUpYOriginalPosition: undefined;
    rightDownYOriginalPosition: undefined;
    yZeroPosition: number;
};

type TwoUpAndOneDownBarProperties = {
    graphHeight: number;
    graphMargin: number;
    leftUpYOriginalPosition: number;
    leftDownYOriginalPosition: undefined;
    rightUpYOriginalPosition: number;
    rightDownYOriginalPosition: number;
    yZeroPosition: number;
};

type OverlayPositions = {
    leftUpYPosition?: number;
    leftDownYPosition?: number;
    rightUpYPosition?: number;
    rightDownYPosition?: number;
};

function getOverlayPositions(
    graphType: GraphType,
    properties: SingleUpBarProperties | TwoUpBarProperties | OneUpAndOneDownBarProperties | TwoUpAndOneDownBarProperties
): OverlayPositions {
    const {
        graphHeight,
        graphMargin,
        leftUpYOriginalPosition,
        leftDownYOriginalPosition = 0,
        rightUpYOriginalPosition = 0,
        rightDownYOriginalPosition = 0,
        yZeroPosition = 0,
    } = properties;

    let leftUpYPosition, leftDownYPosition, rightUpYPosition, rightDownYPosition;
    switch (graphType) {
        case GraphType.SINGLE_UP_BAR:
            leftUpYPosition =
                leftUpYOriginalPosition +
                (leftUpYOriginalPosition >= graphHeight - graphMargin * 4 ? -HOVER_Y_OFFSET : HOVER_Y_OFFSET);
            break;

        case GraphType.TWO_UP_BARS:
            leftUpYPosition =
                leftUpYOriginalPosition + HOVER_INFO_HEIGHT > graphHeight - graphMargin
                    ? graphHeight - HOVER_INFO_HEIGHT
                    : leftUpYOriginalPosition;
            rightUpYPosition =
                rightUpYOriginalPosition + HOVER_INFO_HEIGHT > graphHeight - graphMargin
                    ? graphHeight - HOVER_INFO_HEIGHT
                    : rightUpYOriginalPosition;
            break;

        case GraphType.ONE_UP_AND_ONE_DOWN_BARS:
            leftUpYPosition =
                leftUpYOriginalPosition + HOVER_INFO_HEIGHT > yZeroPosition
                    ? leftUpYOriginalPosition - HOVER_INFO_HEIGHT
                    : leftUpYOriginalPosition;

            leftDownYPosition =
                leftDownYOriginalPosition - HOVER_INFO_HEIGHT < yZeroPosition
                    ? leftDownYOriginalPosition
                    : leftDownYOriginalPosition - HOVER_INFO_HEIGHT;

            break;

        case GraphType.TWO_UP_AND_ONE_DOWN_BARS:
            leftUpYPosition =
                leftUpYOriginalPosition + HOVER_INFO_HEIGHT > yZeroPosition
                    ? leftUpYOriginalPosition - HOVER_INFO_HEIGHT
                    : leftUpYOriginalPosition;
            rightUpYPosition =
                rightUpYOriginalPosition + HOVER_INFO_HEIGHT > yZeroPosition
                    ? rightUpYOriginalPosition - HOVER_INFO_HEIGHT
                    : rightUpYOriginalPosition;
            rightDownYPosition =
                rightDownYOriginalPosition - HOVER_INFO_HEIGHT < yZeroPosition
                    ? rightDownYOriginalPosition
                    : rightDownYOriginalPosition - HOVER_INFO_HEIGHT;
            break;

        default:
            break;
    }

    return {
        leftUpYPosition,
        leftDownYPosition,
        rightUpYPosition,
        rightDownYPosition,
    };
}

export function SegmentableDayOverlay({
    activeSegmentIdentifier,
    leftUpYAlignment,
    leftUpYColor = 'highlight',
    leftDownYAlignment,
    leftDownYColor,
    leftYScaleFormatter,
    rightUpYAlignment,
    rightUpYColor,
    rightDownYAlignment,
    rightDownYColor,
    rightYScaleFormatter,
    shouldShowLeftDownYValue = false,
    shouldShowRightUpYValue = false,
    shouldShowRightDownYValue = false,
    useCustomStyleColors = false,
    useOuterXPosition = false,
    xOffset = 0,
}: {
    activeSegmentIdentifier?: Date;
    leftUpYAlignment?: Alignments;
    leftUpYColor?: string;
    leftDownYAlignment?: Alignments;
    leftDownYColor?: string;
    leftYScaleFormatter: Formatter;
    rightUpYAlignment?: Alignments;
    rightUpYColor?: string;
    rightDownYAlignment?: Alignments;
    rightDownYColor?: string;
    rightYScaleFormatter?: Formatter;
    shouldShowLeftDownYValue?: boolean;
    shouldShowRightUpYValue?: boolean;
    shouldShowRightDownYValue?: boolean;
    useCustomStyleColors?: boolean;
    useOuterXPosition?: boolean;
    xOffset?: number;
}) {
    const {
        xScale: x,
        leftYScale,
        rightYScale,
        data,
        dimensions: { height, margin },
        yZeroPosition,
        graphType,
    } = useContext(GraphContext);

    if (activeSegmentIdentifier) {
        const selectedDayData = data.find(d => d.x && (d.x as Date).getTime() === activeSegmentIdentifier.getTime());

        const leftUpYValue = selectedDayData?.leftUpY;
        const leftDownYValue = selectedDayData?.leftDownY;
        const rightUpYValue = selectedDayData?.rightUpY;
        const rightDownYValue = selectedDayData?.rightDownY;

        const hasSomeDataForHoveredDate =
            leftUpYValue ||
            (shouldShowLeftDownYValue && leftDownYValue) ||
            (shouldShowRightUpYValue && rightUpYValue) ||
            (shouldShowRightDownYValue && rightDownYValue);

        const xOriginalPosition = x((activeSegmentIdentifier as unknown) as number) || 0;
        const leftUpYOriginalPosition = leftYScale(leftUpYValue);
        const leftDownYOriginalPosition = leftYScale(-Math.abs(leftDownYValue));
        const rightUpYOriginalPosition = rightYScale && rightYScale(rightUpYValue);
        const rightDownYOriginalPosition = rightYScale && rightYScale(-Math.abs(rightDownYValue));

        const {
            leftUpYPosition = leftUpYOriginalPosition,
            leftDownYPosition = leftDownYOriginalPosition,
            rightUpYPosition = rightUpYOriginalPosition,
            rightDownYPosition = rightDownYOriginalPosition,
        } = getOverlayPositions(graphType, {
            graphHeight: height,
            graphMargin: margin,
            leftUpYOriginalPosition,
            leftDownYOriginalPosition,
            rightUpYOriginalPosition,
            rightDownYOriginalPosition,
            yZeroPosition,
        });

        return (
            <>
                {!hasSomeDataForHoveredDate && (
                    <Overlay
                        hasData={false}
                        x={x((activeSegmentIdentifier as unknown) as number) || 0}
                        y={NO_DATA_Y_OFFSET}
                    >
                        <FormattedMessage id="noData" />
                    </Overlay>
                )}
                {hasSomeDataForHoveredDate && (
                    <>
                        {!!leftUpYValue && (
                            <Overlay
                                withDataClasses={
                                    !useCustomStyleColors
                                        ? `border-color-${leftUpYColor} text-color-white bg-${leftUpYColor}`
                                        : ''
                                }
                                hasData
                                x={useOuterXPosition ? xOriginalPosition - xOffset : xOriginalPosition}
                                y={leftUpYPosition}
                                alignment={leftUpYAlignment}
                                useCustomStyleColors={useCustomStyleColors}
                                style={
                                    useCustomStyleColors
                                        ? {
                                              borderColor: leftUpYColor,
                                              color: '#fff',
                                              backgroundColor: leftUpYColor,
                                          }
                                        : {}
                                }
                            >
                                {leftYScaleFormatter(leftUpYValue)}
                            </Overlay>
                        )}
                        {shouldShowLeftDownYValue && !!leftDownYValue && (
                            <Overlay
                                withDataClasses={`border-color-${leftDownYColor} text-color-white bg-${leftDownYColor}`}
                                hasData
                                x={useOuterXPosition ? xOriginalPosition - xOffset : xOriginalPosition}
                                y={leftDownYPosition}
                                alignment={leftDownYAlignment}
                            >
                                {leftYScaleFormatter(leftDownYValue)}
                            </Overlay>
                        )}
                        {shouldShowRightUpYValue && !!rightUpYValue && rightYScaleFormatter && (
                            <Overlay
                                hasData
                                x={useOuterXPosition ? xOriginalPosition + xOffset : xOriginalPosition}
                                y={rightUpYPosition}
                                useCustomStyleColors
                                style={{ borderColor: rightUpYColor, color: '#fff', backgroundColor: rightUpYColor }}
                                alignment={rightUpYAlignment}
                            >
                                {rightYScaleFormatter(rightUpYValue)}
                            </Overlay>
                        )}
                        {shouldShowRightDownYValue && !!rightDownYValue && rightYScaleFormatter && (
                            <Overlay
                                withDataClasses={`border-color-${rightDownYColor} text-color-white bg-${rightDownYColor}`}
                                hasData
                                x={useOuterXPosition ? xOriginalPosition + xOffset : xOriginalPosition}
                                y={rightDownYPosition}
                                alignment={rightDownYAlignment}
                            >
                                {rightYScaleFormatter(rightDownYValue)}
                            </Overlay>
                        )}
                    </>
                )}
            </>
        );
    }
    return null;
}

const mapStateToProps = (state: State) => {
    return { activeSegmentIdentifier: getActiveSegmentIdentifier(state) };
};

export default connect(mapStateToProps)(SegmentableDayOverlay);
