import { loadSettingsFromStorage, saveSettingsToStorage } from '@features/settings/saga';
import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { loadSettingsSuccess as settingsLoaded, Settings } from '../../features/settings/reducer';
import { configureReporting } from '../../setup/errorReporting';
import { setColumnOrderForUseCase, setFilteredColumnNamesForUseCase, setSortedBy, setUseCase } from './actions';
import { getUseCaseConfig } from './selectors';
import { getUseCaseConfigForKey, UseCaseConfig } from './useCaseConfig';

const { captureException } = configureReporting(window, import.meta.env);

export const VEHICLE_ANALYSIS_STORAGE_KEYS = {
    FILTERED_COLUMNS: 'vehicleAnalysisFilteredColumnNames',
    COLUMN_ORDER: 'vehicleAnalysisColumnOrder',
    USE_CASE: 'vehicleAnalysisUseCase',
    SORT_BY: 'vehicleAnalysisSortBy',
};

const mergeSettings = (oldValue: Settings, newValue: Record<string, string[]>) => ({ ...oldValue, ...newValue });

export function* clearAllSettings() {
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS, {});
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER, {});
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.USE_CASE, '');
    yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.SORT_BY, '');
}

export function* saveUseCase({ payload }: { payload: string }) {
    try {
        yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.USE_CASE, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSortedBy({ payload }: { payload: string }) {
    try {
        yield saveSettingsToStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.SORT_BY, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveFilteredColumnNames({
    payload,
}: {
    payload: { useCaseKey: string; filteredColumnNames: string[] };
}) {
    try {
        const { useCaseKey, filteredColumnNames } = payload;
        const key = VEHICLE_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS;
        const savedData: Settings = yield loadSettingsFromStorage(key, null);
        yield saveSettingsToStorage(key, mergeSettings(savedData, { [useCaseKey]: filteredColumnNames }));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveColumnOrder({ payload }: { payload: { useCaseKey: string; columnOrder: string[] } }) {
    try {
        const { useCaseKey, columnOrder } = payload;
        const key = VEHICLE_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER;
        const savedData: Settings = yield loadSettingsFromStorage(key, null);
        yield saveSettingsToStorage(key, mergeSettings(savedData, { [useCaseKey]: columnOrder }));
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const useCase: string = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.USE_CASE, null);
        const sortBy: string = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.SORT_BY, null);
        const columnOrders: string[] = yield loadSettingsFromStorage(VEHICLE_ANALYSIS_STORAGE_KEYS.COLUMN_ORDER, []);
        const filteredNames: string[] = yield loadSettingsFromStorage(
            VEHICLE_ANALYSIS_STORAGE_KEYS.FILTERED_COLUMNS,
            []
        );

        const useCaseConfig: UseCaseConfig[] = yield select(getUseCaseConfig);
        if (getUseCaseConfigForKey(useCase, useCaseConfig)) {
            yield put(setUseCase(useCase));
        }

        if (sortBy) {
            yield put(setSortedBy(sortBy));
        }

        yield all(
            Object.entries(columnOrders)
                .filter(([, order]) => _.isArray(order))
                .map(([useCaseKey, columnOrder]) => put(setColumnOrderForUseCase({ useCaseKey, columnOrder })))
        );

        yield all(
            Object.entries(filteredNames)
                .filter(([, order]) => _.isArray(order))
                .map(([useCaseKey, columnOrder]) =>
                    put(setFilteredColumnNamesForUseCase({ useCaseKey, filteredColumnNames: columnOrder }))
                )
        );
    } catch (e) {
        yield clearAllSettings();
        yield call(captureException, e);
    }
}

export default function* root() {
    yield takeLatest(setUseCase, saveUseCase);
    yield takeLatest(setSortedBy, saveSortedBy);
    yield takeLatest(setColumnOrderForUseCase, saveColumnOrder);
    yield takeLatest(setFilteredColumnNamesForUseCase, saveFilteredColumnNames);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
