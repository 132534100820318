import _ from 'lodash';

export default function LanguageData({
    messages = {},
    category = '',
    customOrder = [],
    hiddenMessages = [],
}: {
    messages?: Record<string, any>;
    category?: string;
    customOrder?: string[];
    hiddenMessages?: string[];
}) {
    const items = Object.keys(messages).filter(key => {
        if (!_.isEmpty(hiddenMessages)) {
            return !hiddenMessages.includes(key) && key.startsWith(category) && key.endsWith('Title');
        }
        return key.startsWith(category) && key.endsWith('Title');
    });

    if (_.isEmpty(customOrder)) {
        items.sort((a, b) => {
            const aWithoutSpecialChars = _.get(messages, a, '').replace('(', '');
            const bWithoutSpecialChars = _.get(messages, b, '').replace('(', '');
            return aWithoutSpecialChars.localeCompare(bWithoutSpecialChars);
        });
    } else {
        items.sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));
    }

    const data = {
        items: items.map(titleKey => {
            const baseKey = titleKey.replace(/Title$/, '');
            return {
                id: baseKey.replace(/\./g, '/'),
                title: titleKey,
                description: `${baseKey}Description`,
            };
        }),
    };

    return data;
}
