import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { isNil } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '../../setup/store';
import { deleteReportConfiguration } from './reducer';

const DeleteConfiguration = ({
    configId,
    children,
    onDelete = () => {},
    onClose = () => {},
    showDialog,
}: {
    configId: string;
    children?: ReactElement;
    onDelete?: () => void;
    onClose?: () => void;
    showDialog?: boolean | null;
}) => {
    const [internalShowDialog, setInternalShowDialog] = useState<boolean | null | undefined>(showDialog);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const isUncontrolled = isNil(showDialog);

    const onClickDelete = () => {
        onDelete();
        setIsLoading(true);
        dispatch(deleteReportConfiguration(configId)).then(() => {
            if (isUncontrolled) {
                setInternalShowDialog(false);
            }
            setIsLoading(false);
            onClose();
        });
    };

    const onClickCancel = () => {
        if (isUncontrolled) {
            setInternalShowDialog(false);
        }
        onClose();
    };

    useEffect(() => {
        if (!isUncontrolled) {
            setInternalShowDialog(showDialog);
        }
    }, [isUncontrolled, showDialog]);

    const shouldShowDialog = isUncontrolled ? internalShowDialog : showDialog;

    return (
        <>
            {shouldShowDialog ? (
                <ConfirmationDialog
                    show={shouldShowDialog}
                    title={intl.formatMessage({ id: 'reports.delete.title' })}
                    content={intl.formatMessage({ id: 'reports.confirm.delete' })}
                    onClickConfirm={onClickDelete}
                    onClickCancel={onClickCancel}
                    cancelButtonText={intl.formatMessage({ id: 'cancel' })}
                    confirmButtonText={
                        isLoading ? (
                            <Spinner text={intl.formatMessage({ id: 'loading' })} isInverse />
                        ) : (
                            intl.formatMessage({ id: 'confirm' })
                        )
                    }
                    useOverflow
                    bsSize={Dialog.SIZE_SM}
                />
            ) : null}

            {children &&
                React.cloneElement(children, {
                    onClick: (e: MouseEvent) => {
                        e.preventDefault();
                        setInternalShowDialog(true);
                    },
                })}
        </>
    );
};

export default DeleteConfiguration;
