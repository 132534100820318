import { formatOneDigitNumber, plainAverage, withUnit } from '@utils/formatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import { METERS } from '../constants/units';
import createColumn from './createColumn';
export const heightSignal = {
    unit: METERS,
    displayedUnit: METERS,
    format: compose<ReactNode>(withUnit(METERS), formatOneDigitNumber),
    average: plainAverage,
};

export const drivenElevationUp = createColumn({
    key: 'drivenElevationUp',
    dataField: 'drivenElevationUp',
    labelId: 'drivenElevationUp',
    formatter: heightSignal.format,
    unit: heightSignal.unit,
});
export const drivenElevationDown = createColumn({
    key: 'drivenElevationDown',
    dataField: 'drivenElevationDown',
    labelId: 'drivenElevationDown',
    formatter: heightSignal.format,
    unit: heightSignal.unit,
});
