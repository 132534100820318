import { useContext } from 'react';

import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';

export default function SingleDataPoint({
    selectedElement,
    color,
}: {
    selectedElement: DataEntry | undefined;
    color: string;
}) {
    const { xScale: x, leftYScale: y } = useContext(GraphContext);

    if (selectedElement) {
        return (
            <circle
                stroke={color}
                r={4}
                cy={y(selectedElement.leftUpY)}
                cx={x(selectedElement.x as number)}
                className="dataPoint"
            />
        );
    }
    return null;
}
