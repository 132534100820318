import { VehicleFuelType } from '@api/index';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { TreeTabs } from '../../constants/treeTabs';
import { TreeStatePart } from '../../setup/types';
import { getFeatureToggles } from './../../common/permissions/selectors';
import { defaultSelectedFuelTypes } from './reducer';

export const getSelectedVehicleIds = (state: TreeStatePart) => _.get(state, 'tree.selectedVehicles', []);
export const getSelectedDriverIds = (state: TreeStatePart) => _.get(state, 'tree.selectedDrivers', []);
export const getSelectedVehicleGroupIds = (state: TreeStatePart) => _.get(state, 'tree.selectedVehicleGroups', []);
export const getSelectedDriverGroupIds = (state: TreeStatePart) => _.get(state, 'tree.selectedDriverGroups', []);

const getSelectedFuelTypesFromState = (state: TreeStatePart) =>
    _.get(state, 'tree.selectedFuelTypes', defaultSelectedFuelTypes);

export const getSelectedFuelTypes = createSelector(
    [getSelectedFuelTypesFromState, getFeatureToggles],
    (selectedFuelTypesFromState: VehicleFuelType[], featureToggles: Record<string, unknown>) =>
        featureToggles.truE_EEF ? selectedFuelTypesFromState : Object.values(VehicleFuelType)
);
export const getActiveTab = (state: TreeStatePart) => _.get(state, 'tree.tab', TreeTabs.VEHICLES);
export const showEmptyGroups = (state: TreeStatePart) => state.tree.showEmptyGroups;
