import BandXAxis from '@components/graphComponents/BandXAxis';
import { Bar } from '@components/graphComponents/Bar';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import { YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import colors from '@rio-cloud/rio-uikit/Colors';
import { range } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { BarInfo, computeDynamicBars, roundToOneDecimalPoint } from './utils/histogramUtils';

const NUMBER_OF_BARS = 5;

const temperatureBarLabel = (bar: BarInfo, barIndex: number, numberOfBars: number): string => {
    const { min: barMinValue, max: barMaxValue } = bar;

    switch (barIndex) {
        case numberOfBars - 1:
            return `${barMinValue}ºC ↔ ${roundToOneDecimalPoint(barMaxValue)}ºC`;
        default:
            return `${barMinValue}ºC ↔ ${roundToOneDecimalPoint(barMaxValue - 0.1)}ºC`;
    }
};

const processTemperatureData = (data: number[]): (BarInfo & { label: string })[] => {
    const barsInfo = computeDynamicBars(data, NUMBER_OF_BARS);

    const finalBarInfo = barsInfo.map((bar, index) => ({
        ...bar,
        label: temperatureBarLabel(bar, index, NUMBER_OF_BARS),
    }));

    return finalBarInfo;
};

const TemperatureHistogram = ({
    temperatureData,
    intl: { formatMessage },
}: {
    temperatureData: number[];
    intl: IntlShape;
}) => {
    const [dimensions, setDimensions] = useState({
        height: 200,
        width: 0,
        margin: 40,
    });

    const xScale = scaleBand<number>()
        .domain(range(NUMBER_OF_BARS))
        .range([-(dimensions.margin / 2), dimensions.width - dimensions.margin])
        .padding(0.4);

    const yScale = scaleLinear()
        .domain([100, 0])
        .range([0, dimensions.height - dimensions.margin]);

    useEffect(() => {
        const maxWidth = 950;
        setDimensions(prevDimensions => ({
            ...prevDimensions,
            width: Math.min(document.body.clientWidth - 40, maxWidth),
        }));
    }, []);

    const barsInfo = processTemperatureData(temperatureData);

    const graphData = barsInfo.map((bar, barIndex) => ({ x: barIndex, leftUpY: bar.value }));

    const xScaleFormatter = (index: number) => barsInfo[index].label;

    return (
        <>
            <p style={{ color: 'text-color-dark' }} className="text-light line-height-16 margin-top-0 margin-bottom-20">
                <FormattedMessage id="ambientTemperature.temperatureDistribution" />
            </p>
            <div data-test="temperature-histogram" style={{ width: dimensions.width }}>
                <div className="graph bg-white position-relative overflow-hidden" style={{ height: dimensions.height }}>
                    <GraphDataProvider
                        formatMessage={formatMessage}
                        dimensions={{ ...dimensions, margin: 40 }}
                        xScale={xScale}
                        leftYScale={yScale}
                        data={graphData}
                    >
                        <svg
                            width={'100%'}
                            height={'100%'}
                            preserveAspectRatio="xMinYMin meet"
                            viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                        >
                            <BandXAxis tickFormatter={xScaleFormatter} />
                            <YAxisLines />
                            <Bar
                                colorSchema={() => colors['color-highlight']}
                                xOffsetWithMargin
                                width={xScale.bandwidth()}
                            />
                            <g transform="translate(-8 0)">
                                <YAxisTicks formatter={(value: number) => `${value}%`} />
                            </g>
                        </svg>
                    </GraphDataProvider>
                </div>
            </div>
        </>
    );
};

export default injectIntl(TemperatureHistogram);
