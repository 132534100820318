import omit from 'lodash/fp/omit';
import { User } from 'oidc-client';

const stripSnakeProps = omit(['family_name', 'given_name']);

export type AuthenticatedUserWithProfile = Pick<User, 'access_token' | 'expires_in'> & { profile: OICDUserProfile };

export type OICDUserProfile = {
    azp?: string;
    email?: string;
    family_name?: string;
    given_name?: string;
    locale?: string;
    name?: string;
    sub?: string;
    account?: string;
    [key: string]: unknown;
};

export type MappedUserProfile = {
    azp?: string;
    email?: string;
    familyName?: string;
    givenName?: string;
    locale?: string;
    name?: string;
    sub?: string;
    account?: string;
    [key: string]: unknown;
};

export const mapUserProfile = (profile: OICDUserProfile = {}): MappedUserProfile => ({
    azp: profile.azp,
    email: profile.email,
    familyName: profile.family_name,
    givenName: profile.given_name,
    locale: profile.locale,
    name: profile.name,
    sub: profile.sub,
    account: profile.account,
    ...stripSnakeProps(profile),
});
