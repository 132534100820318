import { columns, getColumn } from '@utils/columns';

import createColumn, { Column } from '../../columns/createColumn';
import { Driver, HydratedEntity } from '../../types';
import ClickableDriverList from './ClickableDriverList';
import DriverLabel from './DriverLabel';
import { VehData } from './types';

export const driverNames = (drivers: Driver[], data: { id: string; childrenCount?: number }) => (
    <DriverLabel drivers={drivers} data={data} />
);

const replacements: { [key: string]: Record<string, unknown> } = {
    drivers: { formatter: driverNames },
};

export const tableColumns = columns.map(column => {
    const replacementColumnProps = replacements[column.key];
    return replacementColumnProps ? createColumn({ ...column, ...replacementColumnProps }) : column;
});

// eslint-disable-next-line react/display-name
export const driverNamesList = (onDriverNameClick: (entity: HydratedEntity) => void) => (
    // why do we need this?? without drivers it breaks
    drivers: Driver[],
    data: VehData
) => <ClickableDriverList onClick={onDriverNameClick} entities={data.children ? data.children : []} />;

export const getTopColumns = (
    isDriverNameClickable: boolean,
    onDriverNameClick: (driver: unknown) => void,
    hasPerformVehicle: boolean
) => {
    const defaultDriversColumn = (hasPerformVehicle ? getColumn('explicitDrivers') : getColumn('drivers')) as Column;
    const driverColumn = isDriverNameClickable
        ? createColumn({
              ...defaultDriversColumn,
              formatter: driverNamesList(onDriverNameClick),
          })
        : defaultDriversColumn;
    return [getColumn('vehicle'), driverColumn];
};
