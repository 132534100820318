import Card from '@components/Card';
import { getColumn } from '@utils/columns';
import { getRatingCSSColor } from '@utils/rating';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { LoginStatePart, PermissionsStatePart, SettingStatePart } from '../../setup/types';
import { HydratedEntity } from '../../types';
import { enablePerform3 } from '../settings/reducer';

const totalRating = getColumn('totalRating');
const totalRatingV2 = getColumn('totalRatingV2');

export const Total = ({
    entity,
    shouldUseV2Ratings,
}: {
    entity: HydratedEntity;
    shouldUseV2Ratings: boolean;
}): JSX.Element => {
    let column;
    if (shouldUseV2Ratings) {
        column = totalRatingV2;
    } else {
        column = totalRating;
    }

    const value = column?.valueExtractor(entity) as number;
    const { color } = getRatingCSSColor(value);
    return (
        <Card
            title={<FormattedMessage id={column?.labelId} />}
            score={
                <span className={cn('text-size-12 text-bold', color)}>
                    <span className="text-size-200pct"> {column?.formatter(value)} </span>
                </span>
            }
        />
    );
};

export const mapStateToProps = (state: SettingStatePart & PermissionsStatePart & LoginStatePart) => ({
    shouldUseV2Ratings: enablePerform3(state),
});

export default connect(mapStateToProps)(Total);
