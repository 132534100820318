import cn from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';

export enum Alignments {
    RIGHT,
    LEFT,
}

export enum TransitionTypes {
    OVERVIEW = 'overview',
    BATTERY = 'battery',
}

export const Overlay: React.FC<{
    x: number;
    y: number;
    offset?: number;
    alignment?: Alignments;
    hasData?: boolean;
    maxXValue?: number;
    withOutDataClasses?: string;
    withDataClasses?: string;
    useCustomStyleColors?: boolean;
    style?: React.CSSProperties;
    transitionType?: TransitionTypes;
}> = ({
    children,
    x,
    y,
    offset = 0,
    alignment = Alignments.LEFT,
    hasData = true,
    maxXValue,
    withOutDataClasses = 'border-color-light text-color-light bg-white',
    withDataClasses = 'border-color-highlight text-color-highlight bg-white',
    useCustomStyleColors = false,
    style,
    transitionType = TransitionTypes.OVERVIEW,
}) => {
    const [width, setWidth] = useState(0);
    const [xOffset, setXOffset] = useState(offset);
    const ref = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth);
        }
    }, [children]);

    const adjustedXOffset = Alignments.RIGHT === alignment ? x - width - xOffset : x + xOffset;

    useLayoutEffect(() => {
        if (Alignments.RIGHT === alignment && adjustedXOffset < 0) {
            setWidth(x);
        }

        if (Alignments.LEFT === alignment && !!maxXValue && adjustedXOffset + width > maxXValue) {
            setXOffset(adjustedXOffset - x - width / 2);
        }
    }, [adjustedXOffset, alignment, x, maxXValue, width]);

    const transitionStyles = {
        [TransitionTypes.OVERVIEW]: 'transform 100ms ease',
        [TransitionTypes.BATTERY]: 'transform 0ms ease',
    };

    return (
        <div
            ref={ref}
            className={cn(
                !useCustomStyleColors && (hasData ? withDataClasses : withOutDataClasses),
                'border-style-solid border-width-1 padding-5 text-bold'
            )}
            data-test="graph-overlay"
            style={{
                position: 'absolute',
                zIndex: 1,
                transition: transitionStyles[transitionType],
                transform: `translate(${adjustedXOffset}px, ${y}px)`,
                pointerEvents: 'none',
                ...(useCustomStyleColors ? style : {}),
            }}
        >
            {children}
        </div>
    );
};
