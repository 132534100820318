import { VehicleFuelType } from '@api/models/types';
import OneUpAndOneDownBarsGraph from '@components/commonGraphComponents/OneUpAndOneDownBarsGraph';
import SingleUpBarGraph from '@components/commonGraphComponents/SingleUpBarGraph';
import TwoUpAndOneDownBarsGraph from '@components/commonGraphComponents/TwoUpAndOneDownBarsGraph';
import TwoUpBarsGraph from '@components/commonGraphComponents/TwoUpBarsGraph';
import { convertDataToXYValue, mergeExistingDataWithPlaceholders } from '@components/commonGraphComponents/utils';
import { GraphType } from '@components/graphComponents/types';
import { Section, Values } from '@components/summary/types';
import { defaultSelectedFuelTypes } from '@features/tree/reducer';
import _ from 'lodash';
import { IntlFormatters } from 'react-intl';

import { DateRange } from '../../../types';
import { VehicleGraphDimensions } from '.';

const getGraphType = (column: Section, selectedFuelTypes: VehicleFuelType[]) => {
    if (column.key.includes('Consumption')) {
        if (_.isEqual(selectedFuelTypes, defaultSelectedFuelTypes)) {
            return GraphType.TWO_UP_AND_ONE_DOWN_BARS;
        }
        if (!selectedFuelTypes.includes(VehicleFuelType.ELECTRIC)) {
            return GraphType.SINGLE_UP_BAR;
        }
        if (selectedFuelTypes.length === 1 && selectedFuelTypes[0] === VehicleFuelType.ELECTRIC) {
            return GraphType.ONE_UP_AND_ONE_DOWN_BARS;
        }
    } else if (column.key === 'co2Emission') {
        return GraphType.SINGLE_UP_BAR;
    } else {
        if (_.isEqual(selectedFuelTypes, defaultSelectedFuelTypes)) {
            return GraphType.TWO_UP_BARS;
        } else {
            return GraphType.SINGLE_UP_BAR;
        }
    }

    return undefined;
};

const TruEGraph = ({
    column,
    dateRange,
    dimensions,
    dieselData,
    electricData,
    intl,
    languageData,
    tickFormatter,
    selectedFuelTypes,
}: {
    column: Section;
    dateRange: DateRange;
    dimensions: VehicleGraphDimensions;
    dieselData: Values | undefined;
    electricData:
        | {
              values: Values;
              recuperationValues?: Values | undefined;
          }
        | undefined;
    intl: IntlFormatters;
    languageData: Record<string, string>;
    tickFormatter: (dateRange: DateRange) => (d: Date) => string;
    selectedFuelTypes: VehicleFuelType[];
}) => {
    const existingData = convertDataToXYValue({ dieselData, electricData, selectedFuelTypes });
    const mappedData = mergeExistingDataWithPlaceholders({ dateRange, existingData });

    const graphType = getGraphType(column, selectedFuelTypes);

    const truEGraphDimensions = { ...dimensions, horizontalMargin: 80 };

    return (
        <>
            {graphType === GraphType.SINGLE_UP_BAR && (
                <SingleUpBarGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    fuelType={selectedFuelTypes[0]}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
            {graphType === GraphType.TWO_UP_BARS && (
                <TwoUpBarsGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
            {graphType === GraphType.ONE_UP_AND_ONE_DOWN_BARS && (
                <OneUpAndOneDownBarsGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
            {graphType === GraphType.TWO_UP_AND_ONE_DOWN_BARS && (
                <TwoUpAndOneDownBarsGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
        </>
    );
};

export default TruEGraph;
