export enum GraphType {
    SINGLE_UP_BAR,
    TWO_UP_BARS,
    ONE_UP_AND_ONE_DOWN_BARS,
    TWO_UP_AND_ONE_DOWN_BARS,
}

export type DataEntry = {
    x: Date | number;
    leftUpY: any | undefined;
    leftDownY?: any | undefined;
    rightDownY?: any | undefined;
    rightUpY?: any | undefined;
    interpolated?: boolean;
};

export type TimeSegmentUnit = 'hours' | 'day' | 'week' | 'month' | 'year';

export type YAxis = 'leftUpY' | 'leftDownY' | 'rightUpY' | 'rightDownY';

export type Data = DataEntry[];

export type formatMessage = ({ id }: { id: string }) => string;

export type formatDate = (date: Date) => string;

export type Dimensions = {
    height: number;
    width: number;
    margin: number;
    horizontalMargin?: number;
    widthWithoutMargin: number;
    heightWithoutMargin: number;
    legendHeight: number;
    legendLeftMargin: number;
    legendTopMargin: number;
};
