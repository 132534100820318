import Collapse from '@rio-cloud/rio-uikit/Collapse';
import DataTab from '@rio-cloud/rio-uikit/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import { generatePushToDataLayer } from '@utils/tracking';
import React from 'react';

import ErrorBoundary from '../ErrorBoundry';
import Title from './Title';
import { Section } from './types';

function Content({
    account,
    activeTab,
    entities,
    sections,
    isExpanded = true,
    setActiveTab,
    isLoading = false,
    children,
    location = '',
    highlightColor,
}: {
    account?: string;
    activeTab: string | null;
    entities: unknown[];
    sections: Section[];
    location?: string;
    isLoading?: boolean;
    isExpanded: boolean;
    setActiveTab: (tabKey: string) => void;
    children: React.ReactNode;
    highlightColor?: string;
}) {
    const pushToDataLayer = generatePushToDataLayer(account);

    const activeSection = sections.find(s => s.key === activeTab);
    if (!(entities.length && activeSection)) {
        return null;
    }

    const handleActiveTabChange = (tabKey: string) => {
        pushToDataLayer({
            eventTrigger: 'click',
            eventCategory: 'perform, chartSelect',
            eventAction: 'selectDataType',
            eventLabel: `dataSelect, ${location}, ${tabKey}`,
        });

        setActiveTab(tabKey);
    };

    return (
        <ErrorBoundary>
            <DataTabs
                activeKey={isExpanded && !isLoading ? activeTab! : 'no-tab'}
                onSelectTab={handleActiveTabChange}
                bordered={false}
                disableTransition={true}
                tabContentClassName="padding-left-0 padding-right-0 padding-bottom-0 padding-top-0"
                className={!isExpanded ? 'pointer-events-none' : ''}
                commonTabContent={
                    <Collapse open={isExpanded} unmountOnExit={false}>
                        <div>{children}</div>
                    </Collapse>
                }
                arrowClassName={highlightColor}
                tabActiveClassName={highlightColor}
            >
                {sections.map((s, index: number) => (
                    <DataTab
                        key={`${s.key}-${index}`}
                        tabKey={s.key}
                        title={<Title text={s.title} value={s.tabValue} />}
                    />
                ))}
            </DataTabs>
        </ErrorBoundary>
    );
}

export default Content;
