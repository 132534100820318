import './polyfills';

import { configureFetchLanguageData } from '@common/lang/services';
import { mockOAuth, retrieveInitialState, setupOAuth } from '@common/login/login';
import { handleLoginRedirect } from '@common/login/redirect';
import configureFetchLegacyPermissions from '@common/permissions/api';
import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';

import { accessToken as accessTokenStorage } from './common/tokenHandling/accessToken';
import configureFetchHereSettings from './common/tokenHandling/hereToken';
import { env } from './env';
import main, { OAuthConfigSettings, trace } from './main';
import { history, store } from './setup/store';

if (env.shouldSendMetrics) {
    const options: Sentry.BrowserOptions = {
        maxValueLength: 10000,
        dsn: env.runtimeConfig.sentryToken,
        release: `performance-${import.meta.env.COMMIT}`,
        ignoreErrors: ['TypeError: Failed to fetch'],
        beforeSend(event, hint) {
            if (hint && hint.originalException && (hint.originalException as Error).stack?.includes('mapsjs-core')) {
                return null;
            }
            return event;
        },
    };
    if (env.isRunningOnProd) {
        options.environment = 'production';
    }
    Sentry.init(options);
}

const oauthBehavior = (settings: OAuthConfigSettings) => {
    const isMocking = env.featureToggles.mockedToken;
    const promise =
        isMocking || (env.runtimeConfig.login && env.runtimeConfig.login.mockAuthorization)
            ? mockOAuth(settings)
            : setupOAuth(settings);

    return promise.then(() => {
        const { initialRoute } = retrieveInitialState();

        trace('initialRoute lookup', initialRoute);
        if (initialRoute) {
            trace(`history.replace("/${initialRoute}")`);
            history.replace(`/${initialRoute}`);
        }

        return Promise.resolve();
    });
};

if (window.location.href.startsWith(env.runtimeConfig.login.redirectUri)) {
    handleLoginRedirect();
} else {
    main({
        accessTokenStorage,
        configureFetchHereSettings,
        configureFetchLanguageData,
        configureFetchLegacyPermissions,
        document,
        oauthBehavior,
        render,
        store,
    });
}
