import { Text, View } from '@react-pdf/renderer';
import _ from 'lodash';

import ratingToIndex from '../../opcon/ratingToIndex';

const COLORS = ['#D0D8DE', '#A7AFBB', '#697A8B', '#4C5667', '#2a3740'];

const toPercent = (value: number, total: number) => value / total;
const MIN_HEIGHT = 3;
const MAX_HEIGHT = 10;

export default function SimpleBarChart({
    value,
    total = 5,
    minHeight = MIN_HEIGHT,
    maxHeight = MAX_HEIGHT,
    width = 40,
}: {
    value: number;
    total?: number;
    minHeight?: number;
    maxHeight?: number;
    width?: number;
}) {
    const activeIndex = ratingToIndex(value);

    const bars = _.range(total)
        .map(step => toPercent(step, total - 1))
        .map((percent, index) => {
            const height = minHeight * (1 - percent) + maxHeight * percent;
            return (
                <View
                    data-test="bar"
                    key={index}
                    style={{
                        backgroundColor: index <= activeIndex ? COLORS[activeIndex] : '#E5EBF0',
                        height,
                        marginRight: 1,
                        flex: 1,
                    }}
                />
            );
        });

    if (activeIndex < 0) {
        return <Text>-</Text>;
    }

    return (
        <View
            data-test="SimpleBarChart"
            style={{
                width,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
            }}
        >
            {bars}
        </View>
    );
}
