import { getLocale } from '@common/lang/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import { Dispatch } from '@reduxjs/toolkit';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { WELCOME_ROUTE } from '../../constants/routes';
import { State } from '../../setup/types';
import { toggleChangeDialog, toggleHelpDialog } from './actions';
import ChangelogDialog from './changelog/ChangelogDialog';
import HelpDialog from './help/HelpDialog';
import { shouldShowChangeDialog, shouldShowHelpDialog } from './selectors';

const FeedbackLink = injectIntl(({ intl }) => {
    const link = intl.formatMessage({ id: 'beta.feedback.link' });

    if (!/^https:\/\/www.surveymonkey/.test(link)) {
        return null;
    }

    return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={link} target="_blank">
            <FormattedMessage id="feedback" />
        </a>
    );
});

const ServiceInfoContainer = (props: {
    title: string | JSX.Element;
    toggleHelpDialog: () => void;
    toggleChangeDialog: () => void;
    isHelpDialogVisible: boolean;
    isChangeDialogVisible: boolean;
    locale: string;
    shouldShowHarshKPIs: boolean;
    shouldShowExcessiveKPIs: boolean;
}) => {
    const {
        title,
        toggleHelpDialog,
        toggleChangeDialog,
        isHelpDialogVisible,
        isChangeDialogVisible,
        locale,
        shouldShowHarshKPIs,
        shouldShowExcessiveKPIs,
    } = props;
    return (
        <ActionBarItem id="serviceInfo">
            <ActionBarItem.Icon>
                <span className="icon rioglyph rioglyph-info-sign" />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={title}>
                <ServiceInfoItems toggleHelpDialog={toggleHelpDialog} toggleChangeDialog={toggleChangeDialog} />
            </ActionBarItem.Popover>
            <React.Fragment>
                <HelpDialog
                    show={isHelpDialogVisible}
                    toggleHelpDialog={toggleHelpDialog}
                    locale={locale}
                    shouldShowHarshKPIs={shouldShowHarshKPIs}
                    shouldShowExcessiveKPIs={shouldShowExcessiveKPIs}
                />
                <ChangelogDialog show={isChangeDialogVisible} toggleChangeDialog={toggleChangeDialog} locale={locale} />
            </React.Fragment>
        </ActionBarItem>
    );
};

function ServiceInfoItems({
    toggleHelpDialog,
    toggleChangeDialog,
    hasNewReleaseNotes,
}: {
    toggleHelpDialog: () => void;
    toggleChangeDialog: () => void;
    hasNewReleaseNotes?: boolean;
}) {
    return (
        <div>
            <div className="line-height-20">
                <a className="ShowReleaseNotesLink cursor-pointer" onClick={toggleChangeDialog}>
                    <FormattedMessage id="releaseNotes.whatsNew" defaultMessage="What's new?" />
                    {hasNewReleaseNotes && <span className={'badge bg-primary margin-left-5'}>1</span>}
                </a>
            </div>
            <div className="line-height-20" data-test="welcomePage-link">
                <NavLink to={WELCOME_ROUTE}>
                    <FormattedMessage id="subModuleName.landingPage" defaultMessage="Welcome page" />
                </NavLink>
            </div>
            <div className="line-height-20">
                <FeedbackLink />
            </div>
            <div className="line-height-20">
                <a className="ShowHelpLink cursor-pointer" onClick={toggleHelpDialog}>
                    <FormattedMessage id="help.header" defaultMessage="Help" />
                </a>
            </div>
        </div>
    );
}

const mapStateToProps = (state: State) => ({
    isHelpDialogVisible: shouldShowHelpDialog(state),
    isChangeDialogVisible: shouldShowChangeDialog(state),
    locale: getLocale(state),
    shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
    shouldShowExcessiveKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleHelpDialog: () => dispatch(toggleHelpDialog()),
    toggleChangeDialog: () => dispatch(toggleChangeDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceInfoContainer);
