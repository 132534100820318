export const ASSISTANCE_SYSTEMS_REQUEST_ATTRIBUTES = [
    'driver_ids',
    'vehicle_ids',
    'acc_distance',
    'no_acc_distance',
    'acc_time',
    'no_acc_time',
    'pcca_distance',
    'no_pcca_distance',
    'pcca_time',
    'no_pcca_time',
];
