import ISODateToMomentDate from '@utils/ISODateToMomentDateObject';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

import { getSignal } from '../../api';
import usePerformData from '../../data/hooks/usePerformData';
import { Loadable as LoadableDataStructure, LoadableType } from '../../data/loadable';
import { DateRange, HydratedEntity, Id, PerformSegmentBy } from '../../types';
import { Variables } from './../../utils/useQuery';
import { ELECTRIC_KPIS_ATTRIBUTES, VEHICLE_REQUEST_ATTRIBUTES } from './queries';

const removeEmptyEntity = (entity: HydratedEntity) => {
    const start = getSignal<Date | null>(entity, 'start', null) as Date;
    return ISODateToMomentDate(start).isValid();
};

const mergeParentsWithChildren = (parents: HydratedEntity[], children: HydratedEntity[]) => {
    const childrenAsMap = _.groupBy(children, child => getSignal(child, 'vehicleIds', []));
    return parents.filter(removeEmptyEntity).map(parent => {
        const vehicleIdsForParent = getSignal<Id[]>(parent, 'vehicleIds', []);
        const childrenForParent = childrenAsMap[vehicleIdsForParent[0]] || [];
        return {
            ...parent,
            level: 1,
            childrenCount: childrenForParent.length,
            children: childrenForParent.filter(removeEmptyEntity).map(child => ({
                ...child,
                level: 2,
            })),
        };
    });
};

type Props = {
    dateRange: DateRange;
    vehicles: Id[];
    performVehicles: Id[];
    containsElectric: boolean;
    isTruEEnabled: boolean;
};

function useVehicleContentLoader({
    dateRange = {
        start: moment()
            .subtract(1, 'week')
            .toDate(),
        end: moment().toDate(),
    },
    vehicles,
    performVehicles,
    containsElectric,
    isTruEEnabled = false,
}: Props) {
    const combustionVehicleRequestAttributes = [
        ...VEHICLE_REQUEST_ATTRIBUTES,
        'operating_days',
        'total_number_of_days',
    ];
    const electricVehicleRequestAttributes = [...ELECTRIC_KPIS_ATTRIBUTES];

    const vehicleRequestAttributes =
        containsElectric && isTruEEnabled
            ? combustionVehicleRequestAttributes.concat(electricVehicleRequestAttributes)
            : combustionVehicleRequestAttributes;
    const loadableEntities = usePerformData(vehicleRequestAttributes, {
        variables: {
            ...dateRange,
            vehicleIds: vehicles,
            segmentBy: PerformSegmentBy.vehicle,
        } as Variables,
        shouldMakeRequest: !_.isEmpty(vehicles),
    }) as LoadableType<HydratedEntity[]>;

    const requestedPerformVehicles = _.intersection(vehicles, performVehicles);
    const loadableChildren = usePerformData(vehicleRequestAttributes, {
        variables: {
            ...dateRange,
            vehicleIds: requestedPerformVehicles,
            segmentBy: PerformSegmentBy.driver_and_vehicle,
        } as Variables,
        shouldMakeRequest: !_.isEmpty(vehicles),
    }) as LoadableType<HydratedEntity[]>;

    const parentsWithChildren = useMemo(
        () => LoadableDataStructure.combine(mergeParentsWithChildren, loadableEntities, loadableChildren),
        [loadableChildren, loadableEntities]
    );

    return parentsWithChildren;
}

export default useVehicleContentLoader;
