import { getSignal } from '@api/index';
import usePerformData from '@data/hooks/usePerformData';
import { Loadable, LoadableType } from '@data/loadable';
import { Variables } from '@utils/useQuery';
import { always } from 'lodash/fp';
import React, { ReactElement } from 'react';

import { DateRange, HydratedEntity, PerformSegmentBy } from '../../types';
import { BATTERY_DETAILS_REQUEST_ATTRIBUTES, STATE_OF_CHARGE_REQUEST_ATTRIBUTES } from './queries';
import { stateOfChargeInterval } from './timeInterval';
import { Details, SignalKeys, StateOfChargeDataPoint } from './types';

export enum Status {
    'LOADING' = 'loading',
    'SUCCESS' = 'success',
    'FAILED' = 'failed',
}

export const BatteryContext = React.createContext<{
    stateOfCharge: { status: Status; data?: StateOfChargeDataPoint[] };
}>({ stateOfCharge: { status: Status.LOADING } });

export default function BatteryProvider({
    dateRange,
    children,
    driverIds,
    vehicleIds,
}: {
    dateRange: DateRange;
    children: ReactElement;
    driverIds: (string | null)[];
    vehicleIds: string[];
}) {
    const shouldMakeRequest = vehicleIds.length === 1;
    const stateOfChargeLoadable = usePerformData(
        [...STATE_OF_CHARGE_REQUEST_ATTRIBUTES, ...BATTERY_DETAILS_REQUEST_ATTRIBUTES],
        {
            variables: {
                ...dateRange,
                driverIds,
                vehicleIds: vehicleIds,
                segmentBy: PerformSegmentBy.interval,
                intervalType: stateOfChargeInterval(dateRange),
            } as Variables,
            debounced: false,
            shouldMakeRequest,
        }
    ) as LoadableType<HydratedEntity[]>;

    const mapDetails = (entry: HydratedEntity) => {
        const keys: SignalKeys[] = [
            'aircompressorConsumption',
            'aircompressorConsumptionPerKm',
            'drivetrainConsumption',
            'drivetrainConsumptionPerKm',
            'eptoConsumption',
            'eptoConsumptionPerKm',
            'hvacConsumption',
            'hvacConsumptionPerKm',
            'coolingConsumption',
            'coolingConsumptionPerKm',
            'vpowersupplyConsumption',
            'vpowersupplyConsumptionPerKm',
            'totalPowerConsumption',
            'totalSegment',
            'energyEfficiencyPerKm',
        ];

        const details: Details = keys.reduce((acc, key) => {
            acc[key] = getSignal<number | undefined>(entry, key, undefined);
            return acc;
        }, {} as Details);

        return details;
    };

    const mapStateOfCharge = (entities: HydratedEntity[]): StateOfChargeDataPoint[] =>
        entities.reduce((acc: StateOfChargeDataPoint[], entry) => {
            const stateOfCharge = getSignal<number | undefined>(entry, 'electricMaxStateOfCharge', undefined);
            const date = getSignal<Date | undefined>(entry, 'start', undefined);
            const excessiveIdlingTime = getSignal<number | undefined>(entry, 'excessiveIdlingTime', undefined);

            const details = mapDetails(entry);

            if (stateOfCharge !== undefined && date !== undefined)
                acc.push({ date, stateOfCharge, excessiveIdlingTime, details });
            return acc;
        }, []);

    const stateOfCharge = Loadable.cata(
        stateOfChargeLoadable,
        entities => ({
            status: Status.SUCCESS,
            data: mapStateOfCharge(entities).sort((a, b) => b.date.getTime() - a.date.getTime()),
        }),
        always({ status: Status.FAILED }),
        always({ status: Status.LOADING }),
        always({ status: Status.LOADING })
    );

    const context = { stateOfCharge };

    return <BatteryContext.Provider value={context}>{children}</BatteryContext.Provider>;
}
