import { get } from 'lodash';

import { LangStatePart } from './../../setup/types';
import { DEFAULT_LOCALE } from './lang';

export const getLocale = (state: LangStatePart) => get(state, 'lang.displayLocale', DEFAULT_LOCALE);

export const getLangLocale = (state: LangStatePart) => get(state, 'lang.displayLocale', DEFAULT_LOCALE);

export const getLanguageData = (state: LangStatePart) => get(state, 'lang.displayMessages', {});

export const getSupportedLocale = (state: LangStatePart) => get(state, 'lang.supportedLocale', '');

export const getAvailableLocales = (state: LangStatePart) => get(state, 'lang.availableLocales', {});
