import { createAction } from '@reduxjs/toolkit';

import { OpconQueryPayload, PerformQueryPayload, PositionQueryPayload, VehspecPayload } from '../types';

export {
    requestDriversSuccess,
    requestEntitiesReceived,
    requestEntitiesReceivedFailed,
    requestEntitiesReceivedSuccess,
    requestEntitiesStatisticsReceivedSuccess,
    requestVehiclesSuccess,
    requestVehicleSpecificationFailed,
    requestVehicleSpecificationSuccess,
    requestVehicleSpecificationReceived,
    cleanUpRequestsCache,
} from './reducer';

export const requestDrivers = createAction('requestDrivers');
export const requestDriversFailed = createAction('requestDriversFailed');
export const requestOpConData = createAction<OpconQueryPayload>('requestOpConData');
export const requestPerformanceData = createAction<PerformQueryPayload>('requestPerformanceData');
export const requestPositions = createAction<PositionQueryPayload>('requestPositions');
export const requestVehicles = createAction('requestVehicles');
export const requestVehiclesFailed = createAction('requestVehiclesFailed');
export const requestGroups = createAction('requestGroups');
export const requestGroupsFailed = createAction('requestGroupsFailed');
export const requestVehicleSpecification = createAction<VehspecPayload>('requestVehicleSpecification');
