import { FormattedMessage } from 'react-intl';

import { Column as ColumnType } from '../../columns/createColumn';

const DriverColumn = ({
    column,
    entity,
}: {
    column?: ColumnType;
    entity: { id: string; level: number; childrenIds: string[] };
}) => {
    if (column === undefined) {
        return <></>;
    }

    return (
        <div className=" margin-bottom-5" key={column.labelId}>
            <label className="margin-bottom-0 text-size-12">
                <FormattedMessage id={column.labelId} />
            </label>
            <div className="text-size-18 ellipsis-1">{column.formatter(column.valueExtractor(entity), entity)}</div>
        </div>
    );
};

export default DriverColumn;
