export default class ApiError extends Error {
    public errorCode: number;
    public isApiError = true;
    constructor(errorCode: number, ...params: (string | undefined)[]) {
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }

        this.errorCode = errorCode;
        this.name = 'ApiError';
    }
}
