import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import emailNotificationImageDE from '../static/release-4.7.0-email-notification-DE.png';
import emailNotificationImageEN from '../static/release-4.7.0-email-notification-EN.png';
import newReportEmailNotificationImage from '../static/release-4.7.0-new-report-email-notification.png';

export default function ReportAutomationEmailNotification({ locale }: { locale: string }) {
    const emailNotificationImage = locale.includes('de') ? emailNotificationImageDE : emailNotificationImageEN;

    return (
        <Fragment>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.reportAutomation.emailNotificationTitle" />
            </h3>
            <p className="margin-bottom-25">
                <FormattedMessage id="help.reportAutomation.emailNotificationDescription" />
            </p>
            <div className="display-flex gap-15 align-items-start">
                <div style={{ flexBasis: '27%' }} className="padding-right-5">
                    <p className="margin-top-0 text-bold">
                        <FormattedMessage id="help.reportAutomation.enableEmailNotification" />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'help.reportAutomation.enableEmailNotificationFullDescription'}
                            values={{
                                // eslint-disable-next-line react/display-name
                                p: (chunks: unknown[]) => <p>{chunks}</p>,
                            }}
                        />
                    </p>
                </div>
                <div style={{ flexBasis: '23%' }} className="margin-right-15">
                    <img
                        alt="new-report-email-notification"
                        src={newReportEmailNotificationImage}
                        className="shadow-hard-to-bottom"
                        style={{ maxWidth: '100%', objectFit: 'contain' }}
                    />
                </div>
                <div style={{ flexBasis: '27%' }} className="margin-left-15 margin-right-5">
                    <p className="margin-top-0 text-bold">
                        <FormattedMessage id="help.reportAutomation.emailNotificationTitle" />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'help.reportAutomation.emailNotificationFullDescription'}
                            values={{
                                // eslint-disable-next-line react/display-name
                                p: (chunks: unknown[]) => <p>{chunks}</p>,
                                // eslint-disable-next-line react/display-name
                                i: (chunks: unknown[]) => <i>{chunks}</i>,
                                // eslint-disable-next-line react/display-name
                                b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                            }}
                        />
                    </p>
                </div>
                <div style={{ flexBasis: '23%' }} className="margin-right-20">
                    <img
                        alt="email-notification"
                        src={emailNotificationImage}
                        className="shadow-hard-to-bottom"
                        style={{ maxWidth: '100%', objectFit: 'contain' }}
                    />
                </div>
            </div>
        </Fragment>
    );
}
