import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AnonymousDriver, Driver, DriverType, EssentialDriver, NoCardDriver } from '../types';
import Label from './Label';

export const AnonymizedDriver: React.FC = () => {
    return (
        <span style={{ fontStyle: 'oblique' }}>
            <FormattedMessage id={'anonymized'} />
        </span>
    );
};

export const UnknownDriver: React.FC = () => {
    return <>-</>;
};

const EssentialsDriver: React.FC = () => {
    return <>1</>;
};

export const SingleDriver: React.FC<{ driver?: Driver }> = ({ driver }) => {
    if (!driver) {
        return <UnknownDriver />;
    }
    if ((driver as NoCardDriver)?.type === DriverType.NO_DRIVER_CARD) return <UnknownDriver />;
    if ((driver as AnonymousDriver)?.type === DriverType.ANONYMOUS) return <AnonymizedDriver />;
    if ((driver as EssentialDriver)?.type === DriverType.ESSENTIAL) return <EssentialsDriver />;

    const { firstName, lastName, displayName } = driver as {
        firstName?: string | null;
        lastName?: string | null;
        displayName?: string | null;
    };

    const formattedFirstName = firstName ? (
        <span className="text-light margin-right-3 " data-test="first-name">
            {firstName}
        </span>
    ) : null;

    if (lastName && formattedFirstName) {
        return (
            <span>
                {formattedFirstName} <span>{lastName}</span>
            </span>
        );
    }
    return <>{lastName || firstName || displayName}</> || <UnknownDriver />;
};

const LabelContent: React.FC<{ drivers: Driver[] }> = ({ drivers }) => {
    if (!drivers.length) return <UnknownDriver />;

    const isSingleDriver = drivers.length === 1;
    const firstDriver = drivers?.[0];
    const realDrivers = drivers.filter(
        driver => ('type' in driver && driver.type !== DriverType.NO_DRIVER_CARD) || !('type' in driver)
    );

    return isSingleDriver || realDrivers.length === 1 ? (
        <Label
            id="drivers"
            icon="rioglyph-driver"
            color={(firstDriver as NoCardDriver)?.type === DriverType.NO_DRIVER_CARD ? 'gray' : undefined}
        >
            <span className="display-inline-block">
                <SingleDriver driver={firstDriver} />
            </span>
        </Label>
    ) : (
        <Label id="drivers" icon="rioglyph-driver">
            {realDrivers.length}
        </Label>
    );
};

const DriverLabel: React.FC<{ drivers?: Driver[]; explicit?: boolean }> = ({ drivers = [], explicit = false }) => {
    if (explicit) {
        return drivers.length ? (
            <ul style={{ listStyle: 'none' }} className="padding-0">
                {drivers.map((driver, idx) => (
                    <li
                        key={_.get(driver, 'displayName') || idx}
                        className="margin-0 display-block ellipsis-1 width-100pct"
                    >
                        <Label id="drivers" icon="rioglyph-driver" color="dark">
                            <SingleDriver driver={driver} />
                        </Label>
                    </li>
                ))}
            </ul>
        ) : (
            <span>
                <UnknownDriver />
            </span>
        );
    }

    return <LabelContent drivers={drivers} />;
};

export default React.memo(DriverLabel);
