import { VehicleFuelType } from '@api/models/types';
import SingleUpBarGraph from '@components/commonGraphComponents/SingleUpBarGraph';
import TwoUpAndOneDownBarsGraph from '@components/commonGraphComponents/TwoUpAndOneDownBarsGraph';
import TwoUpBarsGraph from '@components/commonGraphComponents/TwoUpBarsGraph';
import { convertDataToXYValue, mergeExistingDataWithPlaceholders } from '@components/commonGraphComponents/utils';
import { GraphType } from '@components/graphComponents/types';
import { Section, Values } from '@components/summary/types';
import { VehicleGraphDimensions } from '@pages/vehicleAnalysis/vehicleDataGraph';
import { IntlFormatters } from 'react-intl';

import { DateRange } from '../../../types';

const commonKPIS = [
    'foresightedDrivingPercentageV2',
    'mileage',
    'averageWeight',
    'coastingRating',
    'accelerationPedalRatingV2',
    'brakingRating',
    'cruiseControlRatingV2',
    'overspeedRating',
];

const getGraphType = (column: Section) => {
    if (commonKPIS.includes(column.key)) {
        return GraphType.SINGLE_UP_BAR;
    } else if (column.key === 'fuelEfficiency') {
        return GraphType.TWO_UP_BARS;
    }

    return GraphType.TWO_UP_AND_ONE_DOWN_BARS;
};

const TruEGraph = ({
    column,
    dateRange,
    dimensions,
    dieselData,
    electricData,
    intl,
    languageData,
    tickFormatter,
    selectedFuelTypes,
}: {
    column: Section;
    dateRange: DateRange;
    dimensions: VehicleGraphDimensions;
    dieselData: Values | undefined;
    electricData:
        | {
              values: Values;
              recuperationValues?: Values | undefined;
          }
        | undefined;
    intl: IntlFormatters;
    languageData: Record<string, string>;
    tickFormatter: (dateRange: DateRange) => (d: Date) => string;
    selectedFuelTypes: VehicleFuelType[];
}) => {
    const existingData = convertDataToXYValue({ dieselData, electricData, selectedFuelTypes });
    const mappedData = mergeExistingDataWithPlaceholders({ dateRange, existingData });

    const graphType = getGraphType(column);

    const truEGraphDimensions = { ...dimensions, horizontalMargin: 80 };

    return (
        <>
            {graphType === GraphType.SINGLE_UP_BAR && (
                <SingleUpBarGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    fuelType={selectedFuelTypes[0]}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
            {graphType === GraphType.TWO_UP_BARS && (
                <TwoUpBarsGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
            {graphType === GraphType.TWO_UP_AND_ONE_DOWN_BARS && (
                <TwoUpAndOneDownBarsGraph
                    column={column}
                    data={mappedData}
                    dateRange={dateRange}
                    dimensions={truEGraphDimensions}
                    intl={intl}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                />
            )}
        </>
    );
};

export default TruEGraph;
