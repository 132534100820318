import { StyleSheet } from '@react-pdf/renderer';

export const FONT_SIZES = {
    SUB_TITLE: 8,
    TEXT: 10,
    TITLE: 15,
};

export default StyleSheet.create({
    description: {
        marginTop: 20,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        fontFamily: 'Regular',
        fontSize: FONT_SIZES.TEXT,
        padding: 20,
        paddingBottom: 80,
    },
    top: {
        flexDirection: 'row',
    },
    left: {
        flex: 1,
        justifyContent: 'space-between',
    },
    right: {
        width: '10%',
    },
    title: {
        fontSize: FONT_SIZES.TITLE,
        fontFamily: 'Bold',
    },
    subDescription: {
        marginLeft: 10,
        marginTop: 5,
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
