import OverlayTriggerWithInjectedIntl from '@components/OverlayTriggerWithInjectedIntl';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { PADDED_DATE_FORMAT } from '@utils/paddedDateFormat';
import { FormattedDate } from 'react-intl';

interface Props {
    operatingDays?: string[];
    totalNumberOfDays?: number;
}

const Perf3OperationalDays = ({ operatingDays, totalNumberOfDays }: Props) => {
    const tooltip = (
        <Tooltip id="operational-days-information" width="auto">
            <ul style={{ listStyle: 'none' }} className="padding-0 margin-0">
                {operatingDays &&
                    operatingDays.map(day => (
                        <li key={day}>
                            <FormattedDate value={day} {...(PADDED_DATE_FORMAT as { format?: string })} />
                        </li>
                    ))}
            </ul>
        </Tooltip>
    );

    return (
        <>
            {totalNumberOfDays ? (
                <span className="display-flex align-items-center" data-test="perf3OperationalDays">
                    <span>
                        {operatingDays ? operatingDays.length : '-'}/{totalNumberOfDays}
                    </span>
                    <OverlayTriggerWithInjectedIntl placement="top" overlay={tooltip}>
                        <span className="rioglyph rioglyph-info-sign text-color-info" id="operatingDaysInfoIcon" />
                    </OverlayTriggerWithInjectedIntl>
                </span>
            ) : (
                '-'
            )}
        </>
    );
};

export default Perf3OperationalDays;
