import moment from 'moment';

import { DateRange } from '../../types';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_FORMAT_WITH_TIME = 'YYYY-MM-DD-HH.mm.ss';
const MAX_FILE_NAME_SIZE = 255;
const INVALID_CHARS_REGEX = /[\\/:*?"<>|]/g;

export default function fileName({ dateRange, value }: { dateRange: DateRange; value: string }) {
    const rightNow = moment(new Date()).format(DATE_FORMAT);
    const start = moment(dateRange.start).format(DATE_FORMAT_WITH_TIME);
    const end = moment(dateRange.end).format(DATE_FORMAT_WITH_TIME);

    return `${rightNow}--${value}--${start}--${end}`.replace(INVALID_CHARS_REGEX, '').slice(0, MAX_FILE_NAME_SIZE);
}
