import { StyleSheet } from '@react-pdf/renderer';

export const margin = 20;

export default StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        fontFamily: 'Regular',
        fontSize: 10,
        padding: 20,
        paddingBottom: 60,
    },
    top: {
        flexDirection: 'row',
    },
    left: {
        flex: 1,
        justifyContent: 'space-between',
    },
    right: {
        width: '10%',
    },
    title: {
        fontSize: 15,
        fontFamily: 'Bold',
    },
    date: {
        fontFamily: 'Bold',
    },
    databoxContainer: {
        border: '#2a3740',
        borderWidth: 1.2,
        marginBottom: 20,
    },
    databoxTitleContainer: {
        backgroundColor: '#2a3740',
        width: '100%',
        display: 'flex',
    },
    databoxTitle: {
        color: 'white',
        margin: 5,
        fontFamily: 'Bold',
        fontSize: 10,
    },
    emptyOpconWarningBox: {
        flexDirection: 'column',
        width: '100%',
        height: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    emptyOpconWarningTitle: {
        fontFamily: 'Bold',
    },
});
