import { getSignal } from '@api/index';
import { Id, Vehicle } from '@api/models/types';
import { isEmpty, isUndefined } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HydratedEntity, HydratedEntityWithChildren } from '../../types';

export function getAccuracyWarning(row: HydratedEntityWithChildren | HydratedEntity): JSX.Element | null {
    const vehicles = getSignal<Vehicle[]>(row, 'vehicles', []);
    const inaccurateVehiclesIds = getSignal<Id[]>(row, 'inaccurateVehicleIds', []);

    const innacurateVehicles = inaccurateVehiclesIds
        .map((id: string) => vehicles.find(vehicle => vehicle.vehicleId === id))
        .filter((vehicle: Vehicle | undefined) => !isUndefined(vehicle)) as Vehicle[];

    const inaccurateVehicleNames = innacurateVehicles.map((vehicle: Vehicle) => vehicle.name);

    return !isEmpty(inaccurateVehicleNames) ? (
        <AccuracyWarning key="inaccuracyWarnings" inaccurateVehicleNames={inaccurateVehicleNames} />
    ) : null;
}

function AccuracyWarning({ inaccurateVehicleNames }: { inaccurateVehicleNames: string[] }): JSX.Element {
    return (
        <React.Fragment>
            <dt data-test="inaccuracy-warning">
                <FormattedMessage id="inaccuracyWarnings" />
            </dt>
            <dd>
                <FormattedMessage id="inaccuracyWarning" values={{ vehicles: inaccurateVehicleNames.join(', ') }} />
            </dd>
        </React.Fragment>
    );
}

export default AccuracyWarning;
