import { isCalcParamsDialogVisible, toggleCalcParamsDialog } from '@features/settings/reducer';
import { Dispatch } from '@reduxjs/toolkit';
import Button from '@rio-cloud/rio-uikit/Button';
import { trackingAttributes } from '@utils/tracking';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SettingStatePart } from '../../../setup/types';
import CalculationParamsDialog from './CalculationParamsDialog';

export const CalculationParamsSettings: React.FC<{
    isCalcParamsDialogVisible: boolean;
    toggleCalcParamsDialogVisible: (visible: boolean) => void;
}> = ({ isCalcParamsDialogVisible, toggleCalcParamsDialogVisible }) => {
    return (
        <div className="panel panel-default" data-test="calculationParameters">
            <div className="panel-body">
                <div className="row align-items-center display-block-xs display-flex-md padding-top-15 padding-bottom-15">
                    <div className="col-md-3 text-size-16 col-xs-12">
                        <FormattedMessage id="administration.rating.parameterAdaptation" />
                    </div>
                    <div className="col-md-6 col-xs-12 text-color-gray">
                        <FormattedMessage id="administration.rating.parameterAdaptation.description" />
                    </div>
                    <div className="col-md-3 text-right col-xs-12 display-flex align-items-center justify-content-end">
                        <Button
                            {...trackingAttributes(
                                'click',
                                'perform, administrationPage',
                                'customizeParameterAdaptation',
                                'administration'
                            )}
                            data-test="calculationParams"
                            className="padding-left-20 padding-right-20"
                            bsStyle="primary"
                            onClick={() => toggleCalcParamsDialogVisible(true)}
                        >
                            <FormattedMessage id="administration.rating.parameterAdaptation.customize" />
                        </Button>
                    </div>
                    {isCalcParamsDialogVisible && (
                        <CalculationParamsDialog toggleDialog={toggleCalcParamsDialogVisible} />
                    )}
                </div>
            </div>
        </div>
    );
};

export const mapStateToProps = (state: SettingStatePart) => ({
    isCalcParamsDialogVisible: isCalcParamsDialogVisible(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleCalcParamsDialogVisible: (visible: boolean) => dispatch(toggleCalcParamsDialog(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculationParamsSettings);
