import { VehicleFuelType } from '@api/index';
import _ from 'lodash';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { Id } from 'src/types';

import { configureReporting } from '../../setup/errorReporting';
import { loadSettingsSuccess as settingsLoaded } from '../settings/reducer';
import { loadSettingsFromStorage, saveSettingsToStorage } from '../settings/saga';
import {
    collapseTree,
    expandTree,
    setSelectedDriverGroups,
    setSelectedDrivers,
    setSelectedFuelTypeGroups,
    setSelectedVehicleGroups,
    setSelectedVehicles,
    setShowEmptyGroups,
} from './actions';

const { captureException } = configureReporting(window, import.meta.env);

export const TREE_SETTINGS = {
    SELECTED_VEHICLE_GROUPS: 'treeSelectedGroups',
    SELECTED_DRIVER_GROUPS: 'treeSelectedDriversGroups',
    SELECTED_VEHICLES: 'treeSelectedVehicles',
    SELECTED_FUEL_TYPE_VEHICLES: 'treeSelectedFuelTypeVehicles',
    SELECTED_DRIVERS: 'treeSelectedDrivers',
    SHOW_EMPTY_GROUPS: 'treeShowEmptyGroups',
};

export function* toggleTree() {
    yield delay(10);
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        const event = new UIEvent('resize', { bubbles: true, cancelable: false, view: window, detail: 0 });
        yield call(() => window.dispatchEvent(event));
    } else {
        yield call(() => window.dispatchEvent(new Event('resize')));
    }
}

export function* saveShowEmptyGroups({ payload }: { payload: boolean }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SHOW_EMPTY_GROUPS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedDrivers({ payload }: { payload: Id[] }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVERS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedVehicles({ payload }: { payload: Id[] }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLES, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedVehicleGroups({ payload }: { payload: Id[] }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLE_GROUPS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedFuelTypeGroups({ payload }: { payload: VehicleFuelType[] }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_FUEL_TYPE_VEHICLES, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* saveSelectedDriverGroups({ payload }: { payload: Id[] }) {
    try {
        yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVER_GROUPS, payload);
    } catch (e) {
        yield call(captureException, e);
    }
}

function* clearAllSettings() {
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLE_GROUPS, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVER_GROUPS, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_VEHICLES, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_DRIVERS, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SELECTED_FUEL_TYPE_VEHICLES, []);
    yield saveSettingsToStorage(TREE_SETTINGS.SHOW_EMPTY_GROUPS, false);
}

export function* restoreSavedSettings() {
    try {
        const selectedVehicleGroups: Id[] = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_VEHICLE_GROUPS, null);
        const selectedDriverGroups: Id[] = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_DRIVER_GROUPS, null);
        const selectedVehicles: Id[] = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_VEHICLES, null);
        const selectedFuelTypeVehicles: VehicleFuelType[] = yield loadSettingsFromStorage(
            TREE_SETTINGS.SELECTED_FUEL_TYPE_VEHICLES,
            null
        );
        const selectedDrivers: Id[] = yield loadSettingsFromStorage(TREE_SETTINGS.SELECTED_DRIVERS, null);
        const showEmptyGroups: Id[] = yield loadSettingsFromStorage(TREE_SETTINGS.SHOW_EMPTY_GROUPS, false);

        yield put(setShowEmptyGroups(showEmptyGroups));

        if (_.isArray(selectedDriverGroups)) {
            yield put(setSelectedDriverGroups(selectedDriverGroups));
        }

        if (_.isArray(selectedVehicleGroups)) {
            yield put(setSelectedVehicleGroups(selectedVehicleGroups));
        }

        if (_.isArray(selectedVehicles)) {
            yield put(setSelectedVehicles(selectedVehicles));
        }

        if (_.isArray(selectedFuelTypeVehicles)) {
            yield put(setSelectedFuelTypeGroups(selectedFuelTypeVehicles));
        }

        if (_.isArray(selectedDrivers)) {
            yield put(setSelectedDrivers(selectedDrivers));
        }
    } catch (e) {
        yield clearAllSettings();
        yield call(captureException, e);
    }
}

export function* root() {
    yield takeLatest([expandTree, collapseTree], toggleTree);
    yield takeLatest(setSelectedVehicleGroups, saveSelectedVehicleGroups);
    yield takeLatest(setSelectedDriverGroups, saveSelectedDriverGroups);
    yield takeLatest(setSelectedVehicles, saveSelectedVehicles);
    yield takeLatest(setSelectedFuelTypeGroups, saveSelectedFuelTypeGroups);
    yield takeLatest(setSelectedDrivers, saveSelectedDrivers);
    yield takeLatest(setShowEmptyGroups, saveShowEmptyGroups);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}

const mergedTreeSagas = [root];

export default mergedTreeSagas;
