export const DRIVE_PROGRAM_REQUEST_ATTRIBUTES = [
    'driver_ids',
    'drive_program_active_distance',
    'drive_program_active_time',
    'drive_program_efficiency_distance',
    'drive_program_efficiency_time',
    'drive_program_efficiencyplus_distance',
    'drive_program_efficiencyplus_time',
    'drive_program_emergency_distance',
    'drive_program_emergency_time',
    'drive_program_fluid_distance',
    'drive_program_fluid_time',
    'drive_program_heavyduty_distance',
    'drive_program_heavyduty_time',
    'drive_program_maneuver_distance',
    'drive_program_maneuver_time',
    'drive_program_manual_distance',
    'drive_program_manual_time',
    'drive_program_offroad_distance',
    'drive_program_offroad_time',
    'drive_program_offroadwithassistance_distance',
    'drive_program_offroadwithassistance_time',
    'drive_program_performance_distance',
    'drive_program_performance_time',
    'drive_program_total_distance',
    'drive_program_total_time',
    'vehicle_ids',
];
