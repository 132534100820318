import { getFeatureToggles } from '@common/permissions/selectors';
import { requestExcelDownload } from '@features/download/actions';
import DownloadMenu from '@features/download/DownloadMenu';
import fileName from '@features/download/fileName';
import {
    TEMPLATE_EXCEL_DOWNLOAD,
    TEMPLATE_OPCON_REQUEST,
    TEMPLATE_VEHICLE_REQUEST_BY_TIME,
    TEMPLATE_VEHICLE_REQUEST_BY_TIME_ELECTRIC,
} from '@features/download/queries';
import { QueryTemplateExcelResponse, QueryTemplateOpConResponse } from '@features/download/types';
import { Payload as PdfPayload, requestPrint } from '@features/pdf/actions';
import { TEMPLATE_VEHICLE_REQUEST } from '@features/pdf/queries';
import { CalculationParams, savedCalculationParams } from '@features/settings/reducer';
import { SortDirection } from '@utils/sortyByProperty';
import configureGetCreateTimeInterval from '@utils/timeInterval';
import { trackingAttributes } from '@utils/tracking';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { State } from 'src/setup/types';

import ConditionalTooltip from '../../components/ConditionalTooltip';
import { AVAILABLE_CONFIGURATIONS } from '../../constants/pdf';
import { hasSomePerformVehicleSelected as getHasSomePerformVehicleSelected } from '../../data/selectors';
import { DateRange, Id, PerformSegmentBy } from '../../types';
import { UseCaseSetting } from './reducer';
import { getSelectedUseCase, getSortedBy, getUseCaseSetting } from './selectors';

const timeIntervalType = configureGetCreateTimeInterval({
    days: 'day',
    weeks: 'week',
    months: 'month',
    quarters: 'quarter',
    years: 'year',
});

type Props = {
    dateRange: DateRange;
    useCaseKey: string;
    vehicles: Id[];
    dieselVehicles: Id[];
    electricVehicles: Id[];
    onDownloadRequested: (arg: {
        aggregationQuery: QueryTemplateExcelResponse;
        opConQuery: QueryTemplateOpConResponse;
        fileName: string;
    }) => void;
    onPDFRequested: (args: PdfPayload) => void;
    disabled: boolean;
    hasSomePerformVehicleSelected: boolean;
    columnSettings: UseCaseSetting;
    sortBy: { key: string; order: SortDirection } | undefined;
    isLatamTenant?: boolean;
    calculationParameters: CalculationParams;
    isTruEEnabled: boolean;
};

export function VehicleDownloadMenu({
    dateRange,
    useCaseKey,
    vehicles,
    dieselVehicles,
    electricVehicles,
    onDownloadRequested,
    onPDFRequested,
    disabled,
    hasSomePerformVehicleSelected,
    columnSettings,
    sortBy,
    isLatamTenant = false,
    calculationParameters,
    isTruEEnabled,
}: Props) {
    const downloadOptions = [
        { header: true, value: <FormattedMessage id="download.excelReport" /> },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'perform, downloadButton',
                        'downloadFleetAnalysisExcel',
                        'downloadExcel, fleetAnalysis, downloadByVehicles'
                    )}
                >
                    <FormattedMessage id="download.excelByVehicle" />
                </div>
            ),
            onSelect: () => {
                onDownloadRequested({
                    aggregationQuery: TEMPLATE_EXCEL_DOWNLOAD({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        driverIds: [],
                    }),
                    opConQuery: TEMPLATE_OPCON_REQUEST({
                        start: dateRange.start,
                        end: dateRange.end,
                        vehicleIds: vehicles,
                        groupBy: ['asset-id'],
                    }),
                    fileName: fileName({ dateRange, value: 'vehicle' }),
                });
            },
        },
        ...(!isLatamTenant
            ? [
                  {
                      value: (
                          <div
                              {...trackingAttributes(
                                  'click',
                                  'perform, downloadButton',
                                  'downloadFleetAnalysisExcel',
                                  'downloadExcel, fleetAnalysis, downloadByDriversAndVehicles'
                              )}
                          >
                              <FormattedMessage id="download.excelByDriverAndVehicle" />
                          </div>
                      ),
                      onSelect: () => {
                          onDownloadRequested({
                              aggregationQuery: TEMPLATE_EXCEL_DOWNLOAD({
                                  start: dateRange.start,
                                  end: dateRange.end,
                                  vehicleIds: vehicles,
                                  segmentBy: 'driver_and_vehicle',
                                  driverIds: [],
                              }),
                              opConQuery: TEMPLATE_OPCON_REQUEST({
                                  start: dateRange.start,
                                  end: dateRange.end,
                                  vehicleIds: vehicles,
                                  groupBy: ['asset-id', 'driver-id'],
                              }),
                              fileName: fileName({ dateRange, value: 'driver-and-vehicle' }),
                          });
                      },
                  },
              ]
            : []),
    ];

    const pdfExportOptions = [
        { header: true, value: <FormattedMessage id="download.pdfReport" /> },
        {
            value: (
                <div
                    {...trackingAttributes(
                        'click',
                        'perform, downloadButton',
                        'downloadFleetAnalysisPdf',
                        'downloadPdf, fleetAnalysis, downloadByVehicles'
                    )}
                >
                    <FormattedMessage id="download.pdfReportByVehicle" />
                </div>
            ),
            onSelect: () =>
                onPDFRequested({
                    id: AVAILABLE_CONFIGURATIONS.VEHICLE,
                    useCase: useCaseKey,
                    columnSettings,
                    dateRange: { start: moment(dateRange.start).toDate(), end: moment(dateRange.end).toDate() },
                    sortBy,
                    aggregationQuery: TEMPLATE_VEHICLE_REQUEST({
                        ...dateRange,
                        vehicleIds: vehicles,
                    }),
                    summaryQuery: TEMPLATE_VEHICLE_REQUEST_BY_TIME({
                        ...dateRange,
                        intervalType: timeIntervalType(dateRange),
                        vehicleIds: dieselVehicles,
                    }),
                    summaryElectricQuery: TEMPLATE_VEHICLE_REQUEST_BY_TIME_ELECTRIC({
                        ...dateRange,
                        intervalType: timeIntervalType(dateRange),
                        vehicleIds: electricVehicles,
                    }),
                    calculationParameters: calculationParameters,
                    isTruEEnabled,
                }),
        },
        ...(!isLatamTenant
            ? [
                  {
                      value: (
                          <div
                              {...trackingAttributes(
                                  'click',
                                  'perform, downloadButton',
                                  'downloadFleetAnalysisPdf',
                                  'downloadPdf, fleetAnalysis, downloadByDriversAndVehicles'
                              )}
                          >
                              <FormattedMessage id="download.pdfReportByVehicleAndDrivers" />
                          </div>
                      ),
                      onSelect: () =>
                          onPDFRequested({
                              id: AVAILABLE_CONFIGURATIONS.VEHICLE_AND_DRIVER,
                              useCase: useCaseKey,
                              columnSettings,
                              dateRange: {
                                  start: moment(dateRange.start).toDate(),
                                  end: moment(dateRange.end).toDate(),
                              },
                              sortBy,
                              aggregationQuery: TEMPLATE_VEHICLE_REQUEST({
                                  ...dateRange,
                                  vehicleIds: vehicles,
                                  segmentBy: PerformSegmentBy.driver_and_vehicle,
                              }),
                              summaryQuery: TEMPLATE_VEHICLE_REQUEST_BY_TIME({
                                  ...dateRange,
                                  intervalType: timeIntervalType(dateRange),
                                  vehicleIds: dieselVehicles,
                              }),
                              summaryElectricQuery: TEMPLATE_VEHICLE_REQUEST_BY_TIME_ELECTRIC({
                                  ...dateRange,
                                  intervalType: timeIntervalType(dateRange),
                                  vehicleIds: electricVehicles,
                              }),
                              calculationParameters: calculationParameters,
                              isTruEEnabled,
                          }),
                  },
              ]
            : []),
    ];

    return (
        <ConditionalTooltip
            enabled={!disabled && !isLatamTenant && !hasSomePerformVehicleSelected}
            // eslint-disable-next-line react/display-name
            getTooltipChildren={() => <FormattedMessage id={'featureNotIncludedInEssentials'} />}
        >
            <span className="btn-group">
                <DownloadMenu
                    options={downloadOptions.concat(pdfExportOptions)}
                    owner="vehicle"
                    disabled={disabled || (!isLatamTenant && !hasSomePerformVehicleSelected)}
                />
            </span>
        </ConditionalTooltip>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onPDFRequested: (args: PdfPayload) => dispatch(requestPrint(args)),
    onDownloadRequested: ({
        aggregationQuery,
        opConQuery,
        fileName,
    }: {
        aggregationQuery: QueryTemplateExcelResponse;
        opConQuery: QueryTemplateOpConResponse;
        fileName: string;
    }) => dispatch(requestExcelDownload({ owner: 'vehicle', opConQuery, aggregationQuery, fileName })),
});

export default connect(
    (state: State) => ({
        columnSettings: getUseCaseSetting(state, getSelectedUseCase(state)),
        sortBy: getSortedBy(state),
        hasSomePerformVehicleSelected: getHasSomePerformVehicleSelected(state),
        calculationParameters: savedCalculationParams(state),
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
    }),
    mapDispatchToProps
)(VehicleDownloadMenu);
