// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

export const stagingConfig = {
    backend: {
        AUTHENTICATION_SERVICE: 'https://auth.iam.rio.cloud/oauth',
        ROUTE_HISTORY: 'https://api.asset-history.rio.cloud/historic-positions/assets',
        PERFORMANCE_PLATFORM_INFO: 'https://api.perform.rio.cloud/platforminfo',
        USERADMIN_SERVICE: 'https://api.iam.ccp-prod.net/user-management',
        PERFORMANCE_MERGER: 'https://api.perform.rio.cloud/performcache',
        QUERY_SERVICE: 'https://api.perform.rio.cloud',
        CONNECTED_CODRIVER_SERVICE: 'https://api.perform.rio.cloud',
        EXCEL_EXPORT_SERVICE: 'https://api.perform.rio.cloud',
        OPCON: 'https://api.opcon.rio.cloud/v1',
        HERE_PROXY: `https://geocoder.ls.hereapi.com/6.2/geocode.json`,
        MENU_URL: 'https://menu.rio.cloud',
        TAGS_SERVICE: `https://api.tags.rio.cloud`,
        ASSETS_SERVICE: `https://api.assets.rio.cloud`,
        DRIVERS_SERVICE: `https://api.drivers.rio.cloud`,
        PDF_SERVICE: `https://api.perform.rio.cloud/staging/pdf-export`,
        SETTINGS_SERVICE: `https://api.perform.rio.cloud/settings`,
        VEHICLE_SPEC: 'https://api.vehspec.rio.cloud',
        REPORTS_SERVICE: `https://api.perform.rio.cloud/reporting`,
        HERE_GEOCODE_URL: 'https://geocode.search.hereapi.com/v1/geocode',
        HERE_GEOFENCING_URL: 'https://geofencing.hereapi.com/v8/geofence',
        HERE_MAPS_URL: 'https://smap.hereapi.com/v8/maps/index',
        HERE_MAPS_ATTRIBUTES_URL: 'https://smap.hereapi.com/v8/maps/attributes',
    },
    homeRoute: 'https://home.rio.cloud',
    id: 'env.production',
    login: {
        clientId: 'b9ecbea9-16c7-4c88-b2b1-5ea75e4c2f44',
        authority: `https://auth.iam.rio.cloud`,
        mockAuthorization: false,
        preventRedirect: false,
    },
    logoutUri: `https://auth.iam.rio.cloud/logout?redirect_uri=${window.location.origin}/redirect.html`,
};
