import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import { line } from 'd3-shape';
import { useContext, useEffect, useRef } from 'react';

import { ANIMATION_DURATION, WITH_DATA_COLOR, WITHOUT_DATA_COLOR } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';
import { hasValue } from './utils';

export default function Line({ strokeWidth = 4 }: { strokeWidth?: number }) {
    const { xScale: x, leftYScale: y, data } = useContext(GraphContext);
    const group = useRef(null);

    useEffect(() => {
        if (group && group.current) {
            const withDataLineGenerator = line<DataEntry>()
                .defined(d => hasValue(d))
                .x(d => x((d.x as unknown) as number) || 0)
                .y(d => y(d.leftUpY));
            const withoutDataLineGenerator = line<DataEntry>()
                .x(d => x((d.x as unknown) as number) || 0)
                .y(d => (hasValue(d) ? y(d.leftUpY) : y(y.domain()[1])));

            const withDataPath = select(group.current).select('.with-data');
            const withoutDataPath = select(group.current).select('.without-data');

            if (data.length > 1) {
                withDataPath
                    .datum(data)
                    .transition()
                    .ease(easeLinear)
                    .duration(ANIMATION_DURATION)
                    .attr('d', withDataLineGenerator);

                withoutDataPath
                    .datum(data)
                    .transition()
                    .ease(easeLinear)
                    .duration(ANIMATION_DURATION)
                    .attr('d', withoutDataLineGenerator);
            } else if (data.length === 1) {
                const singlePoint = data[0];
                const singlePointY = y(singlePoint.leftUpY);

                if (singlePointY !== undefined) {
                    const [xStart, xEnd] = x.range();

                    withDataPath.attr('d', '');
                    withoutDataPath.attr('d', '');

                    select(group.current)
                        .selectAll('.single-point')
                        .data([singlePoint])
                        .join('line')
                        .attr('class', 'single-point')
                        .attr('x1', xStart)
                        .attr('y1', singlePointY)
                        .attr('x2', xEnd)
                        .attr('y2', singlePointY)
                        .attr('stroke', WITH_DATA_COLOR)
                        .attr('stroke-width', strokeWidth);
                }
            } else {
                withDataPath.attr('d', '');
                withoutDataPath.attr('d', '');
                select(group.current)
                    .selectAll('.single-point')
                    .remove();
            }
        }
    }, [data, x, y]);

    return (
        <g ref={group} className="line">
            <path fill="none" className="without-data" stroke={WITHOUT_DATA_COLOR} strokeWidth={`${strokeWidth}`} />
            <path fill="none" className="with-data" stroke={WITH_DATA_COLOR} strokeWidth={`${strokeWidth}`} />
        </g>
    );
}
