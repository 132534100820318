import { getAccount } from '@common/login/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import AssetTree from '@rio-cloud/rio-uikit/AssetTree';
import RioTree from '@rio-cloud/rio-uikit/Tree';
import TreeCategory from '@rio-cloud/rio-uikit/TreeCategory';
import TreeOption from '@rio-cloud/rio-uikit/TreeOption';
import TreeSearch from '@rio-cloud/rio-uikit/TreeSearch';
import { generatePushToDataLayer } from '@utils/tracking';
import _ from 'lodash';
import { getOr } from 'lodash/fp';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import { State } from 'src/setup/types';
import { Driver, Id, Vehicle, VehicleGroup } from 'src/types';

import { unassignedGroup, VehicleFuelType } from '../../api';
import { DRIVER_ROUTE, VEHICLE_ROUTE } from '../../constants/routes';
import { TreeTabs } from '../../constants/treeTabs';
import { isMobile } from '../ui/reducer';
import {
    selectDriverTab,
    selectVehicleTab,
    setSelectedDriverGroups,
    setSelectedDrivers,
    setSelectedFuelTypeGroups,
    setSelectedVehicleGroups,
    setSelectedVehicles,
    setShowEmptyGroups,
} from './actions';
import {
    getActiveTab,
    getSelectedDriverGroupIds,
    getSelectedDriverIds,
    getSelectedFuelTypes,
    getSelectedVehicleGroupIds,
    getSelectedVehicleIds,
    showEmptyGroups,
} from './selectors';
import TreeFilterButtons from './TreeFilterButtons';
import { getActiveDriverList, getActiveVehicleList, getGroupList } from './uiSelectors';

type Props = {
    account: string | undefined;
    groups: VehicleGroup[];
    vehicles: Vehicle[];
    drivers: Driver[];
    selectedDriverIds: Id[];
    selectedVehicleIds: Id[];
    selectedVehicleGroupIds: Id[];
    selectedFuelTypes: VehicleFuelType[];
    selectVehiclesByFuelType: (arg: VehicleFuelType[]) => void;
    selectVehiclesAndGroups: (arg: {
        account: string | undefined;
        items: Id[];
        groups: Id[];
        previousSelectedItemsIds: Id[];
        previousSelectedGroupsIds: Id[];
    }) => void;
    selectDriversAndGroups: (arg: {
        account: string | undefined;
        items: Id[];
        groups: Id[];
        previousSelectedItemsIds: Id[];
        previousSelectedGroupsIds: Id[];
    }) => void;
    selectTreeVehicleTab: () => void;
    selectTreeDriverTab: () => void;
    selectedDriverGroupIds: Id[];
    categories: (string | null)[];
    currentCategory: string;
    setShowEmptyGroups: (arg: boolean) => void;
    showEmptyGroups?: boolean;
    isMobile?: boolean;
    isTruEEnabled?: boolean;
};

type MappedGroups = (
    | VehicleGroup
    | {
          name: JSX.Element | string;
          id: string;
          entityIds?: string[] | undefined;
          type?: string | undefined;
          position?: 'last' | undefined;
          disabled?: boolean;
          className?: string;
      }
)[];

export const Tree = ({
    account,
    groups = [],
    vehicles = [],
    drivers = [],
    selectedDriverIds,
    selectedVehicleIds,
    selectedVehicleGroupIds,
    selectedFuelTypes = [],
    selectVehiclesByFuelType,
    selectVehiclesAndGroups,
    selectDriversAndGroups,
    selectTreeVehicleTab,
    selectTreeDriverTab,
    selectedDriverGroupIds,
    categories = [],
    currentCategory,
    setShowEmptyGroups,
    showEmptyGroups,
    isMobile,
    isTruEEnabled,
}: Props) => {
    const pathname = useLocation().pathname;
    const [isTreeOpen, handleToggleTree] = useState(!isMobile); /*If we're on mobile we hide the tree*/
    const [expandedDriverGroups, onExpandedDriverGroups] = useState<string[]>([]);
    const [expandedVehicleGroups, onExpandedVehicleGroups] = useState<string[]>([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleToggleEmptyGroups = useCallback(() => setShowEmptyGroups(!showEmptyGroups), [showEmptyGroups]);

    const history = useHistory();
    const intl = useIntl();

    useEffect(() => {
        if (pathname.includes(DRIVER_ROUTE)) {
            selectTreeDriverTab();
        }
        if (pathname.includes(VEHICLE_ROUTE)) {
            selectTreeVehicleTab();
        }
    }, [pathname, selectTreeDriverTab, selectTreeVehicleTab]);

    const changeCategory = (selectedCategoryId: string) => {
        const location = pathname.includes(VEHICLE_ROUTE) ? 'fleetAnalysis' : 'drivingAnalysis';
        const pushToDataLayer = generatePushToDataLayer(account);

        switch (selectedCategoryId) {
            case TreeTabs.VEHICLES:
                pushToDataLayer({
                    eventTrigger: 'click',
                    eventCategory: 'perform, leftSideBar',
                    eventAction: 'openVehicleAnalysis',
                    eventLabel: `vehicleSelect, ${location}`,
                });

                selectTreeVehicleTab();
                history.push(VEHICLE_ROUTE);
                break;
            case TreeTabs.DRIVER:
            default:
                pushToDataLayer({
                    eventTrigger: 'click',
                    eventCategory: 'perform, leftSideBar',
                    eventAction: 'openDriverAnalysis',
                    eventLabel: `driverSelect, ${location}`,
                });

                selectTreeDriverTab();
                history.push(DRIVER_ROUTE);
                break;
        }
    };

    const [mappedGroups, setMappedGroups] = useState<MappedGroups>([]);

    const haveAnyEletricVehicle = vehicles.some(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC);

    const options = [
        <TreeOption
            key="intl-msg:asset-tree.option.showEmptyGroups"
            label={<FormattedMessage id="intl-msg:asset-tree.option.showEmptyGroups" />}
            isChecked={showEmptyGroups}
            onChange={handleToggleEmptyGroups}
        />,
    ];

    // TODO: remove lines 164 to 220 (and readjust code) once AssetTree/Tree components from rio-uikit
    // can handle double spaces as single (and vice-versa) while searching
    const [searchValue, setSearchValue] = useState('');
    const handleUpdateSearch = (value: string) => setSearchValue(value);

    const getVehicleSubtype = (vehicle: Vehicle) => {
        switch (vehicle.fuelType) {
            case VehicleFuelType.ELECTRIC:
                return 'fuel-electric';
            case VehicleFuelType.DIESEL:
            case VehicleFuelType.UNKNOWN:
                return 'fuel-diesel';
            default:
                return '';
        }
    };

    let filteredVehilces = vehicles.filter(item => {
        const name = getOr('', 'name', item).toLowerCase();
        const searchValueToMatch = searchValue
            .toLowerCase()
            .replace(/\s+/g, ' ')
            .trim();

        return (
            selectedFuelTypes?.includes(item.fuelType ?? VehicleFuelType.UNKNOWN) &&
            (name.includes(searchValueToMatch) ||
                name
                    .replace(/\s+/g, ' ')
                    .trim()
                    .includes(searchValueToMatch))
        );
    });

    if (isTruEEnabled) {
        filteredVehilces = filteredVehilces.map(vehicle => ({
            ...vehicle,
            subType: getVehicleSubtype(vehicle),
        })) as Vehicle[];
    }

    useEffect(() => {
        let filteredIds = filteredVehilces.map(vehicle => vehicle.id);
        let stillKept = selectedVehicleIds.filter(id => filteredIds.includes(id));
        selectVehiclesAndGroups({
            account,
            items: stillKept,
            groups: selectedVehicleGroupIds,
            previousSelectedItemsIds: [],
            previousSelectedGroupsIds: [],
        });
    }, [selectedFuelTypes, selectedVehicleGroupIds.length]);

    const filteredDrivers = drivers.filter(item => {
        const nameWithFirstAndOrLast = [getOr('', 'firstName', item), getOr('', 'lastName', item)]
            .join(' ')
            .toLowerCase();
        const nameWithOnlyDisplay = getOr('', 'displayName', item).toLowerCase();
        const searchValueToMatch = searchValue
            .toLowerCase()
            .replace(/\s+/g, ' ')
            .trim();

        return (
            nameWithFirstAndOrLast.includes(searchValueToMatch) ||
            nameWithFirstAndOrLast
                .replace(/\s+/g, ' ')
                .trim()
                .includes(searchValueToMatch) ||
            nameWithOnlyDisplay.includes(searchValueToMatch) ||
            nameWithOnlyDisplay
                .replace(/\s+/g, ' ')
                .trim()
                .includes(searchValueToMatch)
        );
    });

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            setMappedGroups([]);
        } else {
            setMappedGroups(
                groups.map((group: VehicleGroup) =>
                    group.id === unassignedGroup.id
                        ? {
                              ...group,
                              name: <FormattedMessage id="intl-msg:asset-tree.groups.ungrouped" />,
                          }
                        : {
                              ...group,
                              position: 'last',
                          }
                )
            );
        }
    }, [groups, searchValue]);

    useEffect(() => {
        setSearchValue('');
    }, [currentCategory]);

    const treeCategories = _.intersection(categories, Object.values(TreeTabs)).map(category => {
        switch (category) {
            case TreeTabs.VEHICLES:
                return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <TreeCategory
                        key={TreeTabs.VEHICLES}
                        id={TreeTabs.VEHICLES}
                        icon="rioglyph-truck"
                        hasSelection={!_.isEmpty(selectedVehicleIds) || !_.isEmpty(selectedVehicleGroupIds)}
                    >
                        {isTruEEnabled && haveAnyEletricVehicle && (
                            <TreeFilterButtons
                                selectedFuelTypes={selectedFuelTypes}
                                onSelectionChange={selectVehiclesByFuelType}
                            />
                        )}

                        <RioTree
                            groups={mappedGroups}
                            expandedGroups={expandedVehicleGroups}
                            items={filteredVehilces}
                            onExpandGroupsChange={onExpandedVehicleGroups}
                            onSelectionChange={({ items, groups }: { items: Id[]; groups: Id[] }) =>
                                selectVehiclesAndGroups({
                                    account,
                                    items,
                                    groups,
                                    previousSelectedItemsIds: selectedVehicleIds,
                                    previousSelectedGroupsIds: selectedVehicleGroupIds,
                                })
                            }
                            selectedGroups={selectedVehicleGroupIds}
                            selectedItems={selectedVehicleIds}
                            showEmptyGroups={showEmptyGroups}
                            treeOptions={options}
                            onSearchChange={handleUpdateSearch}
                            search={
                                <TreeSearch
                                    value={searchValue}
                                    placeholder={intl.formatMessage({ id: 'find.vehicle' })}
                                    onChange={handleUpdateSearch}
                                />
                            }
                        />
                    </TreeCategory>
                );
            case TreeTabs.DRIVER:
            default:
                return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <TreeCategory
                        key={TreeTabs.DRIVER}
                        id={TreeTabs.DRIVER}
                        icon="rioglyph-driver"
                        hasSelection={!_.isEmpty(selectedDriverIds) || !_.isEmpty(selectedDriverGroupIds)}
                    >
                        <RioTree
                            groups={mappedGroups}
                            expandedGroups={expandedDriverGroups}
                            items={filteredDrivers}
                            onExpandGroupsChange={onExpandedDriverGroups}
                            onSelectionChange={({ items, groups }: { items: Id[]; groups: Id[] }) =>
                                selectDriversAndGroups({
                                    account,
                                    items,
                                    groups,
                                    previousSelectedItemsIds: selectedDriverIds,
                                    previousSelectedGroupsIds: selectedDriverGroupIds,
                                })
                            }
                            selectedGroups={selectedDriverGroupIds}
                            selectedItems={selectedDriverIds}
                            showEmptyGroups={showEmptyGroups}
                            treeOptions={options}
                            onSearchChange={handleUpdateSearch}
                            search={
                                <TreeSearch
                                    value={searchValue}
                                    onChange={handleUpdateSearch}
                                    placeholder={intl.formatMessage({ id: 'find.driver' })}
                                />
                            }
                        />
                    </TreeCategory>
                );
        }
    });

    return (
        <AssetTree
            fly={isMobile && isTreeOpen}
            minWidth={300}
            maxWidth={450}
            currentCategoryId={currentCategory}
            onCategoryChange={changeCategory}
            isOpen={isTreeOpen}
            onToggleTree={handleToggleTree}
        >
            {treeCategories}
        </AssetTree>
    );
};

const isDifferent = (arrayA: string[], arrayB: string[]) =>
    !_.isEmpty(_.difference(arrayA, arrayB)) || !_.isEmpty(_.difference(arrayB, arrayA));

export const mapDispatchToProps = (dispatch: Dispatch) => ({
    selectVehiclesAndGroups: ({
        account,
        items,
        groups,
        previousSelectedItemsIds,
        previousSelectedGroupsIds,
    }: {
        account: string | undefined;
        items: Id[];
        groups: Id[];
        previousSelectedItemsIds: Id[];
        previousSelectedGroupsIds: Id[];
    }) => {
        const pushToDataLayer = generatePushToDataLayer(account);

        const isGroupUpdate = isDifferent(groups, previousSelectedGroupsIds);
        if (isGroupUpdate) {
            pushToDataLayer({
                eventTrigger: 'click',
                eventCategory: 'perform, leftSideBar',
                eventAction: 'selectVehicleGroup',
                eventLabel: 'vehicleSelect, fleetAnalysis',
            });
        }

        const isSingleItemUpdate = isDifferent(items, previousSelectedItemsIds);
        if (isSingleItemUpdate) {
            pushToDataLayer({
                eventTrigger: 'click',
                eventCategory: 'perform, leftSideBar',
                eventAction: 'selectVehicle',
                eventLabel: 'vehicleSelect, fleetAnalysis',
            });
        }

        dispatch(setSelectedVehicles(items));
        dispatch(setSelectedVehicleGroups(groups));
    },
    selectDriversAndGroups: ({
        account,
        items,
        groups,
        previousSelectedItemsIds,
        previousSelectedGroupsIds,
    }: {
        account: string | undefined;
        items: Id[];
        groups: Id[];
        previousSelectedItemsIds: Id[];
        previousSelectedGroupsIds: Id[];
    }) => {
        const pushToDataLayer = generatePushToDataLayer(account);

        const isGroupUpdate = isDifferent(groups, previousSelectedGroupsIds);
        if (isGroupUpdate) {
            pushToDataLayer({
                eventTrigger: 'click',
                eventCategory: 'perform, leftSideBar',
                eventAction: 'selectDriverGroup',
                eventLabel: 'driverSelect, drivingAnalysis',
            });
        }

        const isSingleItemUpdate = isDifferent(items, previousSelectedItemsIds);
        if (isSingleItemUpdate) {
            pushToDataLayer({
                eventTrigger: 'click',
                eventCategory: 'perform, leftSideBar',
                eventAction: 'selectDriver',
                eventLabel: 'driverSelect, drivingAnalysis',
            });
        }

        dispatch(setSelectedDrivers(items));
        dispatch(setSelectedDriverGroups(groups));
    },
    selectVehiclesByFuelType: (selectedFuelTypes: VehicleFuelType[]) =>
        dispatch(setSelectedFuelTypeGroups(selectedFuelTypes)),
    selectTreeDriverTab: () => dispatch(selectDriverTab()),
    selectTreeVehicleTab: () => dispatch(selectVehicleTab()),
    setShowEmptyGroups: (isShown: boolean) => dispatch(setShowEmptyGroups(isShown)),
});

const mapStateToProps = (state: State) => ({
    account: getAccount(state),
    vehicles: getActiveVehicleList(state),
    groups: getGroupList(state),
    drivers: getActiveDriverList(state),
    selectedVehicleGroupIds: getSelectedVehicleGroupIds(state),
    selectedDriverGroupIds: getSelectedDriverGroupIds(state),
    selectedVehicleIds: getSelectedVehicleIds(state),
    selectedDriverIds: getSelectedDriverIds(state),
    selectedFuelTypes: getSelectedFuelTypes(state),
    showEmptyGroups: showEmptyGroups(state),
    currentCategory: getActiveTab(state),
    isMobile: isMobile(state),
    isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
});

export default connect(mapStateToProps, mapDispatchToProps)(Tree);
