import { createSlice } from '@reduxjs/toolkit';

export type LoginState = {
    hasUserSessionEverExpired: boolean;
    userProfile: {
        [key: string]: string | undefined;
    } | null;
    userSessionExpired: boolean;
};

export const defaultState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
};

const loginSlice = createSlice({
    name: 'login',
    initialState: defaultState,
    reducers: {
        userProfileObtained(state, { payload }) {
            return {
                ...state,
                userProfile: payload,
            };
        },
        userSessionExpired(state) {
            return {
                ...state,
                hasUserSessionEverExpired: true,
                userSessionExpired: true,
            };
        },
        userSessionRenewed(state) {
            return {
                ...state,
                userSessionExpired: false,
            };
        },
    },
});

export const { userProfileObtained, userSessionExpired, userSessionRenewed } = loginSlice.actions;

export default loginSlice.reducer;
