import _ from 'lodash';

import { OpconSignals } from '../../types';
import isBelowMileageThreshold from './isBelowMileageThreshold';

export function isValidScore(opcon: OpconSignals | Record<string, never> | undefined, totalMileage: number) {
    const nullScore = { evaluatedMileage: 0 };
    const route = _.get(opcon, 'route.value', nullScore);
    const topology = _.get(opcon, 'topology.value', nullScore);
    const traffic = _.get(opcon, 'traffic.value', nullScore);
    const weight = _.get(opcon, 'weight.value', { evaluatedMileage: totalMileage });
    const range = _.get(opcon, 'range.value', nullScore);

    const facetsThatPassedThreshold = [route, topology, traffic, weight, range].filter(
        score => !isBelowMileageThreshold(score.evaluatedMileage, totalMileage)
    );

    return facetsThatPassedThreshold.length >= 2;
}
