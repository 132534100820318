import _ from 'lodash';
import moment from 'moment';

const getMinDateRangeValue = (tenDaysLimit: boolean | undefined) => {
    return tenDaysLimit
        ? moment()
              .subtract(10, 'days')
              .startOf('day')
              .toDate()
        : null;
};

const getMaxDateRangeValue = (isTodayAllowed: boolean | undefined, useEndOfDay: boolean) => {
    if (!useEndOfDay)
        return moment()
            .add(isTodayAllowed ? 1 : 0, 'day')
            .startOf('day')
            .toDate();
    if (isTodayAllowed) {
        return moment()
            .endOf('day')
            .toDate();
    }
    return moment()
        .subtract(1, 'days')
        .endOf('day')
        .toDate();
};

const getMaxOf = (tenDaysLimit: boolean | undefined, min: Date | null, max: Date) => {
    if (!min) {
        return max;
    }
    return tenDaysLimit && moment(min).isAfter(max) ? min : max;
};

export const TODAY = 'today';
export const YESTERDAY = 'yesterday';
export const CURRENT_WEEK = 'currentWeek';
export const LAST_10_DAYS = 'last10Days';
export const LAST_14_DAYS = 'last14Days';
export const CURRENT_MONTH = 'currentMonth';
export const CURRENT_YEAR = 'currentYear';
export const LAST_WEEK = 'lastWeek';
export const LAST_MONTH = 'lastMonth';
export const LAST_YEAR = 'lastYear';
const knownPresets = [
    TODAY,
    YESTERDAY,
    LAST_10_DAYS,
    LAST_14_DAYS,
    CURRENT_WEEK,
    CURRENT_MONTH,
    CURRENT_YEAR,
    LAST_WEEK,
    LAST_MONTH,
    LAST_YEAR,
];
const allRangePresets = _.reduce(
    knownPresets,
    (result: { [key: string]: boolean }, preset) => {
        result[preset] = true;
        return result;
    },
    {}
);

export const presets: {
    [key: string]: {
        endValue: (isTodayAllowed?: boolean, useEndOfDay?: boolean) => Date;
        startValue: (tenDaysLimit?: boolean) => Date;
    };
} = {
    [TODAY]: {
        startValue: () =>
            moment()
                .startOf('day')
                .toDate(),
        endValue: (isTodayAllowed?: boolean, useEndOfDay = true) => getMaxDateRangeValue(isTodayAllowed, useEndOfDay),
    },
    [YESTERDAY]: {
        startValue: () =>
            moment()
                .subtract(1, 'days')
                .startOf('day')
                .toDate(),
        endValue: (_?: boolean, useEndOfDay = true) => {
            if (useEndOfDay) {
                return moment()
                    .subtract(1, 'days')
                    .endOf('day')
                    .toDate();
            } else
                return moment()
                    .startOf('day')
                    .toDate();
        },
    },
    [LAST_10_DAYS]: {
        startValue: () =>
            moment()
                .subtract(10, 'days')
                .startOf('day')
                .toDate(),
        endValue: (_?: boolean, useEndOfDay = true) => {
            if (useEndOfDay) {
                return moment()
                    .subtract(1, 'days')
                    .endOf('day')
                    .toDate();
            } else
                return moment()
                    .startOf('day')
                    .toDate();
        },
    },
    [LAST_14_DAYS]: {
        startValue: (tenDaysLimit?: boolean) =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(14, 'days')
                    .startOf('day')
                    .toDate()
            ),

        endValue: (_?: boolean, useEndOfDay = true) => {
            if (useEndOfDay) {
                return moment()
                    .subtract(1, 'days')
                    .endOf('day')
                    .toDate();
            } else {
                return moment()
                    .startOf('day')
                    .toDate();
            }
        },
    },
    [CURRENT_WEEK]: {
        startValue: () =>
            moment()
                .startOf('isoweek' as moment.unitOfTime.StartOf)
                .toDate(),
        endValue: (isTodayAllowed?: boolean, useEndOfDay = true) => getMaxDateRangeValue(isTodayAllowed, useEndOfDay),
    },
    [CURRENT_MONTH]: {
        startValue: (tenDaysLimit?: boolean) =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .startOf('month')
                    .toDate()
            ),
        endValue: (isTodayAllowed?: boolean, useEndOfDay = true) => getMaxDateRangeValue(isTodayAllowed, useEndOfDay),
    },
    [CURRENT_YEAR]: {
        startValue: () =>
            moment()
                .startOf('year')
                .toDate(),
        endValue: (isTodayAllowed?: boolean, useEndOfDay = true) => getMaxDateRangeValue(isTodayAllowed, useEndOfDay),
    },
    [LAST_WEEK]: {
        startValue: (tenDaysLimit?: boolean) =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(1, 'weeks')
                    .startOf('isoweek' as moment.unitOfTime.StartOf)
                    .toDate()
            ),
        endValue: (_?: boolean, useEndOfDay = true) => {
            if (useEndOfDay) {
                return moment()
                    .subtract(1, 'weeks')
                    .endOf('isoweek' as moment.unitOfTime.StartOf)
                    .toDate();
            } else {
                return moment()
                    .startOf('isoweek' as moment.unitOfTime.StartOf)
                    .toDate();
            }
        },
    },
    [LAST_MONTH]: {
        startValue: (tenDaysLimit?: boolean) =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(1, 'months')
                    .startOf('month')
                    .toDate()
            ),
        endValue: (_?: boolean, useEndOfDay = true) => {
            if (useEndOfDay) {
                return moment()
                    .subtract(1, 'months')
                    .endOf('month')
                    .toDate();
            } else {
                return moment()
                    .startOf('month')
                    .toDate();
            }
        },
    },
    [LAST_YEAR]: {
        startValue: (tenDaysLimit?: boolean) =>
            getMaxOf(
                tenDaysLimit,
                getMinDateRangeValue(tenDaysLimit),
                moment()
                    .subtract(1, 'years')
                    .startOf('year')
                    .toDate()
            ),
        endValue: (_?: boolean, useEndOfDay = true) => {
            if (useEndOfDay) {
                return moment()
                    .subtract(1, 'years')
                    .endOf('year')
                    .toDate();
            } else {
                return moment()
                    .startOf('year')
                    .toDate();
            }
        },
    },
};

export const generateDateRangeConfig = ({
    featureFlags = allRangePresets,
    tenDaysLimit = false,
    isTodayAllowed = true,
    hasTimePicker = true,
    useEndOfDay = true,
} = {}) =>
    Object.freeze({
        min: getMinDateRangeValue(tenDaysLimit),
        max: getMaxDateRangeValue(isTodayAllowed, useEndOfDay),
        hasTimePicker,
        presets: knownPresets
            .filter(key => _.has(featureFlags, key))
            .map(key => {
                const preset = presets[key];
                const startValue = preset.startValue(tenDaysLimit);
                const endValue = preset.endValue(isTodayAllowed, useEndOfDay);
                return {
                    key,
                    translationKey: `history.dateRange.${key}`,
                    startValue,
                    endValue,
                    disabled: !featureFlags[key] || !moment(startValue).isBefore(endValue),
                };
            }),
    });

export const dayChangeDetection = () => moment().day();

export const getDefaultDateRangeConfig = _.memoize(() => generateDateRangeConfig(), dayChangeDetection);
