import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { EDIT_REPORT_ROUTE } from '../../constants/routes';

const EditConfiguration = ({ configId }: { configId: string }) => {
    const history = useHistory();
    const openEditForm = (id: string) => history.replace(`${EDIT_REPORT_ROUTE}/${id}`);

    return (
        <div onClick={() => openEditForm(configId)} role="button" data-test="edit">
            <span className="rioglyph rioglyph-pencil margin-right-10" />
            <FormattedMessage id="edit" />
        </div>
    );
};

export default EditConfiguration;
