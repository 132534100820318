import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { FormattedDate, FormattedMessage } from 'react-intl';

import Logo from '../assets/man-logo.png';

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-start',
        bottom: 18,
        marginLeft: 20,
        position: 'absolute',
        justifyContent: 'space-between',
    },
    currentPage: {
        fontFamily: 'Bold',
    },
    createdAt: {
        width: 130,
        fontSize: 8,
        textAlign: 'right',
        color: '#2A3740',
    },
    logo: {
        marginRight: 10,
        width: 20,
    },
    disclaimer: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
        fontSize: 7,
    },
});

export default function Footer({ createdOn }: { createdOn: Date }) {
    return (
        <View fixed style={styles.footer}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={styles.logo}>
                    <Image src={Logo} />
                </View>
                <Text
                    fixed
                    style={styles.currentPage}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                />
            </View>
            <View>
                <Text style={styles.createdAt}>
                    <FormattedMessage id="print.createdOn" /> <FormattedDate value={createdOn} />
                </Text>
            </View>
        </View>
    );
}
