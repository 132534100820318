import OverlayTriggerWithInjectedIntl from '@components/OverlayTriggerWithInjectedIntl';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { State } from '../../setup/types';
import { enablePerform3, includeCruiseControlInRating } from '../settings/reducer';
import { isMobile } from '../ui/reducer';

export const AdministrationSettingsFooter = ({
    includeCruiseControlInRating,
    isPerform3Enabled,
    isMobile,
}: {
    includeCruiseControlInRating: boolean;
    isPerform3Enabled: boolean;
    isMobile: boolean;
}): JSX.Element => {
    const showFooter = !isPerform3Enabled && !includeCruiseControlInRating;
    const offset = isMobile ? -15 : -30;

    return (
        <>
            {showFooter && (
                <div
                    className="bg-info text-color-white padding-right-15 padding-left-15 position-fixed z-index-max"
                    style={{ marginLeft: offset, bottom: 0, width: '100%' }}
                    data-test="administrationSettingsFooter"
                >
                    <div className="height-20 padding-right-15 padding-left-15 display-flex gap-25">
                        <OverlayTriggerWithInjectedIntl
                            placement={'top'}
                            overlay={
                                <Tooltip id={'tooltip'} width={200} textAlignment="left">
                                    <FormattedMessage
                                        id="administration.rating.cruiseControl.description"
                                        values={{
                                            // eslint-disable-next-line react/display-name
                                            b: (chunks: unknown[]) => <strong>{chunks}</strong>,
                                            line_break: <br />,
                                        }}
                                    />
                                </Tooltip>
                            }
                        >
                            <div
                                className="display-flex align-items-center gap-5"
                                data-test="cruiseControlSettingInFooter"
                            >
                                <span className="rioglyph rioglyph rioglyph-cruise-control text-size-16" />
                                <FormattedMessage id="administration.rating.cruiseControl" />:{' '}
                                <b>
                                    {includeCruiseControlInRating ? (
                                        <FormattedMessage id="setting.activated" />
                                    ) : (
                                        <FormattedMessage id="setting.deactivated" />
                                    )}
                                </b>
                            </div>
                        </OverlayTriggerWithInjectedIntl>
                    </div>
                </div>
            )}
        </>
    );
};

export const mapStateToProps = (state: State) => ({
    includeCruiseControlInRating: includeCruiseControlInRating(state),
    isPerform3Enabled: enablePerform3(state),
    isMobile: isMobile(state),
});

export default connect(mapStateToProps)(AdministrationSettingsFooter);
