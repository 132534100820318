import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

function TableCaption({ width }: { width?: number }) {
    const style = width
        ? {
              minWidth: width,
              width,
          }
        : {};

    return <col style={style} />;
}

const columns = [
    'name',
    'reportType',
    'selection',
    'interval',
    'download',
    'generationStatus',
    'notificationStatus',
] as const;

type Columns = typeof columns[number];
type ActionColumn = 'actions';

export type ReportRow = { [key in Columns | ActionColumn]: JSX.Element } & { id: string; active: boolean };

export default function ReportTable({
    viewType = TableViewToggles.VIEW_TYPE_TABLE,
    rows,
    onRowClick,
}: {
    rows: ReportRow[];
    viewType?: string;
    onRowClick: (row: ReportRow) => void;
}) {
    const columnLabels: { [key in Columns]: string } = {
        name: 'reports.name',
        reportType: 'reports.type',
        selection: 'reports.selection',
        interval: 'reports.interval',
        download: 'reports.download',
        generationStatus: 'reports.generationStatus',
        notificationStatus: 'reports.emailNotificationStatus',
    };

    const tableClassNames = cn(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
    );

    const intl = useIntl();

    const isNotificationStatusVisible = rows.some(e => e.notificationStatus?.props?.children);

    return (
        <table className={tableClassNames}>
            <colgroup>
                {columns.map(column => (
                    <TableCaption key={column} />
                ))}
                <col className="table-action" />
            </colgroup>
            <thead>
                <tr>
                    {columns.map(column => (
                        <th
                            key={column}
                            className={
                                'user-select-none ' +
                                (column === 'generationStatus' || column === 'notificationStatus' ? 'width-60' : '')
                            }
                            data-field={column}
                            data-sortby={column}
                            title={
                                column !== 'notificationStatus' ||
                                (column === 'notificationStatus' && isNotificationStatusVisible)
                                    ? intl.formatMessage({ defaultMessage: ' ', id: columnLabels[column] })
                                    : undefined
                            }
                        >
                            {column === 'notificationStatus' && !isNotificationStatusVisible ? null : (
                                <FormattedMessage defaultMessage={' '} id={columnLabels[column]} />
                            )}
                        </th>
                    ))}
                    <th className="table-action" />
                </tr>
            </thead>
            <tbody>
                {rows.map(row => (
                    <tr
                        data-test={row.id}
                        key={row.id}
                        onClick={() => onRowClick(row)}
                        tabIndex={0}
                        className={cn('cursor-pointer', { active: row.active })}
                    >
                        {columns.map(col => (
                            <td
                                key={col}
                                data-test={col}
                                data-field={intl.formatMessage({ defaultMessage: ' ', id: columnLabels[col] })}
                                className={
                                    col === 'generationStatus' || col === 'notificationStatus' ? 'text-center' : ''
                                }
                            >
                                <span>
                                    {(col !== 'notificationStatus' ||
                                        (col === 'notificationStatus' && isNotificationStatusVisible)) &&
                                        row[col]}
                                </span>
                            </td>
                        ))}
                        <td className="table-action" data-test="actions">
                            {row['actions']}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
