import StatusBar from '@rio-cloud/rio-uikit/StatusBar';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const BatteryDetails = ({
    name,
    consumption,
    percentage,
    perKm,
    progressBarColor = 'progress-bar-gray',
}: {
    name: string;
    consumption: ReactNode;
    percentage: number;
    perKm: ReactNode;
    progressBarColor?: string;
}) => {
    return (
        <>
            <div className="display-flex justify-content-between align-items-end margin-bottom-10">
                <div className="width-200">
                    <p className="margin-0 text-medium text-color-dark">
                        <FormattedMessage id={name} />
                    </p>
                    <div className="display-flex gap-50 text-light">
                        <span>{consumption}</span>
                        <span>{perKm}</span>
                    </div>
                </div>
                <div>
                    <span className="margin-0 text-medium text-size-18 text-color-dark">{percentage}%</span>
                </div>
            </div>
            <StatusBar
                progress={[
                    {
                        percentage,
                        color: progressBarColor,
                    },
                ]}
            />
        </>
    );
};

export default BatteryDetails;
