export interface Slope {
    low: number;
    medium: number;
    steep: number;
}

export enum Sign {
    PLUS = '+',
    MINUS = '-',
}

export enum Status {
    'LOADING' = 'loading',
    'SUCCESS' = 'success',
    'FAILED' = 'failed',
}
