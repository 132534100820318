import { getSignal } from '@api/index';
import { View } from '@react-pdf/renderer';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { HydratedEntity, OpconSignals } from '../../../types';
import IndexToWording from '../../opcon/IndexToWording';
import { isValidScore } from '../../opcon/isValidScore';
import ratingToIndex from '../../opcon/ratingToIndex';
import SimpleBarChart from '../components/SimpleBarChart';

export default function OperationalConditions({ data }: { data: HydratedEntity }) {
    const opconData = getSignal<OpconSignals | Record<string, never>>(data, 'operatingConditionResponse', {});
    const score = isValidScore(opconData, getSignal(data, 'mileage', 0)) ? _.get(opconData, 'score.value', -1) : -1;

    const activeIndex = ratingToIndex(score);
    return (
        <View style={{ marginRight: 40 }}>
            <View style={{ fontSize: 12 }}>
                <FormattedMessage id="operationCondition" />
            </View>
            <View style={{ justifyContent: 'center', flexDirection: 'row', marginBottom: 5, marginTop: 5 }}>
                <SimpleBarChart value={score} total={5} minHeight={9} maxHeight={30} width={90} />
            </View>
            <View
                style={{ justifyContent: 'center', flexDirection: 'row', fontFamily: 'Bold' }}
                data-test="writtenDescription"
            >
                <IndexToWording index={activeIndex} />
            </View>
        </View>
    );
}
