import cloneDeep from 'lodash/fp/cloneDeep';

import { env } from '../env';

const initial = cloneDeep(env.runtimeConfig) as Record<string, unknown> & { backend: Record<string, string> };

const configReducer = (state = initial) => {
    return state;
};

export default configReducer;
