import { Id } from '@api/models/types';
import useQuery, { Selector, Variables } from '@utils/useQuery';

import { DataStatePart } from '../../setup/types';
import { DateRange } from '../../types';
import { requestVehicleSpecification } from '../actions';
import { vehSpecForRequest } from '../selectors';

export const QUERY = ({ vehicleIds, dateRange }: { vehicleIds: Id[]; dateRange: DateRange }) => ({
    assetIds: vehicleIds,
    dateRange,
});

export const selector = (state: DataStatePart, key: Id) => vehSpecForRequest(state, key);

export const noopAction = () => ({ type: '_noop' });

const useVehspecData = (dateRange: DateRange, vehicleIds: Id[]) => {
    return useQuery(QUERY, {
        endPoint: vehicleIds.length > 1 ? noopAction : (requestVehicleSpecification as any),
        // we need debounce false here in order to no run into a deadlock with _.noop() & the resulting pending request
        debounced: false,
        variables: { vehicleIds, dateRange } as Variables,
        selector: selector as Selector,
    });
};

export default useVehspecData;
