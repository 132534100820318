import { getSignal } from '@api/index';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../../columns/createColumn';
import { HydratedEntity } from '../../../types';
import isNotAReactComponent from '../../../utils/isNotAReactComponent';
import Content from '../tabular/Content';
import { FONT_SIZES } from './styles';

const styles = StyleSheet.create({
    column: {
        width: '25%',
        marginBottom: 10,
    },
    content: {
        fontFamily: 'Bold',
    },
    title: {
        fontSize: FONT_SIZES.SUB_TITLE,
    },
    wrapper: {
        marginTop: 2,
        paddingTop: 2,
    },
    wrapperContent: {
        marginLeft: 10,
        marginRight: 10,
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
});

const OperatingDays = ({
    operatingDays,
    totalNumberOfDays,
}: {
    operatingDays?: string[];
    totalNumberOfDays?: number;
}) => (
    <Text>
        {totalNumberOfDays ? (
            <>
                {operatingDays ? operatingDays.length : '-'} / {totalNumberOfDays}
            </>
        ) : (
            '-'
        )}
    </Text>
);

export default function GeneralDataPdf({
    columns,
    data,
    startDate,
    endDate,
}: {
    columns: Column[];
    data: HydratedEntity;
    startDate: Date;
    endDate: Date;
}) {
    const content = columns
        .map((column, idx) => {
            const content = column.formatter(column.valueExtractor(data), data);
            const wrappedContent = isNotAReactComponent(Content) ? (
                <Text style={{ maxLines: 1 }}>{content}</Text>
            ) : (
                content
            );
            return (
                <View key={idx} style={styles.column}>
                    <View>
                        <Text style={styles.title}>
                            <FormattedMessage id={column.labelId} />
                        </Text>
                    </View>
                    <View wrap={false} style={styles.content}>
                        {wrappedContent}
                    </View>
                </View>
            );
        })
        .concat(
            <View style={styles.column} key="operationDays">
                <View>
                    <Text style={styles.title}>
                        <FormattedMessage id="operationDays" />
                    </Text>
                </View>
                <View wrap={false} style={styles.content}>
                    <OperatingDays
                        operatingDays={getSignal(data, 'operatingDays', undefined)}
                        totalNumberOfDays={getSignal(data, 'totalNumberOfDays', undefined)}
                    />
                </View>
            </View>
        );

    return (
        <View wrap={false} style={styles.wrapper}>
            <View wrap={false} style={styles.wrapperContent}>
                {content}
            </View>
        </View>
    );
}
