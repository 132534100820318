import 'd3-transition';
import '../styles.less';

import colors from '@rio-cloud/rio-uikit/Colors';
import cn from 'classnames';
import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { GraphContext } from './GraphDataProvider';
import { hasValue } from './utils';

export const ANIMATION_DURATION = 50;
export const WITH_DATA_COLOR = colors['color-highlight-dark'];
export const WITHOUT_DATA_COLOR = colors['gray-light'];

export const ValueText: React.FC<{
    value: number;
    formatter: (value: unknown, data?: unknown) => ReactNode;
    className: string;
    label: React.ReactNode;
    show: boolean;
    style?: React.CSSProperties;
    useCustomTextColor?: boolean;
}> = ({ value, formatter, className, label, show, style, useCustomTextColor = false }) => {
    const {
        dimensions: { widthWithoutMargin },
        leftYScale: y,
    } = useContext(GraphContext);
    const ref = useRef<HTMLDivElement | null>(null);

    const [offset, setOffset] = useState({ x: widthWithoutMargin - 20, leftUpY: 0 });

    useEffect(() => {
        const node = ref.current;
        if (node) {
            const { width, height } = node.getBoundingClientRect();
            setOffset({ x: widthWithoutMargin - width, leftUpY: height });
        }
    }, [value, ref, widthWithoutMargin]);

    return (
        <div
            ref={ref}
            className={cn(
                !useCustomTextColor && 'text-color-highlight',
                'guidelines position-absolute bg-white padding-5 text-center',
                className,
                {
                    visible: show,
                }
            )}
            style={{
                pointerEvents: 'none',
                transform: `translate(${offset.x}px, ${y(value) - offset.leftUpY / 2 - 2}px)`,
                ...(useCustomTextColor && style),
            }}
        >
            {label} {formatter(value)}
        </div>
    );
};

export const Guidelines: React.FC<{ average?: number; color?: string; show: boolean }> = ({
    average,
    color = WITH_DATA_COLOR,
    show,
}) => {
    const {
        dimensions: { widthWithoutMargin },
        leftYScale: y,
    } = useContext(GraphContext);

    if (average === undefined) {
        return <></>;
    }

    const guidelines = { average };
    return (
        <g>
            {Object.entries(guidelines).map(([key, value]) => (
                <line
                    key={key}
                    className={cn(key, 'guidelines', { visible: show })}
                    style={{ stroke: color }}
                    x1={0}
                    x2={widthWithoutMargin}
                    y1={y(value)}
                    y2={y(value)}
                    strokeWidth={2}
                />
            ))}
        </g>
    );
};

export function DataPoint() {
    const { xScale: x, leftYScale: y, data } = useContext(GraphContext);
    const group = useRef<SVGCircleElement | null>(null);

    useEffect(() => {
        if (group && group.current) {
            const circles = select(group.current)
                .selectAll('circle')
                .data(data as { x: Date; leftUpY: number }[]);

            circles
                .enter()
                .append('circle')
                .attr('class', 'dataPoint')
                .attr('stroke', d => (hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR))
                .attr('r', 4)
                .attr('cy', d => (hasValue(d) ? y(d.leftUpY) : y(y.domain()[1])))
                .attr('cx', d => x((d.x as unknown) as number) || null);

            circles
                .attr('r', 4)
                .transition()
                .ease(easeLinear)
                .duration(ANIMATION_DURATION)
                .attr('stroke', d => (hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR))
                .attr('cy', d => (hasValue(d) ? y(d.leftUpY) : y(y.domain()[1])))
                .attr('cx', d => x((d.x as unknown) as number) || null);

            circles.exit().remove();
        }
    }, [data, x, y]);

    return <g ref={group} />;
}
