import { HoverInfo } from '@pages/driverAnalysis/types';
import { NumberValue } from 'd3-scale';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';

import { WITH_DATA_COLOR, WITHOUT_DATA_COLOR } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';
import { hasValue, HOVER_INFO_HEIGHT, NO_DATA_Y_OFFSET } from './utils';

interface IntervalProps {
    activeInterval?: Date;
    onHover: (hover: HoverInfo | null) => void;
    useOuterXPosition?: boolean;
    xOffset?: number;
    timeIntervalWidth: number;
}

export default function Interval({
    activeInterval,
    onHover,
    useOuterXPosition = false,
    xOffset = 0,
    timeIntervalWidth = 15,
}: IntervalProps) {
    const {
        xScale: x,
        leftYScale,
        rightYScale,
        data,
        dimensions: { height, margin },
    } = useContext(GraphContext);
    const [hovered, setHovered] = useState<Date | undefined>();

    const getXValue = useMemo(() => (value: NumberValue | Date) => x(value as number) || 0, [x]);

    const calculateWidth = useMemo(
        () => (index: number) => {
            if (data.length <= 1) return margin * 3;

            const dayWidth = timeIntervalWidth * 2 - (getXValue(data[1].x) - getXValue(data[0].x));
            return index === 0 || index === data.length - 1 ? dayWidth / 2 + margin : dayWidth;
        },
        [data, getXValue, margin, timeIntervalWidth]
    );

    const transform = useMemo(
        () => (d: DataEntry) => {
            if (data.length <= 1) return 'translate(0 0)';

            const dayWidth = getXValue(data[1].x) - getXValue(data[0].x);
            return `translate(${getXValue(d.x) - dayWidth / 2} 0)`;
        },
        [data, getXValue]
    );

    const getHoverInfo = useMemo(
        () => (d: DataEntry) => {
            const leftX = useOuterXPosition ? getXValue(d.x) - xOffset : getXValue(d.x);
            const rightX = useOuterXPosition ? getXValue(d.x) + xOffset : getXValue(d.x);
            const leftUpY = !_.isUndefined(d.leftUpY) ? leftYScale(d.leftUpY) : 0;
            const leftDownY =
                !_.isUndefined(d.leftDownY) && leftYScale
                    ? leftYScale(-Math.abs(d.leftDownY)) - HOVER_INFO_HEIGHT
                    : NO_DATA_Y_OFFSET;
            const rightUpY = !_.isUndefined(d.rightUpY) && rightYScale ? rightYScale(d.rightUpY) : NO_DATA_Y_OFFSET;
            const rightDownY =
                !_.isUndefined(d.rightDownY) && rightYScale
                    ? rightYScale(-Math.abs(d.rightDownY)) - HOVER_INFO_HEIGHT
                    : NO_DATA_Y_OFFSET;
            const size = timeIntervalWidth * 2 - (getXValue(data[1].x) - getXValue(data[0].x));

            return {
                leftX,
                rightX,
                leftUpY,
                leftDownY,
                rightUpY,
                rightDownY,
                size,
                data: d,
            };
        },
        [leftYScale, rightYScale, useOuterXPosition, xOffset, getXValue, data, timeIntervalWidth]
    );

    const handleMouseOut = () => {
        setHovered(undefined);
        onHover(null);
    };

    const handleMouseOver = (d: DataEntry) => {
        setHovered(d.x as Date);
        onHover(getHoverInfo(d));
    };

    return (
        <g>
            {data.map((d, idx) => {
                const isHovered = hovered && hovered.getTime() === (d.x as Date).getTime();
                const isActive = activeInterval && activeInterval.getTime() === (d.x as Date).getTime();

                return (
                    <g key={idx} className="interval cursor-pointer">
                        <rect
                            height={height}
                            stroke={hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR}
                            fill={hasValue(d) ? WITH_DATA_COLOR : WITHOUT_DATA_COLOR}
                            fillOpacity={0.1}
                            strokeWidth={1}
                            opacity={isActive || isHovered ? 1 : 0}
                            width={calculateWidth(idx)}
                            transform={transform(d)}
                            onMouseOut={handleMouseOut}
                            onMouseOver={() => handleMouseOver(d)}
                        />
                    </g>
                );
            })}
        </g>
    );
}
