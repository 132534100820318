import { Text, View } from '@react-pdf/renderer';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import isBelowMileageThreshold from '../../opcon/isBelowMileageThreshold';
import styles from './styles';

export default function DisplayOpconWarningConditionally({
    evaluatedMileage,
    totalMileage,
    children,
}: {
    evaluatedMileage: number;
    totalMileage: number;
    children: ReactElement;
}) {
    return isBelowMileageThreshold(evaluatedMileage, totalMileage) ? (
        <View style={styles.emptyOpconWarningBox} data-test="OpconWarning">
            <Text style={styles.emptyOpconWarningTitle}>
                <FormattedMessage id={'inaccuracyOperationConditionWarning'} />
            </Text>
            <FormattedMessage id={'inaccuracyOperationConditionWarningDetail'} />
        </View>
    ) : (
        children
    );
}
