import { getLanguageData } from '@common/lang/selectors';
import Notification from '@rio-cloud/rio-uikit/Notification';
import _ from 'lodash';
import { call, select } from 'redux-saga/effects';

export function* errorNotification(errorKey: string) {
    const languageData: { [key: string]: string } = yield select(getLanguageData);
    const errorMessage = _.get(languageData, errorKey, 'Unknown Error');
    yield call([Notification, Notification.error], errorMessage);
}

export function* infoNotification(errorKey: string) {
    const languageData: { [key: string]: string } = yield select(getLanguageData);
    const infoMessage = _.get(languageData, errorKey, 'Unknown Info');
    yield call([Notification, Notification.info], infoMessage);
}
