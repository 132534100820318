import { HereCredentials } from '@features/vehicleMap/types';
import { get } from 'lodash';

import { TokenHandlingStatePart } from '../../setup/types';

export const NO_ACCESS_TOKEN_AVAILABLE = 'NO_ACCESS_TOKEN_AVAILABLE';

export const NO_ID_TOKEN_AVAILABLE = 'NO_ID_TOKEN_AVAILABLE';

export const NO_HERE_TOKEN_AVAILABLE = 'NO_HERE_TOKEN_AVAILABLE';

// For some reason this creates problems with typescript
//export const getAccessToken = getOr('NO_ACCESS_TOKEN_AVAILABLE', 'tokenHandling.accessToken');
export const getAccessToken = (state: TokenHandlingStatePart): string =>
    get(state, 'tokenHandling.accessToken', NO_ACCESS_TOKEN_AVAILABLE);

export const getIdToken = (state: TokenHandlingStatePart) => get(state, 'tokenHandling.idToken', NO_ID_TOKEN_AVAILABLE);

export const getHereCredentials = (state: TokenHandlingStatePart): HereCredentials | typeof NO_HERE_TOKEN_AVAILABLE =>
    get(state, 'tokenHandling.hereToken', NO_HERE_TOKEN_AVAILABLE);
