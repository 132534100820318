import { TypeOf } from 'io-ts';

import { fetchGetWithJSONResponseCyclicWithPagination } from './api';
import { decodeDrivers, DriverApiResponseType, RawDriver } from './decoder/driverDecoder';

const mergeResponses = <Item, Response extends { items: Item[] }>(responses: Response[]) => ({
    items: responses.reduce((acc: Item[], response: Response) => acc.concat(...response.items), []),
});

export async function fetchDrivers(baseUrl: string, authToken: string) {
    const responses = await fetchGetWithJSONResponseCyclicWithPagination<DriverApiResponseType>(
        baseUrl,
        `drivers?embed=(identifications,tags)`,
        authToken
    );

    const merged = mergeResponses<TypeOf<typeof RawDriver>, DriverApiResponseType>(responses);
    return decodeDrivers(merged);
}
