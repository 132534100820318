import { formatConditionalDigitNumber, formatToValueOrZero, plainAverage, withUnit } from '@utils/formatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import { KILOMETERS } from '../constants/units';
import createColumn from './createColumn';

export const distanceSignal = {
    unit: KILOMETERS,
    displayedUnit: KILOMETERS,
    format: compose<ReactNode>(withUnit(KILOMETERS), formatConditionalDigitNumber),
    average: plainAverage,
};

export const distanceSignalWithDefaultToZero = {
    ...distanceSignal,
    format: compose<ReactNode>(withUnit(KILOMETERS), formatToValueOrZero, formatConditionalDigitNumber),
};

export const mileage = createColumn({
    key: 'mileage',
    dataField: 'mileage',
    labelId: 'mileage',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const coastingCcDistance = createColumn({
    key: 'coastingCcDistance',
    dataField: 'coastingCcDistance',
    labelId: 'coastingCcDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const kickdownDistance = createColumn({
    key: 'kickdownDistance',
    dataField: 'kickdownDistance',
    labelId: 'kickdownDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const accelerationPedalDistanceWithCc = createColumn({
    key: 'accelerationPedalDistanceWithCc',
    dataField: 'accelerationPedalDistanceWithCc',
    labelId: 'accelerationPedalDistanceWithCc',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const serviceBrakeDistance = createColumn({
    key: 'serviceBrakeDistance',
    dataField: 'serviceBrakeDistance',
    labelId: 'serviceBrakeDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const motorBrakeDistance = createColumn({
    key: 'motorBrakeDistance',
    dataField: 'motorBrakeDistance',
    labelId: 'motorBrakeDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const cruiseControlDistanceInPotential = createColumn({
    key: 'cruiseControlDistanceInPotential',
    dataField: 'cruiseControlDistanceInPotential',
    labelId: 'cruiseControlDistanceInPotential',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const noCruiseControlDistanceInPotential = createColumn({
    key: 'noCruiseControlDistanceInPotential',
    dataField: 'noCruiseControlDistanceInPotential',
    labelId: 'noCruiseControlDistanceInPotential',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});
export const cruiseControlPotentialDistance = createColumn({
    key: 'cruiseControlPotentialDistance',
    dataField: 'cruiseControlPotentialDistance',
    labelId: 'cruiseControlPotentialDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});
export const overspeedDistance = createColumn({
    key: 'overspeedDistance',
    dataField: 'overspeedDistance',
    labelId: 'overspeedDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const activeCoastingDistance = createColumn({
    key: 'activeCoastingDistance',
    dataField: 'activeCoastingDistance',
    labelId: 'activeCoastingDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const passiveCoastingDistance = createColumn({
    key: 'passiveCoastingDistance',
    dataField: 'passiveCoastingDistance',
    labelId: 'passiveCoastingDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const retarderDistance = createColumn({
    key: 'retarderDistance',
    dataField: 'retarderDistance',
    labelId: 'retarderDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const coastingDistance = createColumn({
    key: 'coastingDistance',
    dataField: 'coastingDistance',
    labelId: 'coastingDistance',
    formatter: distanceSignal.format,
    unit: distanceSignal.unit,
    dataSort: true,
});

export const driveProgramActiveDistance = createColumn({
    key: 'driveProgramActiveDistance',
    dataField: 'driveProgramActiveDistance',
    labelId: 'driveProgramActiveDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramEfficiencyDistance = createColumn({
    key: 'driveProgramEfficiencyDistance',
    dataField: 'driveProgramEfficiencyDistance',
    labelId: 'driveProgramEfficiencyDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramEfficiencyPlusDistance = createColumn({
    key: 'driveProgramEfficiencyPlusDistance',
    dataField: 'driveProgramEfficiencyplusDistance',
    labelId: 'driveProgramEfficiencyplusDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramEmergencyDistance = createColumn({
    key: 'driveProgramEmergencyDistance',
    dataField: 'driveProgramEmergencyDistance',
    labelId: 'driveProgramEmergencyDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramFluidDistance = createColumn({
    key: 'driveProgramFluidDistance',
    dataField: 'driveProgramFluidDistance',
    labelId: 'driveProgramFluidDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramHeavyDutyDistance = createColumn({
    key: 'driveProgramHeavyDutyDistance',
    dataField: 'driveProgramHeavydutyDistance',
    labelId: 'driveProgramHeavydutyDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramManoeuverDistance = createColumn({
    key: 'driveProgramManoeuverDistance',
    dataField: 'driveProgramManeuverDistance',
    labelId: 'driveProgramManeuverDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramManualDistance = createColumn({
    key: 'driveProgramManualDistance',
    dataField: 'driveProgramManualDistance',
    labelId: 'driveProgramManualDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramOffroadDistance = createColumn({
    key: 'driveProgramOffroadDistance',
    dataField: 'driveProgramOffroadDistance',
    labelId: 'driveProgramOffroadDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramOffroadWithAssistanceDistance = createColumn({
    key: 'driveProgramOffroadWithAssistanceDistance',
    dataField: 'driveProgramOffroadwithassistanceDistance',
    labelId: 'driveProgramOffroadwithassistanceDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramPerformanceDistance = createColumn({
    key: 'driveProgramPerformanceDistance',
    dataField: 'driveProgramPerformanceDistance',
    labelId: 'driveProgramPerformanceDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
    dataSort: true,
});

export const driveProgramTotalDistance = createColumn({
    key: 'driveProgramTotalDistance',
    dataField: 'driveProgramTotalDistance',
    labelId: 'driveProgramTotalDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
});

export const accDistance = createColumn({
    key: 'accDistance',
    dataField: 'accDistance',
    labelId: 'accDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
});

export const noAccDistance = createColumn({
    key: 'noAccDistance',
    dataField: 'noAccDistance',
    labelId: 'noAccDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
});

export const pccaDistance = createColumn({
    key: 'pccaDistance',
    dataField: 'pccaDistance',
    labelId: 'pccaDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
});

export const noPccaDistance = createColumn({
    key: 'noPccaDistance',
    dataField: 'noPccaDistance',
    labelId: 'noPccaDistance',
    formatter: distanceSignalWithDefaultToZero.format,
    unit: distanceSignalWithDefaultToZero.unit,
});
