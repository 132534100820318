import { VehicleFuelType } from '@api/models/types';
import { getAccount, isLATAMTenant } from '@common/login/selectors';
import {
    getAdvanceVehicles,
    getFeatureToggles,
    getPerformReducedBus,
    getPerformVehicles,
} from '@common/permissions/selectors';
import { Loadable, LoadableType } from '@data/loadable';
import { getVehicles } from '@data/selectors';
import { getSelectedFuelTypes } from '@features/tree/selectors';
import { getTableViewType, hideSidebar, isMobile, SidebarData, sidebarData } from '@features/ui/reducer';
import { isFilteringOnSegment } from '@features/ui/selectors';
import { getColumn } from '@utils/columns';
import { calculateColumnOrder } from '@utils/sorting';
import { findValueExtractor, sortByProperty, SortDirection } from '@utils/sortyByProperty';
import _ from 'lodash';
import always from 'lodash/fp/always';
import identity from 'lodash/fp/identity';
import moment from 'moment';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Column } from 'src/columns/createColumn';
import { State } from 'src/setup/types';
import { DateRange, HydratedEntity, HydratedEntityWithChildren, Id, Map, Vehicle } from 'src/types';

import { loadingFormatter } from '../../columns/DOM';
import SimpleErrorState from '../../components/SimpleErrorState';
import SettingsDialog from '../../components/table/SettingsDialog';
import SettingsToolbar from '../../components/table/SettingsToolbar';
import Table, { SortBy } from '../../components/table/Table';
import UseCases from '../../components/UseCases';
import { VEHICLE_ROUTE } from '../../constants/routes';
import AdministrationSettingsFooter from '../../features/settings/AdministrationSettingsFooter';
import Toolbar from '../../features/ui/Toolbar';
import {
    closeRow,
    openRow,
    setColumnOrderForUseCase,
    setFilteredColumnNamesForUseCase,
    setSortedBy,
    setUseCase,
} from './actions';
import MixedFleetAlert from './MixedFleetAlert';
import { DEFAULT_USE_CASE_CONFIG, UseCaseSetting } from './reducer';
import {
    getOpenRows,
    getSelectedUseCase,
    getSelectedUseCaseConfig,
    getSortedBy,
    getUseCaseConfig,
    getUseCaseSetting,
} from './selectors';
import { defaultUseCaseConfig, UseCaseConfig } from './useCaseConfig';
import { decorateWithAdditionalInformation } from './utils';
import DownloadMenu from './VehicleDownloadMenu';
import VehicleFilterSummary from './VehicleFilterSummary';
import VehicleState from './VehicleState';
import VehicleSummary from './VehicleSummary';

const MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_TABLE_COLUMNS: Record<string, string> = {
    drivingConsumption: 'electricAverageDrivingConsumption',
    idlingConsumption: 'electricAverageIdlingConsumption',
    fuelConsumption: 'electricOperatingConsumption',
    operatingFuelConsumption: 'electricAverageOperatingConsumption',
    distanceFuelConsumption: 'electricAverageOperatingConsumptionRange',
    fuelEfficiency: 'electricEfficiency',
};

const sortChildrenBy = (sortFn: (children: HydratedEntity[]) => unknown) => (data: HydratedEntityWithChildren[]) =>
    data.map((d: HydratedEntityWithChildren) => ({
        ...d,
        children: sortFn(d.children),
    }));

const hasLoadableChildren = (loadable: LoadableType<HydratedEntityWithChildren[]>) =>
    Loadable.map(loadable, data => Boolean(data.length));

const alwaysTrue = always(true);
const alwaysFalse = always(false);

const getShouldShowGraph = compose(
    loadable => Loadable.cata(loadable, identity, alwaysTrue, alwaysTrue, alwaysTrue),
    hasLoadableChildren
);

const getAreSettingsEnabled = compose(
    loadable => Loadable.cata(loadable, identity, alwaysFalse, alwaysFalse, alwaysFalse),
    hasLoadableChildren
);

const createSortAndFlattenFunction = (openRows: string[], sortColumn: Column | undefined, sortOrder: SortDirection) => {
    const { key, dataField } = sortColumn || {};

    const dataSort = compose(
        sortChildrenBy((children: HydratedEntity[]) =>
            sortByProperty(children, dataField, sortOrder, findValueExtractor(key))
        ),
        sortChildrenBy((children: HydratedEntity[]) =>
            sortByProperty(children, 'drivers', SortDirection.ASCENDING, findValueExtractor('drivers'))
        ),
        (data: HydratedEntityWithChildren[]) => sortByProperty(data, dataField, sortOrder, findValueExtractor(key))
    );

    const flattenChildren = (data: HydratedEntityWithChildren[]) =>
        data.reduce((result: HydratedEntityWithChildren[], row: HydratedEntityWithChildren) => {
            const isRowOpen = openRows.includes(row.id);
            return [...result, row, ...((isRowOpen && row.children) || [])] as HydratedEntityWithChildren[];
        }, []);

    return compose(flattenChildren, dataSort);
};

const createTransformFunction = (
    advanceVehicleIds: Id[],
    performVehicleIds: Id[],
    getPerformReducedBus: Id[],
    shouldShowFuelType: boolean
) => (entities: HydratedEntityWithChildren[]) =>
    entities.map((entity: HydratedEntityWithChildren) =>
        decorateWithAdditionalInformation({
            entity,
            advanceVehicleIds,
            performVehicleIds,
            performReducedBus: getPerformReducedBus,
            shouldShowFuelType,
        })
    );

const openExpanderRow = ({
    openRows,
    openRow,
    closeRow,
}: {
    openRows: Id[];
    openRow: (id: Id) => void;
    closeRow: (id: Id) => void;
}) => (row: HydratedEntityWithChildren) => {
    const id = row.id;
    if (openRows.includes(id)) {
        closeRow(id);
    } else {
        openRow(id);
    }
};

const findColumnByKey = (columns: Column[], key: string | undefined) => columns.find(column => column.key === key);

const transformColumnStringsToObjects = (
    columnOrder: string[],
    filteredColumnNames: (string | undefined)[],
    summaryColumns: Column[],
    columns: Column[],
    isTruEEnabled: boolean
) => {
    const columnKeys = _.map(columns, 'key');
    const columnKeysInRightOrder = calculateColumnOrder(columnKeys, columnOrder);
    const columnsInRightOrder = columnKeysInRightOrder.map(key => findColumnByKey(columns, key));
    let filteredColumns = columnsInRightOrder
        .filter(Boolean)
        .filter(column => !filteredColumnNames.includes(column?.key));

    if (isTruEEnabled) {
        Object.keys(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_TABLE_COLUMNS).map(dieselColumnKey => {
            const indexOfDieselColumn = filteredColumns.findIndex(col => col && col.key === dieselColumnKey);
            if (indexOfDieselColumn > -1) {
                filteredColumns.splice(
                    indexOfDieselColumn + 1,
                    0,
                    getColumn(MAPPING_BETWEEN_DIESEL_AND_ELECTRIC_TABLE_COLUMNS[dieselColumnKey])
                );
            }
        });
    }

    return {
        columnKeysInRightOrder,
        filteredColumns,
        filteredSummaryColumns: summaryColumns.filter(column => !filteredColumnNames.includes(column.key)),
    };
};

type Props = {
    dateRange?: DateRange;
    onItemClick: (data: SidebarData) => void;
    setUseCase: (useCaseKey: string) => void;
    useCase?: UseCaseConfig | undefined;
    selectedVehicle?: Id;
    vehicles?: Id[];
    parentsWithChildren: LoadableType<HydratedEntityWithChildren[]>;
    useCaseConfig?: UseCaseConfig[];
    setFilteredColumnNamesForUseCase: ({
        useCaseKey,
        filteredColumnNames,
    }: {
        useCaseKey: string;
        filteredColumnNames: string[];
    }) => void;
    setColumnOrderForUseCase: ({ useCaseKey, columnOrder }: { useCaseKey: string; columnOrder: string[] }) => void;
    hideSidebar: () => void;
    useCaseSettings?: UseCaseSetting;
    sortBy?: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    performVehicles?: Id[];
    advanceVehicles?: Id[];
    performReducedBus?: Id[];
    openRow: (id: Id) => void;
    closeRow: (id: Id) => void;
    isFilteringOnSegment: boolean;
    openRows?: Id[];
    viewType: string;
    isMobile?: boolean;
    account?: string;
    isLatamTenant?: boolean;
    allVehicles?: Map<Vehicle>;
    selectedFuelTypes: VehicleFuelType[];
    isTruEEnabled?: boolean;
};

export const Content = (props: Props) => {
    const {
        dateRange = {
            start: moment()
                .subtract(1, 'week')
                .toDate(),
            end: moment().toDate(),
        },
        onItemClick,
        setUseCase,
        useCase = { columns: [], summaryColumns: [], key: '' },
        selectedVehicle,
        vehicles = [],
        parentsWithChildren,
        useCaseConfig = defaultUseCaseConfig,
        setFilteredColumnNamesForUseCase,
        setColumnOrderForUseCase,
        hideSidebar,
        useCaseSettings = DEFAULT_USE_CASE_CONFIG,
        sortBy = { key: 'vehicles', order: SortDirection.ASCENDING },
        setSortBy,
        performVehicles = [],
        advanceVehicles = [],
        performReducedBus = [],
        allVehicles = {},
        openRow,
        closeRow,
        isFilteringOnSegment,
        openRows = [],
        viewType,
        isMobile = false,
        account = '',
        isLatamTenant = false,
        selectedFuelTypes = [],
        isTruEEnabled = false,
    } = props;
    const { columns, summaryColumns, key: selectedUseCaseKey, defaultHiddenColumns } = useCase;
    const [areSettingsVisible, toggleSettings] = useState(false);
    const { columnOrder, filteredColumnNames } = useCaseSettings;
    let { columnKeysInRightOrder, filteredColumns, filteredSummaryColumns } = useMemo(
        () => transformColumnStringsToObjects(columnOrder, filteredColumnNames, summaryColumns, columns, isTruEEnabled),
        [columnOrder, columns, filteredColumnNames, isTruEEnabled, summaryColumns]
    );

    const selectedVehicles = vehicles.map(id => allVehicles[id]).filter(Boolean);
    const categorizeVehicles = (vehicles?: Id[], allVehicles?: Map<Vehicle>) => {
        const selectedDieselVehicles: Id[] = [];
        const selectedElectricVehicles: Id[] = [];

        vehicles?.forEach(id => {
            const selectedVehicle = allVehicles?.[id];
            if (selectedVehicle) {
                (selectedVehicle.fuelType === VehicleFuelType.ELECTRIC
                    ? selectedElectricVehicles
                    : selectedDieselVehicles
                ).push(selectedVehicle.id);
            }
        });

        return { selectedDieselVehicles, selectedElectricVehicles };
    };

    const { selectedDieselVehicles, selectedElectricVehicles } = categorizeVehicles(vehicles, allVehicles);
    const containsElectricVehicle = selectedVehicles.some(
        selectedVehicle => selectedVehicle.fuelType === VehicleFuelType.ELECTRIC
    );
    const shouldShowElectricInfo = isTruEEnabled && containsElectricVehicle;

    const shouldHideCo2Emission = selectedFuelTypes.length === 1 && containsElectricVehicle;
    if (shouldHideCo2Emission) {
        filteredColumns = filteredColumns.filter(column => column?.key !== 'co2Emission');
        filteredSummaryColumns = filteredSummaryColumns.filter(column => column?.key !== 'co2Emission');
    }

    const setColumnOrder = (order: string[]) =>
        setColumnOrderForUseCase({ useCaseKey: selectedUseCaseKey, columnOrder: order });
    const setColumns = (columnNames: string[]) =>
        setFilteredColumnNamesForUseCase({ useCaseKey: selectedUseCaseKey, filteredColumnNames: columnNames });

    const performAndAdvanceVehicles = performVehicles.concat(advanceVehicles).concat(performReducedBus);

    const upsellVehicleIds = _.difference(vehicles, performAndAdvanceVehicles);
    const upsellVehicles = upsellVehicleIds
        .map((vehicleId: string) => allVehicles[vehicleId])
        .filter(Boolean)
        .sort((a, b) => a.name.localeCompare(b.name));

    const expandedChildren: LoadableType<HydratedEntityWithChildren[]> = useMemo(() => {
        const sortAndFlatten = createSortAndFlattenFunction(
            openRows,
            findColumnByKey(columns, sortBy.key),
            sortBy.order
        );
        const transform = createTransformFunction(
            advanceVehicles,
            performVehicles,
            performReducedBus,
            shouldShowElectricInfo
        );
        return Loadable.map(parentsWithChildren, compose<HydratedEntityWithChildren[]>(sortAndFlatten, transform));
    }, [
        openRows,
        columns,
        sortBy.key,
        sortBy.order,
        advanceVehicles,
        performVehicles,
        performReducedBus,
        shouldShowElectricInfo,
        parentsWithChildren,
    ]);

    const shouldShowGraph = isFilteringOnSegment || (getShouldShowGraph(expandedChildren) && Boolean(vehicles.length));
    const areSettingsEnabled = getAreSettingsEnabled(expandedChildren);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onOpenChildren = useCallback(openExpanderRow({ openRows, openRow, closeRow }), [openRows, openRow, closeRow]);

    const noVehiclesSelected = (
        <div className="margin-top-20pct">
            <VehicleState
                headlineId="vehicleAnalysis.noVehiclesSelected"
                messageId="vehicleAnalysis.noVehiclesSelected.explanation"
            />
        </div>
    );

    const handleRowToggle = useCallback(
        ({ id, level, children } = {}, active) => {
            if (!active && id) {
                onItemClick({ id, level, childrenIds: (children || []).map((child: HydratedEntity) => child.id) });
            } else {
                // hideSidebar when there is no id returned, so we must be in an
                // unknown disallowed state.
                hideSidebar();
            }
        },
        [hideSidebar, onItemClick]
    );

    const table = Loadable.cata(
        expandedChildren,
        data => {
            if (!data.length && !upsellVehicles.length) {
                return (
                    <div className={!isFilteringOnSegment ? 'margin-top-20pct' : ''}>
                        <VehicleState
                            headlineId="vehicleAnalysisNoVehiclesReturned"
                            messageId="vehicleAnalysisNoVehiclesReturnedExplanation"
                        />
                    </div>
                );
            }
            return (
                <Table
                    key={_.get(useCase, 'key')}
                    performAndAdvanceVehicles={performAndAdvanceVehicles}
                    filteredColumns={filteredColumns.filter((c): c is Column => !!c)}
                    onOpenChildren={onOpenChildren}
                    sortBy={sortBy}
                    onSort={setSortBy}
                    selectedElements={selectedVehicle ? [selectedVehicle] : []}
                    openRows={openRows}
                    upsellVehicles={upsellVehicles}
                    data={data}
                    onRowClicked={handleRowToggle}
                    viewType={viewType}
                    location="fleetAnalysis"
                    shouldShowFuelType={shouldShowElectricInfo}
                    isTruEEnabled={isTruEEnabled}
                />
            );
        },
        () => (
            <div className="margin-top-20">
                <SimpleErrorState headlineId="error.default" messageId="error.server" />
            </div>
        ),
        () => (
            <Table
                key={'loading'}
                filteredColumns={filteredColumns
                    .filter((c): c is Column => !!c)
                    .map(c => ({ ...c, formatter: loadingFormatter }))}
                onOpenChildren={() => {}}
                sortBy={sortBy}
                onSort={setSortBy}
                selectedElements={[]}
                openRows={[]}
                data={_.fill(Array(3), { level: 1, id: '', isLoading: true })}
                onRowClicked={() => {}}
                viewType={viewType}
                shouldShowFuelType={false}
                isTruEEnabled={false}
            />
        ),
        () => noVehiclesSelected
    );

    const filterSummary = useMemo(
        () => <VehicleFilterSummary loadableEntities={parentsWithChildren} dateRange={dateRange} />,
        [dateRange, parentsWithChildren]
    );

    const filterVehicles = (selectedVehicles: Id[], performVehicles: Id[]) =>
        performVehicles ? _.intersection(selectedVehicles, performVehicles) : performVehicles;

    const selectedPerformVehicles = performVehicles ? _.intersection(vehicles, performVehicles) : vehicles;
    const selectedPerformDieselVehicles = filterVehicles(selectedDieselVehicles, performVehicles);
    const selectedPerformElectricVehicles = filterVehicles(selectedElectricVehicles, performVehicles);
    return (
        <div data-test="VehicleContent">
            <MixedFleetAlert />
            <Toolbar onDateRangeChange={hideSidebar}>
                <div className="table-toolbar-column table-toolbar-column-spacer">
                    <UseCases
                        onSelect={setUseCase}
                        active={selectedUseCaseKey}
                        useCaseConfig={useCaseConfig}
                        location="fleetAnalysis"
                    />
                </div>
                <div className="table-toolbar-column">
                    <DownloadMenu
                        dateRange={dateRange}
                        vehicles={isLatamTenant ? vehicles : selectedPerformVehicles}
                        dieselVehicles={isLatamTenant ? selectedDieselVehicles : selectedPerformDieselVehicles}
                        electricVehicles={isLatamTenant ? selectedElectricVehicles : selectedPerformElectricVehicles}
                        disabled={!areSettingsEnabled}
                        useCaseKey={useCase.key}
                        isLatamTenant={isLatamTenant}
                    />
                </div>
            </Toolbar>
            <SettingsDialog
                areSettingsVisible={areSettingsVisible}
                hiddenColumns={filteredColumnNames}
                toggleSettings={toggleSettings}
                defaultHiddenColumns={defaultHiddenColumns}
                columnOrder={columnKeysInRightOrder}
                setColumnOrder={setColumnOrder}
                defaultColumnOrder={columns.map(column => column.key)}
                setColumns={setColumns}
                columns={columns}
                account={account}
                location="fleetAnalysis"
                useCase={`Fleet${_.upperFirst(_.camelCase(selectedUseCaseKey))}`}
            />
            {!isMobile && shouldShowGraph && (
                <VehicleSummary dateRange={dateRange} columns={filteredSummaryColumns} filterSummary={filterSummary} />
            )}
            {vehicles.length ? (
                <Fragment>
                    <SettingsToolbar
                        disabled={!areSettingsEnabled}
                        toggleSettings={toggleSettings}
                        location="fleetAnalysis"
                    />
                    {table}
                </Fragment>
            ) : (
                noVehiclesSelected
            )}
            <AdministrationSettingsFooter />
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setFilteredColumnNamesForUseCase: ({
        useCaseKey,
        filteredColumnNames,
    }: {
        useCaseKey: string;
        filteredColumnNames: string[];
    }) => dispatch(setFilteredColumnNamesForUseCase({ useCaseKey, filteredColumnNames })),
    setColumnOrderForUseCase: ({ useCaseKey, columnOrder }: { useCaseKey: string; columnOrder: string[] }) =>
        dispatch(setColumnOrderForUseCase({ useCaseKey, columnOrder })),
    setUseCase: (useCaseKey: string) => dispatch(setUseCase(useCaseKey)),
    hideSidebar: () => dispatch(hideSidebar()),
    setSortBy: (sortBy: SortBy) => dispatch(setSortedBy(sortBy)),
    openRow: (id: Id) => dispatch(openRow({ id })),
    closeRow: (id: Id) => dispatch(closeRow({ id })),
});

export default connect(
    (state: State) => ({
        useCaseSettings: getUseCaseSetting(state, getSelectedUseCase(state)),
        useCaseConfig: getUseCaseConfig(),
        useCase: getSelectedUseCaseConfig(state),
        sortBy: getSortedBy(state),
        selectedVehicle: _.get(sidebarData(state, VEHICLE_ROUTE), 'id'),
        performVehicles: getPerformVehicles(state),
        advanceVehicles: getAdvanceVehicles(state),
        performReducedBus: getPerformReducedBus(state),
        isFilteringOnSegment: isFilteringOnSegment(state),
        openRows: getOpenRows(state),
        viewType: getTableViewType(state),
        isMobile: isMobile(state),
        account: getAccount(state),
        isLatamTenant: isLATAMTenant(state),
        allVehicles: getVehicles(state),
        selectedFuelTypes: getSelectedFuelTypes(state),
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
    }),
    mapDispatchToProps
)(Content);
