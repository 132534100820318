import moment from 'moment';
import { Fragment } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import Release500 from './release5.0.0';
import Release510 from './release5.1.0';
import Release520 from './release5.2.0';
import Release530 from './release5.3.0';

const ReleaseTitle = ({ date, versionNumber }: { date: string; versionNumber: string }) => (
    <div className="display-flex align-items-center margin-top-20">
        <p className="text-bold text-size-20 text-muted margin-0" style={{ width: '110px' }}>
            <FormattedDate value={moment(date, moment.ISO_8601).toDate()} />
        </p>
        <p className="text-bold text-size-20 margin-0">v {versionNumber}</p>
    </div>
);

export default function Changelog() {
    return (
        <Fragment>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.changelog.title" />
            </h3>
            <ReleaseTitle date="2023-05-12T00:00:00" versionNumber="5.3.0" />
            <Release530 />
            <ReleaseTitle date="2022-12-22T00:00:00" versionNumber="5.2.0" />
            <Release520 />
            <ReleaseTitle date="2022-10-27T00:00:00" versionNumber="5.1.0" />
            <Release510 />
            <ReleaseTitle date="2022-07-28T00:00:00" versionNumber="5.0.0" />
            <Release500 />
        </Fragment>
    );
}
