import {
    AssetsReportType,
    Driver,
    DriverReportType,
    FleetReportType,
    GroupReportType,
    ReportConfiguration,
    ReportStatus,
    ReportType,
    TargetType,
    Vehicle,
    VehicleGroup,
} from '@api/index';
import { isEmpty, keyBy, omit, pick } from 'lodash';

import { unassignedGroup as ungroupedGroup } from '../../../api';
import { ReportForm } from '../types';
import {
    AssetsAndGroupsReportType,
    DriversAndGroupsReportType,
    EditReportConfigurationRequest,
    ReportConfigurationTargetsType,
} from './../../../api/models/reportConfiguration';

const isAssetReportType = (reportType: ReportType) =>
    [ReportType.asset_details, ReportType.vehspec_test, ReportType.fleet].includes(reportType);

export const handleFormData = (report: ReportForm, groups: VehicleGroup[]): ReportConfiguration => {
    const { entities, ...requestReport } = { ...report, status: ReportStatus.active };

    if (report.entities.groups.length === groups.length) {
        return {
            ...requestReport,
            type: TargetType.fleet,
        } as FleetReportType;
    }

    const onlyExistItemsSelected = !isEmpty(entities.items) && isEmpty(entities.groups);
    const onlyExistGroupsSelected = isEmpty(entities.items) && !isEmpty(entities.groups);

    if (onlyExistGroupsSelected) {
        return {
            ...requestReport,
            type: TargetType.groups,
            groups: entities.groups,
        } as GroupReportType;
    }

    if (!isAssetReportType(report.reportType)) {
        if (onlyExistItemsSelected) {
            return {
                ...requestReport,
                type: TargetType.drivers,
                drivers: entities.items,
            } as DriverReportType;
        }
        return {
            ...requestReport,
            type: TargetType.driversAndGroups,
            drivers: entities.items,
            groups: entities.groups,
        } as DriversAndGroupsReportType;
    } else {
        if (onlyExistItemsSelected) {
            return {
                ...requestReport,
                type: TargetType.assets,
                assets: entities.items,
            } as AssetsReportType;
        }
        return {
            ...requestReport,
            type: TargetType.assetsAndGroups,
            assets: entities.items,
            groups: entities.groups,
        } as AssetsAndGroupsReportType;
    }
};

export const handleResponseApi = (report: ReportConfiguration, groupIds: string[]): ReportForm => {
    const responseReport = omit(report, 'drivers', 'assets', 'groups');
    const entities =
        responseReport.type === TargetType.fleet
            ? {
                  items: [],
                  groups: groupIds,
              }
            : {
                  items: [
                      ...Object.values((report as DriverReportType | DriversAndGroupsReportType)?.drivers || []),
                      ...Object.values((report as AssetsReportType | AssetsAndGroupsReportType)?.assets || []),
                  ],
                  groups: [
                      ...Object.values(
                          (report as GroupReportType | DriversAndGroupsReportType | AssetsAndGroupsReportType)
                              ?.groups || []
                      ),
                  ],
              };
    return {
        ...responseReport,
        status: responseReport.status === ReportStatus.active,
        entities,
    };
};

const convertToListGroupedByGroupId = <A extends { groupIds: string[] }[]>(items: A) => {
    return Object.values(items).reduce((acc, item) => {
        item.groupIds.forEach(group => {
            if (!acc.has(group)) {
                acc.set(group, []);
            }
            acc.set(group, acc.get(group)?.concat([item]));
        });
        return acc;
    }, new Map<string, { groupIds: string[] }[] | undefined>());
};

const getUniqueItemsInGroups = <T extends { groupIds: string[]; id?: string }>(
    report: AssetsAndGroupsReportType | DriversAndGroupsReportType | GroupReportType,
    groupsMap: Map<string, { groupIds: string[] }[] | undefined>
): T[] => {
    const { items } = report.groups.reduce(
        (acc: { items: { groupIds: string[]; id?: string }[]; itemsIds: Set<unknown> }, group) => {
            const itemsInGroup = groupsMap.get(group);

            if (itemsInGroup === undefined || itemsInGroup.length === 0) {
                return acc;
            }

            if (acc.itemsIds.size === 0) {
                return {
                    itemsIds: new Set(itemsInGroup.map((item: { groupIds: string[]; id?: string }) => item.id)),
                    items: itemsInGroup,
                };
            }

            itemsInGroup.forEach((item: { groupIds: string[]; id?: string }) => {
                if (!acc.itemsIds.has(item.id)) {
                    acc.itemsIds.add(item.id);
                    acc.items.push(item);
                }
            });

            return acc;
        },
        { itemsIds: new Set(), items: [] }
    );

    return items as T[];
};

export const selectTree = (
    report: ReportConfiguration,
    vehicles: Vehicle[],
    groups: VehicleGroup[],
    drivers: Driver[]
): { selectedDrivers: Driver[]; selectedGroups: VehicleGroup[]; selectedVehicles: Vehicle[] } => {
    const defaults = { selectedVehicles: [], selectedDrivers: [], selectedGroups: [] };
    switch (report.type) {
        case TargetType.assets: {
            const vehiclesAsMap = keyBy(vehicles, 'vehicleId');
            return {
                ...defaults,
                selectedVehicles: report.assets.map(asset => vehiclesAsMap[asset]).filter(Boolean),
            };
        }

        case TargetType.assetsAndGroups: {
            const groupAsMap = keyBy(groups, 'id');
            const groupsSelected = report.groups.map(group => groupAsMap[group]).filter(Boolean);

            const vehiclesAsMap = keyBy(vehicles, 'vehicleId');
            const individualVehiclesSelected = report.assets.map(asset => vehiclesAsMap[asset]).filter(Boolean);
            const selectedVehiclessWithUngroupedGroup = individualVehiclesSelected.map(v => ({
                ...v,
                groupIds: [ungroupedGroup.id],
            }));

            const vehiclesGroupedByGroup = convertToListGroupedByGroupId(vehicles);
            const vehiclesFromSelectedGroups = getUniqueItemsInGroups<Vehicle>(report, vehiclesGroupedByGroup);

            return {
                ...defaults,
                selectedGroups: [...groupsSelected, ungroupedGroup],
                selectedVehicles: [...selectedVehiclessWithUngroupedGroup, ...vehiclesFromSelectedGroups],
            };
        }

        case TargetType.drivers: {
            const driversAsMap = keyBy(drivers, 'driverId');
            const driversSelected = report.drivers.map(driver => driversAsMap[driver]).filter(Boolean);

            return {
                ...defaults,
                selectedDrivers: driversSelected,
            };
        }

        case TargetType.driversAndGroups: {
            const groupAsMap = keyBy(groups, 'id');
            const groupsSelected = report.groups.map(group => groupAsMap[group]).filter(Boolean);

            const driversAsMap = keyBy(drivers, 'driverId');
            const individualDriversSelected = report.drivers.map(driver => driversAsMap[driver]).filter(Boolean);
            const selectedDriversWithUngroupedGroup = individualDriversSelected.map(d => ({
                ...d,
                groupIds: [ungroupedGroup.id],
            }));

            const driversGroupedByGroup = convertToListGroupedByGroupId(drivers);
            const driversFromSelectedGroups = getUniqueItemsInGroups<Driver>(report, driversGroupedByGroup);

            return {
                ...defaults,
                selectedGroups: [...groupsSelected, ungroupedGroup],
                selectedDrivers: [...selectedDriversWithUngroupedGroup, ...driversFromSelectedGroups],
            };
        }

        case TargetType.groups: {
            const groupAsMap = keyBy(groups, 'id');

            const vehiclesGroupedByGroup = convertToListGroupedByGroupId(vehicles);
            const vehiclesFromSelectedGroups = getUniqueItemsInGroups<Vehicle>(report, vehiclesGroupedByGroup);

            const driverGroupedByGroup = convertToListGroupedByGroupId(drivers);
            const driversFromSelectedGroups = getUniqueItemsInGroups<Driver>(report, driverGroupedByGroup);

            return {
                selectedGroups: report.groups.map(group => groupAsMap[group]).filter(Boolean),
                selectedVehicles: vehiclesFromSelectedGroups,
                selectedDrivers: driversFromSelectedGroups,
            };
        }

        case TargetType.fleet: {
            return {
                selectedVehicles: Object.values(vehicles),
                selectedDrivers: Object.values(drivers),
                selectedGroups: Object.values(groups),
            };
        }
    }
};

export const handlePatch = (
    reportForm: Omit<ReportForm, 'reports'>,
    groups: VehicleGroup[]
): EditReportConfigurationRequest => {
    const newReport = handleFormData(reportForm, groups);
    const patchedReport = pick(newReport, 'name', 'informViaEmail', 'id', 'languageCode', 'timeframe');
    const selection = pick(newReport, 'assets', 'drivers', 'groups', 'type');

    return {
        ...patchedReport,
        ...selection,
        targets: handleTargets(reportForm.entities.items, reportForm.entities.groups, newReport.type),
        status: reportForm.status ? ReportStatus.active : ReportStatus.disabled,
    };
};

const handleTargets = (items: string[], groups: string[], type: TargetType): ReportConfigurationTargetsType[] => {
    switch (type) {
        case TargetType.assets:
            return [{ type: TargetType.assets, assets: items }];
        case TargetType.assetsAndGroups:
            return [
                { type: TargetType.groups, groups: groups },
                { type: TargetType.assets, assets: items },
            ];
        case TargetType.drivers:
            return [{ type: TargetType.drivers, drivers: items }];
        case TargetType.driversAndGroups:
            return [
                { type: TargetType.groups, groups: groups },
                { type: TargetType.drivers, drivers: items },
            ];
        case TargetType.groups:
            return [{ type: TargetType.groups, groups: groups }];
        case TargetType.fleet:
            return [{ type: TargetType.fleet }];
    }
};
