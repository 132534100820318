import { getAccessToken } from '@common/tokenHandling/selectors';
import { cleanUpRequestsCache } from '@data/reducer';
import { backendConfig } from '@data/selectors';
import { hideSidebar } from '@features/ui/reducer';
import { isEqual } from 'lodash';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { configureReporting } from '../../setup/errorReporting';
import { infoNotification } from '../ui/notificationSaga';
import {
    CalculationParams,
    CalculationParamsPreset,
    defaultCalculationParams,
    defaultCalculationParamsPreset,
    defaultOverspeedLimit,
    loadSettingsSuccess as settingsLoaded,
    setCurrentCalculationParams,
    setCurrentCalculationParamsPreset,
    setCurrentOverspeedLimit,
    setSavedCalculationParams,
    setSavedCalculationParamsPreset,
    setSavedOverspeedLimit,
    toggleCalcParamsDialog,
} from './reducer';
import { apiCall, loadSettingsFromStorage, saveSettingsToStorage } from './saga';

const { captureException } = configureReporting(window, import.meta.env);

export const SETTINGS = {
    CURRENT_CALCULATION_PARAMS: 'currentCalculationParams',
    SAVED_CALCULATION_PARAMS: 'savedCalculationParams',
    CURRENT_CALCULATION_PARAMS_PRESET: 'currentCalculationParamsPreset',
    SAVED_CALCULATION_PARAMS_PRESET: 'savedCalculationParamsPreset',
    CURRENT_OVERSPEED_LIMIT: 'currentOverspeedLimit',
    SAVED_OVERSPEED_LIMIT: 'savedOverspeedLimit',
};

interface calculationParamsI {
    accelerationPedalRatingWeight: number;
    accelerationPedalRatingWeightEnabled: boolean;
    brakingRatingWeight: number;
    brakingRatingWeightEnabled: boolean;
    cruiseControlRatingWeight: number;
    cruiseControlRatingWeightEnabled: boolean;
    coastingRatingWeight: number;
    coastingRatingWeightEnabled: boolean;
    overSpeedRatingWeight: number;
    overSpeedRatingWeightEnabled: boolean;
    harshAccelerationRatingWeight: number;
    harshAccelerationRatingWeightEnabled: boolean;
    harshBrakingRatingWeight: number;
    harshBrakingRatingWeightEnabled: boolean;
    excessiveIdlingRatingWeight: number;
    excessiveIdlingRatingWeightEnabled: boolean;
}

const DEFAULT_VALUES: calculationParamsI = {
    accelerationPedalRatingWeight: 0.2,
    accelerationPedalRatingWeightEnabled: true,
    brakingRatingWeight: 0.2,
    brakingRatingWeightEnabled: true,
    cruiseControlRatingWeight: 0.2,
    cruiseControlRatingWeightEnabled: true,
    coastingRatingWeight: 0.2,
    coastingRatingWeightEnabled: true,
    overSpeedRatingWeight: 0.2,
    overSpeedRatingWeightEnabled: true,
    harshAccelerationRatingWeight: 0,
    harshAccelerationRatingWeightEnabled: true,
    harshBrakingRatingWeight: 0,
    harshBrakingRatingWeightEnabled: true,
    excessiveIdlingRatingWeight: 0,
    excessiveIdlingRatingWeightEnabled: true,
};

export function* resetCalculationParamsToDefault() {
    const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const baseUrl: ReturnType<typeof backendConfig> = yield select(backendConfig, 'QUERY_SERVICE');
    const url = `${baseUrl}/calculation-parameters`;

    yield call(apiCall, url, {
        method: 'PUT',
        body: JSON.stringify(DEFAULT_VALUES),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
}

export function* updateAllCalculationParams(calculationParams: CalculationParams) {
    const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const baseUrl: ReturnType<typeof backendConfig> = yield select(backendConfig, 'QUERY_SERVICE');
    const url = `${baseUrl}/calculation-parameters`;

    const newUpdatedCalculationParams = Object.keys(calculationParams).reduce((acc, calcParamName) => {
        const { weight, enabled } = calculationParams[calcParamName as keyof CalculationParams];
        return {
            ...acc,
            [`${calcParamName}Weight`]: weight / 100,
            [`${calcParamName}WeightEnabled`]: enabled,
        };
    }, DEFAULT_VALUES);

    yield call(apiCall, url, {
        method: 'PUT',
        body: JSON.stringify(newUpdatedCalculationParams),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
}

export function* updateOverspeedLimit(updatedLimit: number) {
    const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
    const baseUrl: ReturnType<typeof backendConfig> = yield select(backendConfig, 'QUERY_SERVICE');
    const url = `${baseUrl}/overspeed-threshold`;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };

    if (updatedLimit === defaultOverspeedLimit) {
        yield call(apiCall, url, {
            method: 'DELETE',
            headers,
        });
    } else {
        yield call(apiCall, url, {
            method: 'POST',
            body: JSON.stringify({ overspeed_threshold: updatedLimit }),
            headers,
        });
    }
}

export function* clearAllSettings() {
    try {
        yield saveSettingsToStorage(SETTINGS.CURRENT_CALCULATION_PARAMS, defaultCalculationParams);
        yield saveSettingsToStorage(SETTINGS.SAVED_CALCULATION_PARAMS, defaultCalculationParams);
        yield saveSettingsToStorage(SETTINGS.CURRENT_CALCULATION_PARAMS_PRESET, defaultCalculationParamsPreset);
        yield saveSettingsToStorage(SETTINGS.SAVED_CALCULATION_PARAMS_PRESET, defaultCalculationParamsPreset);
        yield saveSettingsToStorage(SETTINGS.CURRENT_OVERSPEED_LIMIT, defaultOverspeedLimit);
        yield saveSettingsToStorage(SETTINGS.SAVED_OVERSPEED_LIMIT, defaultOverspeedLimit);
        yield resetCalculationParamsToDefault();
        yield updateOverspeedLimit(defaultOverspeedLimit);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* updateCurrentCalculationParams({
    payload: {
        previousSavedCalcParams,
        updatedCalcParams,
        previousSavedPreset,
        updatedPreset,
        previousSavedOverspeedLimit,
        updatedOverspeedLimit,
        restored,
    },
}: {
    payload: {
        previousSavedCalcParams: CalculationParams;
        updatedCalcParams: CalculationParams;
        previousSavedPreset: CalculationParamsPreset;
        updatedPreset: CalculationParamsPreset;
        restored: boolean;
        previousSavedOverspeedLimit: number;
        updatedOverspeedLimit: number;
    };
}) {
    try {
        yield saveSettingsToStorage(SETTINGS.CURRENT_CALCULATION_PARAMS, updatedCalcParams);
        yield saveSettingsToStorage(SETTINGS.CURRENT_CALCULATION_PARAMS_PRESET, updatedPreset);
        yield saveSettingsToStorage(SETTINGS.CURRENT_OVERSPEED_LIMIT, updatedOverspeedLimit);

        if (!restored) {
            if (!isEqual(updatedCalcParams, previousSavedCalcParams)) {
                yield updateAllCalculationParams(updatedCalcParams);
            }
            if (updatedOverspeedLimit !== previousSavedOverspeedLimit) {
                yield updateOverspeedLimit(updatedOverspeedLimit);
            }
            yield put(cleanUpRequestsCache());
            yield put(hideSidebar());
            yield put(toggleCalcParamsDialog(false));
        }

        yield put(setSavedCalculationParams({ value: updatedCalcParams }));
        yield put(setSavedCalculationParamsPreset({ value: updatedPreset }));
        yield put(setSavedOverspeedLimit({ value: updatedOverspeedLimit }));
    } catch (e) {
        yield call(captureException, e);
        yield delay(1000);
        yield call(infoNotification, 'error.default');
        yield put(
            setCurrentCalculationParams({
                previousSavedCalcParams,
                updatedCalcParams: previousSavedCalcParams,
                previousSavedPreset,
                updatedPreset: previousSavedPreset,
                previousSavedOverspeedLimit,
                updatedOverspeedLimit: previousSavedOverspeedLimit,
                restored: true,
            })
        );
        yield put(setCurrentCalculationParamsPreset({ value: previousSavedPreset }));
        yield put(setCurrentOverspeedLimit({ value: previousSavedOverspeedLimit }));
    }
}

export function* updateSavedCalculationParams({ payload: { value } }: { payload: { value: CalculationParams } }) {
    try {
        yield saveSettingsToStorage(SETTINGS.SAVED_CALCULATION_PARAMS, value);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* updateCurrentPreset({ payload: { value } }: { payload: { value: CalculationParamsPreset } }) {
    try {
        yield saveSettingsToStorage(SETTINGS.CURRENT_CALCULATION_PARAMS_PRESET, value);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* updateSavedPreset({ payload: { value } }: { payload: { value: CalculationParamsPreset } }) {
    try {
        yield saveSettingsToStorage(SETTINGS.SAVED_CALCULATION_PARAMS_PRESET, value);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* updateCurrentOverspeedLimit({ payload: { value } }: { payload: { value: number } }) {
    try {
        yield saveSettingsToStorage(SETTINGS.CURRENT_OVERSPEED_LIMIT, value);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* updateSavedOverspeedLimit({ payload: { value } }: { payload: { value: number } }) {
    try {
        yield saveSettingsToStorage(SETTINGS.SAVED_OVERSPEED_LIMIT, value);
    } catch (e) {
        yield call(captureException, e);
    }
}

export function* restoreSavedSettings() {
    try {
        const token: ReturnType<typeof getAccessToken> = yield select(getAccessToken);
        const baseUrl: ReturnType<typeof backendConfig> = yield select(backendConfig, 'QUERY_SERVICE');
        const url = `${baseUrl}/calculation-parameters`;
        const calcParamsWeightsStoredInBackend: calculationParamsI = yield call(apiCall, url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        const calculationParamsInBackend: CalculationParams = Object.keys(defaultCalculationParams).reduce(
            (acc, calcParamName) => {
                const weightInBackend = calcParamsWeightsStoredInBackend[
                    `${calcParamName}Weight` as keyof calculationParamsI
                ] as number;
                const enabledOrDisabledInBackend = calcParamsWeightsStoredInBackend[
                    `${calcParamName}WeightEnabled` as keyof calculationParamsI
                ] as boolean;

                const { weight: defaultWeight, enabled: defaultEnabledOrDisabled } = defaultCalculationParams[
                    calcParamName as keyof CalculationParams
                ];

                const weight = weightInBackend === undefined ? defaultWeight : weightInBackend;
                const enabledOrDisabled =
                    enabledOrDisabledInBackend === undefined ? defaultEnabledOrDisabled : enabledOrDisabledInBackend;

                return {
                    ...acc,
                    [calcParamName]: {
                        enabled: enabledOrDisabled,
                        weight: weight * 100,
                    },
                };
            },
            defaultCalculationParams
        );

        const currentPreset: CalculationParamsPreset = yield loadSettingsFromStorage(
            SETTINGS.CURRENT_CALCULATION_PARAMS_PRESET,
            defaultCalculationParamsPreset
        );
        const currentOverspeedLimit: number = yield loadSettingsFromStorage(
            SETTINGS.CURRENT_OVERSPEED_LIMIT,
            defaultOverspeedLimit
        );
        yield put(
            setCurrentCalculationParams({
                previousSavedCalcParams: calculationParamsInBackend,
                updatedCalcParams: calculationParamsInBackend,
                previousSavedPreset: currentPreset,
                updatedPreset: currentPreset,
                previousSavedOverspeedLimit: currentOverspeedLimit,
                updatedOverspeedLimit: currentOverspeedLimit,
                restored: true,
            })
        );
        yield put(setCurrentCalculationParamsPreset({ value: currentPreset }));
        yield put(setCurrentOverspeedLimit({ value: currentOverspeedLimit }));

        yield put(setSavedCalculationParams({ value: calculationParamsInBackend }));

        const savedPreset: CalculationParamsPreset = yield loadSettingsFromStorage(
            SETTINGS.SAVED_CALCULATION_PARAMS_PRESET,
            defaultCalculationParamsPreset
        );
        yield put(setSavedCalculationParamsPreset({ value: savedPreset }));

        const savedOverspeedLimit: number = yield loadSettingsFromStorage(
            SETTINGS.SAVED_OVERSPEED_LIMIT,
            defaultOverspeedLimit
        );
        yield put(setSavedOverspeedLimit({ value: savedOverspeedLimit }));
    } catch (e) {
        yield call(captureException, e);
        yield clearAllSettings();
        yield delay(1000);
        yield call(infoNotification, 'info.settingsCouldNotBeRestored');
    }
}

export default function* root() {
    yield takeLatest(setCurrentCalculationParams, updateCurrentCalculationParams);
    yield takeLatest(setSavedCalculationParams, updateSavedCalculationParams);
    yield takeLatest(setCurrentCalculationParamsPreset, updateCurrentPreset);
    yield takeLatest(setSavedCalculationParamsPreset, updateSavedPreset);
    yield takeLatest(setCurrentOverspeedLimit, updateCurrentOverspeedLimit);
    yield takeLatest(setSavedOverspeedLimit, updateSavedOverspeedLimit);
    yield takeLatest(settingsLoaded, restoreSavedSettings);
}
