import { Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

const isValidDate = (date: Date | moment.MomentInput) =>
    date instanceof Date || date instanceof moment || moment(date, moment.ISO_8601).isValid();

export default function FromToRange({ start, end }: { start: string | Date; end: Date | string }) {
    if (isValidDate(start) && isValidDate(end)) {
        return (
            <View>
                <View>
                    <Text data-test="startDate">
                        <FormattedDate value={start} year="numeric" month="2-digit" day="2-digit" />{' '}
                        <FormattedTime value={start} />
                    </Text>
                </View>
                <View>
                    <Text data-test="endDate">
                        <FormattedDate value={end} year="numeric" month="2-digit" day="2-digit" />{' '}
                        <FormattedTime value={end} />
                    </Text>
                </View>
            </View>
        );
    }
    return <Text>-</Text>;
}

FromToRange.propTypes = {
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
};
