export type AccessTokenStorage = {
    discardAccessToken: () => void;
    getAccessToken: () => string | null;
    hasAccessToken: () => boolean;
    saveAccessToken: (token: string) => void;
};

export const configureStorage = (): AccessTokenStorage => {
    let accessToken: string | null = null;
    return {
        discardAccessToken: () => {
            accessToken = null;
        },
        getAccessToken: () => accessToken,
        hasAccessToken: () => Boolean(accessToken),
        saveAccessToken: (token: string) => {
            accessToken = token;
        },
    };
};
