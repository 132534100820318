import { VehicleFuelType } from '@api/index';
import cn from 'classnames';

export const ALL_VEHICLE_FUEL_TYPES = [
    VehicleFuelType.DIESEL,
    VehicleFuelType.ELECTRIC,
    VehicleFuelType.GAS,
    VehicleFuelType.HYDROGEN,
    VehicleFuelType.UNKNOWN,
];
const DIESEL_VEHICLE_TYPES = [VehicleFuelType.DIESEL, VehicleFuelType.GAS, VehicleFuelType.UNKNOWN];
const ELECTRIC_VEHICLE_FUEL_TYPE = [VehicleFuelType.ELECTRIC];

const checkVehicleFuelTypes = (fuelTypesArray1: VehicleFuelType[], fuelTypesArray2: VehicleFuelType[]) => {
    return JSON.stringify(fuelTypesArray1) === JSON.stringify(fuelTypesArray2);
};
const TreeFilterButtons: React.FC<{
    onSelectionChange: (fuelTypes: VehicleFuelType[]) => void;
    selectedFuelTypes: VehicleFuelType[];
}> = ({ onSelectionChange, selectedFuelTypes }) => {
    return (
        <div className="TreeSearch">
            <div className="btn-toolbar width-100pct">
                <div className="btn-group width-100pct">
                    <button
                        onClick={() => onSelectionChange(ALL_VEHICLE_FUEL_TYPES)}
                        className={cn(
                            'btn width-100pct btn-default ToggleButton',
                            checkVehicleFuelTypes(selectedFuelTypes, ALL_VEHICLE_FUEL_TYPES) ? 'active' : ''
                        )}
                    >
                        <div>All</div>
                    </button>
                    <button
                        onClick={() => onSelectionChange([VehicleFuelType.ELECTRIC])}
                        className={cn(
                            'btn width-100pct btn-default ToggleButton',
                            checkVehicleFuelTypes(selectedFuelTypes, ELECTRIC_VEHICLE_FUEL_TYPE) ? 'active' : ''
                        )}
                    >
                        <div>Electric</div>
                    </button>
                    <button
                        onClick={() =>
                            onSelectionChange([VehicleFuelType.DIESEL, VehicleFuelType.GAS, VehicleFuelType.UNKNOWN])
                        }
                        className={cn(
                            'btn width-100pct btn-default ToggleButton',
                            checkVehicleFuelTypes(selectedFuelTypes, DIESEL_VEHICLE_TYPES) ? 'active' : ''
                        )}
                    >
                        <div>Fuel</div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TreeFilterButtons;
