import Button from '@rio-cloud/rio-uikit/Button';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

const ResetToDefaultButton = ({ disabled, resetToDefault }: { disabled: boolean; resetToDefault: () => void }) => {
    const [isConfirmingReset, setIsConfirmingReset] = useState(false);

    return (
        <>
            {isConfirmingReset ? (
                <div className="btn-group">
                    <Button
                        variant="outline"
                        className="btn-icon-only btn-default margin-right-5"
                        onClick={() => setIsConfirmingReset(false)}
                    >
                        <span className={'rioglyph rioglyph-remove'} />
                    </Button>
                    <Button
                        className="btn-icon-only"
                        onClick={() => {
                            setIsConfirmingReset(false);
                            resetToDefault();
                        }}
                        bsStyle="primary"
                    >
                        <span className={'rioglyph rioglyph-ok'} />
                    </Button>
                </div>
            ) : (
                <Button
                    variant="link"
                    className="btn btn-primary btn-link"
                    onClick={() => setIsConfirmingReset(true)}
                    disabled={disabled}
                >
                    <span className="rioglyph rioglyph-revert text-size-small margin-right-10" />
                    <span>
                        <FormattedMessage id="resetChanges" />
                    </span>
                </Button>
            )}
        </>
    );
};

export default ResetToDefaultButton;
