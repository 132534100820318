import { isRight, map } from 'fp-ts/Either';
import { pipe } from 'fp-ts/pipeable';
import { array, intersection, nullType, number, partial, record, string, type, TypeOf, union } from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import _ from 'lodash';
import moment from 'moment';

import { TBM3ReportEntityFactory } from '../helpers/transformer';
import { Entity } from '../models/types';

const RawSegment = intersection([
    partial({
        result_from: string,
        result_to: string,
        vehicle_ids: array(string),
        driver_ids: array(string),
    }),
    record(
        string,
        union(
            [
                string,
                number,
                array(string),
                array(number),
                array(union([string, nullType])),
                nullType,
                partial({
                    day: number,
                    halfyear: number,
                    month: number,
                    quarter: number,
                    week: number,
                    year: number,
                }),
            ],
            'segment'
        )
    ),
]);

const PerformApiResponse = type({
    id: string,
    config: type({
        attributes: array(union([string, nullType])),
    }),
    values: intersection([
        partial({
            total_segment: union([RawSegment, nullType]),
        }),
        type({
            attributes: array(
                type({
                    key: string,
                })
            ),
            segments: array(RawSegment),
        }),
    ]),
});

export type PerformApiResponseType = TypeOf<typeof PerformApiResponse>;

export function decodePerformRequest(rawResponse: PerformApiResponseType): Entity[] {
    const either = pipe(
        PerformApiResponse.decode(rawResponse),
        map(decoded => {
            // The current implementation of the perform api does not always return all the requested fields in every segment
            // This map sets up every field that does not exist in the segment response, although it was requested, to null.
            const requestedAttributes = decoded.config.attributes;
            // driver_ids and vehicle_ids should never be undefined but if not present they should be empty arrays.
            const ARRAY_FIELDS = ['driver_ids', 'vehicle_ids'];
            const expandedSegments = decoded.values.segments.map(segment => {
                const defaults = requestedAttributes
                    .filter((a): a is string => a !== null)
                    .reduce((acc, key) => ({ ...acc, [key]: ARRAY_FIELDS.includes(key) ? [] : undefined }), {});
                return {
                    ...defaults,
                    ...segment,
                };
            });

            return { segments: expandedSegments, id: decoded.id };
        }),
        map(({ segments, id }) =>
            segments.map(segment => {
                const driverIds = segment.driver_ids;

                const start = segment.result_from ? moment(segment.result_from, moment.ISO_8601).toDate() : undefined;
                const end = segment.result_from ? moment(segment.result_to, moment.ISO_8601).toDate() : undefined;

                const cleanedSegment = Object.entries(segment)
                    .filter(([key]) => !['result_from', 'result_to', 'driver_ids'].includes(key))
                    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

                const decodedPerformResponse: {
                    start?: Date;
                    end?: Date;
                    driver_ids?: string[];
                } = {
                    ...cleanedSegment,
                    ...(driverIds ? { driver_ids: driverIds } : {}),
                    ...(start ? { start } : {}),
                    ...(end ? { end } : {}),
                };

                const transformFieldName = (value: unknown, key: string) => _.camelCase(key);

                return TBM3ReportEntityFactory(
                    _.mapKeys(decodedPerformResponse, transformFieldName),
                    decodedPerformResponse,
                    id
                );
            })
        )
    );

    if (isRight(either)) {
        return either.right;
    }

    throw Error(`Decoding Perform Response failed, ${PathReporter.report(either)}`);
}
