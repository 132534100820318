import { createHeaders } from './api';

export async function fetchReportPDF(baseUrl: string, authToken: string, id: string) {
    const requestObject = { method: 'get', headers: createHeaders(authToken) };
    const responses = await fetch(`${baseUrl}/reports/${id}/download`, requestObject);

    return responses.blob();
}

export async function postReportTrigger(baseUrl: string, authToken: string, id: string) {
    const requestObject = { method: 'post', headers: createHeaders(authToken), body: JSON.stringify({ id }) };
    const response = await fetch(`${baseUrl}/trigger`, requestObject);

    return response.status;
}
