import { Id } from '@api/models/types';
import useQuery, { Variables } from '@utils/useQuery';
import moment from 'moment';

import { DateRange } from '../../types';
import { requestOpConData } from '../actions';

export const QUERY = ({
    driverIds,
    vehicleIds,
    dateRange,
    groupBy,
}: {
    driverIds: Id[];
    vehicleIds: Id[];
    dateRange: DateRange;
    groupBy: string[];
}) => {
    return {
        from: moment(dateRange.start).toISOString(true),
        to: moment(dateRange.end).toISOString(true),
        ...(vehicleIds.length ? { asset_ids: vehicleIds } : {}),
        ...(driverIds.length ? { driver_ids: driverIds } : {}),
        facets: ['weight', 'topology', 'range', 'traffic', 'route-type'],
        group_by: groupBy,
    };
};

const useOpconData = ({
    dateRange,
    vehicleIds,
    driverIds,
    groupBy,
    debounced,
}: {
    dateRange: DateRange;
    vehicleIds: Id[];
    driverIds: (Id | null)[];
    groupBy: string[];
    debounced?: boolean;
}) => {
    return useQuery(QUERY, {
        endPoint: requestOpConData,
        variables: { dateRange, vehicleIds, driverIds, groupBy } as Variables,
        debounced,
    });
};

export default useOpconData;
