import { isKoreanTenant } from '@common/login/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import { AssistanceSystemsByVehicle } from '@features/assistanceSystems/AssistanceSystems';
import Battery from '@features/battery/Battery';
import { DriveProgramsByVehicle } from '@features/drivePrograms/DrivePrograms';
import { ForesightedDrivingByVehicle } from '@features/foresightedDriving/ForesightedDriving';
import {
    CalculationParams,
    enablePerform3,
    includeCruiseControlInRating,
    savedCalculationParams,
} from '@features/settings/reducer';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LoginStatePart, PermissionsStatePart, SettingStatePart } from 'src/setup/types';
import { DateRange, HydratedEntity } from 'src/types';

import { getSignal, Id, Vehicle, VehicleType } from '../../api';
import ErrorBoundary from '../../components/ErrorBoundry';
import PerformUpSell from '../../components/PerformUpSell';
import TabbableContent from '../../components/TabbableContent';
import EnvironmentConditions from '../../features/environmentConditions/EnvironmentConditions';
import Total from '../../features/foresightedDriving/Total';
import OperationalRange from '../../features/opcon/OperationalRange';
import OperationConditionRating from '../../features/opcon/OperationConditionRating';
import TrafficJams from '../../features/opcon/TrafficJams';
import VehicleSpecification from '../../features/vehicleSpecification/VehicleSpecification';

type Props = {
    dateRange: DateRange;
    entity: HydratedEntity;
    level: number | undefined;
    hasPerformVehicle: boolean;
    isKoreanTenant: boolean;
    includeCruiseControlInRating: boolean;
    calculationParams: CalculationParams;
    performReducedBusIds: string[];
    shouldShowOvertakeInCruise?: boolean;
    shouldUseV2Ratings?: boolean;
    shouldShowHarshKPIs?: boolean;
    shouldShowExcessiveKPIs?: boolean;
    isTruEEnabled?: boolean;
    isElectricVehicle?: boolean;
};
export const SidepanelTabs = ({
    dateRange,
    entity,
    level,
    hasPerformVehicle,
    isKoreanTenant,
    includeCruiseControlInRating,
    calculationParams,
    performReducedBusIds,
    shouldShowOvertakeInCruise = false,
    shouldUseV2Ratings = true,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveKPIs = false,
    isTruEEnabled = false,
    isElectricVehicle = false,
}: Props) => {
    const vehicles = getSignal<Vehicle[]>(entity, 'vehicles', []);
    const vehicleIds = getSignal<Id[]>(entity, 'vehicleIds', []);
    const driverIds = level === 1 ? [] : getSignal<Id[]>(entity, 'driverIds', []);
    const mileageFromPerformance = getSignal(entity, 'mileage', 0);

    // The opcon expects null to be passed when driverId was null;
    let driverIdsWithUnknownDriverMarkedAsNull = level !== 1 && driverIds.length === 0 ? [null] : driverIds;

    const hasMaxOneDriver = driverIds.length <= 1;
    const upsell = <PerformUpSell vehicles={vehicles} performReducedBusIds={performReducedBusIds} />;
    const props = {
        dateRange,
        vehicleIds,
        driverIds: driverIdsWithUnknownDriverMarkedAsNull,
        mileageFromPerformance,
        canSeeRoute: true,
        isRouteEnabled: level === 1,
    };

    const showTemperatureHistogram = shouldUseV2Ratings;
    const showDrivingPrograms = vehicles[0].type !== VehicleType.VAN && vehicles[0].type !== VehicleType.BUS;
    const showBatteryTab = isTruEEnabled && isElectricVehicle;

    const tabs = [
        ...(showBatteryTab
            ? [
                  {
                      testTag: 'battery',
                      title: <FormattedMessage id="battery" />,
                      content: hasPerformVehicle ? (
                          <Battery dateRange={dateRange} vehicleIds={vehicleIds} driverIds={driverIds} />
                      ) : (
                          upsell
                      ),
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
        ...(shouldUseV2Ratings
            ? [
                  ...(showDrivingPrograms
                      ? [
                            {
                                testTag: 'drivingPrograms',
                                title: <FormattedMessage id="drivingPrograms" />,
                                content: hasPerformVehicle ? (
                                    <DriveProgramsByVehicle
                                        start={dateRange.start}
                                        end={dateRange.end}
                                        vehicleIds={vehicleIds}
                                        driverIds={driverIdsWithUnknownDriverMarkedAsNull}
                                    />
                                ) : (
                                    upsell
                                ),
                                disabled: !hasPerformVehicle,
                            },
                        ]
                      : []),
                  {
                      testTag: 'assistanceSystems',
                      title: <FormattedMessage id="assistanceSystems" />,
                      content: hasPerformVehicle ? (
                          <AssistanceSystemsByVehicle
                              start={dateRange.start}
                              end={dateRange.end}
                              vehicleIds={vehicleIds}
                              driverIds={driverIdsWithUnknownDriverMarkedAsNull}
                          />
                      ) : (
                          upsell
                      ),
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
        ...(!isKoreanTenant
            ? [
                  {
                      testTag: 'map',
                      title: <FormattedMessage id="map" />,
                      content: hasPerformVehicle ? <OperationalRange {...props} /> : upsell,
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
        ...(!isKoreanTenant
            ? [
                  {
                      testTag: 'traffic',
                      title: <FormattedMessage id="traffic" />,
                      content: hasPerformVehicle ? <TrafficJams {...props} /> : upsell,
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
        ...(!isKoreanTenant
            ? [
                  {
                      testTag: 'environmentConditions',
                      title: <FormattedMessage id="environmentConditions" />,
                      content: hasPerformVehicle ? (
                          <EnvironmentConditions {...props} showTemperatureHistogram={showTemperatureHistogram} />
                      ) : (
                          upsell
                      ),
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
        {
            testTag: 'vehspec',
            title: <FormattedMessage id="vehicleSpecification" />,
            content: hasPerformVehicle ? (
                <VehicleSpecification dateRange={dateRange} vehicleIds={vehicleIds} />
            ) : (
                upsell
            ),
            disabled: !hasPerformVehicle,
        },
        ...(hasMaxOneDriver
            ? [
                  {
                      testTag: 'driving',
                      title: <FormattedMessage id="sidepanel.foresightedDriving" />,
                      content: hasPerformVehicle ? (
                          <ErrorBoundary>
                              <ForesightedDrivingByVehicle
                                  start={dateRange.start}
                                  end={dateRange.end}
                                  vehicleIds={vehicleIds}
                                  driverIds={driverIdsWithUnknownDriverMarkedAsNull}
                                  includeCruiseControlInRating={includeCruiseControlInRating}
                                  calculationParams={calculationParams}
                                  shouldShowOvertakeInCruise={shouldShowOvertakeInCruise}
                                  shouldUseV2Ratings={shouldUseV2Ratings}
                                  shouldShowHarshKPIs={shouldShowHarshKPIs}
                                  shouldShowExcessiveIdlingKPIs={shouldShowExcessiveKPIs}
                                  isTruEEnabled={isTruEEnabled}
                              >
                                  {({ entity }) => (
                                      <div className="display-flex equal-height">
                                          {!isKoreanTenant && (
                                              <div style={{ flex: 1 }} className="margin-right-15">
                                                  <OperationConditionRating {...props} />
                                              </div>
                                          )}
                                          <div style={{ flex: 1 }}>
                                              <Total entity={entity} />
                                          </div>
                                      </div>
                                  )}
                              </ForesightedDrivingByVehicle>
                          </ErrorBoundary>
                      ) : (
                          upsell
                      ),
                      disabled: !hasPerformVehicle,
                  },
              ]
            : []),
    ];

    return (
        <TabbableContent
            tabs={tabs}
            initialTab={isKoreanTenant ? tabs.length - 1 : 0}
            needsBottomSpacing={!includeCruiseControlInRating}
            location="fleetAnalysis"
        />
    );
};

export function mapStateToProps(state: PermissionsStatePart & SettingStatePart & LoginStatePart) {
    return {
        isKoreanTenant: isKoreanTenant(state),
        includeCruiseControlInRating: includeCruiseControlInRating(state),
        calculationParams: savedCalculationParams(state),
        shouldShowHarshKPIs: getFeatureToggles(state).showHarshKPIs as boolean,
        shouldUseV2Ratings: enablePerform3(state),
        shouldShowExcessiveKPIs: getFeatureToggles(state).showExcessiveKPIs as boolean,
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
    };
}

export default connect(mapStateToProps)(SidepanelTabs);
