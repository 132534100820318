import configureGetCreateTimeInterval from '@utils/timeInterval';

import { IntervalTypes } from '../../types';

export const stateOfChargeInterval = configureGetCreateTimeInterval<IntervalTypes>({
    days: 'quarter_hour',
    weeks: 'hour',
    months: 'day',
    quarters: 'day',
    years: 'day',
});
