import { formatIntegerNumber, plainAverage, withUnit } from '@utils/formatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import { PERCENTAGE } from '../constants/units';
import createColumn from './createColumn';

export const percentageSignal = {
    unit: PERCENTAGE,
    displayedUnit: PERCENTAGE,
    format: compose<ReactNode>(withUnit(PERCENTAGE), formatIntegerNumber),
    average: plainAverage,
};

export const averageSlopeDown = createColumn({
    key: 'averageSlopeDown',
    dataField: 'averageSlopeDown',
    labelId: 'averageSlopeDown',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    dataSort: true,
});
export const averageSlopeUp = createColumn({
    key: 'averageSlopeUp',
    dataField: 'averageSlopeUp',
    labelId: 'averageSlopeUp',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const foresightedDrivingPercentage = createColumn({
    key: 'foresightedDrivingPercentage',
    dataField: 'foresightedDrivingPercentage',
    labelId: 'foresightedDrivingPercentage',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const foresightedDrivingPercentageV2 = createColumn({
    key: 'foresightedDrivingPercentageV2',
    dataField: 'foresightedDrivingPercentageV2',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const coastingRating = createColumn({
    key: 'coastingRating',
    dataField: 'coastingRating',
    labelId: 'discipline.coasting',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-coasting',
});

export const accelerationPedalRating = createColumn({
    key: 'accelerationPedalRating',
    dataField: 'accelerationPedalRating',
    labelId: 'discipline.accelerationPedal',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-kickdown',
});

export const accelerationPedalRatingV2 = createColumn({
    key: 'accelerationPedalRatingV2',
    dataField: 'accelerationPedalRatingV2',
    labelId: 'discipline.accelerationPedal',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-kickdown',
});

export const brakingRating = createColumn({
    key: 'brakingRating',
    dataField: 'brakingRating',
    labelId: 'discipline.braking',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-brake',
});

export const cruiseControlRating = createColumn({
    key: 'cruiseControlRating',
    dataField: 'cruiseControlRating',
    labelId: 'discipline.cruiseControl',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-cruise-control',
});

export const cruiseControlRatingV2 = createColumn({
    key: 'cruiseControlRatingV2',
    dataField: 'cruiseControlRatingV2',
    labelId: 'discipline.cruiseControl',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-cruise-control',
});

export const overspeedRating = createColumn({
    key: 'overspeedRating',
    dataField: 'overspeedRating',
    labelId: 'discipline.overspeed',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-speed',
});

export const totalRating = createColumn({
    key: 'totalRating',
    dataField: 'foresightedDrivingPercentage',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const totalRatingWithoutCC = createColumn({
    key: 'totalRatingWithoutCC',
    dataField: 'foresightedDrivingPercentageWithoutCc',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const totalRatingV2 = createColumn({
    key: 'totalRatingV2',
    dataField: 'foresightedDrivingPercentageV2',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const totalRatingWithoutCCV2 = createColumn({
    key: 'totalRatingWithoutCCV2',
    dataField: 'foresightedDrivingPercentageWithoutCcV2',
    labelId: 'details.rating',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
});

export const harshAccelerationRating = createColumn({
    key: 'harshAccelerationRating',
    dataField: 'harshAccelerationRating',
    labelId: 'discipline.harshAcceleration',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-exclamation-sign',
});

export const harshBrakingRating = createColumn({
    key: 'harshBrakingRating',
    dataField: 'harshBrakingRating',
    labelId: 'discipline.harshBraking',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-dangerousgoods',
});

export const excessiveIdlingRating = createColumn({
    key: 'excessiveIdlingRating',
    dataField: 'excessiveIdlingRating',
    labelId: 'discipline.excessiveIdling',
    formatter: percentageSignal.format,
    unit: percentageSignal.unit,
    icon: 'rioglyph rioglyph-hour-glass',
});
