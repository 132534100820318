import TableSettingsDialog from '@rio-cloud/rio-uikit/TableSettingsDialog';
import { generatePushToDataLayer } from '@utils/tracking';
import { difference } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { Column } from '../../columns/createColumn';

interface SettingsDialogI {
    columns?: Column[];
    defaultColumnOrder: string[];
    data?: Record<string, string>[];
    setColumns: (filtered: string[]) => void;
    areSettingsVisible: boolean;
    toggleSettings: (bool: boolean) => void;
    setColumnOrder: (order: string[]) => void;
    columnOrder: string[];
    defaultHiddenColumns?: string[];
    hiddenColumns: string[];
    account: string;
    location: string;
    useCase: string;
}

export default function SettingsDialog({
    columnOrder = [],
    areSettingsVisible,
    setColumns,
    toggleSettings,
    setColumnOrder,
    columns = [],
    defaultColumnOrder = [],
    defaultHiddenColumns = [],
    hiddenColumns,
    account,
    location,
    useCase,
}: SettingsDialogI): JSX.Element {
    const pushToDataLayer = generatePushToDataLayer(account);

    const columnLabels = columns.reduce(
        (acc, column) => ({ ...acc, [column.key]: <FormattedMessage id={column.labelId} /> }),
        {}
    );

    const intl = useIntl();

    return (
        <FormattedMessage id={'searchPlaceholder'}>
            {(placeholder: string) => (
                <TableSettingsDialog
                    show={areSettingsVisible}
                    title={<FormattedMessage id="tableSettings" />}
                    onApply={(order: string[], filtered: string[]) => {
                        pushToDataLayer({
                            eventTrigger: 'click',
                            eventCategory: 'perform, tableSettings',
                            eventAction: `select${useCase}TableDataFields`,
                            eventLabel: `tableFields, ${location}, ${difference(order, filtered).join(' ')}`,
                        });

                        setColumns(filtered);
                        setColumnOrder(order);
                    }}
                    onColumnChange={() => {}} //mandatory on rio-uikit library
                    onHide={() => toggleSettings(false)}
                    defaultColumnOrder={defaultColumnOrder}
                    defaultHiddenColumns={defaultHiddenColumns}
                    columnOrder={columnOrder.length ? columnOrder : defaultColumnOrder}
                    hiddenColumns={hiddenColumns}
                    columnsDetails={{}}
                    columnSearchValue={''}
                    columnLabels={columnLabels}
                    disabledColumns={['type', 'from', 'to', 'vehicle', 'drivers', 'vehicles']}
                    applyButtonText={<FormattedMessage id="apply" />}
                    cancelButtonText={<FormattedMessage id="cancel" />}
                    resetButtonText={<FormattedMessage id="resetChanges" />}
                    searchPlaceholder={placeholder}
                    notFoundMessage={intl.formatMessage({ id: 'noColumnsFoundForSearch' })}
                />
            )}
        </FormattedMessage>
    );
}
