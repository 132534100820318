import { FormattedMessage } from 'react-intl';

const Release510 = () => (
    <div className="display-flex gap-20 align-items-start" style={{ marginLeft: '110px' }}>
        <div style={{ flexBasis: '50%' }}>
            <p className="text-bold text-size-18">
                <FormattedMessage id="help.changelog.changedRatings" />
            </p>
            <ul className="padding-left-20">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedRatings.cruiseControl" />
                    </b>
                </li>
                <ul className="padding-left-20">
                    <FormattedMessage
                        id="help.changelog.5.1.0.changedRatings.cruiseControl.description"
                        values={{
                            // eslint-disable-next-line react/display-name
                            li: (chunks: unknown[]) => <li className="margin-bottom-5 margin-top-5">{chunks}</li>,
                        }}
                    />
                </ul>
            </ul>
            <ul className="padding-left-20">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedRatings.braking" />
                    </b>
                </li>
                <ul className="padding-left-20">
                    <FormattedMessage
                        id="help.changelog.5.1.0.changedRatings.braking.description"
                        values={{
                            // eslint-disable-next-line react/display-name
                            li: (chunks: unknown[]) => <li className="margin-bottom-5 margin-top-5">{chunks}</li>,
                        }}
                    />
                </ul>
            </ul>
            <ul className="padding-left-20">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedRatings.acceleratorPedal" />
                    </b>
                </li>
                <ul className="padding-left-20">
                    <FormattedMessage
                        id="help.changelog.5.1.0.changedRatings.acceleratorPedal.description"
                        values={{
                            // eslint-disable-next-line react/display-name
                            li: (chunks: unknown[]) => <li className="margin-bottom-5 margin-top-5">{chunks}</li>,
                        }}
                    />
                </ul>
            </ul>
        </div>
        <div style={{ flexBasis: '50%' }}>
            <p className="text-bold text-size-18">
                <FormattedMessage id="help.changelog.changedRatingsContinued" />
            </p>
            <ul className="padding-left-20">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedRatings.overspeed" />
                    </b>
                </li>
                <ul className="padding-left-20">
                    <FormattedMessage
                        id="help.changelog.5.1.0.changedRatings.overspeed.description"
                        values={{
                            // eslint-disable-next-line react/display-name
                            li: (chunks: unknown[]) => <li className="margin-bottom-5 margin-top-5">{chunks}</li>,
                        }}
                    />
                </ul>
            </ul>
            <ul className="padding-left-20">
                <li className="text-size-16">
                    <b>
                        <FormattedMessage id="help.changelog.changedRatings.coasting" />
                    </b>
                </li>
                <ul className="padding-left-20">
                    <FormattedMessage
                        id="help.changelog.5.1.0.changedRatings.coasting.description"
                        values={{
                            // eslint-disable-next-line react/display-name
                            li: (chunks: unknown[]) => <li className="margin-bottom-5 margin-top-5">{chunks}</li>,
                        }}
                    />
                </ul>
            </ul>
        </div>
    </div>
);

export default Release510;
