import { Column } from 'src/columns/createColumn';

import {
    AircompressorConsumption,
    AircompressorPerKm,
    BatterylossesConsumption,
    BatterylossesPerKm,
    CoolingConsumption,
    CoolingPerKm,
    cruiseControlConsumptionInPotential,
    distanceFuelConsumption,
    DrivetrainConsumption,
    DrivetrainPerKm,
    drivingConsumption,
    electricAverageDrivingConsumption,
    electricAverageIdlingConsumption,
    electricAverageOperatingConsumption,
    electricAverageOperatingConsumptionRange,
    electricCruiseControlConsumptionInPotential,
    electricEfficiency,
    electricNoCruiseControlConsumptionInPotential,
    electricOperatingConsumption,
    electricRecuperation,
    electricRecuperationKmPerKwh,
    electricRecuperationPerHour,
    electricRecuperationPerKm,
    EnergyEfficiencyPerKm,
    EptoConsumption,
    EptoPerKm,
    fuelConsumption,
    fuelEfficiency,
    HvacConsumption,
    HvacPerKm,
    idlingConsumption,
    noCruiseControlConsumptionInPotential,
    operatingFuelConsumption,
    TotalConsumption,
    VpowersupplyConsumption,
    VpowersupplyPerKm,
} from '../columns/consumption';
import {
    excessiveIdlingCountRatio,
    harshAccelerationHardCount,
    harshAccelerationMediumCount,
    harshBrakingHardCount,
    harshBrakingMediumCount,
} from '../columns/count';
import {
    accDistance,
    accelerationPedalDistanceWithCc,
    activeCoastingDistance,
    coastingCcDistance,
    coastingDistance,
    cruiseControlDistanceInPotential,
    cruiseControlPotentialDistance,
    driveProgramActiveDistance,
    driveProgramEfficiencyDistance,
    driveProgramEfficiencyPlusDistance,
    driveProgramEmergencyDistance,
    driveProgramFluidDistance,
    driveProgramHeavyDutyDistance,
    driveProgramManoeuverDistance,
    driveProgramManualDistance,
    driveProgramOffroadDistance,
    driveProgramOffroadWithAssistanceDistance,
    driveProgramPerformanceDistance,
    driveProgramTotalDistance,
    kickdownDistance,
    mileage,
    motorBrakeDistance,
    noAccDistance,
    noCruiseControlDistanceInPotential,
    noPccaDistance,
    overspeedDistance,
    passiveCoastingDistance,
    pccaDistance,
    retarderDistance,
    serviceBrakeDistance,
} from '../columns/distance';
import {
    drivers,
    end,
    explicitDrivers,
    start,
    system,
    tripStartEnd,
    vehicle,
    vehicleNames,
    vehicles,
} from '../columns/DOM';
import { drivenElevationDown, drivenElevationUp } from '../columns/height';
import {
    accelerationPedalRating,
    accelerationPedalRatingV2,
    averageSlopeDown,
    averageSlopeUp,
    brakingRating,
    coastingRating,
    cruiseControlRating,
    cruiseControlRatingV2,
    excessiveIdlingRating,
    foresightedDrivingPercentage,
    foresightedDrivingPercentageV2,
    harshAccelerationRating,
    harshBrakingRating,
    overspeedRating,
    totalRating,
    totalRatingV2,
    totalRatingWithoutCC,
    totalRatingWithoutCCV2,
} from '../columns/percentage';
import { operationCondition } from '../columns/score';
import { averageSpeed } from '../columns/speed';
import {
    accelerationPedalTimeWithCc,
    acceptableIdlingTime,
    accTime,
    activeCoastingTime,
    coastingCCTime,
    coastingTime,
    cruiseControlTimeInPotential,
    driveProgramActiveTime,
    driveProgramEfficiencyPlusTime,
    driveProgramEfficiencyTime,
    driveProgramEmergencyTime,
    driveProgramFluidTime,
    driveProgramHeavyDutyTime,
    driveProgramManoeuverTime,
    driveProgramManualTime,
    driveProgramOffroadTime,
    driveProgramOffroadWithAssistanceTime,
    driveProgramPerformanceTime,
    driveProgramTotalTime,
    drivingTime,
    excessiveIdlingTime,
    harshAccelerationHardTime,
    harshAccelerationMediumTime,
    harshBrakingHardTime,
    harshBrakingMediumTime,
    idlingTime,
    kickdownTime,
    motorBrakeTime,
    noAccTime,
    noCruiseControlTimeInPotential,
    noPccaTime,
    operatingTime,
    passiveCoastingTime,
    pccaTime,
    retarderTime,
    serviceBrakeTime,
} from '../columns/time';
import { averageWeight, co2Emission } from '../columns/weight';

export const columns = [
    explicitDrivers,
    drivers,
    vehicle,
    vehicles,
    vehicleNames,
    start,
    end,
    system,
    tripStartEnd,

    distanceFuelConsumption,
    co2Emission,
    idlingConsumption,
    fuelConsumption,
    averageWeight,
    averageSpeed,
    drivenElevationUp,
    drivenElevationDown,

    drivingTime,
    idlingTime,
    operatingTime,
    activeCoastingTime,
    passiveCoastingTime,
    coastingCCTime,
    kickdownTime,
    accelerationPedalTimeWithCc,
    serviceBrakeTime,
    motorBrakeTime,
    retarderTime,
    cruiseControlTimeInPotential,
    noCruiseControlTimeInPotential,
    coastingTime,
    driveProgramActiveTime,
    driveProgramEfficiencyTime,
    driveProgramEfficiencyPlusTime,
    driveProgramEmergencyTime,
    driveProgramFluidTime,
    driveProgramHeavyDutyTime,
    driveProgramManoeuverTime,
    driveProgramManualTime,
    driveProgramOffroadTime,
    driveProgramOffroadWithAssistanceTime,
    driveProgramPerformanceTime,
    driveProgramTotalTime,
    accTime,
    noAccTime,
    pccaTime,
    noPccaTime,
    harshAccelerationMediumTime,
    harshAccelerationHardTime,
    harshBrakingMediumTime,
    harshBrakingHardTime,
    excessiveIdlingTime,
    acceptableIdlingTime,

    averageSlopeDown,
    averageSlopeUp,
    foresightedDrivingPercentage,
    foresightedDrivingPercentageV2,
    coastingRating,
    accelerationPedalRating,
    accelerationPedalRatingV2,
    brakingRating,
    cruiseControlRating,
    cruiseControlRatingV2,
    overspeedRating,
    totalRating,
    totalRatingWithoutCC,
    totalRatingV2,
    totalRatingWithoutCCV2,
    harshAccelerationRating,
    harshBrakingRating,
    excessiveIdlingRating,

    mileage,
    coastingCcDistance,
    kickdownDistance,
    accelerationPedalDistanceWithCc,
    serviceBrakeDistance,
    motorBrakeDistance,
    cruiseControlDistanceInPotential,
    noCruiseControlDistanceInPotential,
    cruiseControlPotentialDistance,
    overspeedDistance,
    activeCoastingDistance,
    passiveCoastingDistance,
    retarderDistance,
    coastingDistance,
    driveProgramActiveDistance,
    driveProgramEfficiencyDistance,
    driveProgramEfficiencyPlusDistance,
    driveProgramEmergencyDistance,
    driveProgramFluidDistance,
    driveProgramHeavyDutyDistance,
    driveProgramManoeuverDistance,
    driveProgramManualDistance,
    driveProgramOffroadDistance,
    driveProgramOffroadWithAssistanceDistance,
    driveProgramPerformanceDistance,
    driveProgramTotalDistance,
    accDistance,
    noAccDistance,
    pccaDistance,
    noPccaDistance,

    noCruiseControlConsumptionInPotential,
    cruiseControlConsumptionInPotential,
    electricCruiseControlConsumptionInPotential,
    electricNoCruiseControlConsumptionInPotential,
    operatingFuelConsumption,
    drivingConsumption,
    operationCondition,
    fuelEfficiency,
    electricEfficiency,

    harshAccelerationMediumCount,
    harshAccelerationHardCount,
    harshBrakingMediumCount,
    harshBrakingHardCount,
    excessiveIdlingCountRatio,

    electricAverageIdlingConsumption,
    electricAverageDrivingConsumption,
    electricAverageOperatingConsumption,
    electricOperatingConsumption,
    electricAverageOperatingConsumptionRange,
    electricRecuperation,
    electricRecuperationPerKm,
    electricRecuperationKmPerKwh,
    electricRecuperationPerHour,

    DrivetrainConsumption,
    DrivetrainPerKm,
    EptoConsumption,
    EptoPerKm,
    BatterylossesConsumption,
    BatterylossesPerKm,
    AircompressorConsumption,
    AircompressorPerKm,
    CoolingConsumption,
    CoolingPerKm,
    HvacConsumption,
    HvacPerKm,
    VpowersupplyConsumption,
    VpowersupplyPerKm,
    TotalConsumption,
    EnergyEfficiencyPerKm,
];

export const electricColumnNames = [
    'electricAverageOperatingConsumption',
    'electricOperatingConsumption',
    'electricAverageOperatingConsumptionRange',
    'electricAverageDrivingConsumption',
    'electricAverageIdlingConsumption',
    'electricRecuperation',
];

export const combustionColumnNames = [
    'operatingFuelConsumption',
    'fuelConsumption',
    'distanceFuelConsumption',
    'drivingConsumption',
    'idlingConsumption',
    'fuelEfficiency',
];

export const commonColumnNames = [
    'co2Emission',
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
];

export const getColumn = (requestedColumn: string, existingColumns = columns) =>
    existingColumns.find(e => e.key === requestedColumn);
export const getColumns = (requestedColumns: string[], existingColumns = columns) =>
    requestedColumns.reduce((acc: Column[], requestedColumnEl) => {
        const column = getColumn(requestedColumnEl, existingColumns);
        if (column !== undefined) {
            acc.push(column);
        }
        return acc;
    }, []);
