import merge from 'lodash/fp/merge';

import { localConfig } from './env/env.local';
import { localProdConfig } from './env/env.localprod';
import { productionConfig } from './env/env.production';
import { stagingConfig } from './env/env.staging';
import { testConfig } from './env/env.test';

const determineFeatureToggles = (window: Window) => {
    const param = (regex: RegExp, defaultValue: string | null = null) => {
        let result = defaultValue;
        decodeURI(window.location.href).replace(regex, (_, it: string) => {
            result = it;
            return result;
        });
        return result;
    };

    const detectToggle = (
        transform: StringConstructor | ((value: string) => boolean),
        name: string,
        defaultValue: boolean | null = null
    ) => {
        // Note that IE11 throws a SyntaxError when using 'u' here
        // eslint-disable-next-line require-unicode-regexp
        let value = param(new RegExp(`${name}=([^&]+)`));

        if (value) {
            try {
                window.localStorage.setItem(name, value);
            } catch (_) {
                // Intentionally left blank
            }
        } else {
            try {
                value = window.localStorage.getItem(name);
            } catch (_) {
                // Intentionally left blank
            }
        }

        return value ? transform(value) : defaultValue;
    };

    const BooleanToggle = (value: string) => {
        if (value === 'true' || value === '1') {
            return true;
        }
        return false;
    };

    // ... add your custom feature toggles here
    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        developer: detectToggle(String, 'ft_developer'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
        printOnLambda: detectToggle(BooleanToggle, 'ft_printOnLambda', false),
        persistSettings: detectToggle(BooleanToggle, 'ft_persistSettings', true),
        disableDebounce: detectToggle(BooleanToggle, 'ft_disableDebounce', false),
        showOvertakeInCruise: detectToggle(BooleanToggle, 'ft_showOvertakeInCruise', false),
        showHarshKPIs: detectToggle(BooleanToggle, 'ft_showHarshKPIs', false),
        showExcessiveKPIs: detectToggle(BooleanToggle, 'ft_showExcessiveKPIs', false),
        truE_EEF: detectToggle(BooleanToggle, 'ft_truE_EEF', false),
    };
};

const ALL_SCOPES_WILDCARD = ' ';

type Config = {
    backend: {
        USERADMIN_SERVICE?: string;
        AUTHENTICATION_SERVICE?: string;
        ROUTE_HISTORY?: string;
        PERFORMANCE_PLATFORM_INFO: string;
        PERFORMANCE_MERGER: string;
        QUERY_SERVICE: string;
        CONNECTED_CODRIVER_SERVICE: string;
        EXCEL_EXPORT_SERVICE?: string;
        OPCON: string;
        HERE_TOKEN_HANDLING?: string;
        HERE_PROXY?: string;
        MENU_URL: string;
        TAGS_SERVICE: string;
        ASSETS_SERVICE: string;
        DRIVERS_SERVICE: string;
        PDF_SERVICE?: string;
        SETTINGS_SERVICE: string;
        VEHICLE_SPEC?: string;
        REPORTS_SERVICE?: string;
    };
    homeRoute: string;
    id: string;
    login: {
        authority: string;
        mockAuthorization: boolean;
        mockLocale?: string;
        preventRedirect: boolean;
        redirectUri?: string;
        silentRedirectUri?: string;
    };
    logoutUri: string;
};

const getRuntimeConfig = (
    window: Window,
    env: { featureToggles?: { enforcedEnv: string | boolean | null }; [key: string]: unknown } = {}
) => {
    const defaultConfig = {
        backend: {},
        homeRoute: `${window.location.origin}`,
        id: 'env.stub',
        login: {
            clientId: 'b9ecbea9-16c7-4c88-b2b1-5ea75e4c2f44',
            oauthScope: [ALL_SCOPES_WILDCARD],
            mockLocale: 'en-GB',
            preventRedirect: false,
            redirectUri: `${window.location.origin}/redirect.html`,
            silentRedirectUri: `${window.location.origin}/redirect.html`,
        },
        sentryToken: 'https://cf70a1c1c43b408d992f41d263a1eb07@o117480.ingest.sentry.io/6011737',
    };

    let config: Config = localConfig;

    if (env.isRunningOnProd) {
        config = productionConfig;
    }

    if (env.isRunningOnStaging) {
        config = stagingConfig;
    }

    if (env.featureToggles && env.featureToggles.enforcedEnv === 'localprod') {
        config = localProdConfig;
    }

    if (env.featureToggles && env.featureToggles.enforcedEnv === 'production') {
        config = productionConfig;
    }

    if (env.isTestEnv) {
        config = testConfig;
    }

    return merge(merge({}, defaultConfig), config);
};

type Env = {
    featureToggles: { [key: string]: string | boolean | null };
    hasConsole: boolean;
    isLocalEnv: boolean;
    isProdBuild: boolean;
    isRunningOnProd: boolean;
    isRunningOnStaging: boolean;
    isRunningOnTestStage: boolean;
    isTestEnv: boolean;
    runsInSandbox: boolean;
    runtimeConfig: {
        sentryToken?: string;
        homeRoute?: string;
        login: { [key: string]: string };
        backend: Record<string, string>;
        [key: string]: unknown;
    };
    shouldRestrictLog: boolean;
    shouldUseConsoleLogger: boolean;
    shouldSendMetrics: boolean;
};

export const configureEnv = (window: Window & typeof globalThis, importMetaEnv: ImportMetaEnv): Env => {
    const {
        location: { host },
    } = window;
    const { MODE } = importMetaEnv;

    const isProdEnv = host === 'perform.rio.cloud' || host === 'perform3.rio.cloud';
    const isStagingEnv = host === 'staging.perform.rio.cloud' || host === 'staging.perform3.rio.cloud';
    const isTestEnv = MODE === 'test' || MODE === 'cov';
    const isLocalEnv = !isProdEnv && !isStagingEnv && !isTestEnv;

    const shouldSendMetrics = isProdEnv;

    const isProdBuild = MODE === 'production';
    const isRunningOnProd = host === 'perform.rio.cloud' || host === 'perform3.rio.cloud';
    const isRunningOnStaging = host === 'staging.perform.rio.cloud' || host === 'staging.perform3.rio.cloud';
    const isRunningOnTestStage = host === 'test.perform.rio.cloud';
    const runsInSandbox = isProdBuild && !isRunningOnStaging && !isRunningOnProd;

    // "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about accessing
    // the console when the developer tools are not visible
    // so we will be extra defensive here, just to be safe
    const console = window && typeof window['console'] !== 'undefined' ? window.console : null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const shouldRestrictLog = MODE === 'production';
    const shouldUseConsoleLogger = false; //NODE_ENV !== 'test' && !shouldRestrictLog;

    const featureToggles = determineFeatureToggles(window);

    const env = {
        featureToggles,
        hasConsole,
        isLocalEnv,
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        isRunningOnTestStage,
        isTestEnv,
        runsInSandbox,
        runtimeConfig: { backend: {}, login: {} },
        shouldRestrictLog,
        shouldUseConsoleLogger,
        shouldSendMetrics,
    };

    env.runtimeConfig = getRuntimeConfig(window, env);

    return env;
};

export const env = configureEnv(window, import.meta.env);

if (!env.hasConsole && window) {
    // I'm still looking at you IE <o.O>
    const noop = () => {};

    const fakeConsole = {
        ...window.console,
        debug: noop,
        info: noop,
        isFakeConsole: true,
        log: noop,
        warn: noop,
    };

    window.console = fakeConsole;
}

if (env.featureToggles.tracing) {
    // eslint-disable-next-line no-console
    console.log(env);
}
