import { getSignal } from '@api/index';
import { ReactNode } from 'react';

import { EntityType } from '../features/foresightedDriving/types';
import { HydratedEntity } from '../types';

export type Column = {
    key: string;
    unit?: string;
    dataField: string;
    labelId: string;
    valueExtractor: (entity: HydratedEntity | EntityType | Record<string, unknown>) => unknown;
    formatter: (value: any, data?: any) => ReactNode;
    dataSort: boolean;
    icon?: string;
    width?: string;
};

type ColumnTemplate = Omit<Column, 'dataSort' | 'valueExtractor'> &
    Partial<Pick<Column, 'dataSort' | 'valueExtractor'>>;

type RatioColumnTemplate = Omit<Column, 'dataSort' | 'valueExtractor' | 'dataField'> & {
    leftDataField: string;
    rightDataField: string;
};

export type RatioColumn = Column & {
    rightDataField: string;
};

export default function createColumn({
    key,
    unit,
    dataField,
    labelId,
    valueExtractor,
    formatter,
    dataSort,
    icon,
    width,
}: ColumnTemplate): Column {
    return {
        key,
        unit,
        dataField,
        labelId,
        valueExtractor:
            valueExtractor ||
            ((entity: HydratedEntity | EntityType | Record<string, unknown>) =>
                getSignal(entity, dataField, undefined)),
        formatter,
        dataSort: dataSort === undefined ? true : dataSort,
        icon,
        width,
    };
}

export function createRatioColumn({
    key,
    unit,
    leftDataField,
    rightDataField,
    labelId,
    formatter,
}: RatioColumnTemplate): RatioColumn {
    return {
        key,
        unit,
        dataField: leftDataField,
        rightDataField,
        labelId,
        valueExtractor: entity => [
            getSignal(entity, leftDataField, undefined),
            getSignal(entity, rightDataField, undefined),
        ],
        formatter,
        dataSort: true,
    };
}
