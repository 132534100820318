type TrackingAttributes = {
    'data-track-ga-event-trigger': string;
    'data-track-ga-event-category': string;
    'data-track-ga-event-action': string;
    'data-track-ga-event-label'?: string;
    'data-track-ga-event-value'?: string;
};

export function trackingAttributes(trigger: string, category: string, action: string, label?: string, value?: string) {
    const attributes: TrackingAttributes = {
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': category,
        'data-track-ga-event-action': action,
    };

    if (label) {
        attributes['data-track-ga-event-label'] = label;
    }

    if (value) {
        attributes['data-track-ga-event-value'] = value;
    }

    return attributes;
}

const FLEETS_TO_NOT_TRACK = [
    'test',
    '8ef04c71-8105-4bfb-a97c-a70db9d66975',
    '237c58e3-60b5-4442-a9ec-8641ea39325b',
    '05b49e87-83b9-417c-b090-a6f69d277b50',
];

export type InjectedWindow = Window & typeof globalThis & { dataLayer?: { [key: string]: unknown }[] };

export function generatePushToDataLayer(fleetId?: string, injectedWindow: InjectedWindow = window) {
    if (fleetId === undefined || FLEETS_TO_NOT_TRACK.includes(fleetId)) {
        return () => undefined;
    }

    const gtmDataLayer = injectedWindow.dataLayer || ([] as { [key: string]: unknown }[]);

    // name, event_category, event_label, event_action, event_value
    return (args: { [key: string]: unknown }) => gtmDataLayer.push({ event: 'ga_event', ...args });
}
