import { Vehicle } from '@api/models/types';
import _ from 'lodash';
import React from 'react';

import Label from './Label';

const SystemLabel: React.FC<{ vehicles: (Vehicle | undefined)[] }> = ({ vehicles = [] }) => {
    const systems = vehicles.map(vehicle => _.get(vehicle, 'system')).filter(Boolean);
    const formattedSystems = systems.length ? systems : ['-'];

    return <Label id="vehicle.system">{formattedSystems.join(', ').toUpperCase()}</Label>;
};

export default SystemLabel;
