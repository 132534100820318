import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import driverDetailsReport1Image from '../static/release-4.6.0-driver-details-report-1.png';
import driverDetailsReport2Image from '../static/release-4.6.0-driver-details-report-2.png';
import fleetReportImage from '../static/release-4.6.0-fleet-report.png';
import topBottomReportImage from '../static/release-4.6.0-top-bottom-report.png';

export default function ReportAutomationTypes() {
    return (
        <Fragment>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.reportAutomation.reportTypesTitle" />
            </h3>
            <p className="margin-bottom-25">
                <FormattedMessage id="help.reportAutomation.tabDescription" />
            </p>
            <p className="text-bold margin-bottom-15">
                <FormattedMessage id="help.reportAutomation.topBottomReport" />
            </p>
            <div className="display-flex gap-25 align-items-start">
                <img
                    alt="top-bottom-report"
                    src={topBottomReportImage}
                    className="shadow-hard-to-bottom"
                    style={{ maxWidth: '50%', objectFit: 'contain' }}
                />
                <div>
                    <p className="text-bold margin-0 margin-top-5">
                        <FormattedMessage id={'help.reportAutomation.description'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'help.reportAutomation.topBottomReportFullDescription'}
                            values={{
                                // eslint-disable-next-line react/display-name
                                b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                                // eslint-disable-next-line react/display-name
                                p: (chunks: unknown[]) => <p className="margin-0 margin-top-10">{chunks}</p>,
                            }}
                        />
                    </p>
                </div>
            </div>
            <p className="text-bold margin-bottom-25 margin-top-25">
                <FormattedMessage id="help.reportAutomation.driverDetailReport" />
            </p>
            <div className="display-flex gap-20 margin-top-10 align-items-start">
                <img
                    alt="driver-detail-report-1"
                    src={driverDetailsReport1Image}
                    className="shadow-hard-to-bottom"
                    style={{ maxWidth: '25%', objectFit: 'contain' }}
                />
                <img
                    alt="driver-detail-report-2"
                    src={driverDetailsReport2Image}
                    className="shadow-hard-to-bottom"
                    style={{ maxWidth: '25%', objectFit: 'contain' }}
                />
                <div>
                    <p className="text-bold margin-0 margin-top-5">
                        <FormattedMessage id={'help.reportAutomation.description'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'help.reportAutomation.driverDetailReportFullDescription'}
                            values={{
                                // eslint-disable-next-line react/display-name
                                b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                                // eslint-disable-next-line react/display-name
                                p: (chunks: unknown[]) => <p className="margin-0 margin-top-10">{chunks}</p>,
                            }}
                        />
                    </p>
                    <ul className="padding-left-20 padding-right-20 margin-top-10">
                        <FormattedMessage
                            id={'help.reportAutomation.driverDetailReportOperationalConditions'}
                            values={{
                                // eslint-disable-next-line react/display-name
                                li: (chunks: unknown[]) => <li className="margin-bottom-10">{chunks}</li>,
                            }}
                        />
                    </ul>
                </div>
            </div>
            <p className="text-bold margin-bottom-25 margin-top-25">
                <FormattedMessage id="help.reportAutomation.fleetReport" />
            </p>
            <div className="display-flex gap-25 align-items-start">
                <img
                    alt="fleet-report"
                    src={fleetReportImage}
                    className="shadow-hard-to-bottom"
                    style={{ maxWidth: '50%', objectFit: 'contain' }}
                />
                <div>
                    <p className="text-bold margin-0 margin-top-5">
                        <FormattedMessage id={'help.reportAutomation.description'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'help.reportAutomation.fleetReportFullDescription'}
                            values={{
                                // eslint-disable-next-line react/display-name
                                b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                                // eslint-disable-next-line react/display-name
                                p: (chunks: unknown[]) => <p className="margin-0 margin-top-10">{chunks}</p>,
                            }}
                        />
                    </p>
                </div>
            </div>
        </Fragment>
    );
}
