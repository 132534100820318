import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { getSummaryValue } from '@utils/getSummaryValue';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../../columns/createColumn';
import { HydratedSummary } from '../../../types';

const styles = StyleSheet.create({
    summarySection: {
        marginTop: 0,
        flexDirection: 'row',
        borderColor: '#2a3740',
        borderWidth: 1.2,
    },
    summaryItem: {
        flex: 1,
        padding: 10,
        paddingBottom: 20,
    },
    summaryItemEven: {
        borderColor: '#2a3740',
        borderLeftWidth: 1.2,
        flex: 1,
        padding: 10,
    },
    summaryIcon: {
        height: 10,
        width: 10,
        marginRight: 3,
    },
    summaryIconTitleWrapper: { flexDirection: 'row', alignItems: 'center' },
    summaryTitle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    summaryValue: {
        fontSize: 12,
        fontFamily: 'Bold',
    },
});

export default function Summary({ summary, columns }: { summary: HydratedSummary[]; columns: Column[] }) {
    return (
        <View style={styles.summarySection} wrap={false} data-test="SummarySection">
            {columns.map((column, idx) => {
                const summaryField = summary.find(s => s.attribute === column.dataField);
                return (
                    <View key={idx} style={idx > 0 ? styles.summaryItemEven : styles.summaryItem}>
                        <View style={styles.summaryIconTitleWrapper}>
                            {column.icon ? <Image src={column.icon} style={styles.summaryIcon} /> : null}
                            <Text style={styles.summaryTitle}>
                                <FormattedMessage id={column.labelId} />
                            </Text>
                        </View>
                        <Text style={styles.summaryValue} data-test="value">
                            {summaryField && getSummaryValue(column, summaryField)}
                        </Text>
                    </View>
                );
            })}
        </View>
    );
}
