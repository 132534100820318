import { getSignal, VehicleFuelType } from '@api/index';
import { DataboxTitleContainer, WrappableDataboxContainer } from '@features/pdf/components/Databox';
import { CalculationParams } from '@features/settings/reducer';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { Column as ColumnType } from '../../../columns/createColumn';
import { DateRange, Driver, HydratedEntity, HydratedSummary, Vehicle } from '../../../types';
import Logo from '../assets/man-logo.png';
import truckDieselImage from '../assets/truck_diesel_white.png';
import truckElectricImage from '../assets/truck_electric_white.png';
import CalculationParameters from '../components/CalculationParameters';
import Column from '../components/Column';
import DateRangeSection from '../components/DateRangeSection';
import Footer from '../components/Footer';
import Summary from '../components/Summary';
import TableRow from '../components/TableRow';
import VehicleSummary from '../components/VehicleSummary';
import { getColumnWidths } from './getColumnWidths';
import styles from './styles';

export type ContentProps = {
    summary: HydratedSummary[];
    summaryElectric: HydratedSummary[];
    data: { dieselVehicles: HydratedEntity[]; electricVehicles: HydratedEntity[] };
    title: ReactElement;
    vehicles: Vehicle[];
    drivers: Driver[];
    useCaseKey: string;
    dateRange: DateRange;
    summaryColumns: { dieselVehicles: ColumnType[]; electricVehicles: ColumnType[] };
    columns: { dieselVehicles: ColumnType[]; electricVehicles: ColumnType[] };
    sortKey: string;
    calculationParameters: CalculationParams;
    shouldShowFuelType: boolean;
};

const defaultScales: Record<string, number> = {
    drivers: 1.5,
    vehicle: 1.5,
    tripStartEnd: 1,
};

const truckDieselIcon = <Image src={truckDieselImage} />;
const truckElectricIcon = <Image src={truckElectricImage} />;

const getSummaryContent = (
    summaryColumns: ColumnType[],
    summary: HydratedSummary[],
    type: string,
    vehicleCount: number
) => {
    const hasSummaryColumns = Boolean(summaryColumns.length);
    const titleID = type === VehicleFuelType.DIESEL ? 'overview.diesel' : 'overview.electric';

    const vehicleIcon = type === VehicleFuelType.DIESEL ? truckDieselIcon : truckElectricIcon;
    const component = (
        <View style={styles.marginTop}>
            <WrappableDataboxContainer>
                <DataboxTitleContainer>
                    <View style={styles.marginLeftsdddd}>
                        <FormattedMessage
                            id={titleID}
                            values={{
                                vehicleCount,
                                image: vehicleIcon,
                            }}
                        />
                    </View>
                </DataboxTitleContainer>
                <Summary columns={summaryColumns} summary={summary} />
            </WrappableDataboxContainer>
        </View>
    );
    return hasSummaryColumns && component;
};

const getTableContent = (
    columns: ColumnType[],
    data: HydratedEntity[],
    shouldShowFuelType: boolean,
    sortKey: string
) => {
    const columnWidths = getColumnWidths(defaultScales, columns);

    return (
        <View style={styles.table} data-test="Table">
            {columns.map(column => {
                const isHighlighted = sortKey === column.key;
                return (
                    <TableRow
                        width={columnWidths[column.key]}
                        key={column.labelId}
                        column={column}
                        isHighlighted={isHighlighted}
                    >
                        {data.map((entity, index) => (
                            <Column
                                key={index}
                                entity={entity}
                                column={column}
                                isHighlighted={isHighlighted}
                                columnData={{ shouldShowFuelType }}
                            />
                        ))}
                    </TableRow>
                );
            })}
        </View>
    );
};

const getCalculationParametersContent = (
    summaryColumns: ColumnType[],
    calculationParameters: CalculationParams,
    data: HydratedEntity[],
    isElectric?: boolean
) => {
    const containsRating = summaryColumns.filter(column => column.key.includes('Rating')).length > 0;
    return (
        containsRating && (
            <CalculationParameters
                isOverspeedThresholdChanged={getSignal(data[0], 'overspeedThreshold', []).length > 1}
                calculationParams={calculationParameters}
                isDetail={false}
                isElectric={isElectric}
            />
        )
    );
};

export default function Content({
    dateRange,
    data,
    summary,
    summaryElectric,
    title,
    vehicles,
    drivers,
    summaryColumns,
    columns,
    sortKey,
    calculationParameters,
    shouldShowFuelType,
}: ContentProps) {
    const headerContent = (
        <View style={styles.top}>
            <View style={styles.left}>
                <View style={styles.title}>
                    <Text>{title}</Text>
                </View>
                <DateRangeSection
                    title={<FormattedMessage id="timeframe" />}
                    start={dateRange.start}
                    end={dateRange.end}
                />
                <View>
                    <VehicleSummary vehicles={vehicles} drivers={drivers} />
                </View>
            </View>
            <View style={styles.right}>
                <Image src={Logo} data-test="Logo" />
            </View>
        </View>
    );

    const hasDieselVehicles = data && data.dieselVehicles && data.dieselVehicles.length > 0;
    const hasElectricVehicles = data && data.electricVehicles && data.electricVehicles.length > 0;

    return (
        <Document>
            <Page orientation="landscape" size="A4" style={styles.page}>
                {headerContent}

                {hasDieselVehicles && (
                    <>
                        {getSummaryContent(
                            summaryColumns.dieselVehicles,
                            summary,
                            VehicleFuelType.DIESEL,
                            data.dieselVehicles.length
                        )}
                        {getTableContent(columns.dieselVehicles, data.dieselVehicles, shouldShowFuelType, sortKey)}
                        {getCalculationParametersContent(
                            summaryColumns.dieselVehicles,
                            calculationParameters,
                            data.dieselVehicles,
                            false
                        )}
                    </>
                )}

                {hasElectricVehicles && !hasDieselVehicles && (
                    <>
                        {getSummaryContent(
                            summaryColumns.electricVehicles,
                            summaryElectric,
                            VehicleFuelType.ELECTRIC,
                            data.electricVehicles.length
                        )}
                        {getTableContent(columns.electricVehicles, data.electricVehicles, shouldShowFuelType, sortKey)}
                        {getCalculationParametersContent(
                            summaryColumns.electricVehicles,
                            calculationParameters,
                            data.electricVehicles,
                            hasElectricVehicles
                        )}
                    </>
                )}
                <Footer createdOn={new Date()} />
            </Page>
            {hasElectricVehicles && hasDieselVehicles && (
                <>
                    <Page orientation="landscape" size="A4" style={styles.page}>
                        {getSummaryContent(
                            summaryColumns.electricVehicles,
                            summaryElectric,
                            VehicleFuelType.ELECTRIC,
                            data.electricVehicles.length
                        )}
                        {getTableContent(columns.electricVehicles, data.electricVehicles, shouldShowFuelType, sortKey)}
                        {getCalculationParametersContent(
                            summaryColumns.electricVehicles,
                            calculationParameters,
                            data.electricVehicles,
                            hasElectricVehicles
                        )}
                        <Footer createdOn={new Date()} />
                    </Page>
                </>
            )}
        </Document>
    );
}
