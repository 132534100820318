export const KILOMETERS = 'km';
export const KILOMETERS_PER_HOUR = 'kmph';
export const KILOMETERS_PER_LITERS = 'kmpl';
export const KILOWATTS_HOUR = 'kWh';
export const KILOWATTS_HOUR_PER_KM = 'kWhpkm';
export const KILOWATTS_HOUR_PER_HOUR = 'kWhph';
export const KILOMETERS_PER_KILOWATTS_HOUR = 'kmpkWh';
export const METERS = 'm';
export const LITERS = 'l';
export const LITERS_PER_100KM = 'lp100km';
export const LITERS_PER_HOUR = 'lph';
export const NO_UNIT = 'none';
export const PERCENTAGE = 'pct';
export const SECONDS = 's';
export const FUEL_EFFICIENCY = 'fuelEfficiency';
export const ELECTRIC_EFFICIENCY = 'electricEfficiency';
export const FUEL_ELECTRIC_EFFICIENCY = 'fuelAndElectricEfficiency';
export const TIME = 'time';
export const TONS = 't';
export const KG = 'kg';
export const PS = 'ps';
export const MILLI_METERS = 'mm';
export const EVENTS = 'events';

export enum ValidUnit {
    KILOMETERS = 'km',
    KILOMETERS_PER_HOUR = 'kmph',
    KILOMETERS_PER_LITERS = 'kmpl',
    METERS = 'm',
    LITERS = 'l',
    LITERS_PER_100KM = 'lp100km',
    LITERS_PER_HOUR = 'lph',
    NO_UNIT = 'none',
    PERCENTAGE = 'pct',
    SECONDS = 's',
    FUEL_EFFICIENCY = 'fuelEfficiency',
    ELECTRIC_EFFICIENCY = 'electricEfficiency',
    TIME = 'time',
    TONS = 't',
    KG = 'kg',
    PS = 'ps',
    MILLI_METERS = 'mm',
    EVENTS = 'events',
}
