import { VehicleFuelType } from '@api/models/types';
import timeFormatBasedOnDateRange from '@components/graphComponents/timeFormatBasedOnDateRange';
import { Section, Values } from '@components/summary/types';
import { defaultSelectedFuelTypes } from '@features/tree/reducer';
import NonTruEGraphDriver from '@pages/driverAnalysis/driverDataGraph/NonTruEGraphDriver';
import TruEGraphDriver from '@pages/driverAnalysis/driverDataGraph/TruEGraphDriver';
import { memo } from 'react';
import { injectIntl } from 'react-intl';
import { IntlShape } from 'react-intl/lib';
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { DateRange } from '../../../types';

export type VehicleGraphDimensions = {
    height: number;
    width: number;
    margin: number;
    horizontalMargin?: number;
    legendHeight: number;
    legendLeftMargin: number;
    legendTopMargin: number;
};

export const Graph: React.FC<{
    column: Section;
    dateRange: DateRange;
    electricData?: {
        values: Values;
        recuperationValues?: Values | undefined;
    };
    dieselData: Values | undefined;
    intl: IntlShape;
    languageData: Record<string, string>;
    width?: number;
    isTruEEnabled?: boolean;
    selectedFuelTypes?: VehicleFuelType[];
}> = ({
    column,
    dateRange = { start: new Date(), end: new Date() },
    electricData = undefined,
    dieselData = undefined,
    intl,
    languageData,
    width = 1251,
    isTruEEnabled = false,
    selectedFuelTypes = defaultSelectedFuelTypes,
}) => {
    const dimensions: VehicleGraphDimensions = {
        height: 400,
        width,
        margin: 40,
        legendHeight: 40,
        legendLeftMargin: 20,
        legendTopMargin: 12,
    };

    const tickFormatter = timeFormatBasedOnDateRange(intl.formatDate, intl.formatMessage);

    return (
        <>
            {!isTruEEnabled ? (
                <NonTruEGraphDriver
                    dateRange={dateRange}
                    column={column}
                    data={dieselData as Values}
                    intl={intl}
                    width={width}
                />
            ) : (
                <TruEGraphDriver
                    column={column}
                    dateRange={dateRange}
                    dimensions={dimensions}
                    dieselData={dieselData}
                    electricData={electricData}
                    intl={intl}
                    languageData={languageData}
                    tickFormatter={tickFormatter}
                    selectedFuelTypes={selectedFuelTypes}
                />
            )}
        </>
    );
};

export default memo(
    withResizeDetector(injectIntl(Graph), {
        handleWidth: true,
        handleHeight: false,
        refreshMode: 'throttle',
        refreshRate: 100,
    })
);
