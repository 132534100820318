import { ADMINISTRATION_ROUTE, CODRIVER_ROUTE, DRIVER_ROUTE, VEHICLE_ROUTE } from './routes';
import { TreeTabs } from './treeTabs';

export const subModules = [
    {
        id: 'vehicle',
        translationKey: 'subModuleName.vehicle',
        route: VEHICLE_ROUTE,
        treeTab: TreeTabs.VEHICLES,
        legacyPermission: 'history',
    },
    {
        id: 'driver',
        translationKey: 'subModuleName.driver',
        route: DRIVER_ROUTE,
        treeTab: TreeTabs.DRIVER,
        legacyPermission: 'details',
    },
    {
        id: 'coDriver',
        translationKey: 'subModuleName.coDriver',
        route: CODRIVER_ROUTE,
        treeTab: null,
        legacyPermission: 'coDriver',
    },
    {
        id: 'administration',
        translationKey: 'subModuleName.administration',
        route: ADMINISTRATION_ROUTE,
        treeTab: null,
        legacyPermission: 'administration',
    },
];
