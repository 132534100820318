import { createSlice } from '@reduxjs/toolkit';

import { Map } from '../../types';

export type DownloadState = { pendingDownloads: Map<boolean> };

const defaultState = {
    pendingDownloads: {},
};

const downloadSlice = createSlice({
    name: 'download',
    initialState: defaultState,
    reducers: {
        requestExcelDownload(state, { payload: { owner } }) {
            return { ...state, pendingDownloads: { ...state.pendingDownloads, [owner]: true } };
        },
        requestExcelDownloadFailed(state, { payload: { owner } }) {
            return { ...state, pendingDownloads: { ...state.pendingDownloads, [owner]: false } };
        },
        requestExcelDownloadSuccess(state, { payload: { owner } }) {
            return { ...state, pendingDownloads: { ...state.pendingDownloads, [owner]: false } };
        },
    },
});

export const { requestExcelDownload, requestExcelDownloadFailed, requestExcelDownloadSuccess } = downloadSlice.actions;

export default downloadSlice.reducer;
