import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { FormattedMessage } from 'react-intl';

import { LoadingCheckbox } from '../forms/components/Checkbox';
import FormGroup from '../forms/components/FormGroup';
import { LoadingInput } from '../forms/components/Input';
import { LoadingTree } from '../forms/components/TreeSelect';

const LoadingState = ({ onClose }: { onClose: () => void }) => {
    return (
        <Sidebar
            title={<FormattedMessage id={'reports.editReport'} />}
            titleClassName={'padding-left-10'}
            maxWidth={600}
            minWidth={400}
            width={450}
            bodyClassName={'padding-20'}
            onClose={onClose}
            footer={
                <div className="display-flex align-items-center" style={{ placeContent: 'space-between' }}>
                    <span />
                    <button disabled={true} className={`btn btn-primary`}>
                        <FormattedMessage id="reports.saveChanges" />
                    </button>
                </div>
            }
            position="right"
            headerButtons={[]}
        >
            <fieldset>
                <FormGroup label={<FormattedMessage id="reports.name" />} htmlFor="name">
                    <LoadingInput />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.type" />} htmlFor="report-type">
                    <LoadingInput />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.interval" />} htmlFor="interval">
                    <LoadingInput />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.language" />} htmlFor="languageCode">
                    <LoadingInput />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.informViaEmail" />} htmlFor="informViaEmail">
                    <LoadingCheckbox />
                </FormGroup>
                <LoadingTree />
            </fieldset>
        </Sidebar>
    );
};

export default LoadingState;
