import { EmailNotificationStatus, GenerationStatus, Report } from '@api/models/types';
import Label from '@components/Label';
import OverlayTriggerWithInjectedIntl from '@components/OverlayTriggerWithInjectedIntl';
import { STYLES_MAP, VARIANTS_MAP } from '@rio-cloud/rio-uikit/Button';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { get } from 'lodash';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import DeleteConfiguration from './DeleteConfiguration';
import DownloadButton from './DownloadButton';
import EditConfiguration from './EditConfiguration';
import { ReportRow } from './ReportTable';
import TriggerConfiguration from './TriggerConfiguration';
import { ReportConfiguration, ReportStatus } from './types';

function getMostRecent(reports: Report[]): Report | undefined {
    if (reports.length === 0) return undefined;
    if (reports.length === 1) return reports[0];
    return [...reports].sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())[0];
}

const getStatusIconAndMessage = (status: GenerationStatus | undefined): { icon: string; message: string } => {
    if (status === undefined) return { icon: '', message: '' };
    switch (status) {
        case GenerationStatus.PENDING:
            return { icon: 'rioglyph-refresh spinning', message: 'reports.generationStatus.pending' };
        case GenerationStatus.GENERATED:
            return { icon: 'rioglyph-ok-sign text-color-success', message: 'reports.generationStatus.generated' };
        case GenerationStatus.FAILED:
            return {
                icon: 'rioglyph-error-sign text-color-danger',
                message: 'reports.generationStatus.error',
            };
        default:
            return { icon: '', message: '' };
    }
};

const getNotificationStatusIconAndMessage = (
    emailNotificationStatus: string | null
): { icon: string; message: string } => {
    switch (emailNotificationStatus) {
        case EmailNotificationStatus.EMAIL_NOTIFICATION_SERVICE_ERROR:
            return {
                icon: 'rioglyph-exclamation-sign text-color-warning',
                message: 'reports.emailNotificationStatus.failed',
            };
        case EmailNotificationStatus.BOUNCE_OR_COMPLAINT:
            return {
                icon: 'rioglyph-exclamation-sign text-color-warning',
                message: 'reports.emailNotificationStatus.disabled',
            };
        default:
            return { icon: '', message: '' };
    }
};

const ActionsButtonDropdown = ({ config }: { config: ReportConfiguration }) => {
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

    return (
        <span onClick={event => event.stopPropagation()}>
            <ButtonDropdown
                title={<span className="rioglyph rioglyph-option-vertical" />}
                bsStyle={STYLES_MAP.DEFAULT}
                variant={VARIANTS_MAP.VARIANT_LINK}
                iconOnly
                items={[
                    {
                        value: <TriggerConfiguration configId={config.id} />,
                    },
                    {
                        value: <EditConfiguration configId={config.id} />,
                    },
                    {
                        value: (
                            <div role="button" data-test="delete">
                                <span className="rioglyph rioglyph-trash margin-right-10" />
                                <FormattedMessage id="delete" />
                            </div>
                        ),
                        onSelect: () => setShowDeleteConfirmationDialog(true),
                    },
                ]}
            />
            <DeleteConfiguration
                configId={config.id}
                showDialog={showDeleteConfirmationDialog}
                onClose={() => setShowDeleteConfirmationDialog(false)}
            />
        </span>
    );
};

const configureConfigurationToRow = (activeReportId?: string) => (config: ReportConfiguration): ReportRow => ({
    id: config.id,
    active: config.id === activeReportId,
    name: <span>{config.name}</span>,
    download: (
        <DownloadButton
            name={config.name}
            generatedReport={getMostRecent(
                config.reports.filter(report => report.reportStatus === GenerationStatus.GENERATED)
            )}
        />
    ),
    reportType: <FormattedMessage id={`reports.reportTypes.${config.reportType}`} />,
    selection: ((config: ReportConfiguration) => {
        switch (config.type) {
            case 'assets':
                return (
                    <Label id="assets" icon="rioglyph-truck">
                        {config.assets.length}
                    </Label>
                );
            case 'assetsAndGroups':
                return (
                    <div className="display-flex align-items-center gap-10">
                        <Label id="group" icon="rioglyph-group">
                            {config.groups.length}
                        </Label>
                        <Label id="assets" icon="rioglyph-truck">
                            {config.assets.length}
                        </Label>
                    </div>
                );
            case 'groups':
                return (
                    <Label id="group" icon="rioglyph-group">
                        {config.groups.length}
                    </Label>
                );
            case 'drivers':
                return (
                    <Label id="drivers" icon="rioglyph-driver">
                        {config.drivers.length}
                    </Label>
                );
            case 'driversAndGroups':
                return (
                    <div className="display-flex align-items-center gap-10">
                        <Label id="group" icon="rioglyph-group">
                            {config.groups.length}
                        </Label>
                        <Label id="drivers" icon="rioglyph-driver">
                            {config.drivers.length}
                        </Label>
                    </div>
                );
            case 'fleet':
                return <FormattedMessage id="reports.entireFleet" />;
        }
        return <></>;
    })(config),
    interval: (
        <span>
            {config.status === ReportStatus.disabled ? (
                <span className="label label-default label-condensed margin-right-5">
                    <FormattedMessage id="reports.inactive" />
                </span>
            ) : null}
            <FormattedMessage id={`reports.intervalTypes.${config.timeframe}`} />
        </span>
    ),
    actions: <ActionsButtonDropdown config={config} />,
    generationStatus: ((config: ReportConfiguration) => {
        const reports = config.reports.filter(report => report.reportStatus !== GenerationStatus.SCHEDULED);
        const { icon, message } = getStatusIconAndMessage(get(reports[0], 'reportStatus', undefined));
        return (
            <>
                {icon && message && (
                    <OverlayTriggerWithInjectedIntl
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip" className="top" textAlignment="left" width="auto">
                                <FormattedMessage id={message} />
                            </Tooltip>
                        }
                    >
                        <span className={'text-size-20 rioglyph ' + icon} />
                    </OverlayTriggerWithInjectedIntl>
                )}
            </>
        );
    })(config),
    notificationStatus: ((config: ReportConfiguration) => {
        const reports = config.reports.filter(report => report.reportStatus !== GenerationStatus.SCHEDULED);
        const { icon, message } = getNotificationStatusIconAndMessage(get(reports[0], 'emailNotificationStatus', ''));
        return (
            <>
                {icon && message && (
                    <OverlayTriggerWithInjectedIntl
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip" className="top" textAlignment="left" width="auto">
                                <FormattedMessage id={message} />
                            </Tooltip>
                        }
                    >
                        <span className={'text-size-20 rioglyph ' + icon} />
                    </OverlayTriggerWithInjectedIntl>
                )}
            </>
        );
    })(config),
});

export default configureConfigurationToRow;
