import europeanFlag from '../static/european-flag.png';
import performAPI from '../static/release-5.3.0-perform-api.png';
import temperatureHistogram from '../static/release-5.3.0-temperature-histogram.png';
import newLogo from '../static/release-new.png';

const releaseNotes = {
    '5.3.0': {
        date: '2023-05-12T00:00:00',
        featureName: 'Perform API, BugFixes',
        content: [
            <div key="releaseNotes.5.3.0">
                <div className="display-flex align-items-center">
                    <img alt="new-logo" src={newLogo} style={{ width: '80px', objectFit: 'contain' }} />
                    <span className="text-bold margin-left-10 text-size-18">Neue Funktionen</span>
                </div>
                <div className="display-flex margin-top-15">
                    <div style={{ flexBasis: '65%' }}>
                        <p className="text-bold margin-0">Perform API</p>
                        <p className="margin-0 margin-top-5">
                            Perform bietet jetzt eine flexible Programmierschnittstelle (API) zur automatischen Abfrage
                            von Perform-Daten an.
                            <br />
                            Neben den operativen Basisdaten wie Fahrer- und Fahrzeuginformationen, Routen, Zeiten,
                            Verbrauch etc. sind auch Perform-spezifische Daten wie Fahrerbewertungen und Schweregrad
                            (Verkehr, Höhenprofil, Straßentypen) Teil des Pakets.
                            <br />
                            <br />
                            Die Nutzung der API ist für Fahrzeuge mit Perform-Buchung möglich. Details sind auf der{' '}
                            <a
                                href="https://rio.cloud/de/marketplace/produkt/perform#c126606"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Produktseite
                            </a>{' '}
                            zu finden.
                        </p>
                        <div className="display-flex margin-top-15 margin-bottom-10 gap-10 align-items-center">
                            <img
                                alt="european-flag"
                                src={europeanFlag}
                                style={{ maxWidth: '10%', objectFit: 'contain' }}
                            />
                            <p className="margin-0 text-medium">Das Feature ist nur für Kunden in Europa verfügbar.</p>
                        </div>
                    </div>
                    <div style={{ flexBasis: '35%' }} className="display-flex justify-content-end margin-left-10">
                        <img alt="perform-api" src={performAPI} style={{ width: '95%', objectFit: 'contain' }} />
                    </div>
                </div>
                <div className="display-flex margin-top-15 gap-15">
                    <div style={{ flexBasis: '50%' }}>
                        <p className="text-bold margin-0">Umgebungstemperatur</p>
                        <p className="margin-0 margin-top-5">
                            Die Umgebungstemperatur kann Auswirkungen auf die Effizienz und Sicherheit haben Perform
                            bietet eine Visualisierung der Temperaturverteilung in einem bestimmten Zeitfenster. Das
                            Histogramm wird in der Seitenleiste unter den Straßentypen auf der Registerkarte
                            &quot;Umgebungsbedingungen&quot; (früher &quot;Höhenprofil&quot;) angezeigt.
                        </p>
                    </div>
                    <div style={{ flexBasis: '50%' }} className="margin-left-10 margin-top-25 margin-right-10">
                        <img
                            alt="temperature-distribution"
                            src={temperatureHistogram}
                            className=" shadow-hard-to-bottom"
                            style={{ maxWidth: '95%', objectFit: 'contain' }}
                        />
                    </div>
                </div>
            </div>,
        ],
    },
};

export default releaseNotes;
