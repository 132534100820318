import { MenuItemProps as MenuItem } from '@rio-cloud/rio-uikit/MenuItem';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import { connect } from 'react-redux';

import { DownloadStatePart } from '../../setup/types';
import { isDownloading } from './selectors';

export const DownloadMenu = ({
    options = [],
    isDownloading,
    disabled = false,
}: {
    options: MenuItem[];
    isDownloading: boolean;
    disabled?: boolean;
}): JSX.Element => {
    if (isDownloading) {
        return <button className="btn btn-default btn-icon-only btn-loading" />;
    }
    return (
        <SimpleButtonDropdown
            disabled={disabled}
            items={options}
            title={<span data-test="toolbar-download" className="rioglyph rioglyph-download" aria-hidden="true" />}
            iconOnly
            pullRight
            dropdownClassName="width-300"
        />
    );
};

export default connect((state: DownloadStatePart, { owner }: { owner: string }) => ({
    isDownloading: isDownloading(state, owner),
}))(DownloadMenu);
