import { View } from '@react-pdf/renderer';

const ProgressBar = ({ color, progress }: { color: string; progress: number }) => (
    <View
        style={{
            height: '100%',
            width: '100%',
            backgroundColor: '#D0D8DE',
        }}
    >
        <View
            data-test="progress"
            style={{
                height: '100%',
                width: `${progress}%`,
                backgroundColor: color,
            }}
        />
    </View>
);

export default ProgressBar;
