import { Id, ReportConfiguration } from '@api/index';
// eslint-disable-next-line prettier/prettier
export type { ReportConfiguration, Id } from '@api/index';
export { ReportStatus, ReportType, ReportTimeframe } from '@api/index';

export enum Status {
  Ready,
  Loading,
  Failed,
  Loaded,
}

export type ReportForm = Omit<ReportConfiguration, 'status' | 'reports' > & {
  entities: { items: Id[]; groups: Id[] };
  status?: boolean;
};
