import { mapValues, sum } from 'lodash';

import { Column as ColumnType } from '../../../columns/createColumn';
import { configureReporting } from '../../../setup/errorReporting';

export const getColumnWidths = (scales: Record<string, number>, columns: ColumnType[]) => {
    const spacePerColumn = 100 / columns.length;

    const adjustedWidths = Object.entries(scales).reduce<Record<string, number>>((acc, [key, value]): Record<
        string,
        number
    > => {
        if (columns.find(c => c.key === key)) {
            // column is being used, include in adjusted width map
            return {
                ...acc,
                [key]: value * spacePerColumn,
            };
        } else {
            return acc;
        }
    }, {});
    const usedSpace = sum(Object.values(adjustedWidths));
    const columnsWithPredefinedSpace = Object.keys(adjustedWidths).length;
    const remainder = (100 - usedSpace) / (columns.length - columnsWithPredefinedSpace);

    const widthMap = columns.reduce<Record<string, number>>(
        (acc, column) => ({
            ...acc,
            [column.key]: adjustedWidths[column.key] || remainder,
        }),
        {}
    );

    // Just in case we're checking if the total sum is less than 100%.
    // due to floating number addition we might get a tiny bit more than 100%
    const totalWidth = sum(Object.values(widthMap));
    if (Math.abs(totalWidth - totalWidth) - 0.001 > 100) {
        // eslint-disable-next-line no-restricted-globals
        const { captureException } = configureReporting(self, import.meta.env);
        captureException(new Error(`The sum of all widths exceeded 100% ${JSON.stringify(widthMap, null, ' ')}`));
    }
    return mapValues(widthMap, v => `${v}%`);
};
