import europeanFlag from '../static/european-flag.png';
import performAPI from '../static/release-5.3.0-perform-api.png';
import temperatureHistogram from '../static/release-5.3.0-temperature-histogram.png';
import newLogo from '../static/release-new.png';

const releaseNotes = {
    '5.3.0': {
        date: '2023-05-12T00:00:00',
        featureName: 'Perform API, Bug Fixes',
        content: [
            <div key="releaseNotes.5.3.0">
                <div className="display-flex align-items-center">
                    <img alt="new-logo" src={newLogo} style={{ width: '80px', objectFit: 'contain' }} />
                    <span className="text-bold margin-left-10 text-size-18">New Features</span>
                </div>
                <div className="display-flex margin-top-15">
                    <div style={{ flexBasis: '65%' }}>
                        <p className="text-bold margin-0">Perform API</p>
                        <p className="margin-0 margin-top-5">
                            Perform now offers a flexible programming interface (API) for automatically querying Perform
                            data.
                            <br />
                            The data can be integrated into a system of your choice. In addition to basic operational
                            data, such as driver and vehicle information, routes, times, consumption, etc.
                            Perform-specific data such driver ratings and severity (traffic, elevation profile, road
                            types) are also part of the package.
                            <br />
                            <br />
                            The use of the API is possible for vehicles with Perform booking. Details are provided on
                            the{' '}
                            <a
                                href="https://rio.cloud/en/marketplace/produkt/perform#c126627"
                                target="_blank"
                                rel="noreferrer"
                            >
                                product page
                            </a>
                            .
                        </p>
                        <div className="display-flex margin-top-15 margin-bottom-10 gap-10 align-items-center">
                            <img
                                alt="european-flag"
                                src={europeanFlag}
                                style={{ maxWidth: '10%', objectFit: 'contain' }}
                            />
                            <p className="margin-0 text-medium">
                                The feature is only available to customers in Europe.
                            </p>
                        </div>
                    </div>
                    <div style={{ flexBasis: '35%' }} className="display-flex justify-content-end">
                        <img alt="perform-api" src={performAPI} style={{ width: '95%', objectFit: 'contain' }} />
                    </div>
                </div>
                <div className="display-flex margin-top-15 gap-15">
                    <div style={{ flexBasis: '50%' }}>
                        <p className="text-bold margin-0">Ambient Temperature</p>
                        <p className="margin-0 margin-top-5">
                            The ambient temperature can have impact on efficiency and safety. Perform provides a
                            visualization of the temperature distribution in a given timeframe. <br />
                            The histogram is shown in the sidebar below the Street types, under the tab
                            &quot;Environment Conditions&quot; (formerly &quot;Height Profile&quot;).
                        </p>
                    </div>
                    <div style={{ flexBasis: '50%' }} className="margin-left-10 margin-top-15 margin-right-10">
                        <img
                            alt="temperature-distribution"
                            src={temperatureHistogram}
                            className=" shadow-hard-to-bottom"
                            style={{ maxWidth: '95%', objectFit: 'contain' }}
                        />
                    </div>
                </div>
            </div>,
        ],
    },
};

export default releaseNotes;
