import * as Sentry from '@sentry/browser';
import { Primitive } from '@sentry/types';
import _ from 'lodash';
import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import matches from 'lodash/fp/matches';
import noop from 'lodash/fp/noop';
import otherwise from 'lodash/fp/stubTrue';

import { configureEnv } from '../env';

export type SentryErrorInfo = {
    tags?: Record<string, Primitive>;
} & Record<string, unknown>;

export function configureReportingGlobal(tags: Record<string, Primitive>, injectedSentry = Sentry) {
    injectedSentry.configureScope(function(scope) {
        if (!_.isEmpty(tags)) {
            scope.setTags(tags);
        }
    });
}

export function configureReporting(injectedWindow = window, importMetaEnv: ImportMetaEnv, injectedSentry = Sentry) {
    const { isProdBuild, isRunningOnProd, isRunningOnStaging, runsInSandbox, isTestEnv } = configureEnv(
        injectedWindow,
        importMetaEnv || import.meta.env
    );

    // "Real" old IE - as in: not Edge in IE11 mode - is quite iffy about
    // accessing the console when the developer tools are not visible
    // so we will be extra defensive here, just to be safe
    const console = typeof injectedWindow['console'] !== 'undefined' ? injectedWindow.console : null;

    // IE... I'm looking at you <o.O>
    const hasConsole = !!console;

    const justLogException =
        hasConsole && !isTestEnv
            ? (e: Error, errorInfo = '(no additional info)') => {
                  console?.error('Exception captured:', e, errorInfo);
              }
            : noop;

    const reportWithSentry =
        injectedSentry && injectedSentry.withScope
            ? (e: Error, errorInfo: SentryErrorInfo = {}) =>
                  injectedSentry.withScope(scope => {
                      const { tags, ...extras } = errorInfo;
                      if (!_.isEmpty(tags)) {
                          scope.setTags(tags);
                      }
                      if (!_.isEmpty(extras)) {
                          scope.setExtras(extras);
                      }
                      injectedSentry.captureException(e as Error);
                  })
            : noop;

    const logToConsoleAndReport = (e: Error, errorInfo?: string | Record<string, unknown>) => {
        justLogException(e, errorInfo as string);
        reportWithSentry(e, errorInfo as SentryErrorInfo);
    };

    const state = {
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        runsInSandbox,
    };

    const captureException = cond([
        [matches({ isRunningOnProd: true }), constant(reportWithSentry)],
        [matches({ isRunningOnStaging: true }), constant(justLogException)],
        [matches({ runsInSandbox: true }), constant(logToConsoleAndReport)],
        [otherwise, constant(justLogException)],
    ])(state);

    const captureSagaCancelledMiddleware = (/* store */) => (next: (val: Record<string, unknown>) => void) =>
        cond([
            [
                matches({ type: 'report/SAGA_CANCELLED' }),
                ({ payload }: { payload: Error }) => captureException(payload),
            ],
            [otherwise, next],
        ]);

    return {
        captureException,
        captureSagaCancelledMiddleware,
    };
}
