import { FormattedMessage } from 'react-intl';

import { errorNotification, successNotification } from '../../middlewares/notificationMiddleware';
import { useAppDispatch } from '../../setup/store';
import { loadReportConfigurations, triggerReport } from './reducer';

const TriggerConfiguration = ({ configId }: { configId: string }) => {
    const dispatch = useAppDispatch();

    const handleTrigger = () => {
        dispatch(triggerReport(configId)).then(res => {
            const status = res.payload;
            if (status !== 201) dispatch(errorNotification('error.default'));
            else dispatch(successNotification('reports.triggerSuccess'));
            dispatch(loadReportConfigurations());
        });
    };

    return (
        <div onClick={handleTrigger} role="button" data-test="trigger">
            <span className="rioglyph rioglyph-angle-double-right margin-right-10" />
            <FormattedMessage id="trigger" />
        </div>
    );
};

export default TriggerConfiguration;
