import { Driver, Vehicle, VehicleGroup } from '@api/index';
import { TreeItem } from '@rio-cloud/rio-uikit';
import Tree from '@rio-cloud/rio-uikit/Tree';
import cn from 'classnames';
import ContentLoader from 'react-content-loader';
import { Controller, ControllerProps } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { unassignedGroup as ungroupedGroup } from '../../../../api';

enum InputStatus {
    error,
    touched,
    success,
}

type Props<Form> = {
    status: InputStatus;
    items: Vehicle[] | Driver[];
    groups: VehicleGroup[];
    searchPlaceholder: string;
    disabled?: boolean;
    numberOfSelectedItems: number;
    maxNumberOfSelectedItems: number;
} & Omit<ControllerProps<Form>, 'render'>;

export default function TreeSelect<Form>(props: Props<Form>) {
    const {
        status,
        groups,
        items,
        searchPlaceholder,
        name,
        disabled,
        numberOfSelectedItems,
        maxNumberOfSelectedItems,
        ...rest
    } = props;

    const mappedGroups = groups.map((group: VehicleGroup) =>
        group.id === ungroupedGroup.id
            ? {
                  ...group,
                  name: <FormattedMessage id="intl-msg:asset-tree.groups.ungrouped" />,
              }
            : group
    );

    return (
        <Controller
            name={name}
            {...rest}
            render={({ field }) => (
                <div data-test={`create-form-${name}`}>
                    <div
                        className={cn('panel panel-default margin-0', {
                            'border-color-danger': status === InputStatus.error,
                        })}
                    >
                        <div className="panel-body padding-0 padding-top-5 overflow-hidden">
                            <Tree
                                showEmptyGroups={false}
                                groups={mappedGroups}
                                items={items as TreeItem[]}
                                expandedGroups={[]}
                                selectedGroups={(field.value as { groups: string[]; items: string[] })?.groups || []}
                                selectedItems={(field.value as { groups: string[]; items: string[] })?.items || []}
                                onSelectionChange={disabled ? () => undefined : field.onChange}
                                scrollHeight={300}
                                searchPlaceholder={searchPlaceholder}
                                hasMultiselect={!disabled}
                                hideSearch={disabled}
                            />
                        </div>
                    </div>
                    {!disabled && (
                        <span
                            className={cn('text-size-12 text-right', { 'has-error': status === InputStatus.error })}
                            data-test={`create-form-${name}-counter`}
                        >
                            <span className={cn('help-block', { 'text-color-danger': status === InputStatus.error })}>
                                <span>
                                    {numberOfSelectedItems} / {maxNumberOfSelectedItems} max
                                </span>
                            </span>
                        </span>
                    )}
                </div>
            )}
        />
    );
}

export function LoadingTree() {
    return (
        <ContentLoader
            className="display-block"
            speed={2}
            viewBox="0 0 300 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="5" ry="5" width="300" height="300" />
        </ContentLoader>
    );
}
