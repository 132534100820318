import { toggleSettings } from '@pages/driverAnalysis/driverAnalysisReducer';
import { areSettingsVisible } from '@pages/driverAnalysis/driverAnalysisSelectors';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../setup/store';

export default function useTableSettings() {
    const dispatch = useAppDispatch();
    return {
        areSettingsVisible: useSelector(areSettingsVisible),
        toggleSettings: (visible: boolean) => dispatch(toggleSettings(visible)),
    };
}
