import { getSignal, VehicleFuelType } from '@api/index';
import { getFeatureToggles, getPerformVehicles } from '@common/permissions/selectors';
import Box from '@components/Box';
import OperatingPeriod from '@components/OperatingPeriod';
import OperationalDays from '@features/operationalDays';
import PDFDownloader from '@features/pdf/detail/DriverPDFDownloader';
import { hideSidebar, showSidebar, SidebarData, sidebarData } from '@features/ui/reducer';
import { getActiveDateRange } from '@features/ui/selectors';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { combustionColumnNames, commonColumnNames, electricColumnNames, getColumns } from '@utils/columns';
import { convertToRows } from '@utils/convertToRows';
import { extractDriverName } from '@utils/stringFormatters';
import { trackingAttributes } from '@utils/tracking';
import _ from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Column as ColumnType } from '../../columns/createColumn';
import { DRIVER_ROUTE } from '../../constants/routes';
import { State } from '../../setup/types';
import { DateRange, Driver, HydratedEntity, HydratedEntityWithChildren } from '../../types';
import { getTopColumns } from './columns';
import { openRow } from './driverAnalysisReducer';
import Column from './DriverColumn';
import Tabs from './DriverSidebarTabs';
import { Data } from './types';

const convertToRow = convertToRows(3);

export const FormattedOperationDays = ({ entity }: { entity: HydratedEntity }) => (
    <div className=" margin-bottom-5">
        <label className="margin-bottom-0 text-size-12">
            <FormattedMessage id="operationDays" />
        </label>
        <div className="text-size-18 ellipsis-1">
            <OperationalDays
                operatingDays={getSignal(entity, 'operatingDays', undefined)}
                totalNumberOfDays={getSignal(entity, 'totalNumberOfDays', undefined)}
            />
        </div>
    </div>
);

export type DriverSidebarData = SidebarData &
    Omit<HydratedEntityWithChildren, 'children' | 'drivers' | 'vehicles'> &
    Partial<Pick<HydratedEntityWithChildren, 'children' | 'drivers' | 'vehicles'>>;

export const DriverSidebar: React.FC<{
    data?: DriverSidebarData;
    onClose: () => void;
    dateRange: DateRange;
    onVehicleClick: (vehicle: Data) => void;
    openParent: (id?: string) => void;
    allowedVehicles: string[];
    shouldShowOvertakeInCruise?: boolean;
    isTruEEnabled: boolean;
}> = ({
    data,
    onClose,
    dateRange,
    onVehicleClick,
    openParent,
    allowedVehicles,
    shouldShowOvertakeInCruise = false,
    isTruEEnabled = false,
}) => {
    const onVehiclesNameClick = useCallback(
        vehicle => {
            onVehicleClick(vehicle);
            openParent(data?.id);
        },
        [onVehicleClick, openParent, data]
    );

    if (!data) {
        return null;
    }

    const isVehiclesNameClickable = data.children && data.children.length > 1 ? true : false;
    const topColumns = getTopColumns(isVehiclesNameClickable, onVehiclesNameClick);

    const topRows = convertToRow(topColumns.map(column => <Column key={column?.key} column={column} entity={data} />));

    const startDate = dateRange.start;
    const endDate =
        dateRange.end.getHours() === 0 && dateRange.end.getMinutes() === 0
            ? moment(dateRange.end)
                  .subtract(1, 'minutes')
                  .toDate()
            : dateRange.end;

    const commonColumns = getColumns(commonColumnNames);

    let combustionColumns = getColumns(combustionColumnNames).concat(commonColumns);
    let electricColumns = getColumns(electricColumnNames).concat(commonColumns);
    const containsElectricVehicle = data.vehicles?.some(vehicle => vehicle.fuelType === VehicleFuelType.ELECTRIC);
    const containsCombustionVehicle = data.vehicles?.some(
        vehicle => vehicle.fuelType === VehicleFuelType.UNKNOWN || vehicle.fuelType === VehicleFuelType.DIESEL
    );

    let displayedCombustionVehicleColumns = combustionColumns
        .map((column: ColumnType) => <Column key={column.key} column={column} entity={data} />)
        .concat([<FormattedOperationDays key="operationDays" entity={data as HydratedEntityWithChildren} />]);
    let displayedElectricVehicleColumns = electricColumns
        .map(column => <Column key={column.key} column={column} entity={data} />)
        .concat([<FormattedOperationDays key="operationDays" entity={data as HydratedEntityWithChildren} />]);

    const driver = extractDriverName(_.get(data, 'drivers[0]', {}) as Driver);
    const vehicleCombustionRows = convertToRow(displayedCombustionVehicleColumns);
    const vehicleElectricRows = convertToRow(displayedElectricVehicleColumns);

    return (
        <Sidebar
            fly
            onClose={onClose}
            position={'right'}
            width={1000}
            title={
                <div
                    className="padding-left-10 justify-content-between flex align-items-center"
                    data-test="DriverSidebar"
                    {...trackingAttributes(
                        'visibility',
                        'perform, detailsPanel',
                        'openDriverDetailsPanel',
                        'openDetailsPanel, drivingAnalysis'
                    )}
                >
                    <FormattedMessage
                        id="driverAnalysis.sidebar.title.oneDriver"
                        values={{
                            driver,
                            start: <FormattedDate value={startDate} />,
                            end: <FormattedDate value={endDate} />,
                        }}
                    />
                </div>
            }
            headerButtons={
                <PDFDownloader
                    entity={data as HydratedEntityWithChildren}
                    dateRange={dateRange}
                    isTruEEnabled={isTruEEnabled}
                />
            }
            bodyClassName={'overflow-y-scroll'}
        >
            <OperatingPeriod
                start={getSignal<Date | string | undefined>(data, 'start', undefined) as Date | string}
                end={getSignal<Date | string | undefined>(data, 'end', undefined) as Date | string}
            />
            <div className="padding-left-20 padding-right-20 padding-top-15">
                <Box rows={topRows} />
                {containsElectricVehicle && isTruEEnabled && (
                    <>
                        {containsCombustionVehicle && (
                            <div className="margin-top-10 display-flex text-size-18 flex-row align-items-center">
                                <span className="rioglyph-icon-pair margin-right-5">
                                    <span className="rioglyph rioglyph-truck"></span>
                                    <span className="rioglyph rioglyph-fuel-electric"></span>
                                </span>
                                <span className="text-size-18">
                                    <FormattedMessage id="bev.data" />
                                </span>
                            </div>
                        )}
                        <Box rows={vehicleElectricRows} hasBorder={true} />
                    </>
                )}
                {containsCombustionVehicle && (
                    <>
                        {containsElectricVehicle && isTruEEnabled && (
                            <div className="margin-top-10 display-flex text-size-18 flex-row align-items-center">
                                <span className="rioglyph-icon-pair margin-right-5">
                                    <span className="rioglyph rioglyph-truck"></span>
                                    <span className="rioglyph rioglyph-fuel-diesel"></span>
                                </span>
                                <span className="text-size-18">
                                    <FormattedMessage id="ice.data" />
                                </span>
                            </div>
                        )}
                        <Box rows={vehicleCombustionRows} hasBorder={false} />
                    </>
                )}
                <Tabs
                    dateRange={dateRange}
                    entity={data}
                    allowedVehicles={allowedVehicles}
                    shouldShowOvertakeInCruise={shouldShowOvertakeInCruise}
                    containsElectricVehicle={containsElectricVehicle}
                />
            </div>
        </Sidebar>
    );
};

export const mapStateToProps = (state: State) => {
    return {
        data: sidebarData(state, DRIVER_ROUTE),
        dateRange: getActiveDateRange(state),
        allowedVehicles: getPerformVehicles(state),
        shouldShowOvertakeInCruise: getFeatureToggles(state).showOvertakeInCruise as boolean,
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onVehicleClick: (data: Data) => dispatch(showSidebar({ data, type: DRIVER_ROUTE })),
        openParent: (id?: string) => {
            if (id) {
                dispatch(openRow({ id }));
            }
        },
        onClose: () => dispatch(hideSidebar()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverSidebar);
