import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import newReportFormImage from '../static/release-4.5.0-new-report-form.png';
import reportTabImage from '../static/release-4.5.0-report-tab.png';
import driverDetailsReport1Image from '../static/release-4.6.0-driver-details-report-1.png';
import driverDetailsReport2Image from '../static/release-4.6.0-driver-details-report-2.png';
import topBottomReportImage from '../static/release-4.6.0-top-bottom-report.png';

export default function ReportAutomationOverview() {
    return (
        <Fragment>
            <h3 className="margin-top-0">
                <FormattedMessage id="help.title.reportAutomation" />
            </h3>
            <p className="">
                <FormattedMessage id="help.reportAutomation.tabDescription" />
            </p>
            <div className="display-flex gap-25 margin-top-25">
                <div style={{ flexBasis: '60%' }}>
                    <img
                        alt="report-tab"
                        src={reportTabImage}
                        className="shadow-hard-to-bottom"
                        style={{ maxWidth: '100%', objectFit: 'contain' }}
                    />
                    <div className="display-flex gap-10 margin-top-10">
                        <div className="margin-left-10 margin-top-10">
                            <p>
                                <FormattedMessage
                                    id={'help.reportAutomation.featuresIntroduction'}
                                    values={{
                                        // eslint-disable-next-line react/display-name
                                        b: (chunks: unknown[]) => <span className="text-bold">{chunks}</span>,
                                    }}
                                />
                            </p>
                            <ul className="padding-left-20">
                                <FormattedMessage
                                    id={'help.reportAutomation.features'}
                                    values={{
                                        // eslint-disable-next-line react/display-name
                                        li: (chunks: unknown[]) => <li className="margin-bottom-10">{chunks}</li>,
                                    }}
                                />
                            </ul>
                        </div>
                        <img
                            alt="new-report-form"
                            src={newReportFormImage}
                            className="margin-top-10 shadow-hard-to-bottom"
                            style={{ maxWidth: '50%', objectFit: 'contain' }}
                        />
                    </div>
                </div>
                <div
                    style={{ flexBasis: '40%' }}
                    className="margin-left-25 padding-left-15 margin-top-15 padding-right-25"
                >
                    <span className="text-bold">
                        <FormattedMessage id={'help.reportAutomation.reportTypesTitle'} />
                    </span>
                    <span className="text-bold">{':'}</span>
                    <ul className="padding-left-20 padding-right-20 margin-top-15">
                        <li>
                            <p className="text-bold margin-0 padding-0">
                                <FormattedMessage id={'help.reportAutomation.topBottomReport'} />
                            </p>
                            <span>
                                <FormattedMessage id={'help.reportAutomation.topBottomReportDescription'} />
                            </span>
                        </li>
                    </ul>
                    <img
                        alt="top-bottom-report"
                        src={topBottomReportImage}
                        className="margin-bottom-20 shadow-hard-to-bottom"
                        style={{ maxWidth: '80%', objectFit: 'contain' }}
                    />
                    <ul className="padding-left-20 margin-top-10">
                        <li>
                            <p className="text-bold margin-0 padding-0">
                                <FormattedMessage id={'help.reportAutomation.driverDetailReport'} />
                            </p>
                            <p className="margin-0 padding-0">
                                <FormattedMessage id={'help.reportAutomation.driverDetailReportDescription'} />
                            </p>
                        </li>
                    </ul>
                    <div className="display-flex justify-content-between padding-right-10 margin-top-15">
                        <img
                            alt="driver-detail-report-1"
                            src={driverDetailsReport1Image}
                            className="shadow-hard-to-bottom"
                            style={{ maxWidth: '40%', objectFit: 'contain' }}
                        />
                        <img
                            alt="driver-detail-report-2"
                            src={driverDetailsReport2Image}
                            className="shadow-hard-to-bottom"
                            style={{ maxWidth: '40%', objectFit: 'contain' }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
