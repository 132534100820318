export const roundToOneDecimalPoint = (n: number) => Math.round(n * 10) / 10;

const ceilToOneDecimalPoint = (n: number) => Math.ceil(n * 10) / 10;

export type BarInfo = {
    min: number;
    max: number;
    value: number;
};

export const computeDynamicBars = (barsData: number[], maxNumberOfBars: number): BarInfo[] => {
    const data = [...barsData];

    const minValue = Math.min(...data);
    const maxValue = Math.max(...data);
    let step = ceilToOneDecimalPoint((maxValue - minValue) / maxNumberOfBars);
    let numberOfBars = maxNumberOfBars;

    if (step < 0.2) {
        step = 0.2;
        const numberOfBarsByStep = Math.ceil((maxValue - minValue) / step);
        numberOfBars = numberOfBarsByStep === 0 ? 1 : numberOfBarsByStep;
    }

    const bars = [];
    const barRangesCounter = new Array(numberOfBars).fill(0);
    const totalNumberOfValues = data.length;

    for (let barIndex = 0; barIndex < numberOfBars; barIndex++) {
        const isFirstBar = barIndex === 0;
        const isLastBar = barIndex === numberOfBars - 1;

        const barMinValue = roundToOneDecimalPoint(step * barIndex + minValue);
        const barMaxValue = roundToOneDecimalPoint(step * (barIndex + 1) + minValue);

        let lastDataEntryIndex = data.length;
        while (lastDataEntryIndex >= 0) {
            if (
                (isFirstBar && data[lastDataEntryIndex] < barMaxValue) ||
                (isLastBar && data[lastDataEntryIndex] >= barMinValue) ||
                (!isLastBar && data[lastDataEntryIndex] >= barMinValue && data[lastDataEntryIndex] < barMaxValue)
            ) {
                barRangesCounter[barIndex]++;
                data.splice(lastDataEntryIndex, 1);
            }
            lastDataEntryIndex = lastDataEntryIndex - 1;
        }

        const barYValue = (barRangesCounter[barIndex] / totalNumberOfValues) * 100;

        bars.push({
            min: barMinValue,
            max: barMaxValue,
            value: isNaN(barYValue) ? 0 : barYValue,
        });
    }

    let placeToAddEmptyBars = 'end';
    while (bars.length < 5) {
        if (placeToAddEmptyBars === 'end') {
            bars.push({
                min: bars[bars.length - 1].max,
                max: roundToOneDecimalPoint(bars[bars.length - 1].max + step),
                value: 0,
            });
            placeToAddEmptyBars = 'start';
        } else {
            bars.unshift({
                min: roundToOneDecimalPoint(bars[0].min - step),
                max: bars[0].min,
                value: 0,
            });
            placeToAddEmptyBars = 'end';
        }
    }

    return bars;
};
