import { FormattedMessage } from 'react-intl';

import europeanFlag from '../../static/european-flag.png';

const Release530 = () => (
    <div style={{ marginLeft: '110px' }}>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.3.0.performAPI" />
        </p>
        <p className="margin-top-0 margin-bottom-0">
            <FormattedMessage id="help.changelog.5.3.0.performAPI.description" />
        </p>
        <div className="display-flex margin-top-5 margin-bottom-15 gap-10 align-items-center">
            <img alt="european-flag" src={europeanFlag} style={{ maxWidth: '40px', objectFit: 'contain' }} />
            <p className="margin-0 text-medium">
                <FormattedMessage id="help.performAPI.europeanNote" />
            </p>
        </div>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.3.0.tabRenamed" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.3.0.tabRenamed.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.5.3.0.ambientTemperature" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage id="help.changelog.5.3.0.ambientTemperature.description" />
        </p>
        <p className="text-bold text-size-18 margin-bottom-5">
            <FormattedMessage id="help.changelog.bugFixes" />
        </p>
        <p className="margin-top-0">
            <FormattedMessage
                id="help.changelog.5.3.0.bugFixes"
                values={{
                    // eslint-disable-next-line react/display-name
                    ul: (chunks: unknown[]) => <ul className="padding-left-15">{chunks}</ul>,
                    // eslint-disable-next-line react/display-name
                    li: (chunks: unknown[]) => <li className="margin-bottom-5">{chunks}</li>,
                }}
            />
        </p>
    </div>
);

export default Release530;
