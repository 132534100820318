import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import { FormattedMessage } from 'react-intl';

export default function EmptyOpConState({ ...rest }) {
    return (
        <div data-test="emptyOpconState" {...rest}>
            <EmptyState
                headline={<FormattedMessage id="inaccuracyOperationConditionWarning" />}
                message={<FormattedMessage id="inaccuracyOperationConditionWarningDetail" />}
                fullWidth
            />
        </div>
    );
}
