import { Overlay, TransitionTypes } from '@components/graphComponents/Overlay';
import { HoverInfo } from '@pages/driverAnalysis/types';
import { getColumn } from '@utils/columns';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { HydratedEntity } from '../../types';
import { StateOfChargeDataPointFiltered } from './types';

const groupBatteryDetails = <T,>(items: T[]): T[][] => {
    return items.reduce((acc, _, i, arr) => (i % 2 === 0 ? [...acc, arr.slice(i, i + 2)] : acc), [] as T[][]);
};

const ChargeGraphOverlay = ({
    hoverInfo,
    data,
    parentDimensions,
}: {
    hoverInfo: HoverInfo;
    data: StateOfChargeDataPointFiltered[];
    parentDimensions: { height: number; width: number; margin: number };
}) => {
    const entity = data.find(d => d.date === hoverInfo.data.x);
    const entityIdlingColumn = (data.find(d => d.date === hoverInfo.data.x) as unknown) as HydratedEntity;
    if (!entity) return null;
    const idlingColumn = getColumn('excessiveIdlingTime');

    const groupedBatteryDetails =
        entity.filteredDetails && entity.filteredDetails.details
            ? groupBatteryDetails(entity.filteredDetails.details)
            : [];

    const energyConsumption = entity.filteredDetails?.energyConsumption;

    const startDate = new Date(hoverInfo.data.x);
    const endDate = new Date(startDate.getTime() + 15 * 60 * 1000);

    // Calculate the offset size for the left side
    const leftOffset = hoverInfo.size > 54 ? 12.5 : 0;
    const hoverSizeLeftX = hoverInfo.leftX + hoverInfo.size + leftOffset;

    // Calculate the offset size for the right side
    const hoverSizeRightX = hoverInfo.leftX + hoverInfo.size / 4;

    // Determine the hover X position based on the leftX position
    let hoverSizeX = hoverInfo.leftX > 600 ? hoverSizeRightX : hoverSizeLeftX;

    // Adjust the X position if leftX is very small
    if (hoverInfo.leftX < 50) {
        hoverSizeX += hoverInfo.size - 7;
    }

    return (
        <Overlay
            hasData={!!hoverInfo.data}
            x={hoverSizeX}
            y={0}
            withDataClasses="border-color-highlight bg-white"
            maxXValue={parentDimensions.width}
            transitionType={TransitionTypes.BATTERY}
        >
            <div
                style={{
                    width: `550px`,
                    height: `${parentDimensions.height - parentDimensions.margin - 12}px`,
                    margin: '0',
                    padding: '0',
                }}
            >
                <div className="display-flex flex-column justify-content-between width-550pct height-100pct">
                    <div className="row">
                        <div className="col-8 margin-bottom-0">
                            <div className="padding-left-10">
                                <div className="width-125">
                                    <p className="text-light text-size-14 margin-0 text-color-dark">
                                        <FormattedMessage id={'battery.idlingWithoutExternalCharging'} />
                                    </p>
                                    <div className="text-medium text-size-14 text-color-highlight">
                                        <span>
                                            {idlingColumn?.formatter(idlingColumn?.valueExtractor(entityIdlingColumn))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 margin-bottom-0 ">
                            <div className="padding-right-10">
                                <div className="width-125">
                                    <p className="margin-0 text-size-14 text-light text-color-dark">
                                        <FormattedMessage id="energyConsumption" />
                                    </p>
                                    <div className="text-medium text-size-14">
                                        <div className="display-flex gap-50 text-light">
                                            <span className="width-120 text-medium text-size-14 text-color-highlight">
                                                {energyConsumption?.consumption ??
                                                    energyConsumption?.consumption_kWh_format}
                                            </span>
                                            <span className="text-size-14 text-light">
                                                {energyConsumption?.percentage} %
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="padding-10 margin-left-10 no-border">
                            <span className="text-medium text-size-14 margin-0 text-color-dark">
                                <FormattedMessage id={'batteryDetails.mainconsumers'} />
                            </span>
                        </div>
                    </div>
                    <div className="row margin-0">
                        {groupedBatteryDetails.map((group, index) => (
                            <div
                                className={`col-4 margin-bottom-20 ${index < 2 ? 'border border-right-only' : ''}`}
                                key={`group-${index}`}
                            >
                                {group.map((item, idx) => (
                                    <div className="padding-0" key={`program-${item.name}-${idx}`}>
                                        <div className="display-flex justify-content-between align-items-end margin-bottom-10">
                                            <div className="width-125">
                                                <p className="margin-0 text-size-14 text-light text-color-dark">
                                                    <FormattedMessage id={`batteryDetails.${item.name}`} />
                                                </p>
                                                <div className="display-flex gap-50 text-light">
                                                    <span className="width-70 text-medium text-size-14 text-color-highlight">
                                                        {item.consumption}
                                                    </span>
                                                    <span className="text-size-14">{item.percentage} %</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {group.length < 2 && <div className="padding-10"></div>}
                            </div>
                        ))}
                    </div>
                    <div className="row margin-0">
                        <div className="margin-left-10 text-size-14 display-flex gap-50 text-light">
                            <FormattedDate
                                value={hoverInfo.data.x}
                                hour="numeric"
                                minute="numeric"
                                year="numeric"
                                month="short"
                                day="2-digit"
                            />
                            -
                            <FormattedTime value={endDate} hour="numeric" minute="numeric" />
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
};

export default ChargeGraphOverlay;
