import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { FormattedMessage } from 'react-intl';

const SimpleErrorState: React.FC<{
    headlineId?: string;
    messageId?: string;
    fullWidth?: boolean;
    [key: string]: unknown;
}> = ({ headlineId = 'error.default', messageId, fullWidth, ...rest }) => {
    return (
        <ErrorState
            fullWidth={fullWidth}
            headline={<FormattedMessage id={headlineId} />}
            message={messageId && <FormattedMessage id={messageId} />}
            {...rest}
        />
    );
};

export default SimpleErrorState;
