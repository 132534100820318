import { Vehicle } from '@api/models/types';
import { getDrivers, getVehicles } from '@data/selectors';
import { AutoSuggestSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import { extractDriverName } from '@utils/stringFormatters';
import { get, map, partialRight } from 'lodash';
import { compose } from 'redux';
import { createSelector } from 'reselect';

import { State } from '../../setup/types';
import { RawDriver } from '../../types';

export const DRIVER = 'drivers';
export const VEHICLE = 'vehicles';

const entityToSuggestionMapper = (entity: Vehicle | RawDriver): AutoSuggestSuggestion => ({
    label: 'vehicleId' in entity ? `${entity.name} (${entity.vin})` : extractDriverName(entity),
    icon: 'vehicleId' in entity ? 'truck' : 'driver',
    id: 'vehicleId' in entity ? entity.vehicleId : entity.driverId,
    type: 'vehicleId' in entity ? VEHICLE : DRIVER,
    entity,
});

export const getSelection = (state: State) => get(state, 'coDriver.selection');

export const getStatus = (state: State) => get(state, ['coDriver', 'pendingDownloads']);

export const getSuggestions = createSelector([getDrivers, getVehicles], (drivers, vehicles) => [
    ...Object.values(drivers).map(entityToSuggestionMapper),
    ...compose(partialRight(map, entityToSuggestionMapper))(vehicles),
]);
