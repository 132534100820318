import { NumberValue } from 'd3-scale';
import React, { useContext, useEffect } from 'react';

import AnimatedRect from './AnimatedRect';
import { GraphContext } from './GraphDataProvider';
import { DataEntry, YAxis } from './types';

const BAR_MAX_WIDTH = 40;
const MINIMUM_SPACE_BETWEEN_BARS = 5;

export function GroupedBars({
    colorSchema,
    hasLeftAndRightBars = true,
    minHeight = 0,
    setHoverInfoXOffset,
}: {
    colorSchema: (d: YAxis) => string;
    hasLeftAndRightBars?: boolean;
    minHeight?: number;
    setHoverInfoXOffset?: (barsXOffset: number) => void;
}): JSX.Element {
    let barsXOffset = 0;

    const {
        xScale: x,
        leftYScale,
        rightYScale,
        data,
        dimensions: { height: graphHeight },
        yZeroPosition,
    } = useContext(GraphContext);

    const barsYOffset = yZeroPosition;

    const getXValue = (value: NumberValue) => x(value as number) || 0;

    const getBarDimensions = (barPosition: YAxis, dataEntry: DataEntry) => {
        const barValue = dataEntry[barPosition];

        if (isNaN(barValue)) {
            return undefined;
        }

        let width = BAR_MAX_WIDTH,
            spaceBetweenGroupedBars = MINIMUM_SPACE_BETWEEN_BARS;
        if (data.length > 1) {
            const segmentSpace = getXValue(data[1].x) - getXValue(data[0].x);
            const spaceForBars = segmentSpace * 0.6;
            spaceBetweenGroupedBars = Math.min(spaceForBars * 0.05, MINIMUM_SPACE_BETWEEN_BARS);
            width = Math.min((spaceForBars - spaceBetweenGroupedBars) / 2, BAR_MAX_WIDTH);
        }

        barsXOffset = width + spaceBetweenGroupedBars;

        let xOffset;
        if (hasLeftAndRightBars) {
            if (barPosition.includes('left')) {
                xOffset = getXValue(dataEntry.x) - width - spaceBetweenGroupedBars;
            } else {
                xOffset = getXValue(dataEntry.x) + spaceBetweenGroupedBars;
            }
        } else {
            xOffset = getXValue(dataEntry.x) - width / 2;
        }

        const hasValue = barValue > 0;

        let yOffset, height;

        switch (barPosition) {
            case 'leftUpY':
                yOffset = hasValue ? leftYScale(barValue) : graphHeight - minHeight;
                height = hasValue ? barsYOffset - leftYScale(barValue) : minHeight;
                break;
            case 'leftDownY':
                yOffset = hasValue ? barsYOffset : graphHeight + minHeight;
                height = hasValue ? barsYOffset - leftYScale(barValue) : minHeight;
                break;
            case 'rightUpY':
                yOffset = hasValue && rightYScale ? rightYScale(barValue) : graphHeight - minHeight;
                height = hasValue && rightYScale ? barsYOffset - rightYScale(barValue) : minHeight;
                break;
            case 'rightDownY':
                yOffset = hasValue && rightYScale ? barsYOffset : graphHeight + minHeight;
                height = hasValue && rightYScale ? barsYOffset - rightYScale(barValue) : minHeight;
                break;
        }

        return {
            width,
            height,
            xOffset,
            yOffset,
        };
    };

    useEffect(() => {
        if (setHoverInfoXOffset) {
            setHoverInfoXOffset(barsXOffset);
        }
    }, [barsXOffset, setHoverInfoXOffset]);

    return (
        <g>
            {data.reduce((acc: JSX.Element[], d: DataEntry, index: number) => {
                if (
                    (isNaN(d.leftUpY) || d.leftUpY === -1) &&
                    (isNaN(d.leftDownY) || d.leftDownY === -1) &&
                    (isNaN(d.rightUpY) || d.rightUpY === -1) &&
                    (isNaN(d.rightDownY) || d.rightDownY === -1)
                ) {
                    return acc;
                }

                const leftUpBarDimensions = getBarDimensions('leftUpY', d);
                const leftDownBarDimensions = getBarDimensions('leftDownY', d);
                const rightUpBarDimensions = getBarDimensions('rightUpY', d);
                const rightDownBarDimensions = getBarDimensions('rightDownY', d);

                acc.push(
                    <React.Fragment key={d.x.toString()}>
                        {leftUpBarDimensions && (
                            <AnimatedRect
                                key={`bar-leftup-${index}`}
                                fill={colorSchema('leftUpY')}
                                width={leftUpBarDimensions.width}
                                x={leftUpBarDimensions.xOffset}
                                y={leftUpBarDimensions.yOffset}
                                height={leftUpBarDimensions.height}
                                dataTest={`bar-leftup-${index}`}
                            />
                        )}
                        {leftDownBarDimensions && (
                            <AnimatedRect
                                key={`bar-leftdown-${index}`}
                                fill={colorSchema('leftDownY')}
                                width={leftDownBarDimensions.width}
                                x={leftDownBarDimensions.xOffset}
                                y={leftDownBarDimensions.yOffset}
                                height={leftDownBarDimensions.height}
                                dataTest={`bar-leftdown-${index}`}
                            />
                        )}
                        {rightUpBarDimensions && (
                            <AnimatedRect
                                key={`bar-rightup-${index}`}
                                fill={colorSchema('rightUpY')}
                                width={rightUpBarDimensions.width}
                                x={rightUpBarDimensions.xOffset}
                                y={rightUpBarDimensions.yOffset}
                                height={rightUpBarDimensions.height}
                                dataTest={`bar-rightup-${index}`}
                            />
                        )}
                        {rightDownBarDimensions && (
                            <AnimatedRect
                                key={`bar-rightdown-${index}`}
                                fill={colorSchema('rightDownY')}
                                width={rightDownBarDimensions.width}
                                x={rightDownBarDimensions.xOffset}
                                y={rightDownBarDimensions.yOffset}
                                height={rightDownBarDimensions.height}
                                dataTest={`bar-rightdown-${index}`}
                            />
                        )}
                    </React.Fragment>
                );
                return acc;
            }, [])}
        </g>
    );
}
