import { BarsLegend } from '@components/graphComponents/BarsLegend';
import XAxis from '@components/graphComponents/DatedXAxis';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import { GroupedBars } from '@components/graphComponents/GroupedBars';
import { Overlay } from '@components/graphComponents/Overlay';
import { DataEntry, GraphType, YAxis } from '@components/graphComponents/types';
import { HOVER_INFO_HEIGHT, HOVER_Y_OFFSET } from '@components/graphComponents/utils';
import { generateTicks, generateTicksFromSeconds, YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import { DieselAndElectric, Formatter, Section } from '@components/summary/types';
import SegmentableDay from '@features/ui/SegmentableDay';
import SegmentableDayOverlay from '@features/ui/SegmentableDayOverlay';
import { HoverInfo } from '@pages/driverAnalysis/types';
import { VehicleGraphDimensions } from '@pages/vehicleAnalysis/vehicleDataGraph';
import { formatSecondsToTime } from '@utils/stringFormatters';
import _ from 'lodash';
import { useState } from 'react';
import { FormattedMessage, IntlFormatters } from 'react-intl';

import { SECONDS } from '../../constants/units';
import { DateRange } from '../../types';
import { BARS_INFO } from './utils';

const electricBarsSchema = (scaleName: YAxis) => {
    switch (scaleName) {
        case 'leftUpY':
            return BARS_INFO.BEV_UP.colorValue;
        case 'leftDownY':
            return BARS_INFO.BEV_DOWN.colorValue;
        default:
            return '';
    }
};

const OneUpAndOneDownBarsGraph = ({
    column,
    data,
    dateRange,
    dimensions,
    intl: { formatNumber = (value: number) => value.toString(), formatDate, formatMessage },
    languageData,
    tickFormatter,
}: {
    column: Section;
    data: DataEntry[];
    dateRange: DateRange;
    dimensions: VehicleGraphDimensions;
    intl: IntlFormatters;
    languageData: Record<string, string>;
    tickFormatter: (dateRange: DateRange) => (d: Date) => string;
}) => {
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);
    const [yZeroPosition, setYZeroPosition] = useState<number>(dimensions.height);

    const yScaleFormatter = (column.formatter as DieselAndElectric<Formatter>).electric;
    const yScaleUnit = (column.unit as DieselAndElectric<string>).electric;

    const hasUpBarDataForHoveredDate = _.get(hoverInfo, 'data.leftUpY', 0) > 0;
    const hasDownBarDataForHoveredDate = _.get(hoverInfo, 'data.leftDownY', 0) > 0;
    const hasNoDataForHoveredDate = !hasUpBarDataForHoveredDate && !hasDownBarDataForHoveredDate;

    const oneUpAndOneDownBarsInfo = {
        BEV_UP: { label: _.get(languageData, 'consumption.electric'), color: BARS_INFO.BEV_UP.colorValue },
        BEV_DOWN: {
            label: _.get(languageData, 'consumption.electricRecuperation'),
            color: BARS_INFO.BEV_DOWN.colorValue,
        },
    };

    return (
        <div
            className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
            style={{ height: dimensions.height + dimensions.legendHeight }}
            data-test="vehicle-one-up-and-one-down-bars-TruE-graph"
        >
            {hoverInfo && (
                <>
                    {hasNoDataForHoveredDate ? (
                        <Overlay hasData x={hoverInfo.leftX} y={HOVER_Y_OFFSET}>
                            <FormattedMessage id="noData" />
                        </Overlay>
                    ) : (
                        <>
                            {hasUpBarDataForHoveredDate && (
                                <Overlay
                                    hasData
                                    x={hoverInfo.leftX}
                                    y={
                                        hoverInfo.leftUpY + HOVER_INFO_HEIGHT > yZeroPosition
                                            ? hoverInfo.leftUpY - HOVER_INFO_HEIGHT
                                            : hoverInfo.leftUpY
                                    }
                                    maxXValue={dimensions.width}
                                    useCustomStyleColors
                                    style={{
                                        borderColor: BARS_INFO.BEV_UP.colorValue,
                                        color: BARS_INFO.BEV_UP.colorValue,
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {yScaleFormatter(hoverInfo.data.leftUpY)}
                                </Overlay>
                            )}
                            {hasDownBarDataForHoveredDate && (
                                <Overlay
                                    withDataClasses={`border-color-${BARS_INFO.BEV_DOWN.colorName} text-color-${BARS_INFO.BEV_DOWN.colorName} bg-white`}
                                    hasData
                                    x={hoverInfo.leftX}
                                    y={
                                        hoverInfo.leftDownY < yZeroPosition
                                            ? hoverInfo.leftDownY + HOVER_INFO_HEIGHT
                                            : hoverInfo.leftDownY
                                    }
                                    maxXValue={dimensions.width}
                                >
                                    {yScaleFormatter(hoverInfo.data.leftDownY)}
                                </Overlay>
                            )}
                        </>
                    )}
                </>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                dimensions={dimensions}
                data={data}
                setYZeroPosition={setYZeroPosition}
                graphType={GraphType.ONE_UP_AND_ONE_DOWN_BARS}
                shouldRoundYScales
            >
                <SegmentableDayOverlay
                    leftUpYColor={BARS_INFO.BEV_UP.colorValue}
                    leftDownYColor={BARS_INFO.BEV_DOWN.colorName}
                    leftYScaleFormatter={yScaleFormatter}
                    shouldShowLeftDownYValue
                    useCustomStyleColors
                />
                <svg
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                        shouldHideTickLine
                    />
                    <YAxisLines tickGenerator={yScaleUnit === SECONDS ? generateTicksFromSeconds : generateTicks} />
                    <YAxisTicks
                        formatter={yScaleUnit === SECONDS ? formatSecondsToTime : formatNumber}
                        tickGenerator={yScaleUnit === SECONDS ? generateTicksFromSeconds : generateTicks}
                        units={yScaleUnit ? `(${_.get(languageData, `unit.${yScaleUnit}`)})` : undefined}
                        unitsColor={BARS_INFO.BEV_UP.colorValue}
                    />
                    <GroupedBars colorSchema={electricBarsSchema} hasLeftAndRightBars={false} minHeight={0} />
                    <SegmentableDay onHover={setHover} />
                    <BarsLegend barsInfo={oneUpAndOneDownBarsInfo} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default OneUpAndOneDownBarsGraph;
