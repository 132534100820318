import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import React from 'react';

import OverlayTriggerWithInjectedIntl from './OverlayTriggerWithInjectedIntl';

export const InfoHover: React.FC<{ children?: never; tooltip?: React.ReactNode }> = ({
    children,
    tooltip: content,
}) => {
    const tooltip = (
        <Tooltip id="tooltip" width={200}>
            {content}
        </Tooltip>
    );

    return (
        <OverlayTriggerWithInjectedIntl placement="right" overlay={tooltip} className="right-top">
            <span className="display-flex align-items-center" data-test="info-overlay">
                {children}
                <span className="rioglyph rioglyph-info-sign text-color-info text-size-18" />
            </span>
        </OverlayTriggerWithInjectedIntl>
    );
};

const InfoOverlay = ({ warnings }: { warnings?: JSX.Element[] }) => {
    const style: React.CSSProperties = {
        listStyle: 'none',
        padding: 0,
        textAlign: 'left',
        margin: 0,
    };

    if (warnings && warnings.length) {
        return <InfoHover tooltip={<dl style={style}>{warnings}</dl>} />;
    }
    return null;
};

export default InfoOverlay;
