import { getSignal, Road } from '@api/index';
import AccessControl from '@common/permissions/AccessControl';
import { SEE_MAP } from '@common/permissions/hasPermissions';
import ErrorBoundary from '@components/ErrorBoundry';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { groupRoadsByCountry } from '@utils/groupRoadsByCountry';
import countries from 'i18n-iso-countries';
import langs from 'i18n-iso-countries/langs/en.json';
import _ from 'lodash';
import React, { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { DateRange } from '../../types';
import EmptyOpConState from './EmptyOpConState';
import GetBasicOpConData from './GetBasicOpConData';
import isBelowMileageThreshold from './isBelowMileageThreshold';
import RoadTypeRow from './RoadTypeRow';
import { OpCon } from './types';

export const VehicleMap = React.lazy(() => import('../vehicleMap/SelfLoadingVehicleMap'));

countries.registerLocale(langs);

interface Props {
    opCon?: OpCon;
    mileageFromPerformance: number;
    dateRange: DateRange;
    vehicleIds: string[];
    canSeeRoute: boolean;
    isRouteEnabled: boolean;
}

export const OperationalRange = ({
    opCon,
    mileageFromPerformance,
    dateRange,
    vehicleIds,
    canSeeRoute,
    isRouteEnabled,
}: Props) => {
    const operationRange = getSignal(opCon || {}, 'range', undefined);
    const route = getSignal(opCon || {}, 'route', {});
    const roads = _.get(route, 'roads', []);
    const totalMileageRoads = _.sum(roads.map((r: Road) => r.mileage));
    const countryFactor = roads.reduce(
        (state: Record<string, { key: string; name: string; mileage: number }>, r: Road) => {
            if (!state[r.country]) {
                state[r.country] = {
                    key: r.country,
                    name: countries.getName(r.country, 'en'),
                    mileage: 0,
                };
            }

            state[r.country].mileage += r.mileage;

            return state;
        },
        {}
    );

    const totalMileage = _.sumBy(roads, 'mileage');

    const countryList = _.keys(countryFactor).map(key => {
        return { name: countryFactor[key].name, factor: countryFactor[key].mileage / totalMileage };
    });

    const roadsByCountryAndType = groupRoadsByCountry(roads);

    if (isBelowMileageThreshold(_.get(operationRange, 'evaluatedMileage', 0), mileageFromPerformance)) {
        return <EmptyOpConState />;
    }

    return (
        <div data-test="operationalRange">
            <ErrorBoundary>
                <AccessControl requiredPermissions={[SEE_MAP]}>
                    <Suspense fallback={<Spinner />}>
                        <VehicleMap
                            dateRange={dateRange}
                            vehicleIds={vehicleIds}
                            operationRange={operationRange}
                            countries={countryList}
                            showTerrain={false}
                            canSeeRoute={canSeeRoute}
                            isRouteEnabled={isRouteEnabled}
                        />
                    </Suspense>
                </AccessControl>

                <table className="margin-top-15 table margin-bottom-20">
                    <colgroup>
                        <col className="width-40pct width-30pct-lg" />
                        <col />
                    </colgroup>
                    <thead>
                        <tr className="text-color-dark text-left">
                            <th className="max-width-250">
                                <FormattedMessage
                                    id={'sidepanel.header.countryAndRoadType'}
                                    values={{ line_break: <br /> }}
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'sidepanel.header.percentOfTotalMileage'}
                                    values={{ line_break: <br /> }}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {roadsByCountryAndType.map(roadsByCountry => {
                            return (
                                <React.Fragment key={roadsByCountry.country}>
                                    <tr>
                                        <td colSpan={2} className="padding-top-15 text-size-14">
                                            {roadsByCountry.country.toUpperCase()}
                                        </td>
                                    </tr>
                                    {roadsByCountry.roads.map((roadsByType, idx) => {
                                        return (
                                            <RoadTypeRow
                                                key={roadsByCountry.country + roadsByType.name + idx}
                                                roads={roadsByType.roads}
                                                name={roadsByType.name}
                                                totalMileage={totalMileageRoads}
                                            />
                                        );
                                    })}
                                    <tr>
                                        <td colSpan={2} className="padding-0" />
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </ErrorBoundary>
        </div>
    );
};

const SelfLoadingOperationalRange = ({
    dateRange,
    driverIds,
    vehicleIds,
    ...props
}: Props & { driverIds: (string | null)[] }) => (
    <GetBasicOpConData start={dateRange.start} end={dateRange.end} driverIds={driverIds} vehicleIds={vehicleIds}>
        <OperationalRange {...props} dateRange={dateRange} vehicleIds={vehicleIds} />
    </GetBasicOpConData>
);

export default SelfLoadingOperationalRange;
