import {
    AssetsReportType,
    CreateReportConfigurationRequest,
    DriverReportType,
    FleetReportType,
    GroupReportType,
    ReportConfiguration,
    ReportConfigurationCreateableFields,
    TargetType,
} from '../models/reportConfiguration';
import { AssetsAndGroupsReportType, DriversAndGroupsReportType } from './../models/reportConfiguration';
import { encodeJson } from './encoder';

export function encodeReportConfiguration(
    raw: Omit<ReportConfiguration, 'id' | 'reports'>
): CreateReportConfigurationRequest {
    const transformConfiguration = transformReportConfiguration(raw);
    return encodeJson(ReportConfigurationCreateableFields, transformConfiguration);
}

function transformReportConfiguration(
    decoded: Omit<ReportConfiguration, 'id' | 'reports'>
): CreateReportConfigurationRequest {
    switch (decoded.type) {
        case TargetType.assets: {
            const { assets, type, ...defaults } = decoded as AssetsReportType;
            return {
                ...defaults,
                targets: [{ assets, type: TargetType.assets }],
            };
        }
        case TargetType.assetsAndGroups: {
            const { assets, groups, type, ...defaults } = decoded as AssetsAndGroupsReportType;
            return {
                ...defaults,
                targets: [
                    { assets, type: TargetType.assets },
                    { groups, type: TargetType.groups },
                ],
            };
        }
        case TargetType.drivers: {
            const { drivers, type, ...defaults } = decoded as DriverReportType;
            return {
                ...defaults,
                targets: [{ drivers, type: TargetType.drivers }],
            };
        }
        case TargetType.driversAndGroups: {
            const { drivers, groups, type, ...defaults } = decoded as DriversAndGroupsReportType;
            return {
                ...defaults,
                targets: [
                    { drivers, type: TargetType.drivers },
                    { groups, type: TargetType.groups },
                ],
            };
        }
        case TargetType.fleet: {
            const { type, ...defaults } = decoded as FleetReportType;
            return {
                ...defaults,
                targets: [{ type }],
            };
        }
        case TargetType.groups: {
            const { groups, ...defaults } = decoded as GroupReportType;
            return {
                ...defaults,
                targets: [{ groups, type: TargetType.groups }],
            };
        }
    }
}
