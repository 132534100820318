import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

export const IdPropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const DateRangePropType = PropTypes.shape({
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]).isRequired,
});

export const datePropType = PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
    PropTypes.string,
]);

export const DriverPropType = PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    driverCard: PropTypes.string,
});
