import _ from 'lodash';
import moment from 'moment';

import { DataEntry, TimeSegmentUnit } from './types';

export const NO_DATA_Y_OFFSET = 40;

export const HOVER_INFO_HEIGHT = 32;
export const HOVER_Y_OFFSET = 40;

export const hasValue = (d: DataEntry) =>
    _.isNumber(d.leftUpY) || _.isNumber(d.leftDownY) || _.isNumber(d.rightUpY) || _.isNumber(d.rightDownY);

export const calculateDateRange = (unit: TimeSegmentUnit) => (start: Date, end: Date) => {
    const step = Math.max(Math.floor(Math.log(Math.abs(moment(start).diff(end, unit)))), 1);
    return _.range(0, moment(end).diff(start, unit) + 1, step).map(e =>
        moment(start)
            .add(e, unit)
            .toDate()
    );
};
