import { array, boolean, intersection, literal, nullType, string, type, TypeOf, union } from 'io-ts';
import { date } from 'io-ts-types/lib/date';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

import { Id } from './types';

export enum ReportStatus {
    'active' = 'ACTIVE',
    'disabled' = 'DISABLED',
}

export enum ReportTimeframe {
    'weekly' = 'WEEKLY',
    'monthly' = 'MONTHLY',
}

export enum ReportType {
    // 'fleet-analysis-overview' = 'fleet-analysis-overview',
    // 'fleet-analysis-consumption' = 'fleet-analysis-consumption',
    // 'fleet-analysis-operating-status' = 'fleet-analysis-operating-status',
    // 'driving-analysis-overview' = 'driving-analysis-overview',
    // 'driving-analysis-ratings' = 'driving-analysis-ratings',
    'driver_top_bottom' = 'DRIVER_TOP_BOTTOM',
    'driver_details' = 'DRIVER_DETAILS',
    'asset_details' = 'ASSET_DETAILS',
    'vehspec_test' = 'VEHSPEC_TEST',
    'fleet' = 'FLEET',
    'unknown' = 'UNKNOWN',
}

export enum TargetType {
    'assets' = 'assets',
    'groups' = 'groups',
    'drivers' = 'drivers',
    'fleet' = 'fleet',
    'assetsAndGroups' = 'assetsAndGroups',
    'driversAndGroups' = 'driversAndGroups',
}

export enum GenerationStatus {
    'PENDING' = 'PENDING',
    'GENERATED' = 'GENERATED',
    'FAILED' = 'FAILED',
    'SCHEDULED' = 'SCHEDULED',
}

export enum EmailNotificationStatus {
    'EMAIL_NOTIFICATION_SERVICE_ERROR' = 'EMAIL_NOTIFICATION_SERVICE_ERROR',
    'EMAIL_SENT' = 'EMAIL_SENT',
    'NO_EMAIL_NOTIFICATION' = 'NO_EMAIL_NOTIFICATION',
    'BOUNCE_OR_COMPLAINT' = 'BOUNCE_OR_COMPLAINT',
}

type ReportName = string;

export type GeneratedReport = {
    reportId: string;
    date: Date;
};

export type Report = {
    reportId: string;
    createdAt: Date;
    updatedAt: Date;
    generatedAt: Date | null;
    reportStatus: GenerationStatus;
    emailNotificationStatus: string | null;
};

type _ReportConfiguration = {
    id: Id;
    name: ReportName;
    timeframe: ReportTimeframe;
    informViaEmail: boolean;
    status: ReportStatus;
    reportType: ReportType;
    evaluationStart: Date;
    createdAt: Date;
    languageCode: string;
    reports: Report[];
};

export type AssetsReportType = {
    type: TargetType.assets;
    assets: Id[];
} & _ReportConfiguration;

export type AssetsAndGroupsReportType = {
    type: TargetType.assetsAndGroups;
    assets: Id[];
    groups: Id[];
} & _ReportConfiguration;

export type GroupReportType = {
    type: TargetType.groups;
    groups: Id[];
} & _ReportConfiguration;

export type DriverReportType = {
    type: TargetType.drivers;
    drivers: Id[];
} & _ReportConfiguration;

export type FleetReportType = {
    type: TargetType.fleet;
} & _ReportConfiguration;

export type DriversAndGroupsReportType = {
    type: TargetType.driversAndGroups;
    drivers: Id[];
    groups: Id[];
} & _ReportConfiguration;

export type ReportConfiguration =
    | AssetsReportType
    | GroupReportType
    | DriverReportType
    | AssetsAndGroupsReportType
    | DriversAndGroupsReportType
    | FleetReportType;
export type CreateReportConfiguration = Omit<ReportConfiguration, 'id' | 'reports'>;

const ReportConfigurationTargets = union([
    type({
        type: literal(TargetType.groups),
        groups: array(string),
    }),
    type({
        type: literal(TargetType.drivers),
        drivers: array(string),
    }),
    type({
        type: literal(TargetType.assets),
        assets: array(string),
    }),
    type({ type: literal(TargetType.fleet) }),
]);

export const ReportConfigurationEditableFields = type({
    name: string,
    informViaEmail: boolean,
    status: union([literal(ReportStatus.active), literal(ReportStatus.disabled)]),
    languageCode: string,
    targets: array(ReportConfigurationTargets),
    timeframe: union([literal(ReportTimeframe.monthly), literal(ReportTimeframe.weekly)]),
});

const ReportConfigurationCommon = intersection([
    ReportConfigurationEditableFields,
    type({
        reportType: string,
    }),
]);

export const ReportConfigurationRequest = intersection([ReportConfigurationCommon, type({ evaluationStart: date })]);

export const ReportConfigurationCreateableFields = ReportConfigurationRequest;

export const ReportConfigurationSingleResponse = intersection([
    ReportConfigurationCommon,
    type({
        reports: array(
            type({
                reportId: string,
                createdAt: DateFromISOString,
                updatedAt: DateFromISOString,
                generatedAt: union([DateFromISOString, nullType]),
                reportStatus: union([
                    literal(GenerationStatus.FAILED),
                    literal(GenerationStatus.GENERATED),
                    literal(GenerationStatus.PENDING),
                    literal(GenerationStatus.SCHEDULED),
                ]),
                emailNotificationStatus: union([
                    literal(EmailNotificationStatus.EMAIL_NOTIFICATION_SERVICE_ERROR),
                    literal(EmailNotificationStatus.EMAIL_SENT),
                    literal(EmailNotificationStatus.NO_EMAIL_NOTIFICATION),
                    literal(EmailNotificationStatus.BOUNCE_OR_COMPLAINT),
                    nullType,
                ]),
            })
        ),
    }),
    intersection([
        type({ id: string }),
        type({ evaluationStart: DateFromISOString }),
        type({ createdAt: DateFromISOString }),
    ]),
]);

export const ReportConfigurationResponse = type({
    items: array(ReportConfigurationSingleResponse),
});

export type ReportConfigurationResponseType = TypeOf<typeof ReportConfigurationResponse>;
export type ReportConfigurationSingleResponseType = TypeOf<typeof ReportConfigurationSingleResponse>;
export type ReportConfigurationRequestType = TypeOf<typeof ReportConfigurationRequest>;
export type EditReportConfigurationRequest = TypeOf<typeof ReportConfigurationEditableFields> & { id: string };
export type CreateReportConfigurationRequest = TypeOf<typeof ReportConfigurationCreateableFields>;
export type ReportConfigurationTargetsType = TypeOf<typeof ReportConfigurationTargets>;
