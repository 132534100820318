import { ReactNode } from 'react';
export const convertToRows = (columnsPerRow: number) => (data: ReactNode[]) =>
    data.reduce((acc: ReactNode[][], item, idx) => {
        if (idx % columnsPerRow === 0) {
            acc.push([]);
        }
        acc[acc.length - 1].push(item);
        return acc;
    }, []);

export default convertToRows;
