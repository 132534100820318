import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

const SectionTitle = ({ title, icon }: { title: string; icon: string }) => (
    <div className="display-flex align-items-center margin-bottom-10">
        <span className={cn(icon, 'rioglyph text-size-16 margin-right-10')} />
        <span className="line-height-16 text-medium">
            <FormattedMessage id={title} />
        </span>
    </div>
);

export default SectionTitle;
