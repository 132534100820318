import { getSignal } from '@api/helpers/transformer';
import SimpleErrorState from '@components/SimpleErrorState';
import usePerformData from '@data/hooks/usePerformData';
import { Loadable, LoadableType } from '@data/loadable';
import { EntityType } from '@features/foresightedDriving/types';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { getColumn } from '@utils/columns';
import toPercent from '@utils/toPercent';
import { Variables } from '@utils/useQuery';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { HydratedEntity, PerformSegmentBy, RawDriver } from '../../types';
import DriveProgramDetails from './DriveProgramDetails';
import { DRIVE_PROGRAM_REQUEST_ATTRIBUTES } from './queries';

const DRIVE_PROGRAM_NAMES = [
    'EfficiencyPlus',
    'Emergency',
    'Fluid',
    'HeavyDuty',
    'Manoeuver',
    'Offroad',
    'OffroadWithAssistance',
    'Performance',
];

const getDriveProgramInfo = (drivingProgram: string, allData: HydratedEntity) => {
    const name = drivingProgram.charAt(0).toLowerCase() + drivingProgram.slice(1);

    const timeColumn = getColumn(`driveProgram${drivingProgram}Time`);
    const time = timeColumn?.formatter(timeColumn?.valueExtractor(allData), allData);

    const distanceColumn = getColumn(`driveProgram${drivingProgram}Distance`);
    const distance = distanceColumn?.formatter(distanceColumn?.valueExtractor(allData), allData);

    const fullDistanceField =
        DRIVE_PROGRAM_NAMES.includes(drivingProgram) || drivingProgram === 'Efficiency'
            ? 'driveProgramActiveDistance'
            : 'driveProgramTotalDistance';

    const totalDistanceColumn = getColumn(fullDistanceField);
    const totalDistance = totalDistanceColumn?.valueExtractor(allData) as number;
    const distanceKm = (distanceColumn?.valueExtractor(allData) as number) || 0;

    const percentage = toPercent(distanceKm, totalDistance, 0);

    return { name, time, distance, distanceKm, percentage };
};

const success = (d: LoadableType<EntityType>) => <>{d}</>;
const failure = () => <SimpleErrorState fullWidth />;
const loading = () => <Spinner />;
const unknown = () => null;

export const DrivePrograms = ({
    data,
    filterUnused = true,
}: {
    data: LoadableType<HydratedEntity>;
    filterUnused?: boolean;
}) => {
    const mappedData = Loadable.map(data, entity => {
        const hasDriveProgramInfo =
            !_.isEmpty(entity) &&
            !_.isUndefined(getSignal(entity, 'driveProgramTotalDistance', undefined)) &&
            !_.isUndefined(getSignal(entity, 'driveProgramTotalTime', undefined));

        if (entity && hasDriveProgramInfo) {
            const active = getDriveProgramInfo('Active', entity);
            const manual = getDriveProgramInfo('Manual', entity);
            const efficiency = getDriveProgramInfo('Efficiency', entity);

            const drivePrograms = DRIVE_PROGRAM_NAMES.map(driveProgramId => getDriveProgramInfo(driveProgramId, entity))
                .filter(({ distanceKm }) => (filterUnused ? distanceKm !== 0 : true))
                .sort((a: { distanceKm: number }, b: { distanceKm: number }) => b.distanceKm - a.distanceKm);

            return (
                <div className="margin-bottom-25 padding-bottom-25">
                    <div className="border-style-solid border-width-1 border-color-light rounded padding-20">
                        <table className="table margin-bottom-0">
                            <colgroup>
                                <col className="width-40pct width-30pct-lg" />
                                <col />
                            </colgroup>
                            <tbody className="no-border">
                                <tr key="program-active">
                                    <td className="border-top-0 padding-top-0 padding-left-0">
                                        <div className="display-flex gap-10 align-items-center">
                                            <span className="text-medium text-size-18">
                                                <FormattedMessage id="drivingProgramsUsage" />
                                            </span>
                                            <span className="text-medium text-size-h3 text-color-highlight">
                                                {active.percentage}%
                                            </span>
                                        </div>
                                    </td>
                                    <td className="border-top-0 padding-top-0 padding-right-0">
                                        <DriveProgramDetails
                                            name="drivingPrograms"
                                            distance={active.distance}
                                            percentage={active.percentage}
                                            time={active.time}
                                            hasManualMode={true}
                                            manualModeDistance={manual.distance}
                                            manualModeTime={manual.time}
                                            progressBarColor="text-color-status-resting"
                                        />
                                    </td>
                                </tr>
                                <tr className="border border-top-only" key="program-efficiency">
                                    <td className="padding-top-10 padding-bottom-0" />
                                    <td className="padding-top-10 padding-bottom-0 padding-right-0">
                                        <DriveProgramDetails
                                            name="drivingPrograms.efficiency"
                                            distance={efficiency.distance}
                                            percentage={efficiency.percentage}
                                            time={efficiency.time}
                                        />
                                    </td>
                                </tr>
                                {drivePrograms.map(({ name, time, distance, percentage }) => {
                                    return (
                                        <tr className="border-top-only" key={`program-${name}`}>
                                            <td className="border-top-0 padding-bottom-0" />
                                            <td className="border-top-0 padding-bottom-0  padding-right-0">
                                                <DriveProgramDetails
                                                    name={`drivingPrograms.${name}`}
                                                    distance={distance}
                                                    percentage={percentage}
                                                    time={time}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        return <EmptyState headline={<FormattedMessage id="noData" />} fullWidth />;
    });

    return Loadable.cata(mappedData, success, failure, loading, unknown);
};

const isMatchingEntityForDriver = (driverId: string | null) => (entity: EntityType) =>
    (entity?.drivers?.length === 0 && driverId === null) ||
    (entity?.drivers?.length === 1 && driverId === (entity.drivers as RawDriver[])?.[0]?.driverId);

export const DriveProgramsByVehicle = ({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    filterUnused = true,
}: {
    vehicleIds?: string[];
    driverIds?: (string | null)[];
    start: Date;
    end: Date;
    filterUnused?: boolean;
}) => {
    const requestDataForOneDriver = driverIds.length === 1;
    const segmentBy = requestDataForOneDriver ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.vehicle;

    const drivingProgramsData = usePerformData(DRIVE_PROGRAM_REQUEST_ATTRIBUTES, {
        variables: { start, end, vehicleIds, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<EntityType[]>;

    const desiredData = Loadable.map(drivingProgramsData, entities =>
        requestDataForOneDriver ? entities.find(isMatchingEntityForDriver(driverIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return <DrivePrograms data={desiredData} filterUnused={filterUnused} />;
};

const isMatchingEntityForVehicle = (vehicleId: string) => (entity: EntityType) =>
    entity?.vehicles?.length === 1 && vehicleId === entity.vehicles[0].vehicleId;

export const DriveProgramsByDriver = ({
    vehicleIds = [],
    driverIds = [],
    start,
    end,
    allowedVehicles,
    filterUnused = true,
}: {
    vehicleIds?: string[];
    driverIds: string[];
    allowedVehicles: string[];
    start: Date;
    end: Date;
    filterUnused?: boolean;
}) => {
    const requestDataForOneVehicle = vehicleIds.length === 1;
    const segmentBy = requestDataForOneVehicle ? PerformSegmentBy.driver_and_vehicle : PerformSegmentBy.driver;

    const drivingProgramsData = usePerformData(DRIVE_PROGRAM_REQUEST_ATTRIBUTES, {
        variables: { start, end, driverIds, vehicleIds: allowedVehicles, segmentBy } as Variables,
        debounced: false,
    }) as LoadableType<EntityType[]>;

    const desiredData = Loadable.map(drivingProgramsData, entities =>
        requestDataForOneVehicle ? entities.find(isMatchingEntityForVehicle(vehicleIds[0])) : _.head(entities)
    ) as LoadableType<HydratedEntity>;

    return <DrivePrograms data={desiredData} filterUnused={filterUnused} />;
};
