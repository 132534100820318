import moment from 'moment';

import { DateRange } from '../types';

const inclusiveDateDiff = (start: Date, end: Date, unit: moment.unitOfTime.Diff) => moment(end).diff(start, unit) + 1;
export const dateRangeSegmentedByStep = ({
    dateRange: { start, end },
    step,
}: {
    dateRange: DateRange;
    step: moment.unitOfTime.Diff;
}) => {
    const endDateToBeConsidered =
        end.getHours() === 0 && end.getMinutes() === 0
            ? moment(end)
                  .subtract(1, 'minutes')
                  .toDate()
            : end;

    const difference = Math.abs(inclusiveDateDiff(start, endDateToBeConsidered, step));
    return Array(difference)
        .fill(moment(start).startOf(step))
        .map((rawDate, offset) =>
            moment(rawDate)
                .add(offset, step)
                .toDate()
        );
};
