import { getColumns } from '@utils/columns';
import _ from 'lodash';

import { Column } from '../../columns/createColumn';
import { tableColumns } from './columns';

const operatingStateColumns = [
    'vehicle',
    'drivers',
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
];
const operatingStateSummaryColumns = [
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
];

const overviewColumns = [
    'vehicle',
    'drivers',
    'tripStartEnd',
    'drivingConsumption',
    'idlingConsumption',
    'averageWeight',
    'mileage',
    'fuelEfficiency',
    'system',
];
const overviewSummaryColumns = [
    'drivingConsumption',
    'idlingConsumption',
    'averageWeight',
    'mileage',
    'fuelEfficiency',
];
const overviewDefaultHiddenColumns = ['system'];

const consumptionColumns = [
    'vehicle',
    'drivers',
    'operatingFuelConsumption',
    'fuelConsumption',
    'distanceFuelConsumption',
    'drivingConsumption',
    'idlingConsumption',
    'co2Emission',
];
const consumptionSummaryColumns = [
    'operatingFuelConsumption',
    'fuelConsumption',
    'distanceFuelConsumption',
    'drivingConsumption',
    'idlingConsumption',
    'co2Emission',
];

export type UseCaseConfig = {
    key: string;
    columns: Column[];
    summaryColumns: Column[];
    defaultHiddenColumns?: string[];
};

export const defaultUseCaseConfig: UseCaseConfig[] = [
    {
        key: 'overview',
        columns: getColumns(overviewColumns, tableColumns),
        summaryColumns: getColumns(overviewSummaryColumns),
        defaultHiddenColumns: overviewDefaultHiddenColumns,
    },
    {
        key: 'consumption',
        columns: getColumns(consumptionColumns, tableColumns),
        summaryColumns: getColumns(consumptionSummaryColumns),
    },
    {
        key: 'operating-condition',
        columns: getColumns(operatingStateColumns, tableColumns),
        summaryColumns: getColumns(operatingStateSummaryColumns),
    },
];

export const getUseCaseConfigForKey = (
    useCaseKey: string,
    useCaseConfig = defaultUseCaseConfig
): UseCaseConfig | undefined => _.find(useCaseConfig, ['key', useCaseKey]);
