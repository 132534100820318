import { Text, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

import styles from './styles';

export default function Databox({ title, children }: { title: ReactNode; children: ReactNode }) {
    return (
        <View style={styles.databoxContainer} wrap={false}>
            <View style={{ flexDirection: 'row' }}>
                <View style={styles.databoxTitleContainer}>
                    <Text style={styles.databoxTitle}>{title}</Text>
                </View>
            </View>
            {children}
        </View>
    );
}

export const WrappableDataboxContainer = ({
    children,
    withBorder = false,
}: {
    children: ReactNode;
    withBorder?: boolean;
}) => (
    <View style={[styles.databoxContainer, withBorder ? {} : { borderWidth: 0 }]} wrap={true}>
        {children}
    </View>
);
export const DataboxTitleContainer = ({ children }: { children: ReactNode }) => (
    <View style={{ flexDirection: 'row' }} fixed>
        <View style={styles.databoxTitleContainer}>
            <Text style={styles.databoxTitle}>{children}</Text>
        </View>
    </View>
);
export const DataboxChildren = ({ children }: { children: ReactNode }) => (
    <View style={{ flexDirection: 'row' }}>{children}</View>
);
