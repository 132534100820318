import { VehicleFuelType } from '@api/index';
import { createSlice } from '@reduxjs/toolkit';

import { TreeTabs } from '../../constants/treeTabs';
export type TreeState = {
    selectedVehicles: string[];
    selectedDrivers: string[];
    selectedVehicleGroups: string[];
    selectedDriverGroups: [];
    showEmptyGroups: boolean;
    selectedFuelTypes: VehicleFuelType[];
};

export const defaultSelectedFuelTypes = [
    VehicleFuelType.DIESEL,
    VehicleFuelType.ELECTRIC,
    VehicleFuelType.GAS,
    VehicleFuelType.HYDROGEN,
    VehicleFuelType.UNKNOWN,
];

export const defaultState: TreeState = {
    showEmptyGroups: false,
    selectedVehicles: [],
    selectedDrivers: [],
    selectedVehicleGroups: [],
    selectedDriverGroups: [],
    selectedFuelTypes: defaultSelectedFuelTypes,
};

const treeSlice = createSlice({
    name: 'tree',
    initialState: defaultState,
    reducers: {
        setShowEmptyGroups(state, { payload }) {
            return { ...state, showEmptyGroups: payload };
        },
        selectVehicleTab(state) {
            return { ...state, tab: TreeTabs.VEHICLES };
        },
        selectDriverTab(state) {
            return { ...state, tab: TreeTabs.DRIVER };
        },
        setSelectedDrivers(state, { payload }) {
            return { ...state, selectedDrivers: payload };
        },
        setSelectedVehicles(state, { payload }) {
            return { ...state, selectedVehicles: payload };
        },
        setSelectedFuelTypeGroups(state, { payload }) {
            return { ...state, selectedFuelTypes: payload };
        },
        setSelectedVehicleGroups(state, { payload }) {
            return { ...state, selectedVehicleGroups: payload };
        },
        setSelectedDriverGroups(state, { payload }) {
            return { ...state, selectedDriverGroups: payload };
        },
    },
});

export const {
    selectVehicleTab,
    selectDriverTab,
    setSelectedDrivers,
    setSelectedVehicles,
    setSelectedFuelTypeGroups,
    setSelectedVehicleGroups,
    setSelectedDriverGroups,
    setShowEmptyGroups,
} = treeSlice.actions;

export default treeSlice.reducer;
