import { VehicleFuelType } from '@api/models/types';
import { Bar } from '@components/graphComponents/Bar';
import XAxis from '@components/graphComponents/DatedXAxis';
import { Guidelines, ValueText } from '@components/graphComponents/Graph';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import { Overlay } from '@components/graphComponents/Overlay';
import { DataEntry } from '@components/graphComponents/types';
import { HOVER_Y_OFFSET } from '@components/graphComponents/utils';
import { generateTicksFromSeconds, YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import { DieselAndElectric, Formatter, Section, Stats } from '@components/summary/types';
import SegmentableDay from '@features/ui/SegmentableDay';
import SegmentableDayOverlay from '@features/ui/SegmentableDayOverlay';
import { HoverInfo } from '@pages/driverAnalysis/types';
import { VehicleGraphDimensions } from '@pages/vehicleAnalysis/vehicleDataGraph';
import colors from '@rio-cloud/rio-uikit/Colors';
import { getRatingCSSColor } from '@utils/rating';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { FormattedMessage, IntlFormatters } from 'react-intl';

import { SECONDS, ValidUnit } from '../../constants/units';
import { DateRange } from '../../types';
import { BARS_INFO, generateTicks } from './utils';

const SingleUpBarGraph = ({
    column,
    data,
    dateRange,
    dimensions,
    intl: { formatNumber = (value: number) => value.toString(), formatDate, formatMessage },
    fuelType,
    languageData,
    tickFormatter,
}: {
    column: Section;
    data: DataEntry[];
    dateRange: DateRange;
    dimensions: VehicleGraphDimensions;
    intl: IntlFormatters;
    fuelType: VehicleFuelType;
    languageData: Record<string, string>;
    tickFormatter: (dateRange: DateRange) => (d: Date) => string;
}) => {
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);

    let average: number | undefined,
        formatter: Formatter = (value: unknown) => <>{value}</>,
        unit: string,
        highlightColorName: string | undefined,
        highlightColorValue: string;
    if (fuelType === VehicleFuelType.DIESEL) {
        average = (column.stats as DieselAndElectric<Stats>).diesel?.average;
        formatter = (column.formatter as DieselAndElectric<Formatter>).diesel;
        unit = (column.unit as DieselAndElectric<string>).diesel;
        highlightColorName = BARS_INFO.ICE.colorName;
        highlightColorValue = BARS_INFO.ICE.colorValue;
    } else {
        average = (column.stats as DieselAndElectric<Stats>).electric?.average;
        formatter = (column.formatter as DieselAndElectric<Formatter>).electric;
        unit = (column.unit as DieselAndElectric<string>).electric;
        highlightColorName = undefined;
        highlightColorValue = BARS_INFO.BEV_UP.colorValue;
    }

    const hasDataForHoveredDate = _.get(hoverInfo, 'data.leftUpY', 0) > 0;
    const colorSchema = useMemo(() => {
        if (unit === ValidUnit.PERCENTAGE) {
            return (d: DataEntry) => getRatingCSSColor(d.leftUpY).hexColor;
        } else {
            return () => colors['color-highlight-dark'];
        }
    }, [unit]);

    return (
        <div
            className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
            style={{ height: dimensions.height }}
            data-test="vehicle-single-up-bar-TruE-graph"
        >
            {hoverInfo && (
                <Overlay
                    withDataClasses={
                        highlightColorName
                            ? `border-color-${highlightColorName} text-color-${highlightColorName} bg-white`
                            : undefined
                    }
                    hasData={hasDataForHoveredDate}
                    maxXValue={dimensions.width}
                    x={hoverInfo?.leftX}
                    y={
                        hasDataForHoveredDate
                            ? hoverInfo.leftUpY +
                              (hoverInfo.leftUpY >= dimensions.height - dimensions.margin * 4
                                  ? -HOVER_Y_OFFSET
                                  : HOVER_Y_OFFSET)
                            : HOVER_Y_OFFSET
                    }
                    useCustomStyleColors={!highlightColorName}
                    style={
                        !highlightColorName
                            ? {
                                  borderColor: highlightColorValue,
                                  color: highlightColorValue,
                                  backgroundColor: '#fff',
                              }
                            : {}
                    }
                >
                    {hasDataForHoveredDate ? formatter(hoverInfo.data.leftUpY) : <FormattedMessage id="noData" />}
                </Overlay>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                dimensions={dimensions}
                data={data}
                yScaleShouldStartAtZero
                shouldRoundYScales
            >
                <SegmentableDayOverlay
                    leftYScaleFormatter={formatter}
                    useCustomStyleColors={!highlightColorName}
                    leftUpYColor={highlightColorName || highlightColorValue}
                />
                {!_.isUndefined(average) && (
                    <ValueText
                        show={Boolean(hoverInfo)}
                        value={average}
                        formatter={formatter}
                        className="text-bold"
                        label={<FormattedMessage id="average" />}
                        style={{ color: highlightColorValue }}
                        useCustomTextColor
                    />
                )}
                <svg
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <Guidelines average={average} color={highlightColorValue} show={Boolean(hoverInfo)} />
                    <YAxisLines tickGenerator={unit === SECONDS ? generateTicksFromSeconds : generateTicks} />
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                    />
                    {unit !== ValidUnit.PERCENTAGE && <Bar colorSchema={() => highlightColorValue} />}
                    {unit === ValidUnit.PERCENTAGE && <Bar colorSchema={colorSchema} minHeight={1} />}
                    <YAxisTicks
                        tickGenerator={unit === SECONDS ? generateTicksFromSeconds : generateTicks}
                        formatter={formatNumber}
                        units={unit ? `(${_.get(languageData, `unit.${unit}`)})` : undefined}
                        unitsColor={highlightColorValue}
                    />
                    <SegmentableDay onHover={setHover} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default SingleUpBarGraph;
