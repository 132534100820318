import { BarName } from '@components/commonGraphComponents/utils';
import colors from '@rio-cloud/rio-uikit/Colors';
import { select } from 'd3-selection';
import { useContext, useEffect, useRef } from 'react';

import { GraphContext } from './GraphDataProvider';

const BAR_LEGEND_WIDTH = 175;
const BAR_LEGEND_SQUARE_SIZE = 15;

export function BarsLegend({
    barsInfo,
    dataTest,
}: {
    barsInfo: { [key in BarName]?: { label: string; color: string } };
    dataTest?: string;
}): JSX.Element {
    const { dimensions } = useContext(GraphContext);
    const ref = useRef(null);

    const barsLabels = Object.keys(barsInfo);

    useEffect(() => {
        if (ref && ref.current) {
            const legendGroup = select(ref.current);

            const legend = legendGroup
                .selectAll('.legend')
                .data(barsLabels)
                .enter()
                .append('g')
                .attr(
                    'transform',
                    (d: string, i: number) =>
                        `translate(
                            ${dimensions.legendLeftMargin + i * BAR_LEGEND_WIDTH}, 
                            ${dimensions.heightWithoutMargin + dimensions.legendTopMargin}
                        )`
                )
                .attr('class', 'legend');

            legend
                .append('rect')
                .attr('width', BAR_LEGEND_SQUARE_SIZE)
                .attr('height', BAR_LEGEND_SQUARE_SIZE)
                .attr('fill', (d: string) => barsInfo[d as BarName]?.color || '');

            legend
                .append('text')
                .attr('x', 20)
                .attr('y', 11.5)
                .attr('fill', colors['gray'])
                .attr('font-size', 12)
                .text((d: string) => barsInfo[d as BarName]?.label || '');
        }
    }, [barsInfo, barsLabels, dimensions, dimensions.height, dimensions.margin]);

    return <g ref={ref} data-test={dataTest} />;
}
