import { calculateColumnOrder } from '@utils/sorting';
import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Column } from '../../../columns/createColumn';
import { useAppDispatch } from '../../../setup/store';
import { setColumnOrder, setFilteredColumnNames, UseCaseKey, UseCaseSettingsType } from '../driverAnalysisReducer';
import { getUseCaseSettings } from '../driverAnalysisSelectors';
import { UseCaseConfig } from '../useCaseConfig';

const findColumnByKey = (columns: Column[], key: string): Column | undefined =>
    columns.find(column => column.key === key);

const transformColumnStringsToObjects = (columnOrder: string[], filteredColumnNames: string[], columns: Column[]) => {
    const columnKeys = _.map(columns, 'key');
    const columnKeysInRightOrder = calculateColumnOrder(columnKeys, columnOrder)
        .map(key => findColumnByKey(columns, key))
        .filter((col): col is Column => Boolean(col));
    return {
        columns: columnKeysInRightOrder,
        selectedColumns: columnKeysInRightOrder.filter(column => !filteredColumnNames.includes(column.key)),
        hiddenColumns: columnKeysInRightOrder.filter(column => filteredColumnNames.includes(column.key)),
    };
};

export default function useDriverTableColumns(useCase?: UseCaseConfig) {
    const dispatch = useAppDispatch();
    const useCaseSettings = useSelector(getUseCaseSettings) as UseCaseSettingsType;
    const { columnOrder, filteredColumnNames } = useCaseSettings;

    const { selectedColumns, hiddenColumns, columns } = useMemo(() => {
        const columns = useCase?.columns || [];
        return transformColumnStringsToObjects(columnOrder, filteredColumnNames, columns);
    }, [columnOrder, useCase, filteredColumnNames]);

    return {
        setColumnOrder: (useCaseKey: UseCaseKey, columnOrder: string[]) => {
            dispatch(setColumnOrder({ useCaseKey, columnOrder }));
        },
        setFilteredColumnNames: (useCaseKey: UseCaseKey, filteredColumns: string[]) => {
            dispatch(setFilteredColumnNames({ useCaseKey, filteredColumnNames: filteredColumns }));
        },
        selectedColumns: selectedColumns,
        hiddenColumns,
        columnOrder: {
            default: useCase?.columns || [],
            sorted: columns,
        },
    };
}
