import { getSignal } from '@api/index';
import WithUnit from '@components/WithUnit';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { formatSecondsToTime } from '@utils/stringFormatters';
import toPercent from '@utils/toPercent';
import _ from 'lodash';
import { Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { ValidUnit } from '../../../constants/units';
import { HydratedEntity } from '../../../types';
import DisplayWarningConditionally from '../components/DisplayOpconWarningConditionally';
import { FONT_SIZES } from './styles';

const styles = StyleSheet.create({
    title: {
        fontSize: FONT_SIZES.SUB_TITLE,
    },
    trafficDataItem: {
        padding: 10,
        paddingLeft: 20,
    },
});

export default function TrafficData({ data }: { data: HydratedEntity }) {
    const opconData = getSignal<Record<string, unknown>>(data, 'operatingConditionResponse', {});
    const traffic = _.get(opconData, 'traffic.value', {}) as Record<string, unknown>;
    const evaluatedMileage = getSignal(traffic, 'evaluatedMileage', 0);
    const mileageFromPerformance = getSignal(data, 'mileage', 0);

    const trafficJam = (traffic.traffic_jam as number) || 0;
    const slowMoving = (traffic.slow_moving as number) || 0;
    const freeFlow = (traffic.free_flow as number) || 0;
    const totalMileage = freeFlow + slowMoving + trafficJam;

    const jamPercent = toPercent(trafficJam, totalMileage);
    const slowPercent = toPercent(slowMoving, totalMileage);
    const trafficPercent = 100 - slowPercent - jamPercent;

    let jamTime = 0;
    if (traffic.traffic_jam_details !== undefined) {
        jamTime = (traffic.traffic_jam_details as Record<string, number>).lost_time;
    }
    let slowTime = 0;
    if (traffic.slow_moving_details !== undefined) {
        slowTime = (traffic.slow_moving_details as Record<string, number>).lost_time;
    }
    const totalTimeLost = formatSecondsToTime(jamTime + slowTime);

    return (
        <DisplayWarningConditionally evaluatedMileage={evaluatedMileage} totalMileage={mileageFromPerformance}>
            <View style={{ flexDirection: 'row' }} data-test="Table">
                <TrafficDataItem messageId={'traffic_jam'} percentage={jamPercent} distance={trafficJam} />
                <TrafficDataItem messageId={'slow_moving_traffic'} percentage={slowPercent} distance={slowMoving} />
                <TrafficDataItem messageId={'normal_traffic'} percentage={trafficPercent} distance={freeFlow} />
                <View style={styles.trafficDataItem} data-test="timeLost">
                    <Text style={styles.title}>
                        <FormattedMessage id={'timeLostInTraffic'} />
                    </Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontFamily: 'Bold' }}>
                            <WithUnit unit={ValidUnit.TIME}>{totalTimeLost}</WithUnit>
                        </Text>
                    </View>
                </View>
                <View style={styles.trafficDataItem} data-test="evaluatedDistance">
                    <Text style={styles.title}>
                        <FormattedMessage id={'evaluatedDistance'} />
                    </Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontFamily: 'Bold' }}>
                            <WithUnit unit={ValidUnit.KILOMETERS}>
                                <FormattedNumber value={trafficJam + slowMoving + freeFlow} maximumFractionDigits={1} />
                            </WithUnit>
                        </Text>
                    </View>
                </View>
            </View>
        </DisplayWarningConditionally>
    );
}

const isInSmallestBucket = (value: number) => value < 1 && value > 0;

export const TrafficDataItem = ({
    messageId,
    percentage,
    distance,
}: {
    messageId: string;
    percentage: number;
    distance: number;
}) => {
    const needsEqualSign = !isInSmallestBucket(distance);
    const formattedMileage = isInSmallestBucket(distance) ? (
        <Fragment>{'<'} 1</Fragment>
    ) : (
        <Fragment>
            <FormattedNumber value={distance} maximumFractionDigits={1} />
        </Fragment>
    );

    const formattedPercentage = isInSmallestBucket(percentage) ? (
        <Fragment>{'<'} 1</Fragment>
    ) : (
        <FormattedNumber value={percentage} maximumFractionDigits={0} />
    );

    return (
        <View style={styles.trafficDataItem} data-test={messageId}>
            <Text style={styles.title}>
                <FormattedMessage id={messageId} />
            </Text>
            <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontFamily: 'Bold' }}>
                    <WithUnit unit={ValidUnit.PERCENTAGE}>{formattedPercentage}</WithUnit>
                    {needsEqualSign ? ' =' : ''}
                </Text>
                <Text style={{ fontFamily: 'Bold' }}>
                    <WithUnit unit={ValidUnit.KILOMETERS}>{formattedMileage}</WithUnit>
                </Text>
            </View>
        </View>
    );
};
