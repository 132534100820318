import { Store } from '@reduxjs/toolkit';
import getOr from 'lodash/fp/getOr';

import { authorizeFetch } from '../../fetch';
import { configureReporting } from '../../setup/errorReporting';
import { changeLocale, languageDataFetched } from './actions';
import { getSupportedLocale as defaultGetSupportedLocale } from './selectors';

const { captureException } = configureReporting(window, import.meta.env);

const MODULE_NAME = `web-performance-v2`;

const sendError = (exception: Error) => {
    captureException(exception, {
        tags: {
            module: MODULE_NAME,
        },
    });
};

// Webpack is weird sometimes, maybe it's Babel, who knows...
const normalizeDynamicImport = (imported: { [key: string]: unknown }) => getOr(imported, 'default', imported);

export const configureFetchPreferredLanguage = (storage: Storage) => (
    userSettingsBackend: string,
    fetch = window.fetch
) => {
    const get = authorizeFetch(storage, fetch);
    return get(`${userSettingsBackend}/user/settings/language`)
        .then((response: Response) => {
            // eslint-disable-next-line no-magic-numbers
            if (!response.ok || response.status === 401) {
                throw new Error(`Fetching user language failed.`);
            }
            return response.json();
        })
        .catch((error: Error) => {
            sendError(error);
            return error;
        });
};

const getMomentLocaleData = (locale: string) => {
    const sanitizedLocale = locale.toLowerCase().replace('_', '-');
    // We try to use the more "precise" locale first, for those where it matters, like 'pt-BR'
    // otherwise just use the first part like 'de'
    if (sanitizedLocale in momentLocaleImports) {
        momentLocaleImports[sanitizedLocale as keyof typeof momentLocaleImports]().catch(captureException);
    } else if (sanitizedLocale.split('-')[0] in momentLocaleImports) {
        momentLocaleImports[sanitizedLocale.split('-')[0] as keyof typeof momentLocaleImports]().catch(
            captureException
        );
    }
};

const getLanguageData = (locale: string) =>
    import(/* webpackChunkName: "[request]" */ `./translations/performanceAnalysis_${locale}.json`)
        .then(normalizeDynamicImport)
        .catch(error => {
            sendError(error);
            return error;
        });

export const configureFetchLanguageData = (
    store: Store,
    fetchLanguageData = getLanguageData,
    fetchMomentLocaleData = getMomentLocaleData,
    getSupportedLocale = defaultGetSupportedLocale
) => (locale?: string) => {
    if (!locale) {
        return Promise.reject(new Error('no locale'));
    }

    store.dispatch(changeLocale(locale));
    const supportedLocale = getSupportedLocale(store.getState());
    // We ignore the promise here, either it loads or we default to english.
    fetchMomentLocaleData(locale);
    return fetchLanguageData(supportedLocale)
        .then(languageData => {
            store.dispatch(languageDataFetched({ locale: supportedLocale, languageData }));
        })
        .catch(error => {
            sendError(error);
            return error;
        });
};

const momentLocaleImports = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    de: () => import('moment/dist/locale/de'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    en: () => import('moment/dist/locale/en-gb'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    'en-GB': () => import('moment/dist/locale/en-gb'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    bg: () => import('moment/dist/locale/bg'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    cs: () => import('moment/dist/locale/cs'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    da: () => import('moment/dist/locale/da'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    el: () => import('moment/dist/locale/el'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    es: () => import('moment/dist/locale/es'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    et: () => import('moment/dist/locale/et'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    fi: () => import('moment/dist/locale/fi'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    fr: () => import('moment/dist/locale/fr'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    hr: () => import('moment/dist/locale/hr'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    hu: () => import('moment/dist/locale/hu'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    it: () => import('moment/dist/locale/it'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    ko: () => import('moment/dist/locale/ko'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    lt: () => import('moment/dist/locale/lt'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    lv: () => import('moment/dist/locale/lv'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    nb: () => import('moment/dist/locale/nb'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    nl: () => import('moment/dist/locale/nl'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    pl: () => import('moment/dist/locale/pl'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    pt: () => import('moment/dist/locale/pt'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    'pt-BR': () => import('moment/dist/locale/pt-br'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    ro: () => import('moment/dist/locale/ro'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    sk: () => import('moment/dist/locale/sk'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    sl: () => import('moment/dist/locale/sl'),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS2307
    sv: () => import('moment/dist/locale/sv'),
};
