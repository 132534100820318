import _ from 'lodash';
import { Column } from 'src/columns/createColumn';
import { SignalValue } from 'src/types';

import { consumptionSignal, electricConsumptionSignal } from '../columns/consumption';
import { distanceSignal } from '../columns/distance';
import { timeSignal } from '../columns/time';
import { weightSignalInKg } from '../columns/weight';
import { DieselAndElectric, Formatter, Section, Stats } from '../components/summary/types';

const summedUnits = [
    consumptionSignal.unit,
    electricConsumptionSignal.unit,
    distanceSignal.unit,
    timeSignal.unit,
    weightSignalInKg.unit,
];

export const getSummaryValue = (
    section: Section | Column,
    stats: { values: { value: SignalValue; date: Date }[]; average?: number }
) =>
    (section.formatter as Formatter)(
        _.includes(summedUnits, section.unit) ? _.sumBy(stats.values, 'value.value') : stats.average
    );

export const getSummaryDieselAndElectricValues = ({
    diesel: { stats: dieselStats, unit: dieselUnit, formatter: dieselFormatter },
    electric: { stats: electricStats, unit: electricUnit, formatter: electricFormatter },
    shouldShowBothValues,
}: DieselAndElectric<{
    stats: Stats;
    unit: string | undefined;
    formatter: Formatter | undefined;
}> & { shouldShowBothValues: boolean }) => {
    const hasDieselValue = !!dieselStats.values && !!dieselFormatter;
    const hasElectricValue = !!electricStats.values && !!electricFormatter;

    if (shouldShowBothValues && (hasDieselValue || hasElectricValue)) {
        return (
            <div className="display-flex flex-column">
                <div>
                    <span className="rioglyph rioglyph-filling-station margin-right-5" />
                    {dieselFormatter &&
                        dieselFormatter(
                            _.includes(summedUnits, dieselUnit)
                                ? _.sumBy(dieselStats.values, 'value.value')
                                : dieselStats.average
                        )}
                </div>
                <span>
                    <span className="rioglyph rioglyph-flash margin-right-5" />
                    {electricFormatter &&
                        electricFormatter(
                            _.includes(summedUnits, electricUnit)
                                ? _.sumBy(electricStats.values, 'value.value')
                                : electricStats.average
                        )}
                </span>
            </div>
        );
    } else if (!shouldShowBothValues && hasDieselValue && !hasElectricValue) {
        return dieselFormatter(
            _.includes(summedUnits, dieselUnit) ? _.sumBy(dieselStats.values, 'value.value') : dieselStats.average
        );
    } else if (!shouldShowBothValues && !hasDieselValue && hasElectricValue) {
        return electricFormatter(
            _.includes(summedUnits, electricUnit) ? _.sumBy(electricStats.values, 'value.value') : electricStats.average
        );
    }

    return '-';
};
