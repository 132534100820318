import { createId, createSignal, getSignal } from '@api/index';
import { getLanguageData, getLocale } from '@common/lang/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import { requestOpConData, requestPerformanceData } from '@data/actions';
import { Loadable } from '@data/loadable';
import { backendConfig, entitiesForRequest } from '@data/selectors';
import { pdf as generatePdf } from '@react-pdf/renderer';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';

import { configureReporting } from '../../../setup/errorReporting';
import { State } from '../../../setup/types';
import { DateRange, HydratedEntity, OpconQueryPayload, PerformQueryPayload, Signal } from '../../../types';
import { enablePerform3, includeCruiseControlInRating, savedCalculationParams } from '../../settings/reducer';
import standardFileName from '../fileName';
import Pdf from './Pdf';
const { captureException } = configureReporting(window, import.meta.env);

const mergeResponses = (
    entity: HydratedEntity,
    performanceResponses: HydratedEntity[],
    opConResponses: HydratedEntity[],
    operationalDays: { signals: { dayCountWithMovement: Signal<number> } }
) => {
    const performance = _.head(performanceResponses);
    const opconData = opConResponses.length ? opConResponses[0].signals : {};
    const opcon = {
        signals: { operatingConditionResponse: createSignal(opconData) },
    };

    return _.merge({}, entity, performance, opcon, operationalDays);
};

export default function usePdfGeneration({
    entity,
    dateRange,
    performanceQuery,
    operationalDaysQuery,
    opConQuery,
    identifier,
    fileSuffix,
    isDriver = false,
    shouldShowFuelType = false,
    isTruEEnabled,
}: {
    entity: HydratedEntity;
    dateRange: DateRange;
    performanceQuery: PerformQueryPayload;
    operationalDaysQuery: PerformQueryPayload;
    opConQuery?: OpconQueryPayload;
    identifier: string;
    fileSuffix: string;
    isDriver?: boolean;
    shouldShowFuelType?: boolean;
    isTruEEnabled?: boolean;
}) {
    //TODO: rethink this; one variable with 3 states could be nicer.
    const [isPendingDownload, setIsPendingDownload] = useState(false);
    const [requestedDownload, setRequestedDownload] = useState(false);

    const store = useStore();
    const state = store.getState();

    const locale: string = useSelector(getLocale);
    const languageData = useSelector(getLanguageData);
    const { printOnLambda, showOvertakeInCruise: shouldShowOvertakeInCruise } = useSelector(
        getFeatureToggles
    ) as Record<string, boolean>;
    const pdfServiceUrl = backendConfig(state, 'PDF_SERVICE');
    const totalRatingWithCC = useSelector(includeCruiseControlInRating);
    const isPerform3Enabled = useSelector(enablePerform3);

    const $performanceQuery = useSelector((state: State) => entitiesForRequest(state, createId(performanceQuery)));
    const $operationalDays = useSelector((state: State) => entitiesForRequest(state, createId(operationalDaysQuery)));
    const $operationCondition = useSelector((state: State) => entitiesForRequest(state, createId(opConQuery)));
    const calculationParameters = savedCalculationParams(state);
    const $entity = Loadable.createDone(entity);

    const $operationalDaysWithoutInvalidDates = Loadable.map($operationalDays, data => ({
        signals: {
            dayCountWithMovement: createSignal(
                data.filter(d => moment(getSignal(d, 'start', undefined), moment.ISO_8601).isValid()).length
            ),
        },
    }));

    // transform the different api responses into one object.
    const $data = Loadable.combine(
        mergeResponses,
        $entity,
        $performanceQuery,
        $operationCondition,
        $operationalDaysWithoutInvalidDates
    );

    useEffect(() => {
        if (!isPendingDownload || requestedDownload) {
            return;
        }
        Loadable.cata(
            $data,
            data => {
                const props = {
                    data,
                    dateRange: { start: moment(dateRange.start).toDate(), end: moment(dateRange.end).toDate() },
                    locale,
                    languageData,
                    settings: {
                        totalRatingWithCC,
                        isDriver,
                        shouldShowOvertakeInCruise,
                        calculationParameters,
                        isPerform3Enabled,
                        shouldShowFuelType,
                        isTruEEnabled,
                    },
                };
                setRequestedDownload(true);

                // feature toggled
                if (printOnLambda) {
                    const headers = {
                        'Content-Type': 'application/json',
                        Accept: 'application/pdf',
                        // Authorization: `Bearer ${authToken}`,
                    };

                    const requestObject = {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(props),
                    };

                    //TODO: SET PDF EXPORT
                    fetch(`${pdfServiceUrl}/detail`, requestObject)
                        .then(async response => {
                            const blob = await response.blob();
                            FileSaver.saveAs(
                                blob,
                                `${standardFileName({ dateRange, value: identifier, suffix: fileSuffix })}.pdf`
                            );
                        })
                        .catch(captureException)
                        .finally(() => {
                            setIsPendingDownload(false);
                            setRequestedDownload(false);
                        });
                } else {
                    generatePdf(Pdf(props))
                        .toBlob()
                        .then(blob => {
                            FileSaver.saveAs(
                                blob,
                                `${standardFileName({ dateRange, value: identifier, suffix: fileSuffix })}.pdf`
                            );
                        })
                        .catch(captureException)
                        .finally(() => {
                            setIsPendingDownload(false);
                            setRequestedDownload(false);
                        });
                }
            },
            () => Promise.reject(),
            () => Promise.resolve(),
            () => Promise.resolve()
        );
    }, [
        $data,
        dateRange,
        identifier,
        isDriver,
        isPendingDownload,
        languageData,
        locale,
        requestedDownload,
        totalRatingWithCC,
        printOnLambda,
        pdfServiceUrl,
        fileSuffix,
        shouldShowOvertakeInCruise,
        calculationParameters,
        shouldShowFuelType,
        isTruEEnabled,
    ]);

    const triggerDownload = () => {
        setIsPendingDownload(true);
        store.dispatch(requestPerformanceData(performanceQuery));
        store.dispatch(requestPerformanceData(operationalDaysQuery));
        if (opConQuery) {
            store.dispatch(requestOpConData(opConQuery));
        }
    };

    // triggerDownload: tries to fetch the missing information, and then starts the download workflow
    // hasPendingDownload: can be used to inform the UI of a loading state.
    return { triggerDownload, hasPendingDownload: requestedDownload || isPendingDownload };
}
