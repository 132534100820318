import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { FormattedMessage } from 'react-intl';

export default function MultipleVehiclesSelectedState({ messageKey }: { messageKey: string }) {
    return (
        <CustomState
            headline={<FormattedMessage id="vehspec.multipleVehiclesSelected" />}
            message={<FormattedMessage id={messageKey} />}
            data-test="multiple-vehicles-state"
            icons={[
                {
                    name: 'rioglyph-van',
                    className: 'text-size-200pct',
                },
                {
                    name: 'rioglyph-truck',
                    className: 'text-size-300pct',
                },
                {
                    name: 'rioglyph-bus',
                    className: 'text-size-200pct',
                },
            ]}
            fullWidth
        />
    );
}
