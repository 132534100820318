import { BarsLegend } from '@components/graphComponents/BarsLegend';
import XAxis from '@components/graphComponents/DatedXAxis';
import GraphDataProvider, { GraphContext } from '@components/graphComponents/GraphDataProvider';
import { GroupedBars } from '@components/graphComponents/GroupedBars';
import { Alignments, Overlay } from '@components/graphComponents/Overlay';
import { DataEntry, GraphType, YAxis } from '@components/graphComponents/types';
import { HOVER_INFO_HEIGHT, HOVER_Y_OFFSET } from '@components/graphComponents/utils';
import { generateTicksFromSeconds, YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import { DieselAndElectric, Formatter, Section } from '@components/summary/types';
import SegmentableDay from '@features/ui/SegmentableDay';
import SegmentableDayOverlay from '@features/ui/SegmentableDayOverlay';
import { HoverInfo } from '@pages/driverAnalysis/types';
import { VehicleGraphDimensions } from '@pages/vehicleAnalysis/vehicleDataGraph';
import { formatSecondsToTime } from '@utils/stringFormatters';
import _ from 'lodash';
import { useState } from 'react';
import { FormattedMessage, IntlFormatters } from 'react-intl';

import { SECONDS } from '../../constants/units';
import { DateRange } from '../../types';
import { BARS_INFO, generateTicks } from './utils';

const dieselAndElectricBarsSchema = (scaleName: YAxis) => {
    switch (scaleName) {
        case 'leftUpY':
            return BARS_INFO.ICE.colorValue;
        case 'rightUpY':
            return BARS_INFO.BEV_UP.colorValue;
        case 'rightDownY':
            return BARS_INFO.BEV_DOWN.colorValue;
        default:
            return '';
    }
};

const TwoUpBarsGraph = ({
    column,
    data,
    dateRange,
    dimensions,
    intl: { formatNumber = (value: number) => value.toString(), formatDate, formatMessage },
    languageData,
    tickFormatter,
}: {
    column: Section;
    data: DataEntry[];
    dateRange: DateRange;
    dimensions: VehicleGraphDimensions;
    intl: Omit<IntlFormatters, 'formatNumber'> & { formatNumber: (value: number) => string };
    languageData: Record<string, string>;
    tickFormatter: (dateRange: DateRange) => (d: Date) => string;
}) => {
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);
    const [hoverInfoXOffset, setHoverInfoXOffset] = useState<number>(0);

    const leftYScaleFormatter = (column.formatter as DieselAndElectric<Formatter>).diesel;
    const leftYScaleUnit = (column.unit as DieselAndElectric<string>).diesel;

    const rightYScaleFormatter = (column.formatter as DieselAndElectric<Formatter>).electric;

    const hasLeftBarDataForHoveredDate = _.get(hoverInfo, 'data.leftUpY', 0) > 0;
    const hasRightBarDataForHoveredDate = _.get(hoverInfo, 'data.rightUpY', 0) > 0;
    const hasNoDataForHoveredDate = !hasLeftBarDataForHoveredDate && !hasRightBarDataForHoveredDate;
    const hasSomeDataForHoveredDate = hasLeftBarDataForHoveredDate || hasRightBarDataForHoveredDate;

    const twoUpBarsInfo = {
        ICE: { label: _.get(languageData, 'vehicleFuelType.fuel'), color: BARS_INFO.ICE.colorValue },
        BEV_UP: { label: _.get(languageData, 'vehicleFuelType.electric'), color: BARS_INFO.BEV_UP.colorValue },
    };

    return (
        <div
            className="graph bg-white margin-bottom-1 position-relative overflow-hidden"
            style={{ height: dimensions.height + dimensions.legendHeight }}
            data-test="vehicle-two-up-bars-TruE-graph"
        >
            {hoverInfo && (
                <>
                    {hasNoDataForHoveredDate && (
                        <Overlay hasData x={hoverInfo.leftX + hoverInfoXOffset} y={HOVER_Y_OFFSET}>
                            <FormattedMessage id="noData" />
                        </Overlay>
                    )}
                    {hasSomeDataForHoveredDate && (
                        <>
                            {hasLeftBarDataForHoveredDate && (
                                <Overlay
                                    withDataClasses={`border-color-${BARS_INFO.ICE.colorName} text-color-${BARS_INFO.ICE.colorName} bg-white`}
                                    hasData
                                    maxXValue={dimensions.width}
                                    x={hoverInfo.leftX}
                                    y={
                                        hoverInfo.leftUpY + HOVER_INFO_HEIGHT > dimensions.height - dimensions.margin
                                            ? dimensions.height - dimensions.margin - HOVER_INFO_HEIGHT
                                            : hoverInfo.leftUpY
                                    }
                                    alignment={Alignments.RIGHT}
                                >
                                    {leftYScaleFormatter(hoverInfo.data.leftUpY)}
                                </Overlay>
                            )}
                            {hasRightBarDataForHoveredDate && (
                                <Overlay
                                    hasData
                                    maxXValue={dimensions.width}
                                    x={hoverInfo.rightX}
                                    y={
                                        hoverInfo.rightUpY + HOVER_INFO_HEIGHT > dimensions.height - dimensions.margin
                                            ? dimensions.height - dimensions.margin - HOVER_INFO_HEIGHT
                                            : hoverInfo.rightUpY
                                    }
                                    useCustomStyleColors
                                    style={{
                                        borderColor: BARS_INFO.BEV_UP.colorValue,
                                        color: BARS_INFO.BEV_UP.colorValue,
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {rightYScaleFormatter(hoverInfo.data.rightUpY)}
                                </Overlay>
                            )}
                        </>
                    )}
                </>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                dimensions={dimensions}
                data={data}
                hoverInfoXOffset={hoverInfoXOffset}
                setHoverInfoXOffset={setHoverInfoXOffset}
                yScaleShouldStartAtZero
                graphType={GraphType.TWO_UP_BARS}
                shouldRoundYScales
            >
                <SegmentableDayOverlay
                    leftUpYAlignment={Alignments.RIGHT}
                    leftUpYColor={BARS_INFO.ICE.colorName}
                    leftYScaleFormatter={leftYScaleFormatter}
                    rightUpYColor={BARS_INFO.BEV_UP.colorValue}
                    rightYScaleFormatter={rightYScaleFormatter}
                    shouldShowRightUpYValue
                    useOuterXPosition
                    xOffset={hoverInfoXOffset}
                />
                <svg
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                        shouldHideTickLine
                    />
                    <YAxisLines tickGenerator={leftYScaleUnit === SECONDS ? generateTicksFromSeconds : generateTicks} />
                    <YAxisTicks
                        formatter={leftYScaleUnit === SECONDS ? formatSecondsToTime : formatNumber}
                        tickGenerator={leftYScaleUnit === SECONDS ? generateTicksFromSeconds : generateTicks}
                        units={leftYScaleUnit ? `(${_.get(languageData, `unit.${leftYScaleUnit}`)})` : undefined}
                        unitsColor={BARS_INFO.ICE.colorValue}
                    />
                    <GraphContext.Consumer>
                        {({ setHoverInfoXOffset }) => (
                            <GroupedBars
                                colorSchema={dieselAndElectricBarsSchema}
                                minHeight={0}
                                setHoverInfoXOffset={setHoverInfoXOffset}
                            />
                        )}
                    </GraphContext.Consumer>
                    <SegmentableDay onHover={setHover} useOuterXPosition xOffset={hoverInfoXOffset} />
                    <BarsLegend barsInfo={twoUpBarsInfo} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default TwoUpBarsGraph;
