import { getSignal, Vehicle } from '@api/index';
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HydratedEntity, HydratedEntityWithChildren, VehicleType } from '../../types';

export function getBusOrVanWarning(row: HydratedEntityWithChildren | HydratedEntity): JSX.Element | null {
    const vehicles = getSignal<Vehicle[]>(row, 'vehicles', []);
    const busesOrVans = vehicles
        .filter(vehicle => [VehicleType.BUS, VehicleType.VAN].includes(vehicle.type))
        .map(vehicle => vehicle.name);

    return !_.isEmpty(busesOrVans) ? (
        <BusOrVanWarning key="busOrVanInformation" busOrVanVehicleNames={busesOrVans} />
    ) : null;
}

function BusOrVanWarning({ busOrVanVehicleNames }: { busOrVanVehicleNames: string[] }): JSX.Element {
    return (
        <React.Fragment>
            <dt>
                <FormattedMessage id="busOrVanInformation" />
            </dt>
            <dd>
                <FormattedMessage
                    id="busOrVanInformationDetail"
                    values={{ vehicles: busOrVanVehicleNames.join(', ') }}
                />
            </dd>
        </React.Fragment>
    );
}

export default BusOrVanWarning;
