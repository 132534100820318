import { GenerationStatus, ReportConfiguration } from '@api/index';
import { getActiveDriverList, getActiveVehicleList, getGroupList } from '@features/tree/uiSelectors';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { REPORT_ROUTE } from '../../../constants/routes';
import { useAppDispatch } from '../../../setup/store';
import { Vehicle } from '../../../types';
import DeleteConfiguration from '../DeleteConfiguration';
import Switch from '../forms/components/Switch';
import Form from '../forms/Form';
import { handlePatch, handleResponseApi, selectTree } from '../forms/handleFormData';
import { editReportConfiguration } from '../reducer';
import { ReportForm } from '../types';

const EditingForm = ({ report: reportConfig, onClose }: { report: ReportConfiguration; onClose: () => void }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const vehicles = useSelector(getActiveVehicleList) as Vehicle[];
    const groups = useSelector(getGroupList);
    const groupIds = groups.map(group => group.id);
    const drivers = useSelector(getActiveDriverList);

    const { selectedVehicles, selectedGroups, selectedDrivers } = selectTree(reportConfig, vehicles, groups, drivers);

    const history = useHistory();
    const formApi = useForm<ReportForm>();
    const [isEditingSelection, setIsEditingSelection] = useState(false);

    // edge case: if user has a form already open and decided to edit another without closing
    // instead of having a default value (cached at first render - doesnt update after rerender), we reset the form.
    // we also disable editing the selection
    useEffect(() => {
        formApi.reset(handleResponseApi(reportConfig, groupIds));
        setIsEditingSelection(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportConfig.id, reportConfig]);

    const onSubmit = (data: ReportForm) => {
        setIsSubmitting(true);
        dispatch(editReportConfiguration(handlePatch(data, groups))).then(res => {
            setIsSubmitting(false);
            if (res.meta.requestStatus !== 'rejected') {
                onClose();
            }
        });
    };

    return (
        <form onSubmit={formApi.handleSubmit(onSubmit)} className="height-100pct">
            <Sidebar
                title={intl.formatMessage({ id: 'reports.editReport' })}
                titleClassName={'padding-left-10'}
                maxWidth={600}
                minWidth={400}
                width={450}
                bodyClassName={'padding-20'}
                onClose={onClose}
                footer={
                    <div className="display-flex align-items-center" style={{ placeContent: 'space-between' }}>
                        <DeleteConfiguration configId={reportConfig.id} onDelete={() => history.replace(REPORT_ROUTE)}>
                            <button data-test="delete" className="btn-link btn-danger btn">
                                <span className="rioglyph rioglyph-trash margin-right-10" />
                                <FormattedMessage id="delete" />
                            </button>
                        </DeleteConfiguration>
                        <button
                            className={`margin-left-5 btn btn-primary ${isSubmitting ? 'btn-loading-overlay' : ''}`}
                            data-test="submit-edit-dialog"
                        >
                            <FormattedMessage id="reports.saveChanges" />
                        </button>
                    </div>
                }
                position="right"
                headerButtons={
                    <Switch
                        name="status"
                        control={formApi.control}
                        enabledText={intl.formatMessage({ id: 'reports.active' })}
                        disabledText={intl.formatMessage({ id: 'reports.inactive' })}
                    />
                }
            >
                <Form
                    formApi={formApi}
                    vehicles={isEditingSelection ? vehicles : selectedVehicles}
                    groups={isEditingSelection ? groups : selectedGroups}
                    drivers={isEditingSelection ? drivers : selectedDrivers}
                    generatedReports={reportConfig.reports.filter(
                        report => report.reportStatus === GenerationStatus.GENERATED
                    )}
                    isEditing
                    isEditingSelection={isEditingSelection}
                    setIsEditingSelection={setIsEditingSelection}
                />
            </Sidebar>
        </form>
    );
};

export default EditingForm;
