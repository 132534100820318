import { ListMenuItem } from '@rio-cloud/rio-uikit/components/listMenu/ListMenuGroup';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { ReactNode } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import Changelog from './changelog/index';
import Faq from './Faq';
import GlossaryItem from './Glossary';
import HelpLink from './HelpLink';
import LanguageData from './LanguageData';
import PerformAPIOverview from './PerformAPIOverview';
import PerformAPISpecification from './PerformAPISpecification';
import ReportAutomationEmailNotification from './ReportAutomationEmailNotification';
import ReportAutomationOverview from './ReportAutomationOverview';
import ReportAutomationReportTypes from './ReportAutomationReportTypes';
import Welcome from './Welcome';

type ListMenuNavItem = { key: string; item: ReactNode | string };

interface LanguageData {
    items: { title: string; id: string }[];
}

const header = <FormattedMessage id="help.header" defaultMessage="Help" />;

const createHeaderLink = ({ id, title }: { id: string; title: string }) => ({
    groupNavItem: (
        <HelpLink id={id}>
            <FormattedMessage id={title} />
        </HelpLink>
    ),
    navItems: [{ key: id, item: null }],
});

const createMenuLinks = (languageData: LanguageData) => {
    return languageData.items.map(({ title, id }) => ({
        key: id,
        item: (
            <HelpLink id={id}>
                <FormattedMessage id={title} />
            </HelpLink>
        ),
    }));
};

function ReportAutomationRoutes({ locale }: { locale: string }) {
    return (
        <Switch>
            <Route exact path="/help/reportAutomation/overview" render={() => <ReportAutomationOverview />} />
            <Route exact path="/help/reportAutomation/reportTypes" render={() => <ReportAutomationReportTypes />} />
            <Route
                exact
                path="/help/reportAutomation/emailNotification"
                render={() => <ReportAutomationEmailNotification locale={locale} />}
            />
        </Switch>
    );
}

function PerformAPIRoutes({ locale }: { locale: string }) {
    return (
        <Switch>
            <Route exact path="/help/performAPI/overview" render={() => <PerformAPIOverview locale={locale} />} />
            <Route
                exact
                path="/help/performAPI/specification"
                render={() => <PerformAPISpecification locale={locale} />}
            />
        </Switch>
    );
}

function GlossaryRoutes({
    languageData,
}: {
    languageData: { items: { id: string; description: string; title: string }[] };
}) {
    const items = languageData.items;
    return (
        <Switch>
            {items.map(({ id, description, title }) => (
                <Route
                    exact
                    path={`/${id}`}
                    key={id}
                    render={() => <GlossaryItem title={title} description={description} />}
                />
            ))}
        </Switch>
    );
}

export const HelpDialog = ({
    show,
    intl: { messages },
    toggleHelpDialog,
    locale,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveKPIs = false,
}: {
    show?: boolean;
    intl: any;
    toggleHelpDialog: () => void;
    locale: string;
    shouldShowHarshKPIs?: boolean;
    shouldShowExcessiveKPIs?: boolean;
}) => {
    if (!show) {
        return null;
    }

    const formattedFaqLanguageData = LanguageData({ messages, category: 'help.faq' });
    const formattedReportAutomationLanguageData = LanguageData({
        messages,
        category: 'help.reportAutomation',
        customOrder: [
            'help.reportAutomation.overviewTitle',
            'help.reportAutomation.reportTypesTitle',
            'help.reportAutomation.emailNotificationTitle',
        ],
    });
    const formattedPerformAPILanguageData = LanguageData({
        messages,
        category: 'help.performAPI',
        customOrder: ['help.performAPI.overviewTitle', 'help.performAPI.specificationTitle'],
    });
    const formattedGlossaryLanguageData = LanguageData({
        messages,
        category: 'help.glossar',
        hiddenMessages: [
            ...(shouldShowHarshKPIs
                ? []
                : ['help.glossar.ratingHarshAccelerationTitle', 'help.glossar.ratingHarshBrakingTitle']),
            ...(shouldShowExcessiveKPIs ? [] : ['help.glossar.ratingExcessiveIdlingTitle']),
        ],
    });

    const welcomeItems = createHeaderLink({ id: '', title: 'help.title.welcome' });
    const changelogItems = createHeaderLink({ id: 'help/changelog', title: 'help.title.changelog' });
    const faqItems = createHeaderLink({ id: 'help/faq', title: 'help.title.faq' });

    const reportAutomationItems: ListMenuItem<ListMenuNavItem> = {
        group: <FormattedMessage id="help.title.reportAutomation" defaultMessage="Report Automation" />,
        navItems: createMenuLinks(formattedReportAutomationLanguageData),
    };
    const performAPIItems: ListMenuItem<ListMenuNavItem> = {
        group: <FormattedMessage id="help.title.performAPI" />,
        navItems: createMenuLinks(formattedPerformAPILanguageData),
    };

    const glossaryItems: ListMenuItem<ListMenuNavItem> = {
        group: <FormattedMessage id="help.title.glossary" defaultMessage="Glossary" />,
        navItems: createMenuLinks(formattedGlossaryLanguageData),
    };

    const leftContent = (
        <div data-test="HelpNavigation">
            <ListMenu
                menuItems={[
                    welcomeItems,
                    changelogItems,
                    faqItems,
                    performAPIItems,
                    reportAutomationItems,
                    glossaryItems,
                ]}
            />
        </div>
    );

    const rightContent = (
        <div data-test="HelpContent" className="position-sticky top-0">
            <Route exact path="/" render={() => <Welcome />} />
            <Route exact path="/help/changelog" render={() => <Changelog />} />
            <Route exact path="/help/faq" render={() => <Faq languageData={formattedFaqLanguageData} />} />
            <ReportAutomationRoutes locale={locale} />
            <PerformAPIRoutes locale={locale} />
            <GlossaryRoutes languageData={formattedGlossaryLanguageData} />
        </div>
    );

    return (
        <MemoryRouter>
            <SplitDialog
                show={true}
                bsSize={Dialog.SIZE_LG}
                title={header}
                leftContent={leftContent}
                leftContentClassName="width-300"
                rightContent={rightContent}
                onClose={toggleHelpDialog}
            />
        </MemoryRouter>
    );
};

export default injectIntl(HelpDialog);
