import { getLanguageData } from '@common/lang/selectors';
import { Action, createAction, Middleware } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Dispatch } from 'redux';

export const errorNotification = createAction('notification/error', (translationId: string) => ({
    payload: translationId,
}));

export const successNotification = createAction('notification/success', (translationId: string) => ({
    payload: translationId,
}));

export const infoNotification = createAction('notification/info', (translationId: string, timeout?: number) => ({
    payload: { translationId, timeout },
}));

const isNotificationActionError = (action: Action<unknown>): action is ReturnType<typeof errorNotification> =>
    action.type === errorNotification.type;

const isNotificationActionSuccess = (action: Action<unknown>): action is ReturnType<typeof successNotification> =>
    action.type === successNotification.type;

const isNotificationActionInfo = (action: Action<unknown>): action is ReturnType<typeof infoNotification> =>
    action.type === infoNotification.type;

export const createNotificationMiddleware = ({
    onError,
    onSuccess,
    onInfo,
}: {
    onError: (message: string) => void;
    onSuccess: (message: string) => void;
    onInfo: (message: string, title?: string, timeout?: number) => void;
}): Middleware => store => (next: Dispatch) => (action: Action<string | typeof errorNotification>) => {
    const languageData = getLanguageData(store.getState());
    if (isNotificationActionError(action)) {
        const errorMessage = _.get(languageData, action.payload, 'Unknown Error');
        onError(errorMessage);
    }
    if (isNotificationActionSuccess(action)) {
        const successMessage = _.get(languageData, action.payload, 'Success');
        onSuccess(successMessage);
    }

    if (isNotificationActionInfo(action)) {
        const infoMessage = _.get(languageData, action.payload.translationId, 'Info');
        if (!!action.payload.timeout) {
            onInfo(infoMessage, '', action.payload.timeout);
        } else {
            onInfo(infoMessage);
        }
    }

    next(action);
};
