import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        fontFamily: 'Regular',
        fontSize: 10,
        padding: 20,
        paddingBottom: 60,
    },
    top: {
        flexDirection: 'row',
    },
    title: {
        fontSize: 15,
        fontFamily: 'Bold',
    },
    left: {
        flex: 1,
        justifyContent: 'space-between',
    },
    right: {
        width: '10%',
    },
    useCase: {
        color: 'white',
        marginTop: 5,
        fontFamily: 'Bold',
        flexDirection: 'row',
    },
    useCaseText: {
        padding: 4,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#114146',
    },
    table: {
        fontSize: 8,
        flexDirection: 'row',
        marginTop: 20,
    },
    marginTop: {
        marginTop: 20,
    },
    marginLeftsdddd: {
        marginLeft: 20,
    },
});
