import langSaga from '@common/lang/langSaga';
import downloadSaga from '@features/download/saga';
import printSaga from '@features/pdf/saga';
import perform3ToggleSaga from '@features/perform3Toggle/perform3ToggleSaga';
import calculationParamsSaga from '@features/settings/calculationParamsSaga';
import cruiseControlSettingsSaga from '@features/settings/cruiseControlSettingsSaga';
import settingsSaga from '@features/settings/saga';
import treeSagas from '@features/tree/saga';
import uiSaga from '@features/ui/settingsSaga';
import coDriverSaga from '@pages/coDriver/saga';
import driverSettingsSaga from '@pages/driverAnalysis/settingsSaga';
import vehicleSettingsSaga from '@pages/vehicleAnalysis/settingsSaga';
import { configureStore } from '@reduxjs/toolkit';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { createHashHistory } from 'history';
import { useDispatch } from 'react-redux';
import createSagaMiddleware, { Saga } from 'redux-saga';

import dataSaga from '../data/saga';
import { createNotificationMiddleware } from '../middlewares/notificationMiddleware';
import { storesInitialized } from './actions';
import reducers from './reducers';

const getUserConfirmation = (message: string, callback: (answer: boolean) => void) => callback(window.confirm(message)); // eslint-disable-line no-alert

const history = createHashHistory({
    getUserConfirmation,
    hashType: 'noslash',
});

const sagaMiddleware = createSagaMiddleware();
const notificationMiddleware = createNotificationMiddleware({
    onError: Notification.error.bind(Notification),
    onSuccess: Notification.success.bind(Notification),
    onInfo: Notification.info.bind(Notification),
});

const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({ serializableCheck: false }).concat([sagaMiddleware, notificationMiddleware]),
    devTools: true,
});
[
    ...treeSagas,
    settingsSaga,
    dataSaga,
    vehicleSettingsSaga,
    downloadSaga,
    driverSettingsSaga,
    cruiseControlSettingsSaga,
    calculationParamsSaga,
    perform3ToggleSaga,
    langSaga,
    printSaga,
    coDriverSaga,
    uiSaga,
].forEach(saga => {
    sagaMiddleware.run(saga as Saga<unknown[]>);
});

// needs to run after boostrapping saga and redux
store.dispatch(storesInitialized());

export { history, store };

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
