import { createAction } from '@reduxjs/toolkit';

export {
    selectVehicleTab,
    selectDriverTab,
    setSelectedDrivers,
    setSelectedVehicles,
    setSelectedFuelTypeGroups,
    setSelectedVehicleGroups,
    setSelectedDriverGroups,
    setShowEmptyGroups,
} from './reducer';

export const expandTree = createAction('forest/private/EXPAND_TREE');

export const collapseTree = createAction('forest/private/COLLAPSE_TREE');
