import { SignalValue } from '@api/models/types';
import { Bar } from '@components/graphComponents/Bar';
import XAxis from '@components/graphComponents/DatedXAxis';
import { Guidelines, ValueText } from '@components/graphComponents/Graph';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import { Overlay } from '@components/graphComponents/Overlay';
import timeFormatBasedOnDateRange from '@components/graphComponents/timeFormatBasedOnDateRange';
import { DataEntry } from '@components/graphComponents/types';
import { YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import { Formatter, Section, Values } from '@components/summary/types';
import SegmentableDay from '@features/ui/SegmentableDay';
import SegmentableDayOverlay from '@features/ui/SegmentableDayOverlay';
import { steps } from '@features/ui/utils';
import colors from '@rio-cloud/rio-uikit/Colors';
import { dateRangeSegmentedByStep } from '@utils/dateRangeSegmentedByStep';
import { getRatingCSSColor } from '@utils/rating';
import { formattedNumber } from '@utils/stringFormatters';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { IntlShape } from 'react-intl/lib';

import { ValidUnit } from '../../../constants/units';
import { DateRange } from '../../../types';
import { HoverInfo } from './../types';

const findMatchingY = (key: Date, data: { x: Date }[], step: moment.unitOfTime.Diff) => {
    const possibleMatch = data.find(
        d =>
            moment(d.x)
                .startOf(step)
                .diff(moment(key).startOf(step), 'days') === 0
    );
    return _.get(possibleMatch, 'leftUpY');
};

export const convertDataToXYValue = ({ data }: { data: { date: Date }[] }) =>
    data.map(e => ({
        x: moment(e.date).toDate(),
        leftUpY: _.get(e, 'value.value'),
    }));

export const mergeExistingDataWithPlaceholders = ({
    dateRange,
    existingData,
}: {
    dateRange: DateRange;
    existingData: { x: Date; leftUpY: SignalValue | undefined }[];
}) => {
    const step = steps(dateRange);
    const xAxisValues = dateRangeSegmentedByStep({ dateRange, step });

    if (!xAxisValues.length) {
        return existingData;
    }

    return xAxisValues.map(date => {
        return {
            x: date,
            leftUpY: findMatchingY(date, existingData, step),
        };
    });
};

const NonTruEGraphDriver = ({
    data = [],
    dateRange,
    intl: { formatDate, formatMessage },
    column,
    width,
}: {
    data: Values | undefined;
    dateRange: DateRange;
    intl: IntlShape;
    column: Section;
    width: number;
}) => {
    const dimensions = { height: 400, width, margin: 40 };
    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);
    const xYList = convertDataToXYValue({ data });
    const mappedData = mergeExistingDataWithPlaceholders({ dateRange, existingData: xYList });
    const hoverOffset = 40;

    const average = (column?.stats as { average?: number | undefined; values?: unknown[] | undefined })?.average;

    const unit = column.unit;
    const colorSchema = useMemo(() => {
        if (unit === ValidUnit.PERCENTAGE) {
            return (d: DataEntry) => getRatingCSSColor(d.leftUpY).hexColor;
        } else {
            return () => colors['color-highlight-dark'];
        }
    }, [unit]);

    const yScale = useMemo(() => {
        if (unit === ValidUnit.PERCENTAGE) {
            return scaleLinear()
                .domain([100, 0])
                .range([dimensions.margin, dimensions.height - dimensions.margin]);
        }
        return undefined;
    }, [dimensions.height, dimensions.margin, unit]);

    const tickFormatter = timeFormatBasedOnDateRange(formatDate, formatMessage);

    return (
        <div className="graph bg-white position-relative overflow-hidden" style={{ height: dimensions.height }}>
            {hoverInfo && (
                <Overlay
                    hasData={!_.isUndefined(hoverInfo?.data)}
                    x={hoverInfo?.leftX}
                    y={
                        hoverInfo.leftUpY +
                        (hoverInfo.leftUpY >= dimensions.height - dimensions.margin * 4 ? -hoverOffset : hoverOffset)
                    }
                >
                    {!_.isUndefined(_.get(hoverInfo, 'data.leftUpY')) ? (
                        (column?.formatter as Formatter)(hoverInfo.data.leftUpY)
                    ) : (
                        <FormattedMessage id="noData" />
                    )}
                </Overlay>
            )}
            <GraphDataProvider
                formatDate={formatDate}
                formatMessage={formatMessage}
                leftYScale={yScale}
                data={mappedData}
                dimensions={dimensions}
            >
                <SegmentableDayOverlay leftYScaleFormatter={column.formatter as Formatter} />
                {!_.isUndefined(average) && (
                    <ValueText
                        show={Boolean(hoverInfo)}
                        value={average}
                        formatter={column.formatter as Formatter}
                        className="text-bold"
                        label={<FormattedMessage id="average" />}
                    />
                )}
                <svg
                    width={'100%'}
                    height={'100%'}
                    preserveAspectRatio="xMinYMin meet"
                    viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                >
                    <Guidelines average={average} show={Boolean(hoverInfo)} />
                    <YAxisLines />
                    <XAxis
                        tickFormatter={tickFormatter}
                        selectedElement={_.get(hoverInfo, 'data')}
                        dateRange={dateRange}
                    />
                    <Bar colorSchema={colorSchema} minHeight={1} />
                    <YAxisTicks formatter={formattedNumber} />
                    <SegmentableDay onHover={setHover} />
                </svg>
            </GraphDataProvider>
        </div>
    );
};

export default NonTruEGraphDriver;
