import { createId, VehspecEntity } from '@api/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import always from 'lodash/fp/always';

import {
    Entity,
    Id,
    Map,
    QueryPayload,
    RawDriver,
    StatisticsReport,
    Vehicle,
    VehicleGroup,
    VehspecPayload,
} from '../types';
import { Loadable, LoadableType } from './loadable';

export type DataState = {
    lastNRequests: Id[];
    entities: Map<Entity>;
    requests: Map<LoadableType<Id[]>>;
    drivers: Map<RawDriver>;
    vehicles: Map<Vehicle>;
    vehicleSpecifications: Map<LoadableType<VehspecEntity[]>>;
    groups: Map<VehicleGroup>;
    statistics: Map<LoadableType<StatisticsReport[]>>;
    driversInitialized: boolean;
    groupsInitialized: boolean;
    vehiclesInitialized: boolean;
};

export const defaultState: DataState = {
    lastNRequests: [],
    entities: {},
    requests: {},
    drivers: {},
    vehicles: {},
    vehicleSpecifications: {},
    groups: {},
    statistics: {},
    driversInitialized: false,
    groupsInitialized: false,
    vehiclesInitialized: false,
};

const MAX_CACHED_REQUESTS = 150;

const merge = (old: Map<Entity>, entities: Entity[]) => ({
    ...old,
    ...entities.reduce((acc, e) => ({ ...acc, [e.id]: e }), {}),
});

const cleanUpCache = (state: DataState, maxCachedRequests: number) => {
    if (Object.keys(state.requests).length >= maxCachedRequests) {
        const lastRequests = state.lastNRequests;
        const requests: Map<LoadableType<string[]>> = lastRequests.reduce(
            (acc, request) => ({ ...acc, [request]: state.requests[request] }),
            {}
        );
        const statistics = lastRequests.reduce(
            (acc, statistic) => ({
                ...acc,
                [statistic]: state.statistics[statistic],
            }),
            {}
        );

        const loadedRequests = Object.values(requests).map(loadable =>
            Loadable.cata(loadable, data => data, always([]), always([]), always([]))
        );
        const entities = _.flatten(loadedRequests).reduce(
            (acc, entity) => ({
                ...acc,
                [entity]: state.entities[entity],
            }),
            {}
        );
        return { ...state, requests, statistics, entities };
    }
    return state;
};

const storeAndReplace = (existing: string[], newRequest: string, maxCachedRequests: number) => {
    const modified = [...existing, newRequest];
    return _.takeRight(modified, maxCachedRequests);
};

const dataSlice = createSlice({
    name: 'data',
    initialState: defaultState,
    reducers: {
        // Assets
        requestDriversSuccess(state, { payload: { drivers = {} } }: PayloadAction<{ drivers: Map<RawDriver> }>) {
            state.drivers = drivers;
            state.driversInitialized = true;
        },
        requestGroupsSuccess(state, { payload: { groups = {} } }: PayloadAction<{ groups: Map<VehicleGroup> }>) {
            state.groups = groups;
            state.groupsInitialized = true;
        },
        requestVehiclesSuccess(state, { payload: { vehicles = {} } }: PayloadAction<{ vehicles: Map<Vehicle> }>) {
            state.vehicles = vehicles;
            state.vehiclesInitialized = true;
        },

        // Common useQuery data structures
        requestEntitiesReceivedFailed(state, { payload: { request } }: PayloadAction<{ request: QueryPayload }>) {
            state.requests[createId(request)] = Loadable.createFailed();
            state.statistics[createId(request)] = Loadable.createFailed();
        },
        requestEntitiesReceived(
            state,
            {
                payload: { request, maxCachedRequests = MAX_CACHED_REQUESTS },
            }: PayloadAction<{ request: QueryPayload; maxCachedRequests?: number }>
        ) {
            const requestId = createId(request);
            return cleanUpCache(
                {
                    ...state,
                    lastNRequests: storeAndReplace(state.lastNRequests, requestId, maxCachedRequests),
                    requests: { ...state.requests, [requestId]: Loadable.createWaiting() },
                    statistics: { ...state.statistics, [requestId]: Loadable.createWaiting() },
                },
                maxCachedRequests
            );
        },
        requestEntitiesStatisticsReceivedSuccess(
            state,
            {
                payload: { request, response },
            }: PayloadAction<{ request: QueryPayload; response: { statistics: StatisticsReport[] } }>
        ) {
            state.statistics[createId(request)] = Loadable.createDone(response.statistics);
        },
        requestEntitiesReceivedSuccess(
            state,
            {
                payload: { request, response },
            }: PayloadAction<{ request: QueryPayload; response: { entities: Entity[] } }>
        ) {
            state.requests[createId(request)] = Loadable.createDone(response.entities.map(e => e.id));
            state.entities = merge(state.entities, response.entities);
        },

        // Vehicle Specification
        requestVehicleSpecificationSuccess(
            state,
            { payload: { request, specs } }: PayloadAction<{ request: VehspecPayload; specs: VehspecEntity[] }>
        ) {
            state.vehicleSpecifications[createId(request)] = Loadable.createDone(specs);
        },
        requestVehicleSpecificationFailed(state, { payload: { request } }: PayloadAction<{ request: VehspecPayload }>) {
            state.vehicleSpecifications[createId(request)] = Loadable.createFailed();
        },
        requestVehicleSpecificationReceived(
            state,
            { payload: { request } }: PayloadAction<{ request: VehspecPayload }>
        ) {
            state.vehicleSpecifications[createId(request)] = Loadable.createWaiting();
        },
        cleanUpRequestsCache(state) {
            return cleanUpCache(
                {
                    ...state,
                    lastNRequests: [],
                },
                0
            );
        },
    },
});

export const {
    requestEntitiesReceivedSuccess,
    requestVehicleSpecificationFailed,
    requestVehicleSpecificationReceived,
    requestVehicleSpecificationSuccess,
    requestEntitiesStatisticsReceivedSuccess,
    requestEntitiesReceived,
    requestEntitiesReceivedFailed,
    requestVehiclesSuccess,
    requestDriversSuccess,
    requestGroupsSuccess,
    cleanUpRequestsCache,
} = dataSlice.actions;
export default dataSlice.reducer;
