import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import { isNumber } from 'lodash';
import { useContext, useEffect, useRef } from 'react';

import { ANIMATION_DURATION } from './Graph';
import { GraphContext } from './GraphDataProvider';
import { DataEntry } from './types';

const hasValueGreaterThanZero = (d: DataEntry) => isNumber(d.leftUpY) && d.leftUpY > 0;

export default function Icon({ name, classes }: { name: string; classes?: string }) {
    const { xScale: x, leftYScale: y, data } = useContext(GraphContext);
    const group = useRef<SVGForeignObjectElement | null>(null);

    useEffect(() => {
        if (group && group.current) {
            const elements = select(group.current)
                .selectAll('foreignObject')
                .data(data);

            elements
                .enter()
                .append('foreignObject')
                .attr('width', 15)
                .attr('height', 15)
                .attr('class', d => (hasValueGreaterThanZero(d) ? `rioglyph ${name} ${classes || ''}` : ''))
                .attr('y', y(10))
                .attr('x', d => x((d.x as unknown) as number) || null);

            elements
                .transition()
                .ease(easeLinear)
                .duration(ANIMATION_DURATION)
                .attr('y', y(10))
                .attr('x', d => x((d.x as unknown) as number) || null);

            elements.exit().remove();
        }
    }, [classes, data, name, x, y]);

    return <g ref={group} />;
}
