import { fetchGetWithJSONResponse } from './api';
import {
    AssetApiResponseType,
    AssociationApiResponseType,
    decodeVehicle,
    PlatformInfoVehicleApiResponseType,
} from './decoder/vehicleDecoder';

export async function fetchVehicles(baseUrlAssets: string, baseUrlPlatformInfo: string, authToken: string) {
    const assetRequest = fetchGetWithJSONResponse(baseUrlAssets, `assets?embed=(tags)`, authToken);
    const associationsRequest = fetchGetWithJSONResponse(baseUrlAssets, `associations?embed=(device)`, authToken);

    const [rawAssets, rawAssociations]: [AssetApiResponseType, AssociationApiResponseType] = await Promise.all([
        assetRequest,
        associationsRequest,
    ]);

    let rawPlatformInfoVehicles: PlatformInfoVehicleApiResponseType;
    try {
        rawPlatformInfoVehicles = await fetchGetWithJSONResponse(baseUrlPlatformInfo, `permitted-assets`, authToken);
    } catch {
        rawPlatformInfoVehicles = { items: [] };
    }

    return decodeVehicle(rawAssets, rawAssociations, rawPlatformInfoVehicles);
}
