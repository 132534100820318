export const VEHICLE_REQUEST_BY_TIME_COMMON_ATTRIBUTES = [
    'average_speed',
    'average_weight',
    'co_2_emission',
    'driver_ids',
    'driving_time',
    'idling_time',
    'interval_desc', // TODO remove this when cache service is no longer used
    'mileage',
    'operating_time',
    'result_from',
    'result_to',
    'vehicle_ids',
];

export const DIESEL_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES = [
    'fuel_efficiency',
    'distance_fuel_consumption',
    'driving_consumption',
    'fuel_consumption',
    'idling_consumption',
    'operating_fuel_consumption',
];

export const VEHICLE_REQUEST_BY_TIME_ATTRIBUTES = [
    ...VEHICLE_REQUEST_BY_TIME_COMMON_ATTRIBUTES,
    ...DIESEL_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES,
];

export const ELECTRIC_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES = [
    'electric_average_driving_consumption',
    'electric_average_idling_consumption',
    'electric_operating_consumption',
    'electric_average_operating_consumption',
    'electric_average_operating_consumption_range',
    'electric_recuperation',
    'electric_recuperation_per_km',
    'electric_recuperation_km_per_kwh',
    'electric_recuperation_per_hour',
    'electric_efficiency',
];

export const VEHICLE_REQUEST_ATTRIBUTES = [
    'average_speed',
    'average_weight',
    'co_2_emission',
    'distance_fuel_consumption',
    'driver_ids',
    'driving_consumption',
    'driving_time',
    'fuel_consumption',
    'fuel_efficiency',
    'idling_consumption',
    'idling_time',
    'inaccurate_vehicle_ids',
    'mileage',
    'operating_fuel_consumption',
    'operating_time',
    'result_from',
    'result_to',
    'vehicle_ids',
];

export const ELECTRIC_KPIS_ATTRIBUTES = [
    'electric_average_idling_consumption',
    'electric_average_driving_consumption',
    'electric_operating_consumption',
    'electric_average_operating_consumption',
    'electric_average_operating_consumption_range',
    'electric_recuperation',
    'electric_efficiency',
];

export const VEHICLE_REQUEST_BY_TIME_ELECTRIC_ATTRIBUTES = [
    ...VEHICLE_REQUEST_BY_TIME_COMMON_ATTRIBUTES,
    ...ELECTRIC_VEHICLE_REQUEST_BY_TIME_ATTRIBUTES,
    ...ELECTRIC_KPIS_ATTRIBUTES,
];
