import { array, intersection, partial, string, type, TypeOf } from 'io-ts';

import { Driver } from '../models/types';
import { decodeJson } from './decoder';
import { getDrivers } from './mfhConverter';

export const RawDriver = intersection([
    type({
        id: string,
        status: string, // enum?
        account_id: string,
        display_name: string,
        _embedded: type({
            identifications: array(
                partial({
                    id: string,
                    identification: string,
                    identification_type: string, // enum?
                })
            ),
        }),
    }),
    partial({
        first_name: string,
        last_name: string,
    }),
]);

const DriverApiResponse = type({
    items: array(RawDriver),
});

export type DriverApiResponseType = TypeOf<typeof DriverApiResponse>;

export function decodeDrivers(rawDrivers: DriverApiResponseType): Driver[] {
    return getDrivers(decodeJson(DriverApiResponse, rawDrivers));
}
