import { isRight } from 'fp-ts/es6/Either';
import { Decoder } from 'io-ts';
import { PathReporter } from 'io-ts/es6/PathReporter';

export function decodeJson<T1, T2>(decoder: Decoder<unknown, T1>, rawResults: T2) {
    const validationResult = decoder.decode(rawResults);
    if (isRight(validationResult)) {
        return validationResult.right;
    }
    // TODO this Error message is lost currently
    throw new Error(`Error decoding JSON, ${PathReporter.report(validationResult)}`);
}
