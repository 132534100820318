import { MAX_API_RESPONSE_WAIT_TIME } from '../../constants/api';
import { DownloadApiPayload } from './types';

export const fetchPostWithLocationHeaderResponse = async (
    serviceBaseUrl: string,
    api: string,
    payload: DownloadApiPayload,
    authToken: string,
    skipFail = false
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    const requestObject = {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
    };

    return fetch(`${serviceBaseUrl}/${api}`, requestObject)
        .then(postResponse => {
            const location = postResponse.headers.get('Location');
            return location ? location : Promise.reject();
        })
        .catch(error => {
            return skipFail ? undefined : Promise.reject(error);
        });
};

export const pollExcel = async ({
    serviceBaseUrl,
    aggregationLocation,
    aggregationId,
    operationalReportId,
    locale,
    authToken,
    drivers,
    timeout = MAX_API_RESPONSE_WAIT_TIME,
    interval = 500,
    isPerform3Enabled = true,
}: {
    serviceBaseUrl: string;
    aggregationLocation: string;
    aggregationId: string;
    operationalReportId?: string;
    locale: string;
    authToken: string;
    drivers: { driver_id: string; display_name: string }[];
    timeout?: number;
    interval?: number;
    isPerform3Enabled: boolean;
}) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    const poll = (aggregationLocation: string, aggregationId: string, timeout: number, interval: number) => {
        const endTime = Number(new Date()) + timeout;
        const checkCondition = async (
            resolve: (value: Blob | PromiseLike<Blob>) => void,
            reject: (reason?: Error) => void
        ) => {
            try {
                const opconPart = operationalReportId ? `&opcon_id=${encodeURIComponent(operationalReportId)}` : '';
                const performPart = `/perf3/${aggregationId}?`;

                const response = await fetch(
                    `${serviceBaseUrl}/export/excel${performPart}locale=${locale}${opconPart}`,
                    {
                        method: 'POST',
                        headers,
                        body: JSON.stringify({ ...drivers, use_v2_rating: isPerform3Enabled }),
                    }
                );
                switch (response.status) {
                    case 200: {
                        const bucket = await fetch(response.headers.get('Location') as string, {
                            method: 'GET',
                            headers: {
                                Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                Authorization: `Bearer ${authToken}`,
                            },
                        });
                        if (bucket.status === 200) {
                            resolve(await bucket.blob());
                        } else {
                            throw new Error('File cannot be downloaded ' + bucket.status);
                        }
                        break;
                    }
                    case 204:
                        if (Number(new Date()) < endTime) {
                            setTimeout(checkCondition, interval, resolve, reject);
                        } else {
                            throw new Error('Response takes too long');
                        }
                        break;
                    default:
                        reject();
                }
            } catch (e) {
                reject(e as Error);
            }
        };
        return new Promise<Blob>(checkCondition);
    };
    return poll(aggregationLocation, aggregationId, timeout, interval);
};
