import { getAccount, isKoreanTenant as isKoreanTenantSelector } from '@common/login/selectors';
import { hasPerformVehicles as hasPerformVehiclesSelector } from '@common/permissions/selectors';
import SimpleErrorState from '@components/SimpleErrorState';
import SkeletonLoader from '@components/SkeletonLoader';
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import Notification from '@rio-cloud/rio-uikit/Notification';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { generatePushToDataLayer, trackingAttributes } from '@utils/tracking';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { matchPath, Route, useHistory, useLocation } from 'react-router-dom';

import { GenerationStatus } from '../../api';
import { CREATE_REPORT_ROUTE, EDIT_REPORT_ROUTE, REPORT_ROUTE, VIEW_REPORT_ROUTE } from '../../constants/routes';
import { URL_MARKETPLACE_PERFORM, URL_MARKETPLACE_PERFORM_KOREA } from '../../constants/URLs';
import { useAppDispatch } from '../../setup/store';
import configureConfigurationToRow from './configureConfigurationToRow';
import CreateReportDialog from './CreateReportDialog';
import { getReports, getStatus, loadReportConfigurations } from './reducer';
import ReportTable, { ReportRow } from './ReportTable';
import { ReportConfiguration, Status } from './types';

const createLoadingColumn = () => {
    const skeleton = <SkeletonLoader>loading ..</SkeletonLoader>;
    return {
        id: Math.random().toString(),
        name: skeleton,
        reportType: skeleton,
        selection: skeleton,
        interval: skeleton,
        download: skeleton,
        actions: skeleton,
        active: false,
        generationStatus: skeleton,
        notificationStatus: <></>,
    };
};

const loading = [createLoadingColumn(), createLoadingColumn(), createLoadingColumn()];

const Centered: React.FC = ({ children, ...rest }) => (
    <div {...rest} className="display-flex justify-content-center max-width-100pct margin-top-15pct">
        {children}
    </div>
);

const matchesPathWithId = (pathname: string, pathToMatch: string) =>
    (matchPath(pathname, { path: pathToMatch })?.params as {
        id?: string;
    })?.id;

function useActiveReportIdFromLocation() {
    const { pathname } = useLocation();
    const matchesEditReportPath = matchesPathWithId(pathname, `${EDIT_REPORT_ROUTE}/:id`);
    const matchesViewReportPath = matchesPathWithId(pathname, `${VIEW_REPORT_ROUTE}/:id`);
    return matchesEditReportPath || matchesViewReportPath;
}

const TableView: React.FC<{
    openCreateForm: () => void;
    openEditForm: (config: { id: string }) => void;
    status: Status;
    reports: ReportConfiguration[];
    configurationToRow: (config: ReportConfiguration) => ReportRow;
    account: string;
}> = ({ openCreateForm, configurationToRow, openEditForm, status, reports, account }) => {
    const [viewType, setViewType] = useState<string>(TableViewToggles.VIEW_TYPE_TABLE);
    const pushToDataLayer = generatePushToDataLayer(account);

    const handleViewTypeChange = (newViewType: string) => {
        pushToDataLayer({
            eventTrigger: 'click',
            eventCategory: 'perform, tableSettings',
            eventAction: 'toggleTableView',
            eventLabel: `${_.camelCase(newViewType)}View, reporting`,
        });

        setViewType(newViewType);
    };

    return (
        <>
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">
                            <div className="btn-toolbar table-btn-toolbar">
                                <button
                                    {...trackingAttributes(
                                        'click',
                                        'perform, reportPage',
                                        'createNewReport',
                                        'reporting'
                                    )}
                                    data-test="create-new"
                                    className="btn btn-primary"
                                    onClick={openCreateForm}
                                >
                                    <FormattedMessage id="reports.createNew" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-toolbar-group-right">
                        <div className="table-toolbar-column">
                            <TableViewToggles onViewTypeChange={handleViewTypeChange} />
                        </div>
                    </div>
                </div>
            </TableToolbar>
            <ReportTable
                onRowClick={openEditForm}
                rows={
                    status === Status.Loading
                        ? loading
                        : _.sortBy(reports, 'createdAt')
                              .reverse()
                              .map(configurationToRow)
                }
                viewType={viewType}
            />
        </>
    );
};

export default function Entry() {
    // filter for hidden columns
    const history = useHistory();
    const { pathname } = useLocation();
    const [inProgressReports, setInProgressReports] = useState(false);
    const dispatcher = useAppDispatch();
    const openCreateForm = () => history.replace(CREATE_REPORT_ROUTE);
    const closeForm = () => history.replace(REPORT_ROUTE);
    const activeReportId = useActiveReportIdFromLocation();
    const openEditForm = (config: { id: string }) => {
        const newPath = `${EDIT_REPORT_ROUTE}/${config.id}`;
        if (config.id === activeReportId) {
            history.replace(REPORT_ROUTE);
        } else {
            history.replace(newPath);
        }
    };
    const account: string = useSelector(getAccount) ?? '';

    const configurationToRow = configureConfigurationToRow(activeReportId);
    const reports: ReportConfiguration[] = useSelector(getReports);
    const status = useSelector(getStatus);
    const isEmpty = status === Status.Loaded && !reports.length;
    const hasErrored = status === Status.Failed;
    const isLoaded = status === Status.Loaded;

    useEffect(() => {
        dispatcher(loadReportConfigurations());

        const loadReportConfigsInterval = setInterval(() => {
            if (inProgressReports) {
                dispatcher(loadReportConfigurations());
            }
        }, 6000);

        return () => {
            clearInterval(loadReportConfigsInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgressReports]);

    useEffect(() => {
        const currentDate = new Date();
        const thresholdDate = currentDate.setDate(currentDate.getDate() - 1);
        const isGenerating = reports.some(reportConfig =>
            reportConfig.reports.some(
                report =>
                    report.reportStatus === GenerationStatus.PENDING &&
                    new Date(report.updatedAt).getTime() > thresholdDate
            )
        );
        setInProgressReports(isGenerating);
    }, [reports]);

    useEffect(() => {
        const isViewMode = matchPath(pathname, { path: `${VIEW_REPORT_ROUTE}/:id` });
        if (isLoaded && activeReportId && isViewMode) {
            const isActiveReportIdNotPresent = reports.every(({ id }) => id !== activeReportId);
            if (isActiveReportIdNotPresent) {
                history.replace(REPORT_ROUTE);
                Notification.error(
                    <FormattedMessage id={'report.configuration.notAvailable'} />,
                    <FormattedMessage id={'report.configuration.notFound'} />,
                    7000,
                    undefined
                );
            }
        }
    }, [activeReportId, history, isLoaded, pathname, reports]);

    const hasPerformVehicles = useSelector(hasPerformVehiclesSelector);
    const isKoreanTenant = useSelector(isKoreanTenantSelector);
    if (!hasPerformVehicles) {
        return (
            <Centered data-test="perform-up-sell-automatic-report">
                <CustomState
                    headline={<FormattedMessage id="upSell.premiumFeature" />}
                    message={<FormattedMessage id="reports.upSell.message" values={{ line_break: <br /> }} />}
                    icons={[
                        {
                            name: 'rioglyph rioglyph-stars',
                        },
                    ]}
                    buttons={[
                        {
                            text: (
                                <span>
                                    <FormattedMessage id="bookNow" />
                                </span>
                            ),
                            href: isKoreanTenant ? URL_MARKETPLACE_PERFORM_KOREA : URL_MARKETPLACE_PERFORM,
                        },
                    ]}
                />
            </Centered>
        );
    }

    if (hasErrored) {
        return (
            <Centered data-test="error-state">
                <SimpleErrorState fullWidth headlineId={'error.default'} messageId={'error.server'} />
            </Centered>
        );
    }

    const EmptyView = () => (
        <Centered data-test="empty-state">
            <CustomState
                headline={<FormattedMessage id="reports.noReportsAvailable.title" />}
                message={<FormattedMessage id="reports.noReportsAvailable.message" />}
                icons={[
                    {
                        name: 'rioglyph rioglyph-pdf-file ',
                        className: 'text-size-300pct',
                    },
                ]}
                buttons={[
                    {
                        text: <FormattedMessage id="reports.createNew" />,
                        onClick: openCreateForm,
                    },
                ]}
            />
        </Centered>
    );

    return (
        <>
            {isEmpty ? (
                <EmptyView />
            ) : (
                <TableView
                    status={status}
                    openCreateForm={openCreateForm}
                    openEditForm={openEditForm}
                    configurationToRow={configurationToRow}
                    reports={reports}
                    account={account}
                />
            )}
            <Route path="/reports/new" render={() => <CreateReportDialog onClose={closeForm} />} />
        </>
    );
}
