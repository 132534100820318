import { TreeItemName } from '@rio-cloud/rio-uikit';

import {
    ApiResponse as _ApiResponse,
    CardNumber as _CardNumber,
    Entity as _Entity,
    GenericSignals as _GenericSignals,
    OpconQueryPayload as _OpconQueryPayload,
    OpconSignals as _OpconSignals,
    PerformQueryPayload as _PerformQueryPayload,
    PositionQueryPayload as _PositionQueryPayload,
    QueryPayload as _QueryPayload,
    Road as _Road,
    Route as _Route,
    Signal as _Signal,
    Signals as _Signals,
    SignalValue as _SignalValue,
    StatisticsReport as _StatisticsReport,
    System as _System,
    Vehicle as _Vehicle,
    VehicleGroup as _VehicleGroup,
    VehicleStatus as _VehicleStatus,
    VehicleType as _VehicleType,
    VehspecPayload as _VehspecPayload,
    Vin as _Vin,
} from '../../src/api/index';

export type Map<T> = { [key: string]: T }; // | undefined
export type AuthToken = string;
export type TimeInMilliSeconds = number;
export type Id = string;

export const System = _System;
export const VehicleStatus = _VehicleStatus;
export const VehicleType = _VehicleType;
export type ApiResponse = _ApiResponse;
export type CardNumber = _CardNumber;
export type Entity = _Entity;
export type GenericSignals = _GenericSignals;
export type OpconQueryPayload = _OpconQueryPayload;
export type OpconSignals = _OpconSignals;
export type PerformQueryPayload = _PerformQueryPayload;
export type PositionQueryPayload = _PositionQueryPayload;
export type VehspecPayload = _VehspecPayload;
export type QueryPayload = _QueryPayload;
export type Road = _Road;
export type Route = _Route;
export type Signal<T> = _Signal<T>;
export type Signals = _Signals;
export type SignalValue = _SignalValue;
export type StatisticsReport = _StatisticsReport;
export type Vehicle = _Vehicle;
export type VehicleGroup = _VehicleGroup;
export type Vin = _Vin;

export type RawDriver = {
    driverId: Id;
    groupIds: Id[];
    firstName?: string;
    lastName?: string;
    displayName: string;
    name: string | TreeItemName;
    fleetId: Id | undefined;
    id: Id;
    legacyRioId: Id;
    cardNumber: CardNumber;
    status: string;
    type: string;
};

export enum DriverType {
    ANONYMOUS = 'ANONYMOUS',
    NO_DRIVER_CARD = 'NO_DRIVER_CARD',
    ESSENTIAL = 'ESSENTIAL',
}

export type PerformDriver = RawDriver;

export type EssentialDriver = Omit<PerformDriver, 'firstName' | 'lastName' | 'displayName' | 'cardNumber'> & {
    firstName: null;
    lastName: null;
    displayName: null;
    cardNumber: null;
    type: DriverType.ESSENTIAL;
};

export interface NoCardDriver {
    id: Id;
    name: TreeItemName;
    type: DriverType.NO_DRIVER_CARD;
}

export interface AnonymousDriver {
    id: Id;
    name: TreeItemName;
    type: DriverType.ANONYMOUS;
    driverId: Id;
}

export type Driver = PerformDriver | EssentialDriver | NoCardDriver | AnonymousDriver;

export interface HydratedEntity {
    drivers: Driver[];
    vehicles: Vehicle[];
    id: string;
    signals?: {
        start?: Signal<Date>;
    } & GenericSignals;
    warnings?: JSX.Element[];
    shouldShowFuelType?: boolean;
}

export interface HydratedEntityWithChildren extends HydratedEntity {
    children: HydratedEntity[];
    childrenCount?: number;
    level: number;
}

export interface HydratedEntityWithOpcon extends HydratedEntity {
    signals: {
        start?: Signal<Date>;
    } & GenericSignals &
        OpconSignals;
}
export interface HydratedEntityWithShouldShowFuelType extends HydratedEntity {
    shouldShowFuelType: boolean;
}

export type HydratedSummary = StatisticsReport & { values: { value: SignalValue; date: Date }[] };

export type DateRange = {
    start: Date;
    end: Date;
};

export type IntervalTypes = 'quarter_hour' | 'hour' | 'day' | 'week' | 'month' | 'year' | 'quarter' | 'halfyear';

export enum PerformSegmentBy {
    'interval' = 'interval',
    'vehicle' = 'vehicle',
    'driver' = 'driver',
    'driver_and_vehicle' = 'driver_and_vehicle',
}

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
