import React from 'react';

import { Column } from '../../columns/createColumn';
import { HydratedEntity } from '../../types';

const Discipline = ({
    title,
    entity,
    columns,
}: {
    title: React.ReactNode;
    entity: HydratedEntity;
    columns: (Column | undefined)[];
}): JSX.Element => {
    const pattern = [4, 3, 1];
    return (
        <div className="row">
            <div className="col-md-4">{title}</div>
            {columns.map((column, index) => {
                if (column) {
                    return (
                        <div key={column.key} className={`col-md-${pattern[index % pattern.length]}`}>
                            {column.formatter(column.valueExtractor(entity), entity)}
                        </div>
                    );
                }
                return <></>;
            })}
        </div>
    );
};

export default Discipline;
