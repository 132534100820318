import { SingleDriver } from '@components/DriverLabel';
import { HydratedEntity } from 'src/types';

import Label from '../../components/Label';

const ClickableDriverList = ({
    onClick,
    entities,
}: {
    onClick: (entity: HydratedEntity) => void;
    entities: HydratedEntity[];
}) => {
    return (
        <div className="text-size-18 ellipsis-1">
            <ul className="padding-0" style={{ listStyle: 'none' }} data-test="clickable-drivers">
                {entities.map(entity => (
                    <li
                        className="hover-text-color-highlight cursor-pointer"
                        data-test="driver"
                        key={entity.id}
                        onClick={() => onClick(entity)}
                    >
                        <Label id="drivers" icon="rioglyph-driver">
                            <SingleDriver driver={entity.drivers[0]} />
                        </Label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClickableDriverList;
