import XAxis from '@components/graphComponents/DatedXAxis';
import GraphDataProvider from '@components/graphComponents/GraphDataProvider';
import Icon from '@components/graphComponents/Icon';
import Interval from '@components/graphComponents/Interval';
import Line from '@components/graphComponents/Line';
import timeFormatBasedOnDateRange from '@components/graphComponents/timeFormatBasedOnDateRange';
import { DataEntry } from '@components/graphComponents/types';
import { YAxisLines, YAxisTicks } from '@components/graphComponents/YAxis';
import ChargeGraphOverlay from '@features/battery/ChargeOverlay';
import { HoverInfo } from '@pages/driverAnalysis/types';
import { scaleLinear } from 'd3-scale';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { DateRange } from 'src/types';

import { StateOfChargeDataPoint, StateOfChargeDataPointFiltered } from './types';

const ChargeGraph = ({
    stateOfCharges,
    dateRange,
    intl: { formatMessage, formatDate },
}: {
    stateOfCharges: StateOfChargeDataPointFiltered[];
    dateRange: DateRange;
    intl: IntlShape;
}) => {
    const [dimensions, setDimensions] = useState({
        height: 200,
        width: 0,
        margin: 40,
    });

    const [hoverInfo, setHover] = useState<HoverInfo | null>(null);

    const data = mapDataPoints(stateOfCharges);
    const leftYScale = scaleLinear()
        .domain([100, 0])
        .range([0, dimensions.height - dimensions.margin]);

    useEffect(() => {
        const maxWidth = 950;
        setDimensions(prevDimensions => ({
            ...prevDimensions,
            height: 270,
            width: Math.min(document.body.clientWidth - 40, maxWidth),
        }));
    }, []);

    const defaultProviderProps = {
        leftYScale,
        formatDate: formatDate,
        formatMessage: formatMessage,
        dimensions: { ...dimensions, margin: 40 },
    };

    return (
        <div data-test="battery-graph" style={{ width: dimensions.width }}>
            <div className="graph bg-white position-relative overflow-hidden" style={{ height: dimensions.height }}>
                {hoverInfo && (
                    <ChargeGraphOverlay hoverInfo={hoverInfo} data={stateOfCharges} parentDimensions={dimensions} />
                )}
                <GraphDataProvider {...defaultProviderProps} data={data}>
                    <svg
                        width={'100%'}
                        height={'100%'}
                        preserveAspectRatio="xMinYMin meet"
                        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
                    >
                        <YAxisLines />
                        <XAxis
                            dateRange={dateRange}
                            selectedElement={_.get(hoverInfo, 'data')}
                            tickFormatter={timeFormatBasedOnDateRange(formatDate, formatMessage)}
                        />
                        <Line strokeWidth={2} />
                        <YAxisTicks formatter={(value: number) => `${value}%`} />
                        <g>
                            <Icon name="rioglyph-hour-glass" classes="text-color-danger" />
                        </g>
                        <Interval onHover={setHover} timeIntervalWidth={15} />
                    </svg>
                </GraphDataProvider>
            </div>
        </div>
    );
};

const mapDataPoints = (datapoints: StateOfChargeDataPoint[]): DataEntry[] =>
    datapoints.map(dataPoint => ({
        x: dataPoint.date,
        leftUpY: dataPoint.stateOfCharge,
    }));

export default injectIntl(ChargeGraph);
