import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { UiStatePart } from '../../setup/types';
import { DateRange } from '../../types';
import { steps } from './utils';

export const getDateRange = (state: UiStatePart) => {
    return _.get(state, 'ui.dateRange');
};

export const getActiveDateRange = (state: UiStatePart) => {
    if (isFilteringOnSegment(state)) {
        return getFilteredSegment(state) as DateRange;
    }
    return getDateRange(state);
};

export const getFilteredSegment = (state: UiStatePart) => {
    return _.get(state, 'ui.filteredSegment');
};

export const isFilteringOnSegment = (state: UiStatePart) => {
    return !!getFilteredSegment(state);
};

export const getActiveSegmentIdentifier = createSelector(
    [getDateRange, getFilteredSegment],
    (dateRange, filteredSegment) => {
        if (!filteredSegment) {
            return undefined;
        }
        const step = steps(dateRange);
        return moment(filteredSegment.start)
            .startOf(step)
            .toDate();
    }
);
