import { getFeatureToggles } from '@common/permissions/selectors';
import { requestPerformanceData } from '@data/actions';
import { Loadable, LoadableType } from '@data/loadable';
import { entitiesForRequest } from '@data/selectors';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import _ from 'lodash';
import always from 'lodash/fp/always';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataStatePart, PermissionsStatePart, State } from 'src/setup/types';
import { DateRange, HydratedEntity, Id, IntervalTypes, PerformSegmentBy } from 'src/types';

import { createId, PerformQueryPayload } from '../api';

const DEFAULT_RETURN_VALUE = Loadable.createNotRequested();
const WAITING_RETURN_VALUE = Loadable.createWaiting();

const isQueryFinished = (loadable: LoadableType<HydratedEntity[]>) =>
    Loadable.cata(loadable, always(true), always(true), always(false), always(false));

const stateSelector = (state: State) => state;

export type Variables = {
    vehicleIds: Id[];
    vehicleId: string;
    driverIds: Id[];
    dateRange: DateRange;
    groupBy: string[];
    start: string | Date;
    end: string | Date;
    segmentBy: PerformSegmentBy;
    intervalType: IntervalTypes;
    [key: string]: unknown;
};

export type Query = (variables: Variables) => { [key: string]: unknown } | PerformQueryPayload;

export type Selector = (state: DataStatePart & PermissionsStatePart, key: string) => LoadableType<HydratedEntity[]>;

export const noopAction = () => ({ type: '_noop' });

const useQuery = (
    query: Query,
    {
        variables = {} as Variables,
        selector = entitiesForRequest,
        endPoint = requestPerformanceData,
        debounced = true,
        shouldMakeRequest = true,
    }: {
        variables: Variables;
        selector?: Selector;
        endPoint: typeof noopAction | ActionCreatorWithPayload<any, string>;
        debounced?: boolean;
        shouldMakeRequest?: boolean;
    }
) => {
    const state = useSelector(stateSelector);
    // In order to speed up integration tests there is a flag to disable debouncing.
    const { disableDebounce } = useSelector(getFeatureToggles);
    const dispatch = useDispatch();
    const [isProcessingTriggered, setIsProcessingTriggered] = useState(false);
    const [requestInProcess, setRequestInProcess] = useState<{
        compiledQueryId: string;
        compiledQuery: unknown;
        endPoint: (compiledQuery: unknown) => void;
    } | null>(null);
    const compiledQuery = query(variables);
    const compiledQueryId = createId(compiledQuery);
    const requestInProcessId = requestInProcess ? requestInProcess.compiledQueryId : '';

    const hasFinishedRequest = requestInProcess ? isQueryFinished(selector(state, requestInProcessId)) : false;

    const debouncedSetRequestInProcess = useRef(
        _.debounce(nextRequest => setRequestInProcess(nextRequest), 1000, {
            leading: true, // we also trigger the initial request. this might put a tiny bit more stress on the API
            trailing: true,
        })
    ).current;
    const result = selector(state, compiledQueryId);

    const currentRequestStatus = result || DEFAULT_RETURN_VALUE;
    const isCurrentRequestNotRequested = Loadable.isNotRequested(currentRequestStatus);

    function compiledQueryChanged() {
        if (isCurrentRequestNotRequested && shouldMakeRequest) {
            if (debounced && !disableDebounce) {
                if (!requestInProcess) {
                    setIsProcessingTriggered(true);
                    debouncedSetRequestInProcess({ compiledQuery, compiledQueryId, selector, endPoint });
                }
            } else {
                dispatch(endPoint(compiledQuery));
            }
        }
    }
    function requestInProcessChanged() {
        if (requestInProcess) {
            dispatch(requestInProcess.endPoint(requestInProcess.compiledQuery));
        }
    }
    function hasFinishedRequestChanged() {
        if (hasFinishedRequest) {
            if (compiledQueryId !== requestInProcessId && isCurrentRequestNotRequested) {
                debouncedSetRequestInProcess({ compiledQuery, compiledQueryId, selector, endPoint });
            } else {
                setIsProcessingTriggered(false);
                setRequestInProcess(null);
            }
        }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(compiledQueryChanged, [compiledQueryId]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(requestInProcessChanged, [requestInProcessId]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(hasFinishedRequestChanged, [hasFinishedRequest]);

    if (!_.isFunction(query)) {
        // eslint-disable-next-line no-console
        console.warn('[useQuery]: Expects that query is a function');
    }
    if (result && !Loadable.isLoadable(result)) {
        // eslint-disable-next-line no-console
        console.warn('[useQuery]: Expects that the returned value is of the type: Loadable');
    }

    if (isCurrentRequestNotRequested && isProcessingTriggered) {
        return WAITING_RETURN_VALUE;
    }

    return currentRequestStatus;
};

export default useQuery;
