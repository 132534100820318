import StatusBar from '@rio-cloud/rio-uikit/StatusBar';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const DriveProgramDetails = ({
    name,
    distance,
    percentage,
    time,
    hasManualMode = false,
    manualModeDistance,
    manualModeTime,
    progressBarColor = 'progress-bar-gray',
}: {
    name: string;
    distance: ReactNode;
    percentage: number;
    time: ReactNode;
    hasManualMode?: boolean;
    manualModeDistance?: ReactNode;
    manualModeTime?: ReactNode;
    progressBarColor?: string;
}) => {
    return (
        <>
            <div className="display-flex justify-content-between align-items-end margin-bottom-10">
                <div>
                    <p className="margin-0 text-medium">
                        <FormattedMessage id={name} />
                    </p>
                    <div className="display-flex gap-10">
                        <span>{distance}</span>
                        <span>{time}</span>
                    </div>
                </div>
                {hasManualMode ? (
                    <div className="display-flex flex-column align-items-end">
                        <p className="margin-0 text-medium">
                            <FormattedMessage id="drivingPrograms.manualMode" />
                        </p>
                        <div className="display-flex gap-10">
                            <span>{manualModeDistance}</span>
                            <span>{manualModeTime}</span>
                        </div>
                    </div>
                ) : (
                    <div>
                        <span className="margin-0 text-medium text-size-18">{percentage}%</span>
                    </div>
                )}
            </div>
            <StatusBar
                progress={[
                    {
                        percentage,
                        color: progressBarColor,
                    },
                ]}
            />
        </>
    );
};

export default DriveProgramDetails;
