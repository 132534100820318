import { isRight, map } from 'fp-ts/Either';
import { pipe } from 'fp-ts/pipeable';
import { array, intersection, nullType, number, partial, string, type, TypeOf, union } from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import moment from 'moment';

import { VehspecEntity } from '../models/types';

const RawSpec = intersection([
    type({
        asset_id: string,
    }),
    partial({
        created_at: union([string, number, nullType]),
        vin: union([string, number, nullType]),
        vehicle_type: union([string, nullType]),
        manufacturer: union([string, nullType]),
        cabin_type: union([string, number, nullType]),
        motor_type: union([string, number, nullType]),
        output: union([string, number, nullType]),
        emission_class: union([string, number, nullType]),
        gearing_type: union([string, number, nullType]),
        axis_ratio: union([string, number, nullType]),
        wheel_base: union([string, number, nullType]),
        fuel_tanks: array(union([string, number, nullType])),
        urea_tank: union([string, number, nullType]),
        body: union([string, number, nullType]),
    }),
]);
const VehspecResponse = type({
    id: string,
    request: type({
        asset_ids: array(string),
        from: string,
        to: string,
    }),
    vehicle_specifications: array(RawSpec),
});

export type VehspecResponseApiType = TypeOf<typeof VehspecResponse>;

export function decodeVehspecResponse(rawResponse: VehspecResponseApiType): VehspecEntity[] {
    const either = pipe(
        VehspecResponse.decode(rawResponse),
        map(decoded => {
            return decoded.vehicle_specifications.map(specification => {
                return {
                    start: moment(decoded.request.from, moment.ISO_8601).toDate(),
                    end: moment(decoded.request.to, moment.ISO_8601).toDate(),
                    manufacturer: specification.manufacturer || null,
                    vehicleId: specification.asset_id,
                    cabin: specification.cabin_type,
                    vehicleType: specification.vehicle_type,
                    motor: specification.motor_type,
                    output: specification.output,
                    emission: specification.emission_class,
                    gearing: specification.gearing_type,
                    axisRatio: specification.axis_ratio,
                    wheelBase: specification.wheel_base,
                    fuelTanks: specification.fuel_tanks || [],
                    ureaTank: specification.urea_tank,
                    body: specification.body,
                };
            });
        })
    );

    if (isRight(either)) {
        return either.right;
    }
    throw Error(`Decoding Vehspec Response failed, ${PathReporter.report(either)}`);
}
