import { Driver, Report, ReportTimeframe, ReportType, Vehicle, VehicleGroup } from '@api/index';
import { getAvailableLocales } from '@common/lang/selectors';
import Button from '@rio-cloud/rio-uikit/Button';
import { noop, uniq } from 'lodash';
import { useEffect } from 'react';
import { FieldPathValue, UseFormReturn, useWatch, Validate } from 'react-hook-form';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { REPORT_FORM } from '../CreateReportDialog';
import DownloadButton from '../DownloadButton';
import { ReportForm } from '../types';
import Checkbox from './components/Checkbox';
import FormGroup from './components/FormGroup';
import Input, { InputStatus } from './components/Input';
import Select from './components/Select';
import TreeSelect from './components/TreeSelect';

const MAXIMUM_NUMBER_OF_ITEMS = 2000;

type Props = {
    formApi: UseFormReturn<ReportForm>;
    vehicles: Vehicle[];
    drivers: Driver[];
    groups: VehicleGroup[];
    generatedReports?: Report[];
    isEditing?: boolean;
    isEditingSelection?: boolean;
    setIsEditingSelection?: (setting: boolean) => void;
};

const Form = ({
    formApi,
    vehicles,
    drivers,
    groups,
    generatedReports,
    isEditing = false,
    isEditingSelection = true,
    setIsEditingSelection = noop,
}: Props) => {
    const intl = useIntl();
    const {
        register,
        control,
        setValue,
        formState: { errors },
    } = formApi;
    const availableLocales: string[] = uniq(Object.values(useSelector(getAvailableLocales)));

    const reportType = useWatch({ control, name: 'reportType' });
    const name = useWatch({ control, name: 'name' });
    const entities = useWatch({ control, name: 'entities' });

    const isDriverSelection = [ReportType.driver_details, ReportType.driver_top_bottom].includes(reportType);

    // Resets targets when changing reportType
    useEffect(() => {
        if (!isEditing) setValue('entities', { items: [], groups: [] });
    }, [reportType, setValue, isEditing]);

    const numberOfSelectedItems = (selection: { items: string[]; groups: string[] } | undefined) => {
        if (!selection) return 0;
        const groupedItems = isDriverSelection
            ? selection.groups.flatMap((group: string) =>
                  drivers?.filter(d => d.groupIds.includes(group)).map(d => d.driverId)
              )
            : selection.groups.flatMap((group: string) =>
                  vehicles?.filter(d => d.groupIds.includes(group)).map(d => d.id)
              );

        return [...new Set([...selection.items, ...groupedItems])].length;
    };

    function validateSelection(selection: { items: []; groups: [] }): boolean {
        const numSelectedItems = numberOfSelectedItems(selection);
        return numSelectedItems > 0 && numSelectedItems <= MAXIMUM_NUMBER_OF_ITEMS;
    }

    return (
        <>
            <fieldset>
                <FormGroup label={<FormattedMessage id="reports.name" />} htmlFor="name">
                    <Input
                        type="text"
                        register={register('name', { required: true, minLength: 5, maxLength: 200 })}
                        status={errors?.name ? InputStatus.error : InputStatus.success}
                        errorMessage={<FormattedMessage id="reports.name.error" />}
                        data-test="create-form-name"
                    />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.type" />} htmlFor="report-type">
                    <Select
                        name="reportType"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        options={[
                            {
                                id: ReportType.driver_top_bottom,
                                label: intl.formatMessage({ id: 'reports.reportTypes.DRIVER_TOP_BOTTOM' }),
                            },
                            {
                                id: ReportType.driver_details,
                                label: intl.formatMessage({ id: 'reports.reportTypes.DRIVER_DETAILS' }),
                            },
                            {
                                id: ReportType.fleet,
                                label: intl.formatMessage({ id: 'reports.reportTypes.FLEET' }),
                            },
                        ]}
                        disabled={isEditing}
                    />
                </FormGroup>

                <FormGroup label={<FormattedMessage id="reports.interval" />} htmlFor="interval">
                    <Select
                        name="timeframe"
                        options={[
                            {
                                id: ReportTimeframe.monthly,
                                label: intl.formatMessage({ id: 'reports.intervalTypes.MONTHLY' }),
                            },
                            {
                                id: ReportTimeframe.weekly,
                                label: intl.formatMessage({ id: 'reports.intervalTypes.WEEKLY' }),
                            },
                        ]}
                        control={control}
                    />
                </FormGroup>

                {isEditing && (
                    <>
                        <FormGroup label={<FormattedMessage id="reports.language" />} htmlFor="languageCode">
                            <Select
                                name="languageCode"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                options={availableLocales.map(locale => ({
                                    label: intl.formatMessage({ id: `reports.locale.${locale}` }),
                                    id: locale,
                                }))}
                            />
                        </FormGroup>
                    </>
                )}

                <FormGroup label={<FormattedMessage id="reports.informViaEmail" />} htmlFor="informViaEmail">
                    <Checkbox name="informViaEmail" control={control} message="reports.informViaEmail.description" />
                </FormGroup>

                {isDriverSelection && (
                    <FormGroup label={<FormattedMessage id="drivers" />} htmlFor="drivers">
                        <TreeSelect
                            name="entities"
                            status={'entities' in errors ? InputStatus.error : InputStatus.success}
                            control={control}
                            rules={{
                                required: true,
                                validate: validateSelection as Validate<FieldPathValue<{ items: []; groups: [] }, any>>,
                            }}
                            groups={groups}
                            items={drivers}
                            searchPlaceholder={intl.formatMessage({
                                id: 'intl-msg:asset-tree.search.placeholder.driver',
                            })}
                            disabled={!isEditingSelection}
                            numberOfSelectedItems={numberOfSelectedItems(entities)}
                            maxNumberOfSelectedItems={MAXIMUM_NUMBER_OF_ITEMS}
                        />
                    </FormGroup>
                )}

                {!isDriverSelection && (
                    <FormGroup label={<FormattedMessage id="vehicles" />} htmlFor="vehicles">
                        <TreeSelect
                            name="entities"
                            status={'entities' in errors ? InputStatus.error : InputStatus.success}
                            control={control}
                            rules={{
                                required: true,
                                validate: validateSelection as Validate<FieldPathValue<{ items: []; groups: [] }, any>>,
                            }}
                            groups={groups}
                            items={vehicles}
                            searchPlaceholder={intl.formatMessage({
                                id: 'intl-msg:asset-tree.search.placeholder.vehicles',
                            })}
                            disabled={!isEditingSelection}
                            numberOfSelectedItems={numberOfSelectedItems(entities)}
                            maxNumberOfSelectedItems={MAXIMUM_NUMBER_OF_ITEMS}
                        />
                    </FormGroup>
                )}
                {isEditing && (
                    <div className="padding-bottom-10">
                        <Button
                            block
                            asToggle
                            active={isEditingSelection}
                            onClick={() => setIsEditingSelection(!isEditingSelection)}
                            data-test="edit-selection"
                        >
                            <span className={'rioglyph rioglyph-pencil'} />
                            <FormattedMessage id="reports.editSelection" />
                        </Button>
                    </div>
                )}
            </fieldset>
            <input type="submit" data-test="submit" id={REPORT_FORM} hidden />
            {isEditing && generatedReports && generatedReports.length > 0 && (
                <table className="table" data-test="generated-reports-table">
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="pdf.generatedOn" />
                            </th>
                            <th>
                                <FormattedMessage id="download" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {generatedReports.map(
                            (report, idx) =>
                                report.generatedAt !== null && (
                                    <tr key={idx}>
                                        <td>
                                            <FormattedDate value={report.generatedAt} />
                                        </td>
                                        <td>
                                            <DownloadButton name={name} generatedReport={report} />
                                        </td>
                                    </tr>
                                )
                        )}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default Form;
