import { formatOneDigitNumber, plainAverage, withUnit } from '@utils/formatters';
import { ReactNode } from 'react';
import { compose } from 'redux';

import { EVENTS, NO_UNIT } from '../constants/units';
import createColumn, { createRatioColumn } from './createColumn';

export const countSignal = {
    unit: EVENTS,
    displayedUnit: EVENTS,
    format: compose<ReactNode>(withUnit(EVENTS), formatOneDigitNumber),
    average: plainAverage,
};

export const ratioFormatter = (values: number[]) => {
    if ((!values[0] && !values[1]) || !values[1]) {
        return '-';
    } else if (!values[0] && values[1]) {
        return `-/${values[1]}`;
    } else if (values[0] && values[1]) {
        return `${values[0]}/${values[1]}`;
    }
    return '-';
};

export const harshAccelerationMediumCount = createColumn({
    key: 'harshAccelerationMediumCount',
    dataField: 'harshAccelerationMediumCount',
    labelId: 'harshAccelerationMediumCount',
    formatter: countSignal.format,
    unit: countSignal.unit,
});

export const harshAccelerationHardCount = createColumn({
    key: 'harshAccelerationHardCount',
    dataField: 'harshAccelerationHardCount',
    labelId: 'harshAccelerationHardCount',
    formatter: countSignal.format,
    unit: countSignal.unit,
});

export const harshBrakingMediumCount = createColumn({
    key: 'harshBrakingMediumCount',
    dataField: 'harshBrakingMediumCount',
    labelId: 'harshBrakingMediumCount',
    formatter: countSignal.format,
    unit: countSignal.unit,
});

export const harshBrakingHardCount = createColumn({
    key: 'harshBrakingHardCount',
    dataField: 'harshBrakingHardCount',
    labelId: 'harshBrakingHardCount',
    formatter: countSignal.format,
    unit: countSignal.unit,
});

export const excessiveIdlingCountRatio = createRatioColumn({
    key: 'excessiveIdlingCount',
    leftDataField: 'excessiveIdlingCount',
    rightDataField: 'totalIdlingCount',
    labelId: 'excessiveIdlingCount',
    formatter: ratioFormatter,
    unit: NO_UNIT,
});
