import ConditionalTooltip from '@components/ConditionalTooltip';
import { CalculationParams, CalculationParamsPreset } from '@features/settings/reducer';
import NumberInput from '@rio-cloud/rio-uikit/NumberInput';
import Select from '@rio-cloud/rio-uikit/Select';
import Switch from '@rio-cloud/rio-uikit/Switch';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import cn from 'classnames';
import { CSSProperties, Fragment, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const CALCULATION_PARAMS = {
    coastingRating: { icon: 'coasting', translation: 'coasting' },
    accelerationPedalRating: { icon: 'kickdown', translation: 'accelerationPedal' },
    brakingRating: { icon: 'brake', translation: 'braking' },
    cruiseControlRating: { icon: 'cruise-control', translation: 'cruiseControl' },
    overSpeedRating: { icon: 'speed', translation: 'overspeed' },
    harshAccelerationRating: { icon: 'exclamation-sign', translation: 'harshAcceleration' },
    harshBrakingRating: { icon: 'dangerousgoods', translation: 'harshBraking' },
    excessiveIdlingRating: { icon: 'hour-glass', translation: 'excessiveIdling' },
};

const DEFAULT_PRESETS = [
    { id: 'none', label: 'administration.rating.parameterAdaptation.preset.none' },
    { id: 'mixed-fleet', label: 'administration.rating.parameterAdaptation.preset.mixedFleet' },
];

const shouldHideCalculationParam = (key: string, shouldShowHarshKPIs: boolean, shouldShowExcessiveKPIS: boolean) => {
    return (
        (!shouldShowHarshKPIs && ['harshAccelerationRating', 'harshBrakingRating'].includes(key)) ||
        (!shouldShowExcessiveKPIS && key === 'excessiveIdlingRating')
    );
};

const AvailablePercentageTooltipContent = () => (
    <FormattedMessage
        id="administration.rating.parameterAdaptation.available.tooltip"
        values={{
            // eslint-disable-next-line react/display-name
            b: (chunks: unknown[]) => <strong>{chunks}</strong>,
        }}
    />
);

const CalculationParamsDialogBody = ({
    calculationParams,
    calculationParamsPreset,
    overspeedLimit,
    showCalculationParamsPreset,
    setCalculationParams,
    setCalculationParamsPreset,
    setOverspeedLimit,
    shouldShowHarshKPIs = false,
    shouldShowExcessiveKPIS = false,
}: {
    calculationParams: CalculationParams;
    calculationParamsPreset: CalculationParamsPreset;
    overspeedLimit: number;
    showCalculationParamsPreset: boolean;
    setCalculationParams: (newCalculationParams: CalculationParams) => void;
    setCalculationParamsPreset: (updatedPreset: CalculationParamsPreset) => void;
    setOverspeedLimit: (updatedLimit: number) => void;
    shouldShowHarshKPIs?: boolean;
    shouldShowExcessiveKPIS?: boolean;
}) => {
    const intl = useIntl();
    const options = DEFAULT_PRESETS.map(preset => ({
        id: preset.id as CalculationParamsPreset,
        selected: preset.id === calculationParamsPreset,
        label: intl.formatMessage({ id: preset.label }),
    }));

    const handlePresetChange = (updatedPreset: { id: CalculationParamsPreset; label: string } | undefined) => {
        if (!updatedPreset) return;

        setCalculationParamsPreset(updatedPreset.id);

        if (updatedPreset.id === CalculationParamsPreset['mixed-fleet']) {
            setCalculationParams({
                coastingRating: { enabled: true, weight: 33 },
                accelerationPedalRating: { enabled: false, weight: 0 },
                brakingRating: { enabled: false, weight: 0 },
                cruiseControlRating: { enabled: true, weight: 33 },
                overSpeedRating: { enabled: true, weight: 34 },
                harshAccelerationRating: { enabled: true, weight: 0 },
                harshBrakingRating: { enabled: true, weight: 0 },
                excessiveIdlingRating: { enabled: true, weight: 0 },
            });
        }
    };

    const handleCalcParamWeightChange = (calcParamName: keyof CalculationParams, newWeight?: number) => {
        const { weight: currentWeight, ...currentCalculationParam } = calculationParams[calcParamName];

        if (newWeight === undefined || currentWeight === newWeight) return;

        setCalculationParams({
            ...calculationParams,
            [calcParamName]: { ...currentCalculationParam, weight: newWeight },
        });
    };

    const handleCalcParamSwitchChange = (calcParamName: keyof CalculationParams, enabled: boolean) => {
        const { weight: currentWeight, enabled: currentEnabled } = calculationParams[calcParamName];

        if (currentEnabled === enabled) return;

        setCalculationParams({
            ...calculationParams,
            [calcParamName]: {
                weight: enabled ? currentWeight : 0,
                enabled,
            },
        });

        if (
            calculationParamsPreset === CalculationParamsPreset['mixed-fleet'] &&
            enabled &&
            (calcParamName === 'accelerationPedalRating' || calcParamName === 'brakingRating')
        ) {
            setCalculationParamsPreset(CalculationParamsPreset.none);
        }
    };

    const handleOverspeedLimitChange = (updatedLimit?: number) => {
        if (updatedLimit) {
            setOverspeedLimit(updatedLimit + 1);
        }
    };

    const availablePercentage = useMemo(() => {
        const total = Object.keys(calculationParams).reduce(
            (acc, calcParamName) => acc + calculationParams[calcParamName as keyof CalculationParams].weight,
            0
        );
        return 100 - total;
    }, [calculationParams]);

    return (
        <>
            {showCalculationParamsPreset && (
                <div
                    className="display-flex align-items-center gap-10 margin-bottom-20"
                    data-test="calculationParamsPreset"
                >
                    <span className="text-color-dark">
                        <FormattedMessage id="administration.rating.parameterAdaptation.preset.select" />
                    </span>
                    <Select onChange={handlePresetChange} options={options} className="width-25pct" />
                </div>
            )}
            <table className="table table-bordered margin-bottom-10">
                <colgroup>
                    <col className="width-25pct" />
                    <col className="width-35pct table-action" />
                    <col className="width-20pct table-action" />
                    <col className="width-15pct table-action" />
                </colgroup>
                <tbody>
                    {Object.keys(CALCULATION_PARAMS).map(key => {
                        if (shouldHideCalculationParam(key, shouldShowHarshKPIs, shouldShowExcessiveKPIS)) {
                            return <Fragment key={`${key}-hidden`}></Fragment>;
                        }

                        const calcParamName = key as keyof CalculationParams;
                        const { icon, translation } = CALCULATION_PARAMS[calcParamName];
                        const { enabled, weight } = calculationParams[calcParamName];
                        return (
                            <tr key={calcParamName}>
                                <td>
                                    <div className="display-flex align-items-center">
                                        <span className={`rioglyph rioglyph-${icon} text-size-20 margin-right-10`} />
                                        <FormattedMessage id={`discipline.${translation}`} />
                                    </div>
                                </td>
                                <td className="table-action">
                                    {key === 'overSpeedRating' && (
                                        <div
                                            className="display-flex align-items-center margin-left-5 margin-right-10"
                                            data-test="overspeedLimit"
                                        >
                                            <span
                                                className="text-color-dark margin-right-5"
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                <FormattedMessage id="speedLimit" />
                                            </span>
                                            <div className="margin-0 width-150">
                                                <NumberInput
                                                    className="text-right margin-right-5"
                                                    min={1}
                                                    max={189}
                                                    value={overspeedLimit - 1}
                                                    disabled={!enabled}
                                                    onChange={handleOverspeedLimitChange}
                                                />
                                            </div>
                                            <span className="text-color-dark margin-left-5">
                                                <FormattedMessage id="unit.kmph" />
                                            </span>
                                        </div>
                                    )}
                                </td>
                                <td className="table-action">
                                    <div className="display-flex align-items-center margin-right-10 justify-content-end">
                                        <span
                                            className="margin-right-10 text-medium"
                                            data-test={`${translation}Switch`}
                                            style={{ userSelect: 'none' } as CSSProperties}
                                        >
                                            {enabled ? (
                                                <FormattedMessage id="setting.activated" />
                                            ) : (
                                                <FormattedMessage id="setting.deactivated" />
                                            )}
                                        </span>
                                        <Switch
                                            keyName={`${translation}Switch`}
                                            onChange={(enabled: boolean) =>
                                                handleCalcParamSwitchChange(calcParamName, enabled)
                                            }
                                            checked={enabled}
                                        />
                                    </div>
                                </td>
                                <td className="table-action">
                                    <div
                                        className="display-flex align-items-center margin-left-10 margin-right-15"
                                        data-test={`${translation}Input`}
                                    >
                                        <div className="margin-0 width-80">
                                            <NumberInput
                                                className="text-right margin-right-5"
                                                min={0}
                                                max={100}
                                                value={weight}
                                                disabled={!enabled}
                                                onChange={(newWeight?: number) =>
                                                    handleCalcParamWeightChange(calcParamName, newWeight)
                                                }
                                            />
                                        </div>
                                        <span className="text-color-dark margin-left-5">%</span>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="display-flex justify-content-end align-items-center" data-test="parameterAdaptionAvailable">
                {availablePercentage !== 0 && (
                    <Tooltip id="available-percentage-sticky-tooltip" width="auto" className="margin-right-0">
                        <AvailablePercentageTooltipContent />
                    </Tooltip>
                )}
                <ConditionalTooltip
                    enabled={availablePercentage === 0}
                    getTooltipChildren={() => <AvailablePercentageTooltipContent />}
                    placement="left"
                    width="auto"
                >
                    <span
                        className="padding-left-10 margin-right-10 text-color-dark line-height"
                        style={{ lineHeight: '30px' }}
                    >
                        <FormattedMessage id="administration.rating.parameterAdaptation.available" />:{' '}
                        <span className={cn('text-color-' + (availablePercentage < 0 ? 'danger' : 'dark'))}>
                            {availablePercentage}%
                        </span>
                    </span>
                </ConditionalTooltip>
            </div>
        </>
    );
};

export default CalculationParamsDialogBody;
